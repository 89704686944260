import * as React from 'react';
import { getLocalTimeZone, today } from '@internationalized/date';
import {
  Button as AriaButton,
  Calendar as AriaCalendar,
  CalendarCell as AriaCalendarCell,
  CalendarCellProps as AriaCalendarCellProps,
  CalendarGrid as AriaCalendarGrid,
  CalendarGridBody as AriaCalendarGridBody,
  CalendarGridBodyProps as AriaCalendarGridBodyProps,
  CalendarGridHeader as AriaCalendarGridHeader,
  CalendarGridHeaderProps as AriaCalendarGridHeaderProps,
  CalendarGridProps as AriaCalendarGridProps,
  CalendarHeaderCell as AriaCalendarHeaderCell,
  CalendarHeaderCellProps as AriaCalendarHeaderCellProps,
  Heading as AriaHeading,
  RangeCalendar as AriaRangeCalendar,
  RangeCalendarStateContext as AriaRangeCalendarStateContext,
  composeRenderProps,
} from 'react-aria-components';
import ChevronLeft from 'src/assets/svgicons/line/chevron-left.svg';
import ChevronRight from 'src/assets/svgicons/line/chevron-right.svg';
import { cn } from 'src/lib/cn';
import { Icon } from '../Icon';

const Calendar = AriaCalendar;

const RangeCalendar = AriaRangeCalendar;

const CalendarHeading = (props: React.HTMLAttributes<HTMLElement>) => {
  return (
    <header className="flex items-center justify-between gap-1 pb-3" {...props}>
      <AriaButton
        slot="previous"
        className="rounded-lg p-2 hover:bg-purple-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-500"
      >
        <Icon className="size-5 text-purple-400">
          <ChevronLeft />
        </Icon>
      </AriaButton>
      <AriaHeading className="grow text-center text-base font-semibold text-primary" />
      <AriaButton
        slot="next"
        className="rounded-lg p-2 hover:bg-purple-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-500"
      >
        <Icon className="size-5 text-purple-400">
          <ChevronRight />
        </Icon>
      </AriaButton>
    </header>
  );
};

const CalendarGrid = ({ className, ...props }: AriaCalendarGridProps) => (
  <AriaCalendarGrid
    className={cn(
      'border-separate border-spacing-x-0 border-spacing-y-1',
      className
    )}
    {...props}
  />
);

const CalendarGridHeader = ({ ...props }: AriaCalendarGridHeaderProps) => (
  <AriaCalendarGridHeader {...props} />
);

const CalendarHeaderCell = ({
  className,
  ...props
}: AriaCalendarHeaderCellProps) => (
  <AriaCalendarHeaderCell
    className={cn(
      'size-10 px-2 py-2.5 text-sm font-medium text-primary',
      className
    )}
    {...props}
  />
);

const CalendarGridBody = ({
  className,
  ...props
}: AriaCalendarGridBodyProps) => (
  <AriaCalendarGridBody className={cn('[&>tr>td]:p-0', className)} {...props} />
);

const CalendarCell = ({ className, ...props }: AriaCalendarCellProps) => {
  const isRange = Boolean(React.useContext(AriaRangeCalendarStateContext));

  return (
    <AriaCalendarCell
      className={composeRenderProps(className, (className, renderProps) => {
        return cn(
          'size-10 rounded-lg px-2 py-2.5 text-center text-sm font-normal text-primary data-[hovered]:bg-purple-100',
          'focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-500',
          /* Disabled */
          renderProps.isDisabled && 'pointer-events-none opacity-50',
          /* Selected */
          renderProps.isSelected &&
            'bg-primary font-medium text-white focus-visible:ring-2 first-letter:focus-visible:ring-purple-500',
          /* Hover */
          renderProps.isHovered &&
            renderProps.isSelected &&
            (renderProps.isSelectionStart ||
              renderProps.isSelectionEnd ||
              !isRange) &&
            'data-[hovered]:text-primary-foreground data-[hovered]:bg-primary focus-visible:ring-2 focus-visible:ring-purple-500',
          /* Selection Range Cells */
          renderProps.isSelected &&
            isRange &&
            !renderProps.isSelectionStart &&
            !renderProps.isSelectionEnd &&
            'rounded-none bg-purple-50 font-medium text-primary data-[hovered]:bg-purple-100 focus-visible:bg-purple-100',
          /* Outside Month */
          renderProps.isOutsideMonth && 'text-purple-400',
          /* Current Date */
          renderProps.date.compare(today(getLocalTimeZone())) === 0 &&
            !renderProps.isSelected &&
            'underline underline-offset-8',
          /* Unavailable Date */
          renderProps.isUnavailable &&
            'cursor-default text-red-500 data-[hovered]:bg-inherit',
          renderProps.isInvalid &&
            'bg-red-500 text-white data-[focused]:bg-red-500 data-[hovered]:bg-red-500 data-[focused]:text-white data-[hovered]:text-white',
          className
        );
      })}
      {...props}
    />
  );
};

export {
  Calendar,
  CalendarCell,
  CalendarGrid,
  CalendarGridBody,
  CalendarGridHeader,
  CalendarHeaderCell,
  CalendarHeading,
  RangeCalendar,
};
