export const BRIEF_STATUS = [
  'Draft',
  'Review',
  'Approved',
  'Shared',
  'Live',
  'Complete',
] as const;

export const BRIEF_STAGE = [
  'Concepts',
  'Editing',
  'Scripts',
  'Design',
  'Shoot',
];
