import type { PermissionRole } from '@magicbrief/prisma/generated/client2';

/**
 * ensures array order and allows flexibility for future roles and aliases of roles
 **/
export const weightedPermissionRoles: { [weight: number]: PermissionRole[] } = {
  2048: ['wizard'],
  1024: ['superuser'],
  512: ['owner'],
  256: ['admin'],
  128: ['editor'],
  64: ['viewer'],
  0: ['unset'],
};
