import Analytics, { AnalyticsInstance } from 'analytics';
import segmentPlugin from '@analytics/segment';
import { originalSourcePlugin } from '@analytics/original-source-plugin';

/** @deprecated use useNewAnalyticsEvent instead */
export let segment: AnalyticsInstance | undefined = undefined;

export function initAnalytics() {
  const key = import.meta.env.VITE_SEGMENT_FRONTEND_KEY;

  segment = Analytics({
    app: 'magicbrief',
    plugins: [
      originalSourcePlugin(),
      segmentPlugin({
        writeKey: key,
      }),
    ],
  });
  return segment;
}
