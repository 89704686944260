import { useCallback, useState } from 'react';
import { Combobox } from '@headlessui/react';
import { AppRouter } from '@magicbrief/server/src/trpc/router';
import { inferProcedureOutput } from '@trpc/server';
import { toast } from 'react-toastify';
import { Icon } from 'src/components/Icon';
import XClose from 'src/assets/svgicons/line/x-close.svg';
import {
  ComboboxInput,
  ComboboxOption,
  ComboboxOptionLabel,
  ComboboxOptions,
  ComboboxTransition,
} from 'src/components/Select/ComboboxContent';
import { useI18nContext } from 'src/i18n/i18n-react';
import { trpc } from 'src/lib/trpc';
import Tag from 'src/components/LibraryDetailsView/components/LibraryDetailsSidebar/components/Tag/Tag';
import { Alert } from 'src/components/Alert';
import useFeatureFlag from 'src/utils/useFeatureFlag';
import {
  useAddBriefToCollection,
  useAddBriefToNewCollection,
  useRemoveBriefFromCollection,
} from '../../hooks';
import type { BriefCollection } from '@magicbrief/prisma/generated/client2';

type Props = {
  briefID: number;
  briefInCollections: Exclude<
    inferProcedureOutput<AppRouter['briefs']['getBriefByUuid']>,
    null
  >['BriefsInCollections'];
};

const BriefCollectionsList: React.FunctionComponent<Props> = ({
  briefID,
  briefInCollections,
}): JSX.Element => {
  const { LL } = useI18nContext();
  const [searchQuery, setSearchQuery] = useState('');
  const resetSearch = useCallback(() => setSearchQuery(''), []);

  const directoriesFeatureFlag = useFeatureFlag('DIRECTORIES');

  const briefCollections = trpc.briefs.getBriefCollections.useQuery(undefined, {
    enabled: !directoriesFeatureFlag,
  });

  const addBriefToCollection = useAddBriefToCollection({
    onError() {
      toast.error(LL.briefs.errors.addToCollection(), {
        className: 'toast-danger',
      });
    },
  });
  const addBriefToNewCollection = useAddBriefToNewCollection({
    onError() {
      toast.error(LL.briefs.errors.addToCollection(), {
        className: 'toast-danger',
      });
    },
  });
  const removeBriefFromCollection = useRemoveBriefFromCollection({
    onError() {
      toast.error(LL.briefs.errors.removeFromCollection(), {
        className: 'toast-danger',
      });
    },
  });

  const filteredCollections = briefCollections.data?.filter((x) =>
    x.name.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase())
  );

  if (briefCollections.error) {
    return <Alert type="error">{LL.briefs.errors.listCollections()}</Alert>;
  }

  return (
    <>
      <Combobox<BriefCollection | string | null>
        value={null}
        multiple={false}
        onChange={(selected) => {
          if (selected) {
            if (typeof selected === 'string') {
              addBriefToNewCollection.mutate({
                briefID,
                name: selected,
              });
            } else {
              addBriefToCollection.mutate({
                briefID,
                collectionID: selected.id,
              });
            }
          }
        }}
      >
        <ComboboxTransition onHide={resetSearch}>
          <div className="relative">
            <ComboboxInput
              displayValue={() => ''}
              placeholder={LL.ad.boardSearchInput()}
              handleQueryChange={(ev) => {
                setSearchQuery(ev.target.value);
              }}
            />
          </div>
          <div className="absolute w-full">
            <ComboboxOptions>
              {filteredCollections?.map((x) => {
                return (
                  <ComboboxOption key={x.id} value={x}>
                    {({ selected }) => (
                      <ComboboxOptionLabel selected={selected} disabled={false}>
                        {x.name}
                      </ComboboxOptionLabel>
                    )}
                  </ComboboxOption>
                );
              })}
              {searchQuery && (
                <ComboboxOption
                  key="--mb-new-collection-button--"
                  value={searchQuery}
                >
                  <ComboboxOptionLabel selected={false} disabled={false}>
                    {LL.ad.createCollection({ name: searchQuery })}
                  </ComboboxOptionLabel>
                </ComboboxOption>
              )}
            </ComboboxOptions>
          </div>
        </ComboboxTransition>
      </Combobox>
      {!!briefInCollections.length && (
        <div className="flex flex-row flex-wrap gap-3 pt-3">
          {briefInCollections.map((x) => (
            <Tag key={x.collectionID}>
              <div className="flex min-w-0 flex-row items-center gap-3">
                <div className="truncate">{x.BriefCollection.name}</div>
                <button
                  onClick={() =>
                    removeBriefFromCollection.mutate({
                      briefID,
                      collectionID: x.collectionID,
                    })
                  }
                  className="cursor-pointer appearance-none text-primary"
                >
                  <Icon className="h-4 w-4">
                    <XClose />
                  </Icon>
                </button>
              </div>
            </Tag>
          ))}
        </div>
      )}
    </>
  );
};

export default BriefCollectionsList;
