import { Variants } from 'framer-motion';

export const buttonAnimationVariants: Variants = {
  hover: {
    scale: 1.05,
  },
  tap: {
    scale: 0.8,
    transition: {
      duration: 0.15,
      type: 'spring',
    },
  },
};
