import React, { forwardRef } from 'react';
import { ArrayElement } from '@magicbrief/common';
import { Cell, flexRender } from '@tanstack/react-table';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { cn } from 'src/lib/cn';
import { Props } from './InsightsTable';

export const TableCell = forwardRef<
  HTMLTableCellElement,
  {
    cell: Cell<ArrayElement<Props['ads']>, unknown>;
    className?: string;
    style?: React.CSSProperties;
  }
>(({ cell, className, style }, ref) => {
  const isPinned = cell.column.getIsPinned();

  const styles: React.CSSProperties = {
    width: `calc(var(--header-${cell.column?.id}-size) * 1px)`,
    zIndex: isPinned ? 1 : 0,
    left: isPinned === 'left' ? `${cell.column.getStart('left')}px` : undefined,
    ...style,
  };

  return (
    <td
      className={cn(isPinned ? 'sticky' : 'relative', className)}
      style={styles}
      ref={ref}
    >
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </td>
  );
});

TableCell.displayName = 'TableCell';

export const DraggableTableCell: React.FC<{
  cell: Cell<ArrayElement<Props['ads']>, unknown>;
  className?: string;
}> = ({ cell, className }) => {
  const { isDragging, setNodeRef, transform } = useSortable({
    id: cell.column.id,
  });

  const isPinned = cell.column.getIsPinned();

  const style: React.CSSProperties = {
    transition: 'width transform 0.2s ease-in-out',
    transform: CSS.Translate.toString(transform), // translate instead of transform to avoid squishing
    opacity: isDragging ? 0.8 : 1,
    zIndex: isDragging ? 2 : isPinned ? 1 : 0,
    left: isPinned === 'left' ? `${cell.column.getStart('left')}px` : undefined,
  };

  return (
    <TableCell
      style={style}
      ref={setNodeRef}
      className={className}
      cell={cell}
    />
  );
};
