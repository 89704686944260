import { forwardRef } from 'react';
import { Button } from 'react-aria-components';
import { Icon } from 'src/components/Icon';

export const TableCellEmptyValueButton = forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren
>(({ children }, ref) => {
  return (
    <Button
      ref={ref}
      className="bg-white h-8 w-8 rounded-full text-sm leading-5 font-medium border border-[#EFEBFF] flex flex-row items-center justify-center gap-1.5 box-border focus:outline-none border-dashed text-[#4022A4]/40"
    >
      <Icon className="h-3.5 w-3.5 icon-path:stroke-[3]">{children}</Icon>
    </Button>
  );
});

TableCellEmptyValueButton.displayName = 'TableCellEmptyValueButton';

export default TableCellEmptyValueButton;
