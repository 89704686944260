import { forwardRef, memo } from 'react';
import { ArrayElement } from '@magicbrief/common';
import { Table } from '@tanstack/react-table';
import {
  SortableContext,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';
import { TableCell, DraggableTableCell } from './TableCell';
import { Props } from './InsightsTable';

interface TableBodyProps {
  table: Table<ArrayElement<Props['ads']>>;
}

export const TableBody = forwardRef<HTMLTableRowElement, TableBodyProps>(
  ({ table }, ref) => (
    <tbody>
      {table.getRowModel().rows.map((row) => {
        const cells = row.getVisibleCells();
        const first = cells[0];
        const restCells = cells.slice(1);

        return (
          <tr
            ref={ref}
            key={row.id}
            className="group/ad h-14 select-none overflow-hidden"
          >
            <TableCell
              key={first.id}
              cell={first}
              className="h-14 overflow-hidden truncate border-b border-solid border-b-secondary bg-white align-middle text-sm font-normal text-gray-900"
            />
            {restCells.map((cell) => {
              return (
                <SortableContext
                  key={cell.id}
                  items={table.getState().columnOrder}
                  strategy={horizontalListSortingStrategy}
                >
                  <DraggableTableCell
                    key={cell.id}
                    cell={cell}
                    className="h-14 overflow-hidden truncate border-b border-solid border-b-secondary bg-white align-middle text-sm font-normal text-gray-900"
                  />
                </SortableContext>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  )
);
TableBody.displayName = 'TableBody';

export const MemoizedTableBody = memo(
  TableBody,
  (prev, next) => prev.table.options.data === next.table.options.data
) as typeof TableBody;
