import {
  Button,
  Dialog,
  Heading,
  Modal,
  ModalOverlay,
} from 'react-aria-components';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AlertOctagon from 'src/assets/svgicons/duocolor/alert-octagon.svg';
import XClose from 'src/assets/svgicons/line/x-close.svg';
import { Alert } from 'src/components/Alert';
import { AriaButton } from 'src/components/Button/Button';
import { Icon } from 'src/components/Icon';
import { useI18nContext } from 'src/i18n/i18n-react';
import { trpc } from 'src/lib/trpc';
import { useDeleteNodesFromDirectory } from 'src/utils/useDirectories';
import { computeDirectoryPathFromDirectoryToRoot } from '../computeDirectoryPath';

type Props = {
  onOpenChange: (isOpen: boolean) => void;
  entity: {
    directoryNodeUuid: string;
    entityName: string;
  } | null;
};

export const DeleteDirectoryModal: React.FC<Props> = ({
  onOpenChange,
  entity,
  ...props
}): JSX.Element => {
  return (
    <ModalOverlay
      isOpen={!!entity}
      onOpenChange={onOpenChange}
      className="fixed inset-0 z-500 flex min-h-full items-center justify-center overflow-y-auto bg-gray-900/60 p-4 text-center"
    >
      <Modal className="flex max-h-[90%] w-full max-w-md rounded-xl bg-white p-6 text-left align-middle shadow-xl">
        <Dialog className="flex min-h-0 min-w-0 flex-auto outline-none">
          {({ close }) => (
            <>
              {entity && (
                <DeleteDirectoryModalBody
                  close={close}
                  entity={entity}
                  {...props}
                />
              )}
            </>
          )}
        </Dialog>
      </Modal>
    </ModalOverlay>
  );
};

type DeleteDirectoryModalBodyProps = {
  close: () => void;
  entity: Exclude<Props['entity'], null>;
};

const DeleteDirectoryModalBody: React.FC<DeleteDirectoryModalBodyProps> = ({
  close,
  entity,
}) => {
  const { LL } = useI18nContext();
  const navigate = useNavigate();

  const deleteDirectory = useDeleteNodesFromDirectory();
  const directoryStructure =
    trpc.directories.getDirectoryStructureForOrganisation.useQuery();

  const isDirectory =
    !!directoryStructure.data?.directories[entity.directoryNodeUuid];

  const directoryPath = directoryStructure.data
    ? computeDirectoryPathFromDirectoryToRoot(
        entity.directoryNodeUuid,
        directoryStructure.data.directories
      )
    : null;

  // we want to navigate as we delete because there is a race condition where the user
  // is still in the directory that they are deleting
  const navigateOnDelete = () => {
    // if directory is a root directory, see if there are other root directories
    const rootDirectories = directoryStructure.data?.root ?? [];

    if (rootDirectories.includes(entity.directoryNodeUuid)) {
      if (rootDirectories.length > 1) {
        // if we are deleting a root directory, find an alternative root directory
        const alternativeDirectory = rootDirectories.filter(
          (uuid) => uuid !== entity.directoryNodeUuid
        )[0];

        navigate(`/library/d/${alternativeDirectory}`);
      } else {
        navigate('/library/discover');
      }
      // else traverse children to find it and then get the parent node
    } else {
      const directories = Object.values(
        directoryStructure.data?.directories ?? {}
      );
      const matchingDirectory = directories.find(
        (directory) => directory.uuid === entity.directoryNodeUuid
      );
      if (matchingDirectory == null || !matchingDirectory.parent) {
        navigate('/library/discover');
      } else {
        navigate(`/library/d/${matchingDirectory.parent}`);
      }
    }
  };

  const submit = async () => {
    navigateOnDelete();

    deleteDirectory.mutate(
      {
        directoryNodeUuids: [entity.directoryNodeUuid],
      },
      {
        async onSuccess(response) {
          if (response.rejected.length) {
            toast('An error occurred deleting the board', {
              className: 'toast-danger',
            });
            return;
          }
          close();
          toast(isDirectory ? 'Board deleted' : 'Item un-pinned from board', {
            className: 'toast-success',
          });
          if (
            !window.location.pathname.includes(entity.directoryNodeUuid) ||
            !directoryPath
          ) {
            return;
          }
          const pathname = directoryPath[0]
            ? window.location.pathname.replace(
                entity.directoryNodeUuid,
                directoryPath[0]
              )
            : window.location.pathname.replace(
                `/d/${entity.directoryNodeUuid}`,
                ''
              );

          navigate(pathname);
        },
      }
    );
  };

  return (
    <div className="flex min-w-0 flex-auto flex-col gap-4">
      <div className="flex flex-row items-center justify-between gap-4 text-purple-800">
        <Heading className="truncate text-xl font-bold">
          {isDirectory
            ? `Delete ${entity.entityName}`
            : `Un-Pin "${entity.entityName}" from Board`}
        </Heading>
        <Button onPress={close}>
          <Icon>
            <XClose />
          </Icon>
        </Button>
      </div>

      {(deleteDirectory.error || !!deleteDirectory.data?.rejected.length) && (
        <Alert
          icon={
            <Icon>
              <AlertOctagon />
            </Icon>
          }
          className="w-full"
          type="error"
        >
          {deleteDirectory.error && (
            <p className="text-xs font-normal">
              {LL.errors.genericWithDetail({
                detail: deleteDirectory.error.message,
              })}
            </p>
          )}
          {deleteDirectory.data?.rejected && (
            <p className="text-xs font-normal">
              <p>
                The following errors occurred while trying to move the board:
              </p>
              <ul className="list-disc pl-2 pt-2">
                {deleteDirectory.data.rejected.map((x) => (
                  <li key={x.directoryNodeUuid}>{x.reason}</li>
                ))}
              </ul>
            </p>
          )}
        </Alert>
      )}

      {isDirectory && (
        <p className="text-xs font-normal text-purple-800">
          Deleting this board will delete all of it&apos;s sub-boards. Items
          pinned to affected boards are not deleted.
        </p>
      )}

      <AriaButton
        colour="danger"
        onPress={submit}
        loading={deleteDirectory.isLoading}
      >
        {isDirectory ? 'Delete Board' : 'Un-Pin from Board'}
      </AriaButton>
    </div>
  );
};
