import { uuidv4 } from '@firebase/util';

export function updateUserLocalData(data: object, merge = true) {
  const oldData = localStorage.getItem('local');
  const parsedOldData = JSON.parse(oldData || '{}') || {};

  let updated;

  if (merge) updated = { ...parsedOldData, ...data };
  else updated = data;

  localStorage.setItem('local', JSON.stringify(updated));

  return updated;
}

export function getUserLocalData() {
  return JSON.parse(localStorage.getItem('local') || '{}');
}

export function getAnonymousId() {
  const anonymousId = localStorage.getItem('anonymousId');
  if (!anonymousId) {
    const uuid = uuidv4();
    localStorage.setItem('anonymousId', uuid);
    return uuid;
  }
  return anonymousId;
}
