import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { InsightsFacebookSelectableDatePreset } from '@magicbrief/common';
import { trpc } from 'src/lib/trpc';
import { useInsightsSearchParams } from './useInsightsSearchParams';
import { useRefetchData } from './useInsightsQueries';

/**
 * Hook that sets the default date preset based on user's ad account preference
 */
export const useInsightsDefaultDatePreset = () => {
  const [searchParams] = useSearchParams();
  const { accountUuid } = useParams();
  const { params } = useInsightsSearchParams();
  const { refetch } = useRefetchData();

  const timePeriod = searchParams.get('timePeriod');

  const { data: facebookAdAccount } =
    trpc.insights.getFacebookAdAccount.useQuery(
      { uuid: accountUuid || '' },
      { enabled: !!accountUuid }
    );

  useEffect(() => {
    if (
      !timePeriod &&
      facebookAdAccount?.defaultDatePreset != null &&
      facebookAdAccount?.defaultDatePreset !== params.get('timePeriod')
    ) {
      params.set('timePeriod', facebookAdAccount.defaultDatePreset);
      if (accountUuid) {
        refetch({
          adAccountUuid: accountUuid,
          forTimePeriod: {
            datePreset:
              facebookAdAccount.defaultDatePreset as InsightsFacebookSelectableDatePreset,
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountUuid, facebookAdAccount?.defaultDatePreset, timePeriod]);

  return null;
};
