import { useContext } from 'react';
import { GlobalDispatchContext, GlobalStateContext } from './context';

export function useGlobalStateContext() {
  const value = useContext(GlobalStateContext);

  if (!value) {
    throw new Error(
      'useGlobalStateContext cannot be called outside of a GlobalStateContext.Provider'
    );
  }
  return value;
}

export function useGlobalDispatchContext() {
  const value = useContext(GlobalDispatchContext);

  if (!value) {
    throw new Error(
      'useGlobalDispatchContext cannot be called outside of a GlobalDispatchContext.Provider'
    );
  }
  return value;
}
