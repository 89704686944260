import { useCallback } from 'react';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';
import { useI18nContext } from 'src/i18n/i18n-react';

export function useCopyToClipboard() {
  const { LL } = useI18nContext();
  const handleCopy = useCallback(
    (text: string, successMessage?: string) => {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          toast.success(successMessage ?? LL.copiedToClipboard(), {
            className: 'toast-success ',
          });
        })
        .catch((e) => {
          toast(LL.errors.writeClipboardError(), { className: 'toast-danger' });
          Sentry.captureException(e, (scope) => {
            scope.setTransactionName('Copy text to clipboard');
            return scope;
          });
        });
    },
    [LL]
  );

  return handleCopy;
}
