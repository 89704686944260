import { createContext, useContext } from 'react';
import { UserAssetResponse } from '@magicbrief/server/src/storage/storage.trpc';

type AssetDetailContextData = {
  asset: UserAssetResponse;
};

export type AssetDetailContextParams = {
  uuid: string;
  data: AssetDetailContextData | null;
  sidebarAsComments?: boolean;
};

type AssetDetailContextValue = (params: AssetDetailContextParams) => void;

const AssetDetailContext = createContext<AssetDetailContextValue | null>(null);

export function useAssetDetailContext() {
  const context = useContext(AssetDetailContext);

  if (!context) {
    throw new Error(
      'useAssetDetailContext can only be called within a AssetDetailContextProvider'
    );
  }

  return context;
}

export const AssetDetailContextProvider = AssetDetailContext.Provider;

const AssetDetailVideoContext = createContext<HTMLVideoElement | null>(null);
const AssetDetailVideoDispatchContext = createContext<
  ((video: HTMLVideoElement | null) => void) | null
>(null);

export const AssetVideoDetailContextProvider = AssetDetailVideoContext.Provider;
export const AssetVideoDetailDispatchContextProvider =
  AssetDetailVideoDispatchContext.Provider;

export function useAssetDetailVideoContext() {
  const context = useContext(AssetDetailVideoContext);
  // Allow null to be returned here.
  return context;
}

export function useAssetDetailVideoDispatchContext() {
  const context = useContext(AssetDetailVideoDispatchContext);
  // Allow null to be returned here.
  return context;
}
