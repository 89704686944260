import { InsightsAdFacebookAssetsList } from '@magicbrief/server/src/insights/types';
import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import type { InsightsTableColorSchema } from '@magicbrief/common';

type InsightsAdPreview = {
  assets: InsightsAdFacebookAssetsList | null;
  creativeType?: 'carousel' | 'image' | 'video' | 'unknown';
  uuid: string;
  /* Allows us to portal the preview into the Dialog's DOM and avoid accessibility conflicts */
  portalId?: string;
};

/** Insights Ad Preview */
export const insightsAdPreviewAtom = atom<InsightsAdPreview | null>(null);
export const hasInsightsAdPreviewAtom = atom(
  (get) => get(insightsAdPreviewAtom) != null
);
export const insightsAdPreviewPortalIdAtom = atom((get) => {
  const insightsAdPreview = get(insightsAdPreviewAtom);
  return insightsAdPreview?.portalId;
});

/** Insights Tagging */
export const selectedInsightsAdsAtom = atom<Array<string>>([]);

// Read Atoms
export const hasSelectedInsightsAdsAtom = atom<boolean>(
  (get) => get(selectedInsightsAdsAtom).length > 0
);
export const countOfSelectedInsightsAdsAtom = atom<number>(
  (get) => get(selectedInsightsAdsAtom).length
);

// Write Atoms
export const toggleInsightsAdsAtom = atom(null, (get, set, update: string) => {
  const index = get(selectedInsightsAdsAtom).findIndex(
    (uuid) => uuid === update
  );

  if (index === -1) {
    set(selectedInsightsAdsAtom, [...get(selectedInsightsAdsAtom), update]);
  } else {
    set(
      selectedInsightsAdsAtom,
      get(selectedInsightsAdsAtom).filter((uuid) => uuid !== update)
    );
  }
});
export const clearInsightsAdsAtom = atom(null, (get, set) => {
  if (get(selectedInsightsAdsAtom).length > 0) {
    set(selectedInsightsAdsAtom, []);
  }
});

export const tableColorPreferenceAtom =
  atomWithStorage<InsightsTableColorSchema>(
    'tableColorPreference',
    'positive-neutral'
  );
