import { useState } from 'react';
import { ArrayElement, KeysOfType } from '@magicbrief/common';
import { SetURLSearchParams, useSearchParams } from 'react-router-dom';

type TypesafeSearchParamsMap<
  T extends {
    [key: string]: string | string[] | null;
  } = { [key: string]: string[] },
> = {
  [Key in keyof T]: null extends T[Key]
    ? string
    : T[Key] extends never | never[]
    ? string[]
    : T[Key] extends Array<string>
    ? Exclude<T[Key], null>
    : T[Key] extends Array<never>
    ? string[]
    : Exclude<T[Key], null>;
};

const DELIMITER = /(?<!\\),/g;

const ESCAPED_COMMA = '\\,';

export class TypesafeSearchParams<
  T extends { [key: string]: (string | null) | string[] } = {
    [key: string]: string[];
  },
> {
  #setSearchParams;
  #defaultValues;
  #isEmpty = false;

  constructor(
    defaultValues: {
      [Key in keyof T]: T[Key] extends string ? T[Key] | null : T[Key];
    },
    setSearchParams: SetURLSearchParams
  ) {
    this.#defaultValues = defaultValues;
    this.#setSearchParams = setSearchParams;
    this.#isEmpty = !this.hasAny();
  }

  updateSearchParamReferences(setSearchParams: SetURLSearchParams) {
    this.#setSearchParams = setSearchParams;
    this.#isEmpty = !this.hasAny();
  }

  getURLSearchParamsInstance() {
    return new URLSearchParams(window.location.search);
  }

  #setURLSearchParams(params: URLSearchParams) {
    this.#setSearchParams(params);
    this.#isEmpty = !this.hasAny();
  }

  hasAny(): boolean {
    const searchParams = this.getURLSearchParamsInstance();
    for (const key of Object.keys(this.#defaultValues)) {
      if (searchParams.has(key as string)) {
        return true;
      }
    }
    return false;
  }

  /**
   * Get the list of values for a given search parameter.
   */
  get<U extends keyof T>(
    key: U
  ): T[U] extends Array<unknown>
    ? TypesafeSearchParamsMap<T>[U]
    : TypesafeSearchParamsMap<T>[U] | null {
    if (this.#isEmpty) {
      const defaultValue = this.#defaultValues[
        key
      ] as TypesafeSearchParamsMap<T>[U];

      return defaultValue;
    }

    const retreived = this.getURLSearchParamsInstance().get(key as string);

    const splitValue = retreived
      ?.split(DELIMITER)
      ?.map((x) => x.replace(ESCAPED_COMMA, ','));

    const value = (
      Array.isArray(this.#defaultValues[key])
        ? splitValue ?? []
        : splitValue?.[0] ?? null
    ) as T[U] extends Array<unknown>
      ? TypesafeSearchParamsMap<T>[U]
      : TypesafeSearchParamsMap<T>[U] | null;
    return value;
  }

  protected _set<U extends keyof T>(
    params: URLSearchParams,
    key: U,
    value: T[U] extends Array<unknown>
      ? TypesafeSearchParamsMap<T>[U]
      : TypesafeSearchParamsMap<T>[U] | null
  ) {
    if (Array.isArray(value)) {
      if (value.length) {
        params.set(
          key as string,
          value.map((x) => x.replace(',', ESCAPED_COMMA)).join(',')
        );
      } else {
        params.delete(key as string);
      }
    } else if (value) {
      params.set(key as string, (value as string).replace(',', ESCAPED_COMMA));
    } else {
      params.delete(key as string);
    }
  }

  /**
   * Set the values of a given search parameter, overwriting
   * all existing values for that parameter.
   */
  set<U extends keyof T>(
    key: U,
    value: T[U] extends Array<unknown>
      ? TypesafeSearchParamsMap<T>[U]
      : TypesafeSearchParamsMap<T>[U] | null
  ) {
    const searchParams = this.getURLSearchParamsInstance();

    if (this.#isEmpty) {
      this.#isEmpty = false;
      this._setMany(searchParams, this.#defaultValues);
    }
    this._set(searchParams, key, value);
    this.#setURLSearchParams(searchParams);
  }

  protected _setMany(
    params: URLSearchParams,
    values: Partial<{
      [Key in keyof T]: T[Key] extends string ? T[Key] | null : T[Key];
    }>
  ) {
    for (const [key, value] of Object.entries(values)) {
      this._set(params, key, value);
    }
  }

  /**
   * Set the values of the given search parameters, overwriting
   * all existing values for those parameters.
   */
  setMany(
    values: Partial<{
      [Key in keyof T]: T[Key] extends string ? T[Key] | null : T[Key];
    }>
  ) {
    const searchParams = this.getURLSearchParamsInstance();
    this._setMany(searchParams, values);
    this.#setURLSearchParams(searchParams);
  }

  /**
   * Returns a boolean that indicates whether the specified value
   * for the given parameter currently exists in the search
   * parameters.
   */
  has<U extends keyof T>(
    key: U,
    value: T[U] extends Array<infer I> ? I : T[U]
  ): boolean {
    if (this.#isEmpty) {
      const defaultValue = this.#defaultValues[key];
      if (defaultValue === null) {
        return false;
      }
      if (Array.isArray(defaultValue)) {
        return defaultValue.includes(value as string);
      } else {
        return defaultValue === value;
      }
    }
    const retreived = this.get(key);

    if (Array.isArray(retreived)) {
      return retreived.some((x) => x === (value as string));
    }

    return retreived === value;
  }

  protected _toggle<U extends KeysOfType<T, string[]>>(
    searchParams: URLSearchParams,
    key: U,
    value: ArrayElement<T[U]>
  ) {
    if (this.#isEmpty) {
      this.#isEmpty = false;
      this._setMany(searchParams, this.#defaultValues);
    }

    const filter = searchParams.get(key as string)?.split(DELIMITER) ?? [];

    const valueIdx = filter.findIndex(
      (x) => x.replace(ESCAPED_COMMA, ',') === value
    );

    if (valueIdx === -1) {
      searchParams.set(
        key as string,
        [...filter, value.replace(',', ESCAPED_COMMA)].join(',')
      );
      return;
    }

    const params = [
      ...filter.slice(0, valueIdx),
      ...filter.slice(valueIdx + 1),
    ];

    if (params.length) {
      searchParams.set(key as string, params.join(','));
    } else {
      searchParams.delete(key as string);
    }
  }

  /**
   * Appends the provided value to the list of values for the
   * specified parameter if it doesn't already exist in the list.
   * If the value already exists in the list of values for the
   * parameter, it is deleted instead.
   */
  toggle<U extends KeysOfType<T, string[]>>(key: U, value: ArrayElement<T[U]>) {
    const searchParams = this.getURLSearchParamsInstance();

    this._toggle(searchParams, key, value);
    this.#setURLSearchParams(searchParams);
  }

  /**
   * Appends the provided values to the lists of values for the
   * specified parameters if they doesn't already exist in
   * their repsective lists.
   * Values that already exist in the list of values for a given
   * parameter are deleted instead.
   */
  toggleMany(values: Partial<Pick<T, KeysOfType<T, string[]>>>) {
    const searchParams = this.getURLSearchParamsInstance();

    for (const key of Object.keys(values)) {
      const valueArr = values[key as keyof typeof values];
      if (valueArr) {
        for (const value of valueArr) {
          this._toggle(
            searchParams,
            key as KeysOfType<T, string[]>,
            value as ArrayElement<T[KeysOfType<T, string[]>]>
          );
        }
      }
    }
    this.#setURLSearchParams(searchParams);
  }

  protected _deleteByKey(searchParams: URLSearchParams, key: keyof T) {
    if (this.#isEmpty) {
      this.#isEmpty = false;
      this._setMany(searchParams, this.#defaultValues);
    }

    searchParams.delete(key as string);
  }

  protected _deleteByKeyValuePair<U extends KeysOfType<T, string[]>>(
    searchParams: URLSearchParams,
    key: U,
    value: ArrayElement<T[U]>
  ) {
    if (this.#isEmpty) {
      this.#isEmpty = false;
      this._setMany(searchParams, this.#defaultValues);
    }

    if (this.has(key, value as T[U] extends Array<infer I> ? I : T[U])) {
      this._toggle(searchParams, key, value);
    }
  }

  /**
   * Delete the specified parameter and all
   * it's values from the search parameters.
   */
  deleteByKey(key: keyof T) {
    const searchParams = this.getURLSearchParamsInstance();
    this._deleteByKey(searchParams, key);
    this.#setURLSearchParams(searchParams);
  }

  /**
   * Delete the specified parameter with matching values from the search parameters
   */
  deleteByKeyValuePair<U extends KeysOfType<T, string[]>>(
    key: U,
    value: ArrayElement<T[U]>
  ) {
    const searchParams = this.getURLSearchParamsInstance();
    this._deleteByKeyValuePair(searchParams, key, value);
    this.#setURLSearchParams(searchParams);
  }

  /**
   * Convert the search parameters for this instance to an object
   * where the keys are the search parameters and the values are
   * an array of the values associated to each parameter.
   *
   * Values are undefined where the parameter is not present
   * in the search parameters at all.
   */
  toObject<U extends keyof T>(keys?: U[]) {
    const searchParams = this.getURLSearchParamsInstance();

    const keysToReturn = keys ?? Object.keys(this.#defaultValues);
    return keysToReturn.reduce(
      (acc, curr) => {
        const val = searchParams.get(curr as string);
        return {
          ...acc,
          [curr]: Array.isArray(this.#defaultValues[curr])
            ? val?.split(DELIMITER).map((x) => x.replace(ESCAPED_COMMA, ','))
            : val?.replace(ESCAPED_COMMA, ',') ?? undefined,
        };
      },
      {} as typeof keys extends undefined
        ? { [Key in keyof T]: T[Key] | undefined }
        : {
            [Key in U]: T[Key] | undefined;
          }
    );
  }

  /**
   * Get the number of active filter properties.
   */
  size() {
    const searchParams = this.getURLSearchParamsInstance();

    return Object.keys(this.#defaultValues).reduce((acc, curr) => {
      return searchParams.get(curr as string) ? acc + 1 : acc;
    }, 0);
  }

  clear() {
    const searchParams = this.getURLSearchParamsInstance();
    const keys = Object.keys(this.#defaultValues);
    for (const key of keys) {
      this._deleteByKey(searchParams, key);
    }
    this.#setURLSearchParams(searchParams);
  }
}

export function useTypesafeSearchParams<
  T extends {
    [key: string]: (string | null) | string[];
  } = { [key: string]: string[] },
>(values: {
  [Key in keyof T]: T[Key] extends string ? T[Key] | null : T[Key];
}) {
  const [, setSearchParams] = useSearchParams();
  const [instance] = useState(
    new TypesafeSearchParams<T>(values, setSearchParams)
  );
  instance.updateSearchParamReferences(setSearchParams);

  return instance;
}
