import React from 'react';
import classNames from 'classnames';
import { Tab, TabList, TabListProps, TabProps } from 'react-aria-components';

export function HeadlineTabList<T extends object>({
  className,
  ...props
}: TabListProps<T>) {
  return (
    <TabList<T>
      className={(args) =>
        classNames(
          'relative grid shrink-0 auto-cols-fr grid-flow-col grid-rows-1',
          typeof className === 'function' ? className(args) : className
        )
      }
      {...props}
    />
  );
}

export const HeadlineTab: React.FC<TabProps> = ({ className, ...props }) => {
  return (
    <Tab
      className={(args) =>
        classNames(
          'cursor-default border-b border-r border-t border-solid border-purple-200 border-b-purple-200 bg-purple-50 px-3 py-2 text-purple-800 first:rounded-tl-xl first:border-l last:rounded-tr-xl focus:outline-none selected:bg-white lg:px-6 lg:py-4',
          typeof className === 'function' ? className(args) : className
        )
      }
      {...props}
    />
  );
};

export const HeadlineTabTitle: React.FC<
  React.PropsWithChildren<{ className?: string }>
> = ({ children, className }) => {
  return (
    <span
      className={classNames(
        'text-[40px] font-semibold leading-[44px] tracking-tight',
        className
      )}
    >
      {children}
    </span>
  );
};

export const HeadlineTabSubtitle: React.FC<
  React.PropsWithChildren<{ className?: string }>
> = ({ children, className }) => {
  return (
    <span className={classNames('text-base font-semibold', className)}>
      {children}
    </span>
  );
};

export const HeadlineTabAside: React.FC<
  React.PropsWithChildren<{ className?: string }>
> = ({ children, className }) => {
  return (
    <span
      className={classNames(
        'text-sm font-normal text-text-secondary',
        className
      )}
    >
      {children}
    </span>
  );
};
