import classNames from 'classnames';
import { LocalizedString } from 'typesafe-i18n';
import { useI18nContext } from 'src/i18n/i18n-react';
import { Tooltip } from 'src/components/Tooltip/Tooltip';

type InsightsCardStatusProps = {
  effectiveStatus: string;
  isTooltipDisabled?: boolean;
};

const activeStyle = 'bg-green-500';
const intermediateStyle = 'bg-blue-500';
const pausedStyle = 'bg-gray-400';
const problemsStyle = 'bg-red-500';

function getStyle(effectiveStatus: string) {
  switch (effectiveStatus) {
    case 'ACTIVE':
      return activeStyle;
    case 'PAUSED':
    case 'CAMPAIGN_PAUSED':
    case 'ADSET_PAUSED':
    case 'ARCHIVED':
    case 'DELETED':
      return pausedStyle;
    case 'PENDING_REVIEW':
    case 'IN_PROCESS':
      return intermediateStyle;
    case 'DISAPPROVED':
    case 'PENDING_BILLING_INFO':
    case 'WITH_ISSUES':
      return problemsStyle;
    default:
      return pausedStyle;
  }
}

export const InsightsCardStatus: React.FunctionComponent<
  InsightsCardStatusProps
> = ({ effectiveStatus, isTooltipDisabled }) => {
  const { LL } = useI18nContext();
  const label: (() => LocalizedString) | null =
    LL.insights.effectiveStatus[
      effectiveStatus as keyof typeof LL.insights.effectiveStatus
    ] || null;

  return (
    <Tooltip isDisabled={isTooltipDisabled} delay={0} offset={7}>
      <div
        className={classNames(
          'box-content h-[8px] w-[8px] shrink-0 rounded-full border-2 border-solid border-white/50',
          getStyle(effectiveStatus)
        )}
      />
      <span>{label()}</span>
    </Tooltip>
  );
};
