import React, { forwardRef } from 'react';
import classNames from 'classnames';

type CheckboxSize = 'normal' | 'medium' | 'large';

type Props = {
  size?: CheckboxSize;
  className?: string | null;
  checkboxClassName?: string | null;
} & Omit<React.ComponentPropsWithoutRef<'input'>, 'type' | 'size'>;

const baseStyle = 'rounded border-gray-300 focus:ring-purple-600 text-primary';
const sizeStyles: Record<CheckboxSize, string> = {
  normal: 'h-4 w-4',
  medium: 'h-5 w-5',
  large: 'h-6 w-6',
};

const baseLabelStyle =
  'text-sm select-none peer-checked:font-semibold text-primary';

const Checkbox = forwardRef<HTMLInputElement, Props>(
  (
    {
      id,
      size = 'normal',
      children,
      className,
      checkboxClassName,
      checked,
      title,
      disabled,
      ...props
    },
    ref
  ) => {
    return (
      <label
        title={title}
        className={classNames(
          'flex items-center gap-3',
          className,
          disabled
            ? 'checkbox-disabled group cursor-default opacity-50'
            : 'cursor-pointer'
        )}
      >
        <input
          {...props}
          checked={checked}
          ref={ref}
          id={id}
          disabled={disabled}
          type="checkbox"
          className={classNames(
            'peer form-checkbox',
            baseStyle,
            checkboxClassName,
            sizeStyles[size]
          )}
        />
        {children &&
        (typeof children === 'string' ||
          typeof children === 'number' ||
          typeof children === 'boolean') ? (
          <span className={baseLabelStyle}>{children}</span>
        ) : (
          children
        )}
      </label>
    );
  }
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
