import { InsightsFacebookSelectableDatePreset } from '@magicbrief/common';

export function computeUrlParamsForReport(
  timePeriod: PrismaJson.ForTimePeriod | undefined | null,
  attributionWindow: 'default' | 'custom' | undefined | null
): URLSearchParams {
  const params = new URLSearchParams();

  if (timePeriod) {
    const datePreset: InsightsFacebookSelectableDatePreset | 'custom' =
      timePeriod?.datePreset ?? 'last_14d';

    params.set('timePeriod', datePreset);

    if ('since' in timePeriod && timePeriod.since) {
      params.set('since', timePeriod.since);
    }

    if ('until' in timePeriod && timePeriod.until) {
      params.set('until', timePeriod.until);
    }
  }

  if (attributionWindow) {
    params.set('attributionWindow', attributionWindow);
  }

  return params;
}
