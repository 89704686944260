import {
  ComponentProps,
  ElementType,
  FC,
  Fragment,
  JSXElementConstructor,
  MouseEventHandler,
  PropsWithChildren,
  ReactNode,
} from 'react';
import { Menu as HeadlessMenu } from '@headlessui/react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Float } from '@headlessui-float/react';

type BaseMenuItemProps = PropsWithChildren<{
  icon?: ReactNode;
}>;

type MenuItemProps = BaseMenuItemProps & {
  activeClassName?: string;
  inactiveClassName?: string;
  className?: string;
  disabled?: boolean;
};

type ExternalLinkMenuItemProps = MenuItemProps & {
  href: string;
  onClick?: () => void;
};

type ReactRouterLinkMenuItemProps = MenuItemProps & {
  to: string;
};

type ButtonMenuItemProps = MenuItemProps & {
  onClick: MouseEventHandler<HTMLButtonElement> | null;
};

function styles(
  active: boolean,
  disabled?: boolean,
  inactiveClassName?: string,
  activeClassName?: string
) {
  return classNames(
    active
      ? activeClassName ?? '!bg-purple-100 text-primary'
      : inactiveClassName ?? 'text-primary',
    disabled ? 'cursor-not-allowed text-primary/40' : '',
    'group flex h-10 items-center gap-3 px-4 py-2 text-sm font-semibold'
  );
}

const BaseMenuItemContent: FC<BaseMenuItemProps> = ({ children, icon }) => {
  return (
    <>
      {icon && (
        <div className="flex h-5 w-5 items-center justify-center">{icon}</div>
      )}
      {children}
    </>
  );
};

export const ExternalLinkMenuItem: FC<ExternalLinkMenuItemProps> = ({
  href,
  activeClassName,
  inactiveClassName,
  className,
  disabled,
  onClick,
  ...rest
}) => {
  if (disabled) {
    return (
      <ButtonMenuItem
        onClick={onClick ?? null}
        activeClassName={activeClassName}
        inactiveClassName={inactiveClassName}
        className={className}
        disabled={disabled}
        {...rest}
      />
    );
  }
  return (
    <HeadlessMenu.Item>
      {({ active }) => (
        <a
          href={href}
          target="_blank"
          className={classNames(
            styles(active, disabled, inactiveClassName, activeClassName),
            className
          )}
          rel="noreferrer"
        >
          <BaseMenuItemContent {...rest} />
        </a>
      )}
    </HeadlessMenu.Item>
  );
};

export const ReactRouterLinkMenuItem: FC<ReactRouterLinkMenuItemProps> = ({
  to,
  activeClassName,
  inactiveClassName,
  className,
  disabled,
  ...rest
}) => {
  return (
    <HeadlessMenu.Item>
      {({ active }) =>
        disabled ? (
          <span
            role="link"
            aria-disabled={disabled ? 'true' : undefined}
            className={classNames(
              styles(active, disabled, inactiveClassName, activeClassName),
              className
            )}
          >
            <BaseMenuItemContent {...rest} />
          </span>
        ) : (
          <Link
            role="link"
            aria-disabled={disabled ? 'true' : undefined}
            to={to}
            className={classNames(
              styles(active, disabled, inactiveClassName, activeClassName),
              className
            )}
          >
            <BaseMenuItemContent {...rest} />
          </Link>
        )
      }
    </HeadlessMenu.Item>
  );
};

export const ButtonMenuItem: FC<ButtonMenuItemProps> = ({
  onClick,
  activeClassName,
  inactiveClassName,
  className,
  disabled,
  ...rest
}) => {
  return (
    <HeadlessMenu.Item>
      {({ active }) => (
        <button
          disabled={disabled}
          type="button"
          onClick={onClick ?? undefined}
          className={classNames(
            styles(active, disabled, inactiveClassName, activeClassName),
            'w-full !bg-white hover:!bg-purple-100',
            className
          )}
        >
          <BaseMenuItemContent {...rest} />
        </button>
      )}
    </HeadlessMenu.Item>
  );
};

export const MenuItemGroup: FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
}) => {
  return <div className={classNames('py-1', className)}>{children}</div>;
};

type PassthruMenuProps<
  T extends keyof JSX.IntrinsicElements | JSXElementConstructor<unknown>,
> = Omit<Parameters<typeof HeadlessMenu<T>>[0], 'ref'>;

type MenuProps<T extends ElementType = 'button'> = {
  items: ReactNode;
  portal?: boolean;
  rootClassName?: string;
  placement?: ComponentProps<typeof Float>['placement'];
  shift?: boolean;
  originClassName?: string;
  disabled?: boolean;
} & PassthruMenuProps<T>;

export function Menu<T extends ElementType = 'button'>({
  items,
  portal,
  rootClassName,
  placement = 'bottom-start',
  shift = false,
  originClassName,
  disabled,
  ...rest
}: MenuProps<T>): JSX.Element {
  return (
    <HeadlessMenu as="div" className={classNames('flex', rootClassName)}>
      <Float
        flip
        shift={shift}
        portal={portal}
        as={Fragment}
        offset={8}
        placement={placement}
        tailwindcssOriginClass
        originClass={originClassName}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <HeadlessMenu.Button disabled={disabled} {...rest} />
        <HeadlessMenu.Items className="w-56 divide-y divide-solid divide-purple-100 overflow-hidden rounded-md bg-white shadow-md ring-1 ring-purple-300 focus:outline-none">
          {items}
        </HeadlessMenu.Items>
      </Float>
    </HeadlessMenu>
  );
}
