import React from 'react';
import { cn } from 'src/lib/cn';

type Props = React.PropsWithChildren<{
  containerRef?: React.MutableRefObject<HTMLDivElement | null>;
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  onContainerClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onClickCapture?: (event: React.MouseEvent<HTMLDivElement>) => void;
  selected?: boolean;
  cardContentClassName?: string;
}>;

const BaseCard: React.FunctionComponent<Props> = ({
  children,
  containerRef,
  onKeyDown,
  onContainerClick,
  onClickCapture,
  selected,
  cardContentClassName,
}): JSX.Element => {
  return (
    <div
      ref={containerRef}
      className="group flex rounded-lg focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-600 focus-visible:ring-offset-2"
      tabIndex={0}
      onKeyDown={onKeyDown}
      role="button"
      onClickCapture={onClickCapture}
      onClick={onContainerClick}
    >
      <div
        className={cn(
          'fade-in-out flex h-auto max-h-fit min-w-0 flex-auto select-none flex-col gap-3 rounded-lg border-2 border-solid bg-white p-3 shadow shadow-primary/15 transition-colors hover:bg-gray-50 focus-visible:outline-0',
          selected ? 'border-primary' : 'border-transparent',
          cardContentClassName
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default BaseCard;
