const ASSET_COLLECTION_STORAGE_KEY = 'assetCollection';

export function getLastSavedAssetCollectionFromLocalStorage() {
  return localStorage.getItem(ASSET_COLLECTION_STORAGE_KEY);
}

export function setLastSavedAssetCollectionInLocalStorage(uuid: string) {
  return localStorage.setItem(ASSET_COLLECTION_STORAGE_KEY, uuid);
}

export function deleteLastSavedAssetCollectionInLocalStorage(uuid: string) {
  const current = getLastSavedAssetCollectionFromLocalStorage();
  if (current === uuid) {
    localStorage.removeItem(ASSET_COLLECTION_STORAGE_KEY);
  }
}
