import classNames from 'classnames';
import { Icon } from 'src/components/Icon';
import XClose from 'src/assets/svgicons/line/x-close.svg';

type Props = {
  onClick: () => void;
  className?: string;
};

const ModalCloseButton: React.FunctionComponent<Props> = ({
  onClick,
  className,
}) => {
  return (
    <button
      onClick={onClick}
      className={classNames(
        'appearance-none cursor-pointer text-purple-800 outline-none focus-visible:outline-purple-500 focus-visible:outline-2 rounded-md w-6 h-6',
        className
      )}
    >
      <Icon>
        <XClose />
      </Icon>
    </button>
  );
};

export default ModalCloseButton;
