import { useState } from 'react';
import {
  Button,
  Dialog,
  Heading,
  Modal,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  Tabs,
} from 'react-aria-components';
import { toast } from 'react-toastify';
import { DirectoryItemType } from '@magicbrief/common';
import { Icon } from 'src/components/Icon';
import { useI18nContext } from 'src/i18n/i18n-react';
import { AriaButton } from 'src/components/Button/Button';
import XClose from 'src/assets/svgicons/line/x-close.svg';
import { Alert } from 'src/components/Alert';
import AlertOctagon from 'src/assets/svgicons/duocolor/alert-octagon.svg';
import { useAddEntitiesToDirectory } from 'src/utils/useDirectories';
import { trpc } from 'src/lib/trpc';
import Spinner from 'src/components/Loaders/Spinner';
import Pin02 from 'src/assets/svgicons/line/pin-02.svg';
import EntityDirectoryManagement from 'src/components/Directories/EntityDirectoryManagement';
import { useInitiateAddEntityToDirectory } from 'src/components/Directories/useDirectoryActions';
import { DirectoryPicker } from '../DirectoryPicker/DirectoryPicker';

type Props = {
  onOpenChange: (isOpen: boolean) => void;
  entity: {
    entityUuid: string;
    entityType: DirectoryItemType;
    proxyEntityType?: 'PlatformAd' | null;
    entityName: string;
  } | null;
};

export const AddToDirectoryModal: React.FC<Props> = ({
  onOpenChange,
  entity,
  ...props
}): JSX.Element => {
  return (
    <ModalOverlay
      isOpen={!!entity}
      onOpenChange={onOpenChange}
      className="fixed inset-0 z-500 overflow-y-auto bg-gray-900/60 flex min-h-full items-center justify-center p-4 text-center"
    >
      <Modal className="flex w-full max-w-md rounded-xl bg-white p-6 text-left align-middle shadow-xl max-h-[90%]">
        <Dialog className="flex outline-none min-h-0 flex-auto min-w-0">
          {({ close }) => (
            <>
              {entity && (
                <AddToDirectoryModalBody
                  close={close}
                  entity={entity}
                  {...props}
                />
              )}
            </>
          )}
        </Dialog>
      </Modal>
    </ModalOverlay>
  );
};

type AddToDirectoryModalBodyProps = {
  entity: Exclude<Props['entity'], null>;
  close: () => void;
};

const AddToDirectoryModalBody: React.FC<AddToDirectoryModalBodyProps> = ({
  close,
  entity,
}) => {
  const { LL } = useI18nContext();
  const addParams = useInitiateAddEntityToDirectory();
  const directoryStructure =
    trpc.directories.getDirectoryStructureForOrganisation.useQuery();
  const [tab, setTab] = useState<'browse' | 'search'>('search');
  const [selectedDirectoryUuid, setSelectedDirectoryUuid] = useState<
    string | null
  >(null);
  const existing = trpc.directories.getDirectoryNodesAliasedToEntity.useQuery(
    {
      entityUuid: entity.entityUuid,
      entityType: entity.entityType,
    },
    {
      enabled: !entity.proxyEntityType,
    }
  );
  const mutate = useAddEntitiesToDirectory();

  const submit = async () => {
    if (!selectedDirectoryUuid) {
      return;
    }

    mutate.mutate(
      {
        destinationDirectoryNodeUuid: selectedDirectoryUuid,
        entities: [entity],
      },
      {
        async onSuccess() {
          close();
          toast('Pinned to Board', {
            className: 'toast-success',
          });
        },
      }
    );
  };

  return (
    <div className="flex flex-auto flex-col gap-4 min-w-0">
      <div className="flex flex-row items-center justify-between text-purple-800 gap-4">
        <Heading className="text-xl font-bold truncate">
          {`Pin ${entity.entityName} to Board`}
        </Heading>
        <Button onPress={close}>
          <Icon>
            <XClose />
          </Icon>
        </Button>
      </div>

      {mutate.error && (
        <Alert
          icon={
            <Icon>
              <AlertOctagon />
            </Icon>
          }
          className="w-full"
          type="error"
        >
          <p className="text-xs font-normal">
            {LL.errors.genericWithDetail({
              detail: mutate.error.message,
            })}
          </p>
        </Alert>
      )}

      {((existing.isLoading && !entity.proxyEntityType) ||
        directoryStructure.isLoading) && (
        <Spinner className="justify-self-center self-center text-purple-800" />
      )}
      {(existing.data || !!entity.proxyEntityType) &&
        directoryStructure.data && (
          <Tabs
            selectedKey={tab}
            defaultSelectedKey="search"
            onSelectionChange={(key) => {
              setTab(key as typeof tab);
            }}
            className="flex flex-col gap-2 min-h-0"
          >
            <TabList
              aria-label="Ad Insights"
              className="flex flex-row gap-3 text-xs text-primary/40 font-semibold"
            >
              <Tab
                className="outline-none focus:outline-none focus-visible:outline-none disabled:text-gray-400 selected:text-purple-800 cursor-pointer"
                id="search"
              >
                Search &amp; Manage
              </Tab>
              <Tab
                id="browse"
                className="outline-none focus:outline-none focus-visible:outline-none selected:text-purple-800 cursor-pointer"
              >
                Browse
              </Tab>
            </TabList>
            <TabPanel id="browse" className="flex flex-auto min-h-0">
              <div className="flex flex-auto flex-col gap-4">
                <div className="flex-auto overflow-y-auto">
                  <DirectoryPicker
                    disabledKeys={existing.data?.reduce<string[]>(
                      (acc, x) =>
                        x.ParentDirectoryNode
                          ? [...acc, x.ParentDirectoryNode.uuid]
                          : acc,
                      []
                    )}
                    onSelectionChange={(uuid) => setSelectedDirectoryUuid(uuid)}
                  />
                </div>
                {selectedDirectoryUuid && directoryStructure.data && (
                  <div className="p-2 bg-purple-100 rounded-lg flex-auto text-xs font-semibold text-purple-800 flex flex-row justify-center">
                    <span className="flex items-center gap-2">
                      <Icon className="h-4 w-4 animate-pulse">
                        <Pin02 />
                      </Icon>
                      <span className="col-span-3 truncate text-center">
                        {directoryStructure.data.directories[
                          selectedDirectoryUuid
                        ].name ?? 'Unknown Board'}
                      </span>
                    </span>
                  </div>
                )}
                <AriaButton
                  isDisabled={!selectedDirectoryUuid}
                  onPress={submit}
                  loading={mutate.isLoading}
                >
                  {selectedDirectoryUuid
                    ? `Pin to ${
                        directoryStructure.data.directories[
                          selectedDirectoryUuid
                        ].name ?? 'Unknown Board'
                      }`
                    : 'Pin'}
                </AriaButton>
              </div>
            </TabPanel>

            <TabPanel id="search">
              <EntityDirectoryManagement
                entityType={entity.entityType}
                entityUuid={entity.entityUuid}
                proxyEntityType={entity.proxyEntityType}
                onSuccess={(data) => {
                  addParams({
                    proxyEntityType: null,
                    entityType: data.fulfilled[0].entityType,
                    entityUuid: data.fulfilled[0].entityUuid,
                    entityName: entity.entityName,
                  });
                }}
              />
            </TabPanel>
          </Tabs>
        )}
    </div>
  );
};
