import React, { forwardRef } from 'react';
import classNames from 'classnames';
import Checkbox from './Checkbox';

type Props = React.PropsWithChildren<
  Omit<React.ComponentPropsWithoutRef<'input'>, 'type'>
>;

const CheckboxButton = forwardRef<HTMLInputElement, Props>(
  ({ children, checked, ...rest }, ref) => {
    return (
      <Checkbox
        {...rest}
        ref={ref}
        checked={checked}
        size="medium"
        className="border border-solid border-gray-300 rounded-md p-2.5"
        checkboxClassName="rounded border-2 border-primary text-primary focus:ring-purple-600"
      >
        <span
          className={classNames(
            'text-xs text-primary select-none',
            checked ? 'font-semibold' : 'font-medium'
          )}
        >
          {children}
        </span>
      </Checkbox>
    );
  }
);

CheckboxButton.displayName = 'CheckboxButton';

export default CheckboxButton;
