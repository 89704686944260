import { FC, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { captureException } from '@sentry/react';
import classNames from 'classnames';
import Spinner from 'src/components/Loaders/Spinner';
import { BaseModal, ModalHeader } from 'src/components/Modal';
import OrganisationAvatar from 'src/components/OrganisationAvatar';
import { useI18nContext } from 'src/i18n/i18n-react';
import { trpc } from 'src/lib/trpc';
import Input from 'src/components/Input';

type Props = {
  show: boolean;
  onClose: () => void;
  currentOrg?: {
    uuid: string;
    profilePicURL: string | null;
    name: string | null;
  };
};

const SwitchWorkspaceModal: FC<Props> = ({ show, onClose, currentOrg }) => {
  const [textFilter, setTextFilter] = useState('');
  const { LL } = useI18nContext();
  const navigate = useNavigate();
  const trpcUtils = trpc.useUtils();
  const updateUserCurrentOrganisation =
    trpc.user.updateUserCurrentOrganisation.useMutation({
      onSuccess: () => {
        void trpcUtils.directories.getDirectoryStructureForOrganisation.invalidate();
        toast.success(LL.organisations.switcher.success(), {
          className: 'toast-success',
        });
        setTimeout(() => {
          navigate(`/${window.location.search}`);
          window.location.reload();
        }, 600);
      },
      onError: (e, opts) => {
        captureException(e, (scope) => {
          scope.setExtras({
            organisationId: opts.organisationId,
          });
          scope.setTransactionName('user->switchOrganisation');
          return scope;
        });
      },
    });

  const allOrgs = trpc.user.getUserOrgs.useQuery();
  const otherOrgs =
    allOrgs.data?.filter(
      (userOrg) =>
        userOrg.organisationUuid !== currentOrg?.uuid &&
        userOrg.orgName
          .toLocaleLowerCase()
          .includes(textFilter.toLocaleLowerCase())
    ) ?? [];

  return (
    <BaseModal
      show={show}
      onClose={onClose}
      panelClassName="flex flex-col max-w-sm w-full overflow-clip h-[26.5rem] bg-white rounded-xl"
    >
      <ModalHeader onClose={onClose} className="px-8 pt-6">
        {LL.workspaceSwitcher.title()}
      </ModalHeader>

      <div className="mt-2 flex flex-row items-center justify-between gap-4 border-b border-solid border-b-purple-300 px-8 py-6">
        {updateUserCurrentOrganisation.isLoading ? (
          <div className="flex flex-auto flex-col items-center gap-4 self-center text-primary">
            <Spinner />
            <p className="shrink text-center font-semibold text-primary">
              {LL.workspaceSwitcher.switching({
                name:
                  otherOrgs.find(
                    (userOrg) =>
                      userOrg.orgID ===
                      updateUserCurrentOrganisation.variables?.company_id
                  )?.orgName ?? '',
              })}
            </p>
          </div>
        ) : (
          <div className="flex shrink flex-row items-center gap-4 overflow-hidden">
            <div className="flex flex-row items-center gap-4 overflow-hidden">
              <div className="h-8 w-8 shrink-0">
                <OrganisationAvatar profilePicURL={currentOrg?.profilePicURL} />
              </div>
              <span className="shrink truncate font-semibold text-primary">
                {currentOrg?.name}
              </span>
            </div>
            <div className="flex h-6 items-center justify-center rounded-full border border-solid border-purple-500 px-2 text-xs text-purple-500">
              {LL.workspaceSwitcher.current()}
            </div>
          </div>
        )}
      </div>

      {(otherOrgs.length > 1 || textFilter.length > 0) && (
        <div className="mx-4 mt-3">
          <Input
            onChange={(e) => setTextFilter(e.target.value)}
            placeholder={LL.workspaceSwitcher.filterOrganisations()}
            name="filter"
            label=""
          />
        </div>
      )}

      <div
        className={classNames(
          'align-center m-4 flex flex-auto shrink grow-0 flex-col divide-y divide-dotted divide-purple-100 overflow-auto rounded-lg border border-solid border-transparent',
          {
            '!border-purple-100': otherOrgs.length > 0,
          }
        )}
      >
        {allOrgs.isLoading && (
          <div className="text-primary">
            <Spinner />
          </div>
        )}

        {allOrgs.data &&
          otherOrgs.map((org) => (
            <button
              disabled={
                updateUserCurrentOrganisation.isLoading ||
                updateUserCurrentOrganisation.isSuccess
              }
              onClick={() => {
                updateUserCurrentOrganisation.mutate({
                  company_id: org.orgID,
                  organisationId: org.orgID,
                });
              }}
              key={org.orgID}
              className="flex shrink-0 cursor-pointer appearance-none flex-row items-center gap-4 overflow-hidden bg-white px-4 py-6 first:rounded-t-lg last:rounded-b-lg hover:bg-purple-100 disabled:cursor-not-allowed disabled:opacity-70"
            >
              <div className="h-8 w-8 shrink-0">
                <OrganisationAvatar profilePicURL={org.profilePicURL} />
              </div>
              <span className="shrink truncate font-semibold text-primary">
                {org.orgName}
              </span>
            </button>
          ))}
        {allOrgs.data && otherOrgs.length === 0 && (
          <span className="text-center text-sm font-normal text-gray-500">
            {textFilter.length > 0
              ? LL.workspaceSwitcher.noResults()
              : LL.workspaceSwitcher.none()}
          </span>
        )}
      </div>
    </BaseModal>
  );
};

export default SwitchWorkspaceModal;
