import React from 'react';
import Spinner from 'src/components/Loaders/Spinner';
import { useI18nContext } from 'src/i18n/i18n-react';

const Loading: React.FunctionComponent = (): JSX.Element => {
  const { LL } = useI18nContext();

  return (
    <div className="flex h-full w-full flex-auto items-center justify-center p-4">
      <div className="flex flex-col items-center gap-6">
        <img src="/images/MagicBriefLogo.png" alt="Magic Brief" width="159px" />
        <div className="flex flex-row items-center gap-4 rounded-xl bg-white p-5 text-sm font-semibold text-primary shadow">
          <Spinner />
          <span>{LL.onboarding.userInit()}</span>
        </div>
      </div>
    </div>
  );
};

export default Loading;
