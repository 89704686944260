import { weightedPermissionRoles } from '../constants/weights';
import type { PermissionRole } from '@magicbrief/prisma/generated/client2';

export function getWeightForRole(role: PermissionRole): number | 0 {
  const weightedPermissions = Object.entries(weightedPermissionRoles);

  return weightedPermissions.reduce((acc, [key, value]) => {
    if (value.includes(role)) {
      acc = Number(key);
      weightedPermissions.splice(1);
    }
    return acc;
  }, 0);
}
