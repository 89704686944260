import { createContext } from 'react';
import { User } from 'firebase/auth';

export const FirebaseContext = createContext<User | null>(null);

export const FirebaseDispatchContext = createContext<{
  refreshUser: () => Promise<User | null>;
}>({
  refreshUser: async () => {
    console.error('Calling FirebaseContext outside a FirebaseProvider');
    return null;
  },
});
