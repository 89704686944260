import { lazy, Suspense } from 'react';
import * as Sentry from '@sentry/react';
import { getQueryKey } from '@trpc/react-query';
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  redirect,
  RouteObject,
  RouterProvider,
} from 'react-router-dom';
import { init as facebookInit } from 'src/lib/facebook';
import { Redirector } from 'src/routes/Redirector';
import { getFeatureFlagValue } from 'src/utils/getFeatureFlagValue';
import {
  AppWithNavLoader,
  AuthenticationLoader,
  BrandsLoader,
  BriefEditorLoader,
  BriefsLoader,
  InsightsAnalysisLoader,
  InsightsLoader,
  LibraryLoader,
  SlackLoader,
  StoryboardsLoader,
  SubRouteLoader,
  TikTokLoader,
} from './components/SuspenseLoaders/SuspenseLoaders';
import { GlobalProvider } from './contexts/GlobalContext/GlobalContext';
import { MagicBriefRouterErrorBoundary } from './lib/MagicBriefErrorBoundary';
import queryClient from './lib/queryClient';
import { trpc, trpcProxyClient } from './lib/trpc';
import AssetDetailsOutlet from './pages/AssetDetailsOutlet/AssetDetailsOutlet';
import AssetsSidebar from './pages/Assets/components/AssetsSidebar';
import BriefsSidebar from './pages/Briefs/components/BriefsSidebar';
import { brandProfileRouteLoader } from './pages/Briefs/routes/BrandProfile/routeLoader';
import { productListRouteLoader } from './pages/Briefs/routes/ProductList/routeLoader';
import { productProfileRouteLoader } from './pages/Briefs/routes/ProductProfile/routeLoader';
import { RedirectCollectionToDirectory } from './pages/Directory/RedirectCollectionToDirectory';
import InsightsSidebar from './pages/Insights/components/InsightsSidebar';
import { InsightsDetailOutlet } from './pages/Insights/InsightsDetailOutlet/InsightsDetailOutlet';
import { insightsAccountRouteLoader } from './pages/Insights/routes/InsightsAccount/route-loader';
import { insightsAnalysisRouteLoader } from './pages/Insights/routes/InsightsAnalysis/route-loader';
import LibraryDetailsOutlet from './pages/LibraryDetailsOutlet/LibraryDetailsOutlet';
import { InitialLibraryFilterContextValue } from './pages/LibraryV2/contexts/constants';
import AnalyticsOutlet from './pages/outlets/AnalyticsOutlet/AnalyticsOutlet';
import { DirectoriesOutlet } from './pages/outlets/DirectoriesOutlet/DirectoriesOutlet';
import FirebaseOutlet from './pages/outlets/FirebaseOutlet/FirebaseOutlet';
import InitialiseUserOutlet from './pages/outlets/InitialiseUserOutlet/InitialiseUserOutlet';
import { PageTakeLimit } from './utils/useAdLibrary';

/** Authentication */

const RequireAuthenticatedOutlet = lazy(
  () => import('./pages/outlets/RequireAuthenticatedOutlet')
);
const RequireUnauthenticatedOutlet = lazy(
  () => import('./pages/outlets/RequireUnauthenticatedOutlet')
);
const AuthenticationOutlet = lazy(
  () => import('./pages/outlets/AuthenticationOutletNew/AuthenticationOutlet')
);
const Login = lazy(
  () => import('./pages/outlets/AuthenticationOutletNew/components/Login')
);
const Logout = lazy(
  () => import('./pages/outlets/AuthenticationOutletNew/components/Logout')
);
const Authentication = lazy(() => import('./pages/Authentication'));
const Invite = lazy(() => import('./pages/Invite'));
const AuthExtension = lazy(() => import('./pages/AuthExtension'));

/** User */

const Notifications = lazy(() => import('./pages/Notifications'));

/** Organisations */

const Organisations = lazy(() => import('./pages/Organisations'));
const OrganisationSwitcher = lazy(
  () =>
    import(
      'src/pages/Organisations/routes/OrganisationSwitcher/OrganisationSwitcher'
    )
);

/** Library */

const PacksCollection = lazy(
  () => import('./pages/LibraryV2/routes/Packs/PacksCollection')
);
const Discover = lazy(() => import('./pages/LibraryV2/routes/Discover'));
const Library = lazy(() => import('./pages/LibraryV2'));
const LibraryDirectory = lazy(
  () => import('./pages/LibraryV2/routes/LibraryDirectory')
);
const SavedLibrary = lazy(
  () => import('./pages/LibraryV2/routes/SavedLibrary')
);
const Packs = lazy(() => import('./pages/LibraryV2/routes/Packs'));
const Brands = lazy(() => import('./pages/LibraryV2/routes/Brands'));
const BrandOverviewFeatureFlagged = lazy(
  () =>
    import(
      './pages/LibraryV2/routes/Brands/BrandOverviewFeatureFlagged/BrandOverviewFeatureFlagged'
    )
);
const LinkedLibraryItem = lazy(() => import('./pages/LinkedLibraryItem'));

/** Insights */

const Insights = lazy(() => import('./pages/Insights/Insights'));
const InsightsAccount = lazy(
  () => import('./pages/Insights/routes/InsightsAccount/InsightsAccount')
);
const InsightsReport = lazy(
  () => import('./pages/Insights/routes/InsightsReport/InsightsReport')
);
const InsightsAnalysis = lazy(
  () => import('./pages/Insights/routes/InsightsAnalysis/InsightsAnalysis')
);
const InsightsCompare = lazy(
  () => import('./pages/Insights/routes/InsightsCompare/InsightsCompare')
);
const InsightsCompareReport = lazy(
  () => import('./pages/Insights/routes/InsightsCompare/InsightsCompareReport')
);

const InsightsSummarySchedules = lazy(
  () =>
    import(
      './pages/Insights/routes/InsightsSummarySchedules/InsightsSummarySchedules'
    )
);
const SharedInsightsReport = lazy(
  () =>
    import('./pages/Insights/routes/SharedInsightsReport/SharedInsightsReport')
);
const SharedInsightsCompareReport = lazy(
  () =>
    import(
      './pages/Insights/routes/SharedInsightsCompareReport/SharedInsightsCompareReport'
    )
);
const InsightsAccountSettings = lazy(
  () =>
    import(
      './pages/Insights/routes/InsightsAccountSettings/InsightsAccountSettings'
    )
);
const SharedInsightsAd = lazy(
  () => import('./pages/Insights/routes/SharedInsightsAd/SharedInsightsAd')
);

/** Slack */

const Slack = lazy(() => import('./pages/Slack/Slack'));
const SlackOauthExchange = lazy(
  () => import('./pages/Slack/routes/SlackOauthExchange/SlackOauthExchange')
);

/** TikTok */

const TikTok = lazy(() => import('./pages/TikTok/TikTok'));
const TikTokOauthExchange = lazy(
  () => import('./pages/TikTok/routes/TikTokOauthExchange/TikTokOauthExchange')
);

/** Directories */

const Directory = lazy(() => import('./pages/Directory/Directory'));
const SharedDirectory = lazy(() => import('./pages/Directory/SharedDirectory'));
const SharedCollection = lazy(
  () => import('./pages/LibraryV2/routes/SharedCollection')
);

/** Briefs */

const Brief = lazy(() => import('./pages/Briefs/routes/Brief'));
const Briefs = lazy(() => import('./pages/Briefs'));
const BriefList = lazy(() => import('./pages/Briefs/routes/BriefList'));
const BriefBrandProfilesProvider = lazy(
  () => import('./pages/Briefs/routes/BrandList/context')
);
const SharedBrief = lazy(() => import('./pages/Briefs/routes/SharedBrief'));
const BrandList = lazy(() => import('./pages/Briefs/routes/BrandList'));
const ProductList = lazy(() => import('./pages/Briefs/routes/ProductList'));
const BrandProfile = lazy(() => import('./pages/Briefs/routes/BrandProfile'));
const ProductProfile = lazy(
  () => import('./pages/Briefs/routes/ProductProfile')
);

/** Boards */

const PublicBoard = lazy(
  () => import('./pages/Board/routes/Board/components/PublicBoard')
);
const BoardsProvider = lazy(() => import('./pages/Board/context'));
const BoardList = lazy(() => import('./pages/Board/routes/BoardList'));
const Board = lazy(() => import('./pages/Board/routes/Board'));

/** Assets */

const AssetCollection = lazy(
  () => import('./pages/Assets/routes/AssetCollection/AssetCollection')
);
const Assets = lazy(() => import('./pages/Assets'));
const AssetList = lazy(() => import('./pages/Assets/routes/AssetList'));
const LinkedAssetItem = lazy(() => import('./pages/LinkedAssetItem'));

/** MagicAI */

const MagicAi = lazy(() => import('./pages/LibraryV2/routes/MagicAi'));

/** Settings */

const Settings = lazy(() => import('./pages/SettingsV2/Settings'));
const ProfileSettingsV2 = lazy(
  () => import('./pages/SettingsV2/ProfileSettings')
);
const WorkspaceSettingsV2 = lazy(
  () => import('./pages/SettingsV2/WorkspaceSettings')
);
const IntegrationsSettings = lazy(
  () => import('./pages/SettingsV2/IntegrationsSettings')
);
const BillingSettings = lazy(
  () => import('./pages/SettingsV2/BillingSettings')
);
const TeamSettings = lazy(() => import('./pages/SettingsV2/TeamSettings'));

const routes: RouteObject[] = [
  {
    errorElement: <MagicBriefRouterErrorBoundary />,
    path: '/',
    children: [
      {
        element: <AnalyticsOutlet />,
        children: [
          {
            element: <FirebaseOutlet />,
            children: [
              // Unauthorised paths, cannot be logged in on these
              {
                element: (
                  <Suspense fallback={<AuthenticationLoader />}>
                    <RequireUnauthenticatedOutlet />
                  </Suspense>
                ),
                children: [
                  {
                    element: (
                      <Suspense fallback={<AuthenticationLoader />}>
                        <AuthenticationOutlet />
                      </Suspense>
                    ),
                    children: [
                      {
                        path: 'login',
                        element: (
                          <Suspense fallback={<AuthenticationLoader />}>
                            <Login />
                          </Suspense>
                        ),
                      },
                      {
                        path: 'register',
                        element: (
                          <Suspense fallback={<AuthenticationLoader />}>
                            <Login />
                          </Suspense>
                        ),
                      },
                    ],
                  },
                ],
              },
              // Other paths
              {
                element: (
                  <InitialiseUserOutlet>
                    <DirectoriesOutlet>
                      <LibraryDetailsOutlet>
                        <AssetDetailsOutlet>
                          <InsightsDetailOutlet>
                            <Outlet />
                          </InsightsDetailOutlet>
                        </AssetDetailsOutlet>
                      </LibraryDetailsOutlet>
                    </DirectoriesOutlet>
                  </InitialiseUserOutlet>
                ),
                children: [
                  {
                    path: 'insights/reports/share/:reportUuid',
                    element: (
                      <Suspense fallback={<AppWithNavLoader />}>
                        <SharedInsightsReport />
                      </Suspense>
                    ),
                  },
                  {
                    path: 'insights/comparison-reports/share/:reportUuid',
                    element: (
                      <Suspense fallback={<AppWithNavLoader />}>
                        <SharedInsightsCompareReport />
                      </Suspense>
                    ),
                  },
                  {
                    path: 'insights/ads/share/:insightsOrganisationAdFacebookUuid',
                    element: (
                      <Suspense fallback={<AppWithNavLoader />}>
                        <SharedInsightsAd />
                      </Suspense>
                    ),
                  },
                  {
                    path: 'board/share/:uuid',
                    element: (
                      <Suspense fallback={<AppWithNavLoader />}>
                        <SharedDirectory basePath="/board/share" />
                      </Suspense>
                    ),
                  },
                  // Shared paths, may be either authenticated or not
                  {
                    path: 'library/share',
                    element: (
                      <Suspense fallback={<AppWithNavLoader />}>
                        <Library />
                      </Suspense>
                    ),
                    children: [
                      {
                        path: 'collection/:id',
                        element: (
                          <Suspense fallback={<AppWithNavLoader />}>
                            <RedirectCollectionToDirectory
                              by="collectionShare"
                              param="id"
                              collectionType="ad"
                              basePath="/library/d"
                            >
                              <SharedCollection />
                            </RedirectCollectionToDirectory>
                          </Suspense>
                        ),
                      },
                    ],
                  },
                  {
                    path: '/briefs/concepts/:id/share/:shareLink',
                    element: (
                      <Suspense fallback={<AppWithNavLoader />}>
                        <PublicBoard />
                      </Suspense>
                    ),
                  },
                  {
                    path: 'ad/:uuid',
                    element: (
                      <Suspense fallback={<AppWithNavLoader />}>
                        <LinkedLibraryItem />
                      </Suspense>
                    ),
                  },
                  {
                    path: 'assets/:uuid',
                    element: (
                      <Suspense fallback={<AppWithNavLoader />}>
                        <LinkedAssetItem />
                      </Suspense>
                    ),
                  },
                  {
                    path: 'briefs/:shareLink/share',
                    element: (
                      <Suspense fallback={<BriefEditorLoader />}>
                        <SharedBrief />
                      </Suspense>
                    ),
                  },
                  // Authenticated-only paths
                  {
                    path: '/',
                    element: (
                      <Suspense fallback={<AppWithNavLoader />}>
                        <RequireAuthenticatedOutlet />
                      </Suspense>
                    ),
                    children: [
                      {
                        path: 'auth-extension',
                        element: (
                          <Suspense fallback={<AuthenticationLoader />}>
                            <AuthExtension />
                          </Suspense>
                        ),
                      },
                      {
                        path: 'logout',
                        element: (
                          <Suspense fallback={<AuthenticationLoader />}>
                            <Logout />
                          </Suspense>
                        ),
                      },
                      {
                        path: 'magicAI/:id',
                        element: (
                          <Suspense fallback={<SubRouteLoader />}>
                            <MagicAi />
                          </Suspense>
                        ),
                      },
                      {
                        path: 'briefs/brands/:brandUUID/profile',
                        loader: brandProfileRouteLoader,
                        element: (
                          <Suspense fallback={<BriefEditorLoader />}>
                            <BriefBrandProfilesProvider>
                              <BrandProfile />
                            </BriefBrandProfilesProvider>
                          </Suspense>
                        ),
                      },
                      {
                        path: 'briefs/brands/:brandUUID/product/:productUUID/profile',
                        loader: productProfileRouteLoader,
                        element: (
                          <Suspense fallback={<BriefEditorLoader />}>
                            <BriefBrandProfilesProvider>
                              <ProductProfile />
                            </BriefBrandProfilesProvider>
                          </Suspense>
                        ),
                      },
                      {
                        path: 'briefs/*',
                        element: (
                          <BoardsProvider>
                            <Outlet />
                          </BoardsProvider>
                        ),
                        children: [
                          {
                            path: ':uuid',
                            element: (
                              <Suspense fallback={<BriefEditorLoader />}>
                                <Brief />
                              </Suspense>
                            ),
                          },
                          {
                            path: 'concepts/:id',
                            element: (
                              <Suspense fallback={<AppWithNavLoader />}>
                                <Board />
                              </Suspense>
                            ),
                          },
                          {
                            path: '',
                            element: (
                              <Suspense fallback={<AppWithNavLoader />}>
                                <Briefs />
                              </Suspense>
                            ),
                            children: [
                              {
                                path: '',
                                element: (
                                  <Suspense fallback={<BriefsLoader />}>
                                    <BriefList />
                                  </Suspense>
                                ),
                              },
                              {
                                path: 'd/:uuid',
                                element: (
                                  <Directory
                                    sidebar={<BriefsSidebar />}
                                    basePath="/briefs/d"
                                  />
                                ),
                              },
                              {
                                path: 'collection/:id',
                                element: (
                                  <Suspense fallback={<BriefsLoader />}>
                                    <RedirectCollectionToDirectory
                                      by="collectionUuid"
                                      param="id"
                                      collectionType="brief"
                                      basePath="/briefs/d"
                                    >
                                      <BriefList />
                                    </RedirectCollectionToDirectory>
                                  </Suspense>
                                ),
                              },
                              {
                                path: 'brands/*',
                                element: (
                                  <Suspense fallback={<BriefsLoader />}>
                                    <BriefBrandProfilesProvider>
                                      <Outlet />
                                    </BriefBrandProfilesProvider>
                                  </Suspense>
                                ),
                                children: [
                                  {
                                    path: '',
                                    element: (
                                      <Suspense fallback={<BriefsLoader />}>
                                        <BrandList />
                                      </Suspense>
                                    ),
                                  },
                                  {
                                    path: ':brandUUID',
                                    loader: productListRouteLoader,
                                    element: (
                                      <Suspense fallback={<BriefsLoader />}>
                                        <ProductList />
                                      </Suspense>
                                    ),
                                  },
                                ],
                              },
                              {
                                path: 'concepts',
                                element: (
                                  <Suspense fallback={<StoryboardsLoader />}>
                                    <BoardList />
                                  </Suspense>
                                ),
                              },
                            ],
                          },
                        ],
                      },
                      {
                        path: 'library/d/:uuid',
                        element: (
                          <Suspense fallback={<AppWithNavLoader />}>
                            <LibraryDirectory />
                          </Suspense>
                        ),
                      },
                      {
                        path: 'library/*',
                        element: (
                          <Suspense fallback={<AppWithNavLoader />}>
                            <Library />
                          </Suspense>
                        ),
                        children: [
                          {
                            path: 'discover',
                            async loader() {
                              const discoverFirstPage =
                                queryClient.getQueryData(
                                  getQueryKey(
                                    trpc.ads.getAds,
                                    {
                                      queryType: 'discover',
                                      count: PageTakeLimit,
                                      filters: InitialLibraryFilterContextValue,
                                      ruleContexts: undefined,
                                    },
                                    'infinite'
                                  )
                                );
                              if (discoverFirstPage) {
                                return discoverFirstPage;
                              }
                              void queryClient.prefetchInfiniteQuery({
                                // eslint-disable-next-line @tanstack/query/exhaustive-deps
                                queryKey: getQueryKey(
                                  trpc.ads.getAds,
                                  {
                                    queryType: 'discover',
                                    count: PageTakeLimit,
                                    filters: InitialLibraryFilterContextValue,
                                    ruleContexts: undefined,
                                  },
                                  'infinite'
                                ),
                                queryFn: async () =>
                                  trpcProxyClient.ads.getAds.query({
                                    queryType: 'discover',
                                    count: PageTakeLimit,
                                    filters: {},
                                    ruleContexts: undefined,
                                  }),
                              });
                              return null;
                            },
                            element: (
                              <Suspense fallback={<LibraryLoader />}>
                                <Discover view="discover" />
                              </Suspense>
                            ),
                          },
                          {
                            path: 'collection/:id',
                            element: (
                              <Suspense fallback={<LibraryLoader />}>
                                <RedirectCollectionToDirectory
                                  by="collectionId"
                                  param="id"
                                  collectionType="ad"
                                  basePath="/library/d"
                                ></RedirectCollectionToDirectory>
                              </Suspense>
                            ),
                          },
                          {
                            path: 'user/*',
                            element: (
                              <Suspense fallback={<LibraryLoader />}>
                                <SavedLibrary usersAdsOnly />
                              </Suspense>
                            ),
                          },
                          {
                            path: 'team/*',
                            element: (
                              <Suspense fallback={<LibraryLoader />}>
                                <SavedLibrary usersAdsOnly={false} />
                              </Suspense>
                            ),
                          },
                          {
                            path: 'packs/:uuid',
                            element: (
                              <Suspense fallback={<LibraryLoader />}>
                                <PacksCollection />
                              </Suspense>
                            ),
                          },
                          {
                            path: 'packs/*',
                            element: (
                              <Suspense fallback={<LibraryLoader />}>
                                <Packs />
                              </Suspense>
                            ),
                          },
                          {
                            path: 'brands',
                            children: [
                              {
                                path: '',
                                element: (
                                  <Suspense fallback={<BrandsLoader />}>
                                    <Brands />
                                  </Suspense>
                                ),
                              },
                              {
                                path: ':uuid',

                                element: (
                                  <Suspense fallback={<LibraryLoader />}>
                                    <BrandOverviewFeatureFlagged />
                                  </Suspense>
                                ),
                              },
                            ],
                          },
                        ],
                      },
                      {
                        path: 'assets/*',
                        element: (
                          <Suspense fallback={<AppWithNavLoader />}>
                            <Assets />
                          </Suspense>
                        ),
                        children: [
                          {
                            path: '',
                            element: (
                              <Suspense fallback={<StoryboardsLoader />}>
                                <AssetList />
                              </Suspense>
                            ),
                          },
                          {
                            path: 'collection/:uuid',
                            element: (
                              <Suspense fallback={<StoryboardsLoader />}>
                                <RedirectCollectionToDirectory
                                  by="collectionUuid"
                                  param="uuid"
                                  collectionType="user_asset"
                                  basePath="/assets/d"
                                >
                                  <AssetCollection />
                                </RedirectCollectionToDirectory>
                              </Suspense>
                            ),
                          },
                          {
                            path: 'collection/:shareLink/share',
                            element: (
                              <Suspense fallback={<BriefEditorLoader />}>
                                <RedirectCollectionToDirectory
                                  by="collectionShare"
                                  param="shareLink"
                                  collectionType="user_asset"
                                  basePath="/assets/d"
                                >
                                  <AssetCollection />
                                </RedirectCollectionToDirectory>
                              </Suspense>
                            ),
                          },
                          {
                            path: 'briefs',
                            element: (
                              <Suspense fallback={<StoryboardsLoader />}>
                                <AssetList type="briefs" />
                              </Suspense>
                            ),
                          },
                          {
                            path: 'd/:uuid',
                            element: (
                              <Directory
                                sidebar={<AssetsSidebar />}
                                basePath="/assets/d"
                              />
                            ),
                          },
                        ],
                      },
                      {
                        path: 'insights/*',
                        element: (
                          <Suspense fallback={<AppWithNavLoader />}>
                            <Insights />
                          </Suspense>
                        ),
                        children: [
                          {
                            path: 'd/:uuid',
                            element: (
                              <Directory
                                sidebar={<InsightsSidebar />}
                                basePath="/insights/d"
                              />
                            ),
                          },
                          {
                            path: 'accounts/:accountUuid',
                            element: (
                              <Suspense fallback={<InsightsLoader />}>
                                <InsightsAccount />
                              </Suspense>
                            ),
                            loader: insightsAccountRouteLoader,
                          },
                          {
                            path: 'accounts/:accountUuid/settings',
                            element: (
                              <Suspense fallback={<InsightsLoader />}>
                                <InsightsAccountSettings />
                              </Suspense>
                            ),
                          },
                          {
                            path: 'analysis/:accountUuid',
                            loader: insightsAnalysisRouteLoader,
                            element: (
                              <Suspense fallback={<InsightsAnalysisLoader />}>
                                <InsightsAnalysis />
                              </Suspense>
                            ),
                          },
                          {
                            path: 'compare/:accountUuid',
                            element: (
                              <Suspense fallback={<InsightsLoader />}>
                                <InsightsCompare />
                              </Suspense>
                            ),
                          },
                          {
                            path: 'comparison-reports/:accountUuid/:reportUuid',
                            element: (
                              <Suspense fallback={<InsightsLoader />}>
                                <InsightsCompareReport />
                              </Suspense>
                            ),
                          },
                          {
                            path: 'reports/:accountUuid/:reportUuid',
                            element: (
                              <Suspense fallback={<InsightsLoader />}>
                                <InsightsReport />
                              </Suspense>
                            ),
                          },
                          {
                            path: 'schedules/:accountUuid',
                            element: (
                              <Suspense fallback={<InsightsLoader />}>
                                <InsightsSummarySchedules />
                              </Suspense>
                            ),
                          },
                        ],
                      },
                      {
                        path: 'slack/*',
                        element: (
                          <Suspense fallback={<AppWithNavLoader />}>
                            <Slack />
                          </Suspense>
                        ),
                        children: [
                          {
                            path: 'oauth',
                            element: (
                              <Suspense fallback={<SlackLoader />}>
                                <SlackOauthExchange />
                              </Suspense>
                            ),
                          },
                          {
                            path: 'schedules',
                            element: (
                              <Suspense fallback={<InsightsLoader />}>
                                <InsightsSummarySchedules />
                              </Suspense>
                            ),
                          },
                        ],
                      },
                      {
                        path: 'tiktok/*',
                        element: (
                          <Suspense fallback={<AppWithNavLoader />}>
                            <TikTok />
                          </Suspense>
                        ),
                        children: [
                          {
                            path: 'oauth',
                            element: (
                              <Suspense fallback={<TikTokLoader />}>
                                <TikTokOauthExchange />
                              </Suspense>
                            ),
                          },
                        ],
                      },
                      {
                        path: 'slack/*',
                        element: (
                          <Suspense fallback={<AppWithNavLoader />}>
                            <Slack />
                          </Suspense>
                        ),
                        children: [
                          {
                            path: 'oauth',
                            element: (
                              <Suspense fallback={<SlackLoader />}>
                                <SlackOauthExchange />
                              </Suspense>
                            ),
                          },
                        ],
                      },
                      {
                        path: 'notifications',
                        element: getFeatureFlagValue('NOTIFICATIONS_IN_APP') ? (
                          <Suspense fallback={<AppWithNavLoader />}>
                            <Notifications />
                          </Suspense>
                        ) : (
                          <Suspense fallback={<AppWithNavLoader />}>
                            <Navigate to="/" />
                          </Suspense>
                        ),
                      },
                      {
                        path: 'organisations/*',
                        element: (
                          <Suspense fallback={<AppWithNavLoader />}>
                            <Organisations />
                          </Suspense>
                        ),
                        children: [
                          {
                            path: 'switch/:uuid',
                            element: (
                              <Suspense fallback={<SubRouteLoader />}>
                                <OrganisationSwitcher />
                              </Suspense>
                            ),
                          },
                        ],
                      },
                      // NEW SETTINGS
                      {
                        path: 'settings/*',
                        element: (
                          <Suspense fallback={<AppWithNavLoader />}>
                            <Settings />
                          </Suspense>
                        ),
                        children: [
                          {
                            path: 'profile',
                            element: (
                              <Suspense fallback={<SubRouteLoader />}>
                                <ProfileSettingsV2 />
                              </Suspense>
                            ),
                          },
                          {
                            path: 'workspace',
                            element: (
                              <Suspense fallback={<SubRouteLoader />}>
                                <WorkspaceSettingsV2 />
                              </Suspense>
                            ),
                          },
                          {
                            path: 'integrations',
                            element: (
                              <Suspense fallback={<SubRouteLoader />}>
                                <IntegrationsSettings />
                              </Suspense>
                            ),
                          },
                          {
                            path: 'billing',
                            element: (
                              <Suspense fallback={<SubRouteLoader />}>
                                <BillingSettings />
                              </Suspense>
                            ),
                          },
                          {
                            path: 'team',
                            element: (
                              <Suspense fallback={<SubRouteLoader />}>
                                <TeamSettings />
                              </Suspense>
                            ),
                          },
                        ],
                      },
                      {
                        path: '/',
                        element: <Redirector />,
                      },
                    ],
                  },
                ],
              },
              {
                path: 'invite/user',
                element: (
                  <Suspense fallback={<AuthenticationLoader />}>
                    <Invite />
                  </Suspense>
                ),
              },
              {
                path: 'email-verification',
                element: (
                  <Suspense fallback={<AuthenticationLoader />}>
                    <Authentication />
                  </Suspense>
                ),
              },

              /** Deprecated paths - required for backwards compatability, remove each one after 3 months of creating the redirect */

              {
                /** @deprecated unused, using email only auth */
                path: 'reset-password',
                loader: () => {
                  return redirect('/profile');
                },
              },
              {
                path: 'library',
                loader: () => {
                  return redirect('/library/discover');
                },
              },
              {
                path: 'settings',
                loader: () => {
                  return redirect('/settings/workspace');
                },
              },
              {
                path: 'profile',
                loader: () => {
                  return redirect('/settings/profile');
                },
              },
            ],
          },
        ],
      },
    ],
  },
];

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router: ReturnType<typeof createBrowserRouter> =
  sentryCreateBrowserRouter(routes);

function App() {
  void facebookInit();
  return (
    <GlobalProvider>
      <RouterProvider router={router} />
    </GlobalProvider>
  );
}

export default App;
