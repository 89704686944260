import { Icon } from 'src/components/Icon';
import Hash01 from 'src/assets/svgicons/duocolor/hash-01.svg';
import { useI18nContext } from 'src/i18n/i18n-react';

type Props = {
  name: string;
};

const CollectionIndicator: React.FunctionComponent<Props> = ({
  name,
}): JSX.Element => {
  const { LL } = useI18nContext();
  return (
    <div className="flex flex-col gap-2">
      <h3 className="text-sm font-semibold text-primary">
        {LL.briefs.collection()}
      </h3>
      <div className="flex flex-row items-center gap-1 text-sm text-primary">
        <Icon className="h-3.5">
          <Hash01 />
        </Icon>
        <span>{name}</span>
      </div>
    </div>
  );
};

export default CollectionIndicator;
