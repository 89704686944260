import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SimpleModal } from 'src/components/Modal';
import { useI18nContext } from 'src/i18n/i18n-react';
import { trpc } from 'src/lib/trpc';
import CreateNamedObjectModal from '../CreateNamedObjectModal/CreateNamedObjectModal';
import { useDuplicateBrief } from '../../hooks';
import CollectionIndicator from '../CollectionIndicator/CollectionIndicator';
import type { Brief } from '@magicbrief/prisma/generated/client2';

type Props = Omit<
  React.ComponentProps<typeof SimpleModal>,
  'title' | 'onClose'
> & {
  onClose: () => void;
  template: Pick<Brief, 'name' | 'uuid'>;
  collectionID?: number;
};

export const CreateBriefFromTemplateModal: React.FunctionComponent<Props> = ({
  onClose,
  collectionID,
  template,
  ...rest
}): JSX.Element => {
  const briefCollections = trpc.briefs.getBriefCollections.useQuery();
  const matchingCollection =
    collectionID && briefCollections.data
      ? briefCollections.data.find((x) => x.id === collectionID)
      : null;
  const { LL } = useI18nContext();
  const [error, setError] = useState<string | null>();
  const duplicateBrief = useDuplicateBrief();
  const navigate = useNavigate();

  const onSubmit = (name: string) => {
    duplicateBrief.mutate(
      {
        uuid: template.uuid,
        name,
        collectionID: template ? undefined : collectionID,
      },
      {
        onError: (error) => {
          setError(error.message);
        },
        onSuccess: (data) => {
          onClose();
          navigate(`/briefs/${data.uuid}`);
        },
      }
    );
  };

  return (
    <CreateNamedObjectModal
      {...rest}
      onClose={onClose}
      onSubmit={onSubmit}
      error={error}
      initialName={`${template.name} (Copy)`}
      title={LL.briefs.createBriefFromTemplate({ name: template.name })}
      isSubmitting={duplicateBrief.isLoading}
      submitLabel={LL.briefs.createBrief()}
    >
      {matchingCollection && (
        <CollectionIndicator name={matchingCollection.name} />
      )}
    </CreateNamedObjectModal>
  );
};
