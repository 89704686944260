import React, { useEffect, useState } from 'react';
import { navigatorDetector } from 'typesafe-i18n/detectors';
import { loadLocaleAsync } from '../i18n/i18n-util.async';
import { detectLocale } from '../i18n/i18n-util';
import TypesafeI18n from '../i18n/i18n-react';

const TypesafeI18nContext: React.FunctionComponent<React.PropsWithChildren> = ({
  children,
}): JSX.Element | null => {
  // Detect locale
  // (Use as advanaced locale detection strategy as you like.
  // More info: https://github.com/ivanhofer/typesafe-i18n/tree/main/packages/detectors)
  const locale = detectLocale(navigatorDetector);

  // Load locales
  // (Use a data fetching solution that you prefer)
  const [localesLoaded, setLocalesLoaded] = useState(false);
  useEffect(() => {
    void loadLocaleAsync(locale).then(() => setLocalesLoaded(true));
  }, [locale]);

  if (!localesLoaded) {
    return null;
  }

  return <TypesafeI18n locale={locale}>{children}</TypesafeI18n>;
};

export default TypesafeI18nContext;
