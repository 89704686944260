import { FC, useState } from 'react';
import Mail02 from 'src/assets/svgicons/duocolor/mail-02.svg';
import AriaModal from 'src/components/AriaModal/AriaModal';
import { AriaButton } from 'src/components/Button/Button';
import { Icon } from 'src/components/Icon';
import Spinner from 'src/components/Loaders/Spinner';
import { useI18nContext } from 'src/i18n/i18n-react';
import {
  removeInviteCodeFromLocalStorage,
  useOrganisationInvitation,
} from 'src/utils/useOrganisationInvitation';

const OrganisationInvitationModal: FC = () => {
  const { LL } = useI18nContext();
  const {
    inviteCode,
    joinWithInviteCode,
    setInviteCodes,
    organisationMetadata,
  } = useOrganisationInvitation();

  const [showModal, setShowModal] = useState(inviteCode != null);

  const onClose = () => {
    if (inviteCode != null) {
      setInviteCodes(removeInviteCodeFromLocalStorage(inviteCode));
    }

    setShowModal(false);
  };

  return (
    <AriaModal
      show={showModal}
      onClose={onClose}
      title=""
      footer={
        <>
          {organisationMetadata.data?.userInOrg ? (
            <AriaButton
              variant="primary"
              isDisabled={inviteCode == null}
              loading={joinWithInviteCode.isLoading}
              onPress={() => {
                if (inviteCode) {
                  joinWithInviteCode.mutate({ inviteCode });
                }
              }}
            >
              {LL.organisations.invitation.switch()}
            </AriaButton>
          ) : (
            <AriaButton
              variant="primary"
              isDisabled={inviteCode == null}
              loading={joinWithInviteCode.isLoading}
              onPress={() => {
                if (inviteCode) {
                  joinWithInviteCode.mutate({ inviteCode });
                }
              }}
            >
              {LL.organisations.invitation.accept()}
            </AriaButton>
          )}
        </>
      }
    >
      <div className="flex flex-col items-center">
        <div className="mb-5 flex h-12 w-12 items-center justify-center rounded-full bg-purple-200 text-primary">
          <Icon>
            <Mail02 />
          </Icon>
        </div>
        {organisationMetadata.isLoading && (
          <div className="flex flex-row items-center justify-center gap-4 text-primary">
            <Spinner />
            <span className="text-sm">
              {LL.organisations.invitation.loading()}
            </span>
          </div>
        )}
        {!organisationMetadata.isLoading && organisationMetadata.data && (
          <>
            <h2 className="text-center text-2xl font-bold leading-6 text-primary">
              {LL.organisations.invitation.title()}
              <br />
              {organisationMetadata.data.name}
            </h2>
            <p className="mt-4 px-2 text-center text-sm font-normal text-purple-700">
              {organisationMetadata.data.userInOrg
                ? LL.organisations.invitation.userInOrgDescription({
                    organisationName: organisationMetadata.data.name,
                  })
                : LL.organisations.invitation.description({
                    organisationName: organisationMetadata.data.name,
                  })}
            </p>
          </>
        )}
      </div>
    </AriaModal>
  );
};

export default OrganisationInvitationModal;
