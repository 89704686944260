import {
  ButtonHTMLAttributes,
  ComponentPropsWithoutRef,
  FC,
  forwardRef,
  PropsWithChildren,
  PropsWithRef,
  ReactNode,
} from 'react';
import classNames from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';

type SidebarComposite = {
  Content: FC<PropsWithRef<SidebarContentProps>>;
  Section: FC<SidebarSectionProps>;
  NavLink: FC<SidebarNavLinkProps>;
  Button: FC<SidebarButtonProps>;
  Divider: FC;
};

type SidebarProps = PropsWithChildren<{
  className?: string;
  side: 'left' | 'right';
}>;

const Sidebar: FC<SidebarProps> & SidebarComposite = ({
  className,
  children,
  side,
}) => {
  return (
    <div
      className={classNames(
        'border-solid border-purple-200 shadow',
        side === 'left' ? 'border-r' : 'border-l',
        className
      )}
    >
      {children}
    </div>
  );
};

type SidebarContentProps = PropsWithChildren<{
  className?: string;
}>;

const SidebarContent = forwardRef<HTMLDivElement, SidebarContentProps>(
  ({ className, children }, ref) => {
    return (
      <div
        ref={ref}
        className={classNames('flex flex-auto flex-col gap-8', className)}
      >
        {children}
      </div>
    );
  }
);

SidebarContent.displayName = 'SidebarContent';

type SidebarSectionProps = PropsWithChildren<{
  title?: ReactNode | null;
  icon?: ReactNode;
  className?: string;
  direction?: 'horizontal' | 'vertical';
  contentClassName?: string;
}>;

const SidebarSection: FC<SidebarSectionProps> = ({
  title,
  children,
  icon,
  className,
  contentClassName,
  direction = 'vertical',
}) => {
  return (
    <div
      className={classNames(
        'flex',
        direction === 'vertical'
          ? 'flex-col gap-2.5'
          : 'flex-row items-center gap-6',
        className
      )}
    >
      {(icon || title) && (
        <div className="flex flex-row items-center gap-2.5 text-primary/50">
          {icon}
          <div className="flex-auto text-xs font-bold uppercase tracking-wider">
            {title}
          </div>
        </div>
      )}
      <div className={classNames('flex flex-col gap-1', contentClassName)}>
        {children}
      </div>
    </div>
  );
};

type SidebarNavLinkProps = PropsWithChildren<
  ComponentPropsWithoutRef<typeof NavLink>
>;

const SidebarNavLink: FC<SidebarNavLinkProps> = ({ to, children, ...rest }) => {
  const location = useLocation();
  const isActive = location.pathname === to;
  return (
    <NavLink
      to={to}
      {...rest}
      onClick={(e) => {
        if (isActive) e.preventDefault(); // Prevent navigation if active
      }}
    >
      {({ isActive }) => (
        <div
          className={classNames(
            'group flex cursor-pointer flex-row items-center gap-2.5 rounded-lg bg-none p-2.5 text-sm font-semibold text-primary hover:bg-purple-100',
            isActive ? 'sidebar-nav-active bg-purple-100' : ''
          )}
        >
          {children}
        </div>
      )}
    </NavLink>
  );
};

type SidebarButtonProps = PropsWithChildren<{
  isActive?: boolean;
}> &
  ButtonHTMLAttributes<HTMLButtonElement>;

const SidebarButton: FC<SidebarButtonProps> = ({
  children,
  isActive,
  className,
  ...rest
}) => {
  return (
    <button
      {...rest}
      className={classNames(
        'group flex cursor-pointer flex-row items-center gap-2.5 rounded-lg bg-none p-2.5 text-sm font-semibold text-primary hover:bg-purple-100',
        isActive ? 'sidebar-nav-active bg-purple-100' : '',
        className
      )}
    >
      {children}
    </button>
  );
};

const SidebarDivider: FC = () => {
  return <div className="h-[1px] bg-purple-200" />;
};

Sidebar.Section = SidebarSection;
Sidebar.NavLink = SidebarNavLink;
Sidebar.Content = SidebarContent;
Sidebar.Divider = SidebarDivider;
Sidebar.Button = SidebarButton;

export default Sidebar;
