import {
  FacebookDate,
  InsightsFacebookSelectableDatePreset,
} from '@magicbrief/common';
import { ForTimePeriodInput } from '@magicbrief/server/src/insights/types';

import { useTypesafeSearchParams } from 'src/utils/useTypesafeSearchParams';

export type MetricString =
  /** @example text:      'adName|isSet' */
  | `${string}|${string}`
  /** @example text:      'adName|is|checkout%20new%20feature' */
  /** @example numerical: 'spend|equals|2.0' */
  /** @example multi:     'creativeType|in|image_video_carousel' */
  | `${string}|${string}|${string}`
  /** @example numerical: 'spend|between|2.0|3.0' */
  | `${string}|${string}|${string}|${string}`;

type InsightsSearchParams = {
  timePeriod: InsightsFacebookSelectableDatePreset | 'custom';
  since: FacebookDate | null;
  until: FacebookDate | null;
  attributionWindow: 'default' | 'custom';
  view: 'table' | 'grid';
};

const DEFAULT_INSIGHTS_SEARCH_PARAMS: InsightsSearchParams = {
  timePeriod: 'last_14d',
  since: null,
  until: null,
  attributionWindow: 'default',
  view: 'table',
};

export function getInsightsSearchParams(
  urlSearchParams: URLSearchParams
): InsightsSearchParams {
  return {
    timePeriod:
      urlSearchParams.get('timePeriod') ??
      DEFAULT_INSIGHTS_SEARCH_PARAMS['timePeriod'],
    since:
      urlSearchParams.get('since') ?? DEFAULT_INSIGHTS_SEARCH_PARAMS['since'],
    until:
      urlSearchParams.get('until') ?? DEFAULT_INSIGHTS_SEARCH_PARAMS['until'],
    attributionWindow:
      urlSearchParams.get('attributionWindow') ??
      DEFAULT_INSIGHTS_SEARCH_PARAMS['attributionWindow'],
    view: urlSearchParams.get('view') ?? DEFAULT_INSIGHTS_SEARCH_PARAMS['view'],
  } as InsightsSearchParams;
}

export function useInsightsSearchParams() {
  const search = useTypesafeSearchParams<InsightsSearchParams>(
    DEFAULT_INSIGHTS_SEARCH_PARAMS
  );

  const getParsedValues = () => {
    const timePeriod = search.get('timePeriod') ?? 'last_14d';
    const since = search.get('since');
    const until = search.get('until');
    const attributionWindow = search.get('attributionWindow') ?? 'default';

    const forTimePeriod: ForTimePeriodInput =
      timePeriod === 'custom'
        ? {
            datePreset: 'custom',
            since: since ?? '',
            until: until ?? '',
          }
        : {
            datePreset: timePeriod,
          };

    return {
      timePeriod,
      since,
      until,
      attributionWindow,
      forTimePeriod,
    };
  };

  return {
    getParsedValues,
    params: search,
  };
}
