import { useContext, useState } from 'react';
import {
  GridList,
  GridListItem,
  ListBoxItemProps,
  Selection,
} from 'react-aria-components';
import classNames from 'classnames';
import { SquareLoaders } from 'src/components/SquareLoaders';
import { trpc } from 'src/lib/trpc';
import { DirectoryAccordion } from '../DirectoryAccordion/DirectoryAccordion';
import { BoardFilterContext } from '../MoveToDirectoryModal/MoveToDirectoryModal';

type Props = {
  showRoot?: boolean;
  className?: string;
  disabledKeys?: string[];
  selectedKeys?: string[];
  onSelectionChange: (uuid: string | null) => void;
};

export const DirectoryPicker: React.FC<Props> = ({
  showRoot,
  onSelectionChange,
  disabledKeys,
  selectedKeys,
  className,
}) => {
  const [uncontrolledSelectedKeys, setSelectedKeys] = useState<Selection>(
    new Set()
  );

  const directoryStructure =
    trpc.directories.getDirectoryStructureForOrganisation.useQuery();

  if (directoryStructure.isLoading) {
    return (
      <div className="flex flex-col gap-1">
        <SquareLoaders amount={4} className="h-10" />
      </div>
    );
  }

  return (
    <DirectoryPickerList
      showRoot={showRoot}
      disabledKeys={disabledKeys}
      className={className}
      onSelectionChange={(keys) => {
        setSelectedKeys(keys);
        const selected = [...keys] as string[];
        onSelectionChange(selected[0] ?? null);
      }}
      selectedKeys={
        selectedKeys ? new Set(selectedKeys) : uncontrolledSelectedKeys
      }
      items={directoryStructure.data?.root.map((x) => ({ uuid: x })) ?? []}
    />
  );
};

type DirectoryPickerListProps = {
  showRoot?: boolean;
  selectedKeys: Selection;
  items: Array<{ uuid: string }>;
  onSelectionChange: (keys: Selection) => void;
  className?: string;
  disabledKeys?: string[] | Set<string>;
};

const DirectoryPickerList: React.FC<DirectoryPickerListProps> = ({
  items,
  selectedKeys,
  onSelectionChange,
  className,
  disabledKeys,
  showRoot,
}) => {
  return (
    <GridList
      disabledKeys={disabledKeys}
      onSelectionChange={onSelectionChange}
      selectedKeys={selectedKeys}
      selectionMode="single"
      selectionBehavior="replace"
      items={showRoot ? [{ uuid: 'root' }] : items}
      className={classNames('overflow-y-auto flex flex-col gap-1', className)}
    >
      {(item) =>
        item.uuid === 'root' ? (
          <DirectoryPickerRootListItem
            disabledKeys={disabledKeys}
            onSelectionChange={onSelectionChange}
            selectedKeys={selectedKeys}
            id={item.uuid}
            key={item.uuid}
          />
        ) : (
          <DirectoryPickerListItem
            disabledKeys={disabledKeys}
            onSelectionChange={onSelectionChange}
            selectedKeys={selectedKeys}
            id={item.uuid}
            key={item.uuid}
            uuid={item.uuid}
            level={0}
            parentDirectoryNodeUuid={null}
          />
        )
      }
    </GridList>
  );
};

type DirectoryPickerRootListItemProps = ListBoxItemProps & {
  selectedKeys: Selection;
  onSelectionChange: (keys: Selection) => void;
  disabledKeys?: string[] | Set<string>;
};

const DirectoryPickerRootListItem: React.FC<
  DirectoryPickerRootListItemProps
> = ({ selectedKeys, onSelectionChange, disabledKeys, ...rest }) => {
  const directoryStructure =
    trpc.directories.getDirectoryStructureForOrganisation.useQuery();

  const hasChildren = !!directoryStructure.data?.root.length;

  return (
    <GridListItem {...rest} className="outline-none">
      {({ isSelected, isDisabled }) => (
        <DirectoryAccordion
          parentDirectoryNodeUuid={null}
          showMenu={false}
          uuid={null}
          level={0}
          name="Root"
          isDisabled={isDisabled}
          isSelected={isSelected}
          hasChildDirectories={hasChildren}
          label={
            <span
              className={classNames(
                'align-middle italic',
                isSelected && 'font-semibold'
              )}
            >
              Root
            </span>
          }
        >
          <GridList
            disabledKeys={disabledKeys}
            onSelectionChange={onSelectionChange}
            selectedKeys={selectedKeys}
            selectionBehavior="replace"
            selectionMode="single"
            items={
              directoryStructure.data?.root.map((x) => ({
                uuid: x,
              })) ?? []
            }
            className="overflow-y-auto flex flex-col"
          >
            {(item) => (
              <DirectoryPickerListItem
                parentDirectoryNodeUuid={null}
                disabledKeys={disabledKeys}
                onSelectionChange={onSelectionChange}
                selectedKeys={selectedKeys}
                id={item.uuid}
                key={item.uuid}
                uuid={item.uuid}
                level={1}
              />
            )}
          </GridList>
        </DirectoryAccordion>
      )}
    </GridListItem>
  );
};

type DirectoryPickerListItemProps = ListBoxItemProps & {
  level: number;
  uuid: string;
  selectedKeys: Selection;
  onSelectionChange: (keys: Selection) => void;
  disabledKeys?: string[] | Set<string>;
  parentDirectoryNodeUuid: string | null;
};

const DirectoryPickerListItem: React.FC<DirectoryPickerListItemProps> = ({
  uuid,
  level,
  selectedKeys,
  onSelectionChange,
  disabledKeys,
  parentDirectoryNodeUuid,
  ...rest
}) => {
  const filterQuery = useContext(BoardFilterContext);

  const directoryStructure =
    trpc.directories.getDirectoryStructureForOrganisation.useQuery();

  const hasChildren =
    !!directoryStructure.data?.directories[uuid].children.length;

  return (
    <GridListItem {...rest} className="outline-none">
      {({ isSelected, isDisabled }) => (
        <DirectoryAccordion
          parentDirectoryNodeUuid={parentDirectoryNodeUuid}
          showMenu={false}
          uuid={uuid}
          level={level}
          name={directoryStructure.data?.directories[uuid]?.name ?? ''}
          isDisabled={isDisabled}
          isSelected={isSelected}
          hasChildDirectories={hasChildren}
          label={
            <span
              className={classNames(isSelected && 'font-semibold align-middle')}
            >
              {directoryStructure.data?.directories[uuid]?.name ??
                'Untitled Board'}
            </span>
          }
          query={filterQuery}
        >
          <GridList
            disabledKeys={disabledKeys}
            onSelectionChange={onSelectionChange}
            selectedKeys={selectedKeys}
            selectionBehavior="replace"
            selectionMode="single"
            items={
              directoryStructure.data?.directories[uuid].children.map((x) => ({
                uuid: x,
              })) ?? []
            }
            className="overflow-y-auto flex flex-col"
          >
            {(item) => (
              <DirectoryPickerListItem
                parentDirectoryNodeUuid={uuid}
                disabledKeys={disabledKeys}
                onSelectionChange={onSelectionChange}
                selectedKeys={selectedKeys}
                id={item.uuid}
                key={item.uuid}
                uuid={item.uuid}
                level={level + 1}
              />
            )}
          </GridList>
        </DirectoryAccordion>
      )}
    </GridListItem>
  );
};
