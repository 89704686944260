import {
  FC,
  lazy,
  PropsWithChildren,
  Suspense,
  useCallback,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { trpc } from 'src/lib/trpc';
import PresenceProvider from 'src/contexts/PresenceProvider';
import { useUserAndOrganisation } from '../../utils/useUserAndOrganisation';
import {
  LibraryDetailContextParams,
  LibraryDetailContextProvider,
} from './context';

const LibraryDetailsModal = lazy(
  () => import('src/components/LibraryDetailsModal/LibraryDetailsModal')
);

type Props = PropsWithChildren;

const LibraryDetailsOutlet: FC<Props> = ({ children }): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();

  const libraryUUID = searchParams.get('libraryUuid');

  const user = useUserAndOrganisation();

  const [state, setState] = useState<LibraryDetailContextParams | null>(null);

  const show = useCallback(
    (params: LibraryDetailContextParams) => {
      const queryString = window.location.search;
      const updatedSearchParams = new URLSearchParams(queryString);
      updatedSearchParams.set('libraryUuid', params.uuid);
      if (params.data?.queryID) {
        updatedSearchParams.set('queryID', params.data.queryID);
      }
      setState(params);
      setSearchParams(updatedSearchParams);
    },
    [setSearchParams]
  );

  const close = useCallback(() => {
    if (libraryUUID) {
      // Replace the current history item with just `?libraryUuid=...` so that
      // the users history will direct them to the ad with no other params set.
      const baseParams = new URLSearchParams({ libraryUuid: libraryUUID });
      history.replaceState(
        null,
        '',
        `${window.location.pathname}?${baseParams.toString()}`
      );
    }
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.delete('libraryUuid');
    updatedSearchParams.delete('view');
    updatedSearchParams.delete('carousel-asset-uuid');
    // Push the cleared query params to the history stack, important we push and not replace so that back button returns user to ad
    setSearchParams(updatedSearchParams);
    setState(null);
  }, [searchParams, setSearchParams, libraryUUID]);

  const adQuery = trpc.ads.getAdByUUID.useQuery(
    { uuid: libraryUUID ?? '' },
    {
      enabled: !!libraryUUID,
    }
  );

  const data =
    adQuery.data ??
    (state?.data?.ad.uuid === libraryUUID ? state.data.ad : null);

  const nextAd = state?.data?.pagination?.getAdAtFlatIndex(
    state.data.pagination.flatIndex + 1
  );

  const prevAd = state?.data?.pagination?.getAdAtFlatIndex(
    state.data.pagination.flatIndex - 1
  );

  const next = nextAd
    ? () => {
        const queryString = window.location.search;
        const updatedSearchParams = new URLSearchParams(queryString);
        updatedSearchParams.set('libraryUuid', nextAd.ad.uuid);
        if (nextAd.queryID) {
          updatedSearchParams.set('queryID', nextAd.queryID);
        }
        setSearchParams(updatedSearchParams);
        setState((s) => ({
          uuid: nextAd.ad.uuid,
          data: {
            ad: nextAd.ad,
            queryID: nextAd.queryID,
            pagination: s?.data?.pagination
              ? {
                  flatIndex: s?.data?.pagination?.flatIndex + 1,
                  getAdAtFlatIndex: s.data?.pagination?.getAdAtFlatIndex,
                }
              : null,
          },
        }));
      }
    : null;

  const prev = prevAd
    ? () => {
        const queryString = window.location.search;
        const updatedSearchParams = new URLSearchParams(queryString);
        updatedSearchParams.set('libraryUuid', prevAd.ad.uuid);
        if (prevAd.queryID) {
          updatedSearchParams.set('queryID', prevAd.queryID);
        }
        setSearchParams(updatedSearchParams);
        setState((s) => ({
          uuid: prevAd.ad.uuid,
          data: {
            ad: prevAd.ad,
            queryID: prevAd.queryID,
            pagination: s?.data?.pagination
              ? {
                  flatIndex: s?.data?.pagination?.flatIndex - 1,
                  getAdAtFlatIndex: s.data?.pagination?.getAdAtFlatIndex,
                }
              : null,
          },
        }));
      }
    : null;

  return (
    <LibraryDetailContextProvider value={show}>
      {!!libraryUUID && !!data && (
        <Suspense>
          {/** Note: we use organisation permissions to build presences here
           because we want to allow tagging in Comments prior to saving an ad
           this is a special case */}
          <PresenceProvider
            entityType="Organisation"
            entityUuid={user.data?.organisation.uuid}
          >
            <LibraryDetailsModal
              shallowAd={data}
              next={next}
              prev={prev}
              afterClose={close}
            />
          </PresenceProvider>
        </Suspense>
      )}
      {children}
    </LibraryDetailContextProvider>
  );
};

export default LibraryDetailsOutlet;
