import {
  ChangeEventHandler,
  MouseEventHandler,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';
import {
  offset,
  shift,
  useClientPoint,
  useFloating,
  useHover,
  useInteractions,
} from '@floating-ui/react';
import classNames from 'classnames';
import secondsToDuration from 'src/utils/secondsToDuration';

export type ProgressRef = {
  setValue(value: number): void;
};

type Props = {
  /**
   * Total duration of parent video in seconds.
   * Provide null when the duration is unknown.
   */
  duration: number | null;
  /**
   * Function called with the user selected time in seconds that the video should move to.
   */
  onTimeUpdate: (time: number) => void;
};

const Progress = forwardRef<ProgressRef, Props>(
  ({ onTimeUpdate, duration }, ref) => {
    const [value, setValue] = useState(0);

    useImperativeHandle<ProgressRef, ProgressRef>(
      ref,
      () => {
        return {
          setValue(v) {
            setValue(v);
          },
        };
      },
      []
    );

    const [showTimestamp, setShowTimestamp] = useState(false);
    const [timestamp, setTimestamp] = useState(0);

    const { refs, floatingStyles, context, isPositioned } = useFloating({
      onOpenChange: setShowTimestamp,
      placement: 'top',
      middleware: [shift(), offset(5)],
    });

    const hover = useHover(context);
    const clientPoint = useClientPoint(context, { axis: 'x' });

    const { getReferenceProps, getFloatingProps } = useInteractions([
      clientPoint,
      hover,
    ]);

    const onChangeHandler: ChangeEventHandler<HTMLInputElement> = useCallback(
      (e) => {
        onTimeUpdate(parseFloat(e.target.value));
      },
      [onTimeUpdate]
    );

    const onMouseMove: MouseEventHandler<HTMLDivElement> = useCallback(
      (e) => {
        if (duration === null) {
          return;
        }
        const rect = e.currentTarget.getBoundingClientRect();
        const x = e.clientX - rect.left; // x position within the element.
        const percent = (x >= 0 ? x : 0) / rect.width;
        setTimestamp(percent * duration);
      },
      [duration]
    );

    return (
      <div
        ref={refs.setReference}
        {...getReferenceProps()}
        onMouseMove={onMouseMove}
        className="group flex justify-end items-end h-3 relative"
      >
        <input
          type="range"
          step={0.01}
          min={0}
          value={value}
          max={duration ?? 0}
          onChange={onChangeHandler}
        />
        <progress
          value={value}
          max={duration ?? 0}
          className="[&::-webkit-progress-value]:bg-white [&::-moz-progress-bar]:bg-white appearance-none absolute overflow-hidden top-1/2 left-0 z-0 h-1 w-full -translate-y-1/2 [&::-webkit-progress-bar]:bg-white/50 bg-white/50 outline-none group-data-[focus]:ring-4 group-data-[focus]:ring-blue-400 rounded-full"
        />
        {showTimestamp && duration !== null && (
          <span
            style={floatingStyles}
            ref={refs.setFloating}
            className={classNames(
              'z-10 flex items-center justify-center drop-shadow-md absolute font-semibold rounded-lg align-middle bg-purple-800 px-1.5 leading-6 text-white transition-opacity duration-200 ease-out text-sm',
              isPositioned ? 'opacity-100' : 'opacity-0'
            )}
            {...getFloatingProps()}
          >
            {secondsToDuration(timestamp)}
          </span>
        )}
      </div>
    );
  }
);

Progress.displayName = 'Progress';

export { Progress };
