import { FC } from 'react';
import classNames from 'classnames';
import { Button } from 'react-aria-components';
import { ArrayElement } from '@magicbrief/common';
import { useUpdateBrief } from 'src/pages/Briefs/hooks';
import { Icon } from 'src/components/Icon';
import Loading02 from 'src/assets/svgicons/line/loading-02.svg';
import { AriaMenu } from 'src/components/AriaMenu/AriaMenu';
import { BRIEF_STATUS } from 'src/pages/Briefs/const';

const draftStyle =
  'border-pink-200 bg-pink-50 text-pink-700 [&svg]:bg-pink-500';

const needsApprovalStyle =
  'text-yellow-700 border-yellow-200 bg-yellow-50 [&svg]:bg-yellow-500';

const sharedStyle =
  'text-blue-700 border-blue-200 bg-blue-50 [&svg]:bg-blue-500';

// const feedbackStyle =
//   'text-purple-700 border-purple-200 bg-purple-100 [&svg]:bg-purple-500';

const completeStyle =
  'text-green-700 bg-green-50 border-green-200  [&svg]:text-green-500';

const archivedStyle =
  'text-gray-700 bg-gray-100 border-gray-200 [&svg]:bg-gray-500';

const styles: Record<string, string> = {
  Draft: draftStyle,
  Review: needsApprovalStyle,
  Shared: sharedStyle,
  Approved: completeStyle,
  Live: completeStyle,
  Complete: archivedStyle,
} satisfies Record<ArrayElement<typeof BRIEF_STATUS>, string>;

type Props = {
  status: string | null;
  uuid: string;
  className?: string;
};

const BriefStatusDisplay: FC<Props> = ({ uuid, className, status }) => {
  const update = useUpdateBrief();

  return (
    <AriaMenu className="overflow-y-auto">
      <Button
        isDisabled={update.isLoading}
        className={classNames(
          status && styles[status],
          'flex items-center gap-2 rounded-md font-semibold border border-solid px-2.5 py-1 text-xs uppercase transition-opacity box-border h-6',
          update.isLoading ? 'opacity-50' : 'opacity-100',
          className
        )}
      >
        {update.isLoading ? (
          <Icon className="h-3 w-3 animate-spin">
            <Loading02 />
          </Icon>
        ) : (
          <svg viewBox="0 0 24 24" className="w-1.5 h-1.5">
            <circle cx="12" cy="12" r="12" fill="currentColor" />
          </svg>
        )}
        <span className="whitespace-nowrap">{status ?? 'None'}</span>
      </Button>
      <AriaMenu.List
        onAction={(key) => {
          update.mutate({
            uuid,
            data: {
              arbitraryStatus: key as string,
            },
          });
        }}
      >
        {BRIEF_STATUS.map((x) => (
          <AriaMenu.Item id={x} key={x}>
            {x}
          </AriaMenu.Item>
        ))}
      </AriaMenu.List>
    </AriaMenu>
  );
};

export default BriefStatusDisplay;
