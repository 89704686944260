/* eslint-disable import/no-named-as-default-member */
import dayjs from 'dayjs';
import BigIntSupport from 'dayjs/plugin/bigIntSupport';
import RelativeTime from 'dayjs/plugin/relativeTime';
import QuarterOfYear from 'dayjs/plugin/quarterOfYear';

dayjs.extend(BigIntSupport);
dayjs.extend(RelativeTime);
dayjs.extend(QuarterOfYear);

export default dayjs;
