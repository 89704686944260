import { FC, PropsWithChildren } from 'react';
import { PermissibleEntityType } from '@magicbrief/prisma/generated/client2';
import { PresenceContext, usePresence } from './PresenceContext';

type ContextProps = {
  entityType: PermissibleEntityType;
  entityUuid?: string;
};

const PresenceProvider: FC<ContextProps & PropsWithChildren> = ({
  entityType,
  entityUuid,
  children,
}) => {
  const { presences, currentUserPresence } = usePresence({
    entityType,
    entityUuid,
  });

  return (
    <PresenceContext.Provider value={{ presences, currentUserPresence }}>
      {children}
    </PresenceContext.Provider>
  );
};

export default PresenceProvider;
