import { useCallback } from 'react';
import { inferProcedureInput } from '@trpc/server';
import { AppRouter } from '@magicbrief/server/src/trpc/router';
import * as Sentry from '@sentry/react';
import { trpc } from 'src/lib/trpc';
import { getAnonymousId } from './user';

/** @deprecated use ./useNewAnalyticsEvent */
export default function useAnalyticsEvent() {
  const { mutateAsync } = trpc.userEvents.recordEvent.useMutation();
  const recordEvent = useCallback(
    async (
      data: Omit<
        inferProcedureInput<AppRouter['userEvents']['recordEvent']>,
        'anonymousIdentifier'
      >
    ) => {
      try {
        const anonymousId = getAnonymousId();
        await mutateAsync({
          ...data,
          anonymousIdentifier: anonymousId,
        });
      } catch (e) {
        Sentry.captureException(e, (scope) => {
          scope.setTransactionName('Recording analytics event');
          scope.setExtras(data);
          return scope;
        });
      }
    },
    [mutateAsync]
  );
  return recordEvent;
}
