import { ComponentProps, FC } from 'react';
import Video from 'src/components/Video';
import useNewAnalyticsEvent from 'src/utils/useNewAnalyticsEvent';

type Props = ComponentProps<typeof Video>;

const InsightsMediaVideo: FC<Props> = ({ ...rest }) => {
  const { recordEvent: newUserEvent } = useNewAnalyticsEvent();

  const onPlayVideo = () => {
    void newUserEvent({
      target: 'Card',
      action: 'Played',
      metadata: {
        card_type: 'Insights',
        url: window.location.href,
      },
    });
  };

  return <Video onPlay={onPlayVideo} {...rest} />;
};

export default InsightsMediaVideo;
