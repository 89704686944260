import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useI18nContext } from 'src/i18n/i18n-react';
import Popover from 'src/components/Popover';
import { Icon } from 'src/components/Icon';
import Bell from 'src/assets/svgicons/duocolor/bell-01.svg';
import { SquareLoaders } from 'src/components/SquareLoaders';
import NotificationItem from 'src/components/Notifications/NotificationItem';
import { Alert } from 'src/components/Alert';
import { trpc } from 'src/lib/trpc';
import { segment } from 'src/lib/segment';
import Navigation from '../Navigation';

const NotificationsPopup: FC = () => {
  const { LL } = useI18nContext();
  const trpcUtils = trpc.useUtils();

  const getInAppNotifications =
    trpc.notifications.getLatestInAppNotifications.useQuery();

  const hasUnreadNotifications =
    getInAppNotifications.status === 'success' &&
    getInAppNotifications.data.length > 0;

  return (
    <Popover
      as={Navigation.Button}
      variant="custom"
      className="relative"
      onClick={() => !open && void segment?.track('viewed_notifications')}
      panelClassName="overflow-hidden"
      asChildren={
        <>
          {hasUnreadNotifications && (
            <span className="absolute top-1 right-2 bg-red-600 w-2 h-2 rounded-full" />
          )}
          <Icon>
            <Bell />
          </Icon>
        </>
      }
    >
      <div className="flex flex-auto flex-col max-w-2xl">
        <div className="flex flex-auto flex-col max-h-60 overflow-y-scroll">
          {getInAppNotifications.status === 'loading' && (
            <div>
              <SquareLoaders className="h-10 w-52 m-4" amount={2} />
            </div>
          )}
          {getInAppNotifications.status === 'error' && (
            <Alert type="error" className="m-2">
              {LL.notifications.error({
                error: getInAppNotifications.error.message,
              })}
            </Alert>
          )}
          {getInAppNotifications.status === 'success' &&
            (getInAppNotifications.data.length === 0 ? (
              <p className="text-purple-300 text-sm italic p-4">
                {LL.notifications.noNew()}
              </p>
            ) : (
              getInAppNotifications.data.map((notification) => (
                <NotificationItem
                  key={notification.uuid}
                  onClicked={() => {
                    void trpcUtils.notifications.getLatestInAppNotifications.invalidate();
                  }}
                  notification={notification}
                />
              ))
            ))}
        </div>
        <div className="p-4 border-t-2 border-solid border-gray-100">
          <Link className="text-primary text-sm" to={'/notifications'}>
            {LL.notifications.viewAll()}
          </Link>
        </div>
      </div>
    </Popover>
  );
};

export default NotificationsPopup;
