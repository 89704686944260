import { FC, useMemo } from 'react';
import { toast } from 'react-toastify';
import { captureException } from '@sentry/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { SimpleModal } from 'src/components/Modal';
import { useI18nContext } from 'src/i18n/i18n-react';
import { Alert } from 'src/components/Alert';
import { AriaButton } from '../../../components/Button/Button';
import { trpc } from '../../../lib/trpc';
import Input from '../../../components/Input';

type Props = {
  reportUuid: string;
  reportName: string;
  show: boolean;
  onClose: () => void;
  reportType: 'report' | 'comparison-report';
};

const RenameReportModal: FC<Props> = ({
  reportUuid,
  reportName,
  show,
  reportType,
  onClose,
}) => {
  const { LL } = useI18nContext();
  const trpcUtils = trpc.useUtils();

  const renameInsightsReport =
    trpc.insightsReports.renameInsightsReport.useMutation({
      onSuccess: () => {
        void trpcUtils.insightsReports.getManyInsightsReports.invalidate();
        void trpcUtils.insightsReports.getManyInsightsReportsAndComparisonReports.invalidate();
        void trpcUtils.insightsReports.getInsightsReport.invalidate({
          uuid: reportUuid,
        });

        onClose();
        toast.success(LL.insights.reports.renameModal.success(), {
          className: 'toast-success',
        });
      },
      onError: (error) => {
        captureException(error);
      },
    });

  const renameInsightsComparisonReport =
    trpc.insightsComparisonReports.update.useMutation({
      onSuccess: () => {
        void trpcUtils.insightsReports.getManyInsightsReportsAndComparisonReports.invalidate();
        void trpcUtils.insightsComparisonReports.getOne.invalidate({
          uuid: reportUuid,
        });
        void trpcUtils.insightsComparisonReports.getAll.invalidate();
        onClose();
        toast.success(LL.insights.reports.renameModal.success(), {
          className: 'toast-success',
        });
      },
      onError: (error) => {
        captureException(error);
      },
    });

  const validator = useMemo(
    () =>
      z.object({
        name: z.string().min(1),
      }),
    []
  );

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<z.infer<typeof validator>>({
    resolver: zodResolver(validator),
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      name: reportName,
    },
  });

  const onSubmit = handleSubmit((values) => {
    if (reportType === 'report') {
      renameInsightsReport.mutate({
        uuid: reportUuid,
        name: values.name,
      });
    } else {
      renameInsightsComparisonReport.mutate({
        uuid: reportUuid,
        name: values.name,
      });
    }
  });

  const isLoading =
    renameInsightsReport.isLoading || renameInsightsComparisonReport.isLoading;

  return (
    <SimpleModal
      title={LL.insights.reports.renameModal.title({ name: reportName })}
      show={show}
      onClose={isLoading ? null : onClose}
    >
      <form onSubmit={onSubmit}>
        <div className="flex flex-col gap-3">
          {renameInsightsReport.error && (
            <Alert type="error">
              {LL.errors.genericWithDetail({
                detail: renameInsightsReport.error.message,
              })}
            </Alert>
          )}

          <Controller
            name="name"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Input
                name="name"
                onChange={onChange}
                value={value}
                placeholder={'Name'}
                type="text"
                label=""
                className="sticky top-0 z-10 w-[190px] flex-auto bg-white lg:w-44"
              />
            )}
          />

          <AriaButton
            variant="primary"
            colour="primary"
            loading={isLoading}
            className="w-full"
            isDisabled={!isValid}
            htmlType="submit"
          >
            {LL.insights.reports.renameModal.submitBtn()}
          </AriaButton>
        </div>
      </form>
    </SimpleModal>
  );
};

export default RenameReportModal;
