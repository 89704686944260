import React, { useEffect, useState } from 'react';
import { Button } from 'react-aria-components';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useSearchParams } from 'react-router-dom';
import { captureException } from '@sentry/react';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import { InsightsAdFacebookAssetsList } from '@magicbrief/server/src/insights/types';
import { ArrayElement } from '@magicbrief/common';
import { createPortal } from 'react-dom';
import XClose from 'src/assets/svgicons/line/x-close.svg';
import { Icon } from 'src/components/Icon';
import Maximize01 from 'src/assets/svgicons/line/maximize-01.svg';
import Download01 from 'src/assets/svgicons/duotone/download-01.svg';
import Copy02 from 'src/assets/svgicons/duotone/copy-02.svg';
import ChevronDown from 'src/assets/svgicons/line/chevron-down.svg';
import ChevronLeft from 'src/assets/svgicons/duocolor/chevron-left.svg';
import ChevronRight from 'src/assets/svgicons/duocolor/chevron-right.svg';
import { AriaMenu } from 'src/components/AriaMenu/AriaMenu';
import useNewAnalyticsEvent from 'src/utils/useNewAnalyticsEvent';
import {
  hasInsightsAdPreviewAtom,
  insightsAdPreviewAtom,
  insightsAdPreviewPortalIdAtom,
} from 'src/pages/Insights/Insights.atoms';
import { useI18nContext } from 'src/i18n/i18n-react';
import Image from 'src/components/Image/Image';
import InsightsMediaVideo from './InsightsMediaVideo';
import { FacebookVideoIframe } from './FacebookVideoIframe';

export const InsightsAdPreview = () => {
  const portalId = useAtomValue(insightsAdPreviewPortalIdAtom);
  const previewEl = document.getElementById(portalId ?? '');

  if (portalId && previewEl) {
    return <>{createPortal(<InsightsAdPreviewContent />, previewEl)}</>;
  }

  // If no element ID is provided, we render the component in place to the DOM
  return <InsightsAdPreviewContent />;
};

const InsightsAdPreviewContent = () => {
  const { LL } = useI18nContext();
  const { recordEvent } = useNewAnalyticsEvent();

  const [searchParams] = useSearchParams();

  const hasInsightsAdPreview = useAtomValue(hasInsightsAdPreviewAtom);
  const [insightsAdPreview, setInsightsAdPreview] = useAtom(
    insightsAdPreviewAtom
  );

  const [assetIndex, setAssetIndex] = useState(0);

  const isCarousel = insightsAdPreview?.creativeType === 'carousel';
  const asset = insightsAdPreview?.assets
    ? insightsAdPreview?.assets[assetIndex]
    : null;
  const thumbnailUrl = asset?.thumbnailUrl ?? '';

  const getAssetDownloadUrl = () => {
    const asset = insightsAdPreview?.assets?.[assetIndex];

    switch (asset?.mediaType) {
      case 'image': {
        return asset.cachedUrl ?? asset.cachedThumbnailUrl ?? '';
      }
      case 'video':
        return asset.cachedUrl ?? '';
      default:
        return '';
    }
  };

  const intercomZIndex = getZIndexByClassName('intercom-lightweight-app');
  const previewZIndex = intercomZIndex ? intercomZIndex + 1 : '1000';

  // If we open the Ad Details view for any ad, close any previews
  useEffect(() => {
    if (searchParams.get('insightsUuid')) {
      setInsightsAdPreview(null);
    }
  }, [searchParams, setInsightsAdPreview]);

  //  Allows user to close the ad preview with the Escape key
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setInsightsAdPreview(null);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [setInsightsAdPreview]);

  //  Resets the carousel index to the first asset when changing ad preview
  useEffect(() => setAssetIndex(0), [insightsAdPreview]);

  if (!hasInsightsAdPreview || !insightsAdPreview?.assets) return null;

  return (
    <div
      id="insights-ad-preview"
      className={clsx(
        /** Base */
        'fixed bottom-0 right-4 flex h-auto w-[300px] flex-col gap-3 rounded-t-xl border border-solid border-purple-400 bg-white px-3 pb-6 pt-3 shadow-2xl',
        /** Animation */
        'transition-all duration-200 ease-out animate-in slide-in-from-bottom'
      )}
      style={{ zIndex: previewZIndex }}
    >
      <div className="flex items-center justify-between">
        <div className="flex rounded-lg">
          <ViewAdDetailsButton />

          <AriaMenu style={{ zIndex: previewZIndex }}>
            <Button className="flex min-w-8 items-center justify-center gap-1.5 rounded-r-lg border-y border-r border-solid border-purple-200 py-1 pl-3 pr-1.5 hover:bg-purple-50 focus:outline-none">
              <Icon className="size-5 text-primary">
                <Download01 />
              </Icon>
              <Icon className="size-4 text-primary">
                <ChevronDown />
              </Icon>
            </Button>

            <AriaMenu.List>
              <AriaMenu.Item
                onAction={async () => {
                  try {
                    const blob = await fetch(thumbnailUrl).then((res) =>
                      res.blob()
                    );

                    const isJpegBlob = blob.type.includes('jpeg');
                    const isPngBlob = blob.type.includes('png');

                    if (isJpegBlob) {
                      const pngBlob = await convertBlobToPng(blob);
                      await copyBlobToClipboard(pngBlob);
                      toast.success(LL.ad.copyThumbnailSuccess());
                      return;
                    } else if (isPngBlob) {
                      await copyBlobToClipboard(blob);
                      toast.success(LL.ad.copyThumbnailSuccess());
                      return;
                    } else {
                      throw new Error('Error copying thumbnail to clipboard');
                    }
                  } catch (error) {
                    toast.error(
                      LL.ad.copyThumbnailError({
                        error:
                          error instanceof Error
                            ? error.message
                            : 'Unknown error',
                      })
                    );
                    captureException(error, (scope) => {
                      scope.setTransactionName('Copy Preview Thumbnail');
                      scope.setExtras({
                        uuid: insightsAdPreview?.uuid,
                        thumbnail: thumbnailUrl,
                      });
                      return scope;
                    });
                  }
                }}
                icon={
                  <Icon>
                    <Copy02 />
                  </Icon>
                }
              >
                {LL.ad.copyThumbnail()}
              </AriaMenu.Item>

              <AriaMenu.Item
                href={getAssetDownloadUrl()}
                target="_blank"
                rel="noopener noreferrer"
                onAction={() =>
                  void recordEvent({
                    target: 'Insights Ad',
                    action: 'Downloaded asset',
                    metadata: {
                      uuid: insightsAdPreview?.uuid,
                    },
                  })
                }
                icon={
                  <Icon>
                    <Download01 />
                  </Icon>
                }
              >
                {LL.ad.downloadAsset()}
              </AriaMenu.Item>
            </AriaMenu.List>
          </AriaMenu>
        </div>

        <ClosePreviewButton />
      </div>

      <div className="flex size-full items-center overflow-hidden rounded-md border border-solid border-purple-200">
        {isCarousel ? (
          <CarouselPreview
            assets={insightsAdPreview?.assets}
            index={assetIndex}
            setIndex={setAssetIndex}
          />
        ) : (
          <SinglePreview asset={insightsAdPreview?.assets?.[0]} />
        )}
      </div>
    </div>
  );
};

const ViewAdDetailsButton = () => {
  const { recordEvent } = useNewAnalyticsEvent();
  const [, setSearchParams] = useSearchParams();

  const insightsAdPreview = useAtomValue(insightsAdPreviewAtom);

  const handleOpenDetailsView = () => {
    if (insightsAdPreview?.uuid) {
      void recordEvent({
        target: 'Insights Preview',
        action: 'Opened',
        metadata: {
          card_type: 'Insight',
          object_url: `${window.location.href}?insightsUuid=${insightsAdPreview.uuid}`,
        },
      });

      setSearchParams((searchParams) => {
        searchParams.set('insightsUuid', insightsAdPreview.uuid);
        return searchParams;
      });
    }
  };

  return (
    <Button
      className="flex items-center justify-center rounded-l-lg border border-solid border-purple-200 px-3 py-2 data-[disabled]:cursor-not-allowed hover:bg-purple-50 focus:outline-none"
      onPress={handleOpenDetailsView}
    >
      <Icon className="size-[18px] text-primary">
        <Maximize01 />
      </Icon>
    </Button>
  );
};

const ClosePreviewButton = () => {
  const setInsightsAdPreview = useSetAtom(insightsAdPreviewAtom);

  return (
    <Button
      className="flex items-center gap-1 rounded-lg border border-solid border-purple-200 bg-white p-2 transition-colors duration-300 hover:bg-purple-50 focus:outline-none focus-visible:outline-none focus-visible:ring focus-visible:ring-secondary"
      onPress={() => setInsightsAdPreview(null)}
    >
      <span className="text-xs font-semibold text-purple-400">ESC</span>
      <Icon className="size-[18px] text-primary">
        <XClose />
      </Icon>
    </Button>
  );
};

const SinglePreview = ({
  asset,
}: {
  asset: ArrayElement<InsightsAdFacebookAssetsList>;
}) => {
  const thumbnailUrl = asset?.cachedThumbnailUrl ?? asset?.thumbnailUrl ?? '';
  const url =
    asset?.mediaType === 'image'
      ? asset?.cachedUrl || asset.thumbnailUrl || asset?.url
      : asset?.cachedUrl;

  const isImage = asset?.mediaType === 'image' && url != null;
  const isCachedVideo = asset?.mediaType === 'video' && url != null;
  const isIframeVideo =
    asset?.mediaType === 'video' && url == null && asset?.embedHtml != null;

  return (
    <>
      {isImage && (
        <Image className="w-full" src={url} fallbackSrc={url} alt="" />
      )}
      {isCachedVideo && (
        <InsightsMediaVideo
          id="insights-video-preview"
          poster={thumbnailUrl}
          sources={[
            {
              type: 'video/mp4',
              src: url,
            },
          ]}
          shouldShowControls
          autoPlay
        />
      )}

      {isIframeVideo && (
        <FacebookVideoIframe
          className="z-2 relative"
          html={asset?.embedHtml ?? ''}
          height={null}
          width={null}
        />
      )}
    </>
  );
};

type CarouselPreviewProps = {
  assets: InsightsAdFacebookAssetsList;
  index: number;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
};

const CarouselPreview = ({ assets, index, setIndex }: CarouselPreviewProps) => {
  const next = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (index === assets.length - 1) {
      return;
    }
    setIndex((current) => current + 1);
  };

  const prev = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (index === 0) {
      return;
    }
    setIndex((current) => current - 1);
  };

  return (
    <div className="relative">
      <div className="flex items-center">
        {index < assets.length - 1 && (
          <button
            className="absolute right-1 z-10 flex h-6 w-6 cursor-pointer select-none appearance-none items-center justify-center rounded-md bg-white shadow"
            onClick={next}
          >
            <Icon className="text-primary">
              <ChevronRight />
            </Icon>
          </button>
        )}
        <SinglePreview asset={assets[index]} />
        {index !== 0 && (
          <button
            className="absolute left-1 z-10 flex h-6 w-6 cursor-pointer select-none appearance-none items-center justify-center rounded-md bg-white shadow"
            onClick={prev}
          >
            <Icon className="text-primary">
              <ChevronLeft />
            </Icon>
          </button>
        )}
      </div>
    </div>
  );
};

async function copyBlobToClipboard(blob: Blob): Promise<void> {
  const items = { [blob.type]: blob } as unknown as Record<
    string,
    ClipboardItemData
  >;

  const clipboardItem = new ClipboardItem(items);
  await navigator.clipboard.write([clipboardItem]);
}

async function convertBlobToPng(imageBlob: Blob): Promise<Blob> {
  const imageSource = URL.createObjectURL(imageBlob);
  const imageElement = await createImageElement(imageSource);
  const blob = await getBlobFromImageElement(imageElement);
  URL.revokeObjectURL(imageSource);
  return blob;
}

async function createImageElement(
  imageSource: string
): Promise<HTMLImageElement> {
  return new Promise(function (resolve, reject) {
    const imageElement = document.createElement('img');
    imageElement.crossOrigin = 'anonymous';
    imageElement.src = imageSource;

    imageElement.onload = function (event) {
      const target = event.target as HTMLImageElement;
      resolve(target);
    };

    imageElement.onabort = reject;
    imageElement.onerror = reject;
  });
}

async function getBlobFromImageElement(
  imageElement: HTMLImageElement
): Promise<Blob> {
  return new Promise(function (resolve, reject) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    if (context) {
      const { width, height } = imageElement;
      canvas.width = width;
      canvas.height = height;
      context.drawImage(imageElement, 0, 0, width, height);

      canvas.toBlob(
        function (blob) {
          if (blob) resolve(blob);
          else reject('Cannot get blob from image element');
        },
        'image/png',
        1
      );
    }
  });
}

function getZIndexByClassName(className: string) {
  const element = document.querySelector(`.${className}`);

  if (element) {
    const style = window.getComputedStyle(element);
    return style.zIndex;
  }

  return null;
}
