import {
  ButtonMenuItem,
  ExternalLinkMenuItem,
  ReactRouterLinkMenuItem,
} from './Menu';

export const MenuItem = {
  ExternalLink: ExternalLinkMenuItem,
  ReactRouterLink: ReactRouterLinkMenuItem,
  Button: ButtonMenuItem,
};
