import { FC } from 'react';
import Translate from 'src/components/HOCs/Translate';
import { useParseMetric } from '../util/useParseMetric';

type Props = {
  metric: string;
};

const MetricDefinition: FC<Props> = ({ metric }) => {
  const { getMetricDefinitions } = useParseMetric();
  const definition = getMetricDefinitions(metric);

  if (!definition) return null;

  return (
    <span className="max-w-72 text-wrap opacity-80">
      <Translate
        text={definition}
        renderComponent={[
          (equation) => (
            <>
              <br />
              <br />
              <span className="text-nowrap rounded-md border border-solid border-[#3B277F] bg-[#211251] p-1 font-mono uppercase">
                {equation}
              </span>
            </>
          ),
        ]}
      />
    </span>
  );
};

export default MetricDefinition;
