import { createContext, useContext } from 'react';
import { GetFacebookAdWithInsightsResponse } from 'src/types/insights';

type InsightsDetailContextData = {
  ad: GetFacebookAdWithInsightsResponse;
  pagination: {
    flatIndex: number;
    getAdAtFlatIndex: (
      index: number
    ) => { ad: GetFacebookAdWithInsightsResponse } | null;
  } | null;
};

export type InsightsDetailContextParams = {
  uuid: string;
  data: InsightsDetailContextData | null;
};

type InsightsDetailContextValue = (params: InsightsDetailContextParams) => void;

const InsightsDetailContext = createContext<InsightsDetailContextValue | null>(
  null
);

export function useInsightsDetailContext() {
  const context = useContext(InsightsDetailContext);

  if (!context) {
    throw new Error(
      'useInsightsDetailContext can only be called within a InsightsDetailContextProvider'
    );
  }

  return context;
}

export const InsightsDetailContextProvider = InsightsDetailContext.Provider;
