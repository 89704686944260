import { FC, PropsWithChildren, useReducer } from 'react';
import {
  GlobalDispatchContext,
  GlobalStateContext,
  GlobalContextValue,
  GlobalContextAction,
} from './context';

const reducer: React.Reducer<GlobalContextValue, GlobalContextAction> = (
  state,
  action
) => {
  switch (action.type) {
    case 'setAssetsUploads':
      return {
        ...state,
        assets: { ...state.assets, activeUploads: action.payload },
      };
  }
};

export const GlobalProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    assets: {
      activeUploads: [],
    },
  });

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};
