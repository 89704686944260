import { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';

type Colour =
  | 'default'
  | 'primary'
  | 'success'
  | 'warning'
  | 'danger'
  | 'info'
  | 'light'
  | 'dark';

type Variant = 'default' | 'pill';

type Props = {
  variant?: Variant;
  color?: Colour;
  className?: string;
  contentClassName?: string;
} & PropsWithChildren;

const Badge: FC<Props> = ({
  children,
  variant = 'default',
  color = 'default',
  className,
  contentClassName,
}) => {
  return (
    <div
      className={classNames(
        'flex w-auto border border-solid px-1 py-0.5 text-xs font-semibold',
        variant === 'default' && 'rounded-md',
        variant === 'pill' && 'rounded-full',
        color === 'default' && 'border-none',
        color === 'primary' && 'border-purple-200 bg-purple-50 text-purple-800',
        color === 'success' && 'border-green-200 bg-green-50 text-green-700',
        color === 'warning' && 'border-yellow-200 bg-yellow-50 text-yellow-700',
        color === 'danger' && 'border-red-200 bg-red-50 text-red-700',
        color === 'info' && 'border-blue-200 bg-blue-50 text-blue-700',
        color === 'light' && 'border-gray-200 bg-gray-50 text-gray-700',
        color === 'dark' && 'border-black bg-black text-white',
        className
      )}
    >
      <div
        className={classNames(
          'flex flex-row items-center gap-1',
          contentClassName
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default Badge;
