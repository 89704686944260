import { useCallback, useState } from 'react';
import { useI18nContext } from 'src/i18n/i18n-react';
import { SimpleModal } from 'src/components/Modal';
import CreateNamedObjectModal from '../CreateNamedObjectModal/CreateNamedObjectModal';
import { useCreateBriefCollection } from '../../hooks';

type Props = Omit<
  React.ComponentProps<typeof SimpleModal>,
  'title' | 'onClose'
> & { onClose: () => void };

const CreateBriefCollectionModal: React.FunctionComponent<Props> = ({
  onClose,
  ...rest
}): JSX.Element => {
  const { LL } = useI18nContext();
  const [error, setError] = useState<string | null>();
  const createCollection = useCreateBriefCollection();

  const onSubmit = useCallback(
    async (name: string) => {
      createCollection.mutate(
        { name },
        {
          onError: (error) => {
            setError(error.message);
          },
          onSuccess: () => {
            onClose();
          },
        }
      );
    },
    [createCollection, onClose]
  );

  return (
    <CreateNamedObjectModal
      {...rest}
      onClose={onClose}
      onSubmit={onSubmit}
      error={error}
      title={LL.briefs.createCollection()}
      isSubmitting={createCollection.isLoading}
      submitLabel={LL.briefs.createCollection()}
    />
  );
};

export default CreateBriefCollectionModal;
