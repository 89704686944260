import { useUserAndOrganisation } from './useUserAndOrganisation';
import type { Entitlement } from '@magicbrief/server/src/lib/entitlements/global';

export const useEntitlementQuotas = (entitlement: Entitlement) => {
  const user = useUserAndOrganisation();
  const quotas = user.data?.quota ?? {};
  const quota = quotas?.[entitlement];
  const hasEntitlement = user.data?.entitlements.includes(entitlement);

  return {
    hasEntitlement,
    quota,
  };
};
