type VideoBroadcastChannelEvent = MessageEvent<VideoEventData>;

export type VideoEventData = {
  type: 'playbackStarted';
  targetId: string;
};

export class VideoEventChannel {
  #channel: BroadcastChannel | null = null;
  // Been stung by users with Safari < 15 before where BroadcastChannel
  // is not available, so provide event listeners as a fallback
  #legacyListener: ((e: CustomEvent<VideoEventData>) => void) | null = null;

  dispatch(data: VideoEventData) {
    if (this.#channel) {
      this.#channel.postMessage(data);
      return;
    }
    window.dispatchEvent(
      new CustomEvent<VideoEventData>('videoLegacyEvent', { detail: data })
    );
  }

  listen(callback: (event: VideoEventData) => void) {
    if (window.BroadcastChannel) {
      if (this.#channel === null) {
        this.#channel = new BroadcastChannel('videoBroadcastChannel');
      }
      this.#channel.onmessage = (e: VideoBroadcastChannelEvent) =>
        callback(e.data);
      return;
    }
    if (this.#legacyListener) {
      window.removeEventListener('videoLegacyEvent', this.#legacyListener);
    }
    this.#legacyListener = (e: CustomEvent<VideoEventData>) => {
      callback(e.detail);
    };
    window.addEventListener('videoLegacyEvent', this.#legacyListener);
  }

  close() {
    if (this.#channel) {
      this.#channel.close();
      this.#channel = null;
    }
    if (this.#legacyListener) {
      window.removeEventListener('videoLegacyEvent', this.#legacyListener);
      this.#legacyListener = null;
    }
  }
}
