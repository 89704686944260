import { useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getQueryKey } from '@trpc/react-query';
import { captureException } from '@sentry/react';
import { trpc } from '../lib/trpc';
import { check as churnkeyCheck } from '../lib/churnkey';
import useFeatureFlag from './useFeatureFlag';

type Props = {
  show: boolean;
  stripeCustomerID?: string | null;
};

export function useChurnkeyCheck({ show, stripeCustomerID }: Props) {
  const [isOpen, setIsOpen] = useState(false);

  const queryClient = useQueryClient();

  const churnkeyAppId = useFeatureFlag('CHURNKEY_APP_ID');

  const customerHash = trpc.billing.createCustomerHash.useQuery(undefined, {
    enabled: show,
  });
  const isLoading = customerHash.isLoading;

  const authHash = customerHash.data;

  useQuery({
    enabled: !!churnkeyAppId && !!stripeCustomerID && !!authHash && show,
    queryKey: ['churnkeyCheck', churnkeyAppId, stripeCustomerID, authHash],
    queryFn: () => {
      if (!churnkeyAppId || !stripeCustomerID || !authHash) {
        return null;
      }

      const onChurnkeyEvent = () => {
        void queryClient.invalidateQueries(
          getQueryKey(trpc.organisation.getOrganisation, undefined, 'query')
        );
      };

      const appId = churnkeyAppId;
      const mode =
        import.meta.env.VITE_DEPLOYMENT === 'production' ? 'live' : 'test';

      churnkeyCheck({
        appId,
        customerId: stripeCustomerID,
        authHash,
        mode,
        provider: 'stripe',
        softWall: false,
        forceCheck: false,
        onPauseWallActivated: () => setIsOpen(true),
        onCancel: onChurnkeyEvent,
        onResume: onChurnkeyEvent,
        onPauseWallClose: () => setIsOpen(false),
        onError: (error, type) => {
          captureException(error, (scope) => {
            scope.setExtras({
              type,
            });
            scope.setTransactionName('churnkey -> check');
            return scope;
          });
          onChurnkeyEvent();
          setIsOpen(false);
        },
      });

      return true;
    },
  });

  return { isOpen, isLoading };
}
