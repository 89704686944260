import { AppRouter } from '@magicbrief/server/src/trpc/router';
import { getQueryKey } from '@trpc/react-query';
import { inferProcedureOutput } from '@trpc/server';
import { LoaderFunctionArgs } from 'react-router-dom';
import queryClient from 'src/lib/queryClient';
import { trpc } from 'src/lib/trpc';

export async function brandProfileRouteLoader({ params }: LoaderFunctionArgs) {
  const brandProfiles = queryClient.getQueryData<
    inferProcedureOutput<AppRouter['brandProfiles']['getBrandProfiles']>
  >(getQueryKey(trpc.brandProfiles.getBrandProfiles, undefined, 'query'));
  return brandProfiles?.find((x) => x.uuid === params.brandUUID) ?? null;
}
