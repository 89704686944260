import { FC } from 'react';
import RefreshCw02 from 'src/assets/svgicons/line/refresh-cw-02.svg';
import { AriaButton } from 'src/components/Button/Button';
import { Icon } from 'src/components/Icon';
import useNewAnalyticsEvent from 'src/utils/useNewAnalyticsEvent';
import { useRefetchData } from '../../util/useInsightsQueries';

const InsightsRefreshDataButton: FC = () => {
  const { refetch, isDisabled, isProcessing, isLoading } = useRefetchData();
  const { recordEvent } = useNewAnalyticsEvent();

  return (
    <AriaButton
      data-intercom-target="insight_refresh"
      variant="white"
      onPress={() => {
        refetch();
        void recordEvent({
          action: 'Sync clicked',
          target: 'Insights Account',
        });
      }}
      loading={isProcessing || isLoading}
      isDisabled={isDisabled}
      icon={
        <Icon>
          <RefreshCw02 />
        </Icon>
      }
    />
  );
};

export default InsightsRefreshDataButton;
