import { FC, MouseEventHandler, PropsWithChildren, ReactNode } from 'react';
import classNames from 'classnames';
import { Icon } from 'src/components/Icon';

const BaseCardHeaderTitleContent: FC<
  PropsWithChildren<{
    onClick?: MouseEventHandler<HTMLButtonElement>;
    className: string;
  }>
> = ({ children, onClick, className }) => {
  if (onClick) {
    return (
      <button
        onClick={onClick}
        className={classNames(
          'grow cursor-pointer appearance-none text-left focus-visible:outline-0',
          className
        )}
      >
        {children}
      </button>
    );
  }
  return <div className={className}>{children}</div>;
};

type Props = PropsWithChildren<{
  onClick?: (shiftKey: boolean) => void;
  paywalled?: boolean;
  logoSrc?: string;
  logoIcon?: ReactNode;
  title: ReactNode;
  subheader?: ReactNode;
}>;

const BaseCardHeader: FC<Props> = ({
  children,
  onClick,
  paywalled,
  logoSrc,
  logoIcon,
  title,
  subheader,
}) => {
  return (
    <div className="group/header flex h-10 items-center justify-between">
      <div className="flex w-full items-center justify-between">
        <BaseCardHeaderTitleContent
          onClick={(event) => onClick?.(event.shiftKey)}
          className={classNames(
            'flex items-center justify-between gap-2.5 truncate',
            paywalled && 'blur-sm'
          )}
        >
          {logoIcon ? (
            <Icon className="h-9 w-9 shrink-0 overflow-hidden rounded-full bg-purple-300 p-1.5 text-white">
              {logoIcon}
            </Icon>
          ) : logoSrc ? (
            <div className="h-9 w-9 shrink-0 overflow-hidden rounded-lg bg-purple-50">
              <img
                className="h-full object-cover"
                src={logoSrc}
                alt="brand logo"
              />
            </div>
          ) : null}
          <div className={classNames('flex min-w-0 flex-grow flex-col')}>
            <h3 className="min-w-0 truncate text-sm font-semibold text-primary">
              {title}
            </h3>
            <div className="flex items-center gap-1.5 text-xxs font-medium text-[#3D1CAF]/60">
              {subheader}
            </div>
          </div>
        </BaseCardHeaderTitleContent>
        {children}
      </div>
    </div>
  );
};

export default BaseCardHeader;
