import { useMatch, useParams } from 'react-router-dom';
import {
  convertSimpleInsightsFiltersToExpression,
  type InsightsFacebookFilter,
  type InsightsFacebookFilterExpression,
} from '@magicbrief/common';
import { INSIGHTS_PAGE_TAKE_LIMIT } from '../../util/constants';
import { useInsightsSearchParams } from '../../util/useInsightsSearchParams';
import {
  useInsightsFilter,
  useInsightsGroup,
  useInsightsSort,
} from '../../util/useInsightsPersistentState';
import type { InsightsFacebookAdGroupWithMetrics } from '@magicbrief/server/src/insights/types';
import type { GetManyFacebookAdsWithInsightsV2Input } from '@magicbrief/server/src/insights/insights.trpc';

interface Props {
  adGroup: InsightsFacebookAdGroupWithMetrics;
  expression?: InsightsFacebookFilter[] | InsightsFacebookFilterExpression;
  comparisonId?: string;
}

export const useAdBreakdownQueryParams = ({
  adGroup,
  expression,
  comparisonId,
}: Props) => {
  const { accountUuid, reportUuid } = useParams();
  const { forTimePeriod, attributionWindow } =
    useInsightsSearchParams().getParsedValues();
  const sort = useInsightsSort();
  const group = useInsightsGroup();
  const filter = useInsightsFilter();

  const isSharedReport = useMatch('/insights/reports/share/:reportUuid');
  const isSharedComparisonReport = useMatch(
    '/insights/comparison-reports/share/:reportUuid'
  );

  let params: GetManyFacebookAdsWithInsightsV2Input;

  switch (true) {
    case !!isSharedReport:
      params = {
        count: INSIGHTS_PAGE_TAKE_LIMIT,
        type: 'report',
        reportUuid: reportUuid ?? '',
        ungroup:
          !expression && group
            ? {
                group,
                value: adGroup.group,
              }
            : undefined,
      };
      break;

    case !!isSharedComparisonReport:
      params = {
        count: INSIGHTS_PAGE_TAKE_LIMIT,
        type: 'comparison-report',
        reportUuid: reportUuid ?? '',
        ungroup: {
          group: 'true',
          value: adGroup.group,
        },
        comparisonId: comparisonId ?? '',
      };
      break;

    default:
      params = {
        count: INSIGHTS_PAGE_TAKE_LIMIT,
        type: 'account',
        insightsAdAccountFacebookUuid: accountUuid ?? '',
        filter: expression
          ? {
              expression: convertSimpleInsightsFiltersToExpression(expression),
              forTimePeriod,
            }
          : {
              forTimePeriod,
              metric: filter,
            },
        sort,
        attributionWindow,
        ungroup:
          !expression && group
            ? {
                group,
                value: adGroup.group,
              }
            : undefined,
      };
      break;
  }

  return params;
};
