import Cookies from 'js-cookie';

interface Referrer {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_term?: string;
  device?: string;
  gclid?: string;
  gaclientID?: string;
  referrer?: string;
  pathReferrer?: string;
  fbclid?: string;
  documentReferrer: string;
}

function parseSbjsCookie(cookie: string): Record<string, string> {
  const pairs = cookie.split('|||');
  const result: Record<string, string> = {};
  pairs.forEach((pair) => {
    const [key, value] = pair.split('=');
    result[key] = value;
  });
  return result;
}

// this will get the existing SBJS cookie for the first land for the user
function getSBJSCookie(): string | null {
  try {
    const cookie = document.cookie
      .split('; ')
      .find((row) => row.startsWith('sbjs_first='));
    return cookie ? decodeURIComponent(cookie.split('=')[1]) : null;
  } catch (e) {
    return null;
  }
}

function getGAClientId(): string | undefined {
  try {
    const gaCookie = document.cookie
      .split('; ')
      .find((row) => row.startsWith('_ga='));

    if (!gaCookie) return undefined;

    const gaParts = gaCookie.split('.');
    if (gaParts.length < 4) return undefined;

    return `${gaParts[2]}.${gaParts[3]}`;
  } catch (e) {
    return;
  }
}

export const getAttributionData = (): Referrer | null => {
  const referrerDataJson = Cookies.get('referrer');
  if (!referrerDataJson) {
    return null;
  }
  try {
    const referrerData: Referrer = JSON.parse(referrerDataJson);
    return referrerData;
  } catch (error) {
    return null;
  }
};

export const setAttributionData = () => {
  let referrer: Referrer = {
    documentReferrer: document.referrer,
  };
  const prevRef = Cookies.get('referrer');
  if (prevRef) {
    referrer = { ...referrer, ...(JSON.parse(prevRef) as Referrer) };
  }
  referrer.documentReferrer = document.referrer;
  referrer.gaclientID = getGAClientId();

  const sbjsCookie = getSBJSCookie();
  if (sbjsCookie) {
    const sbjsValues = parseSbjsCookie(sbjsCookie);
    referrer = {
      ...referrer,
      utm_source: sbjsValues.src,
      utm_medium: sbjsValues.mdm,
      utm_campaign: sbjsValues.cmp,
      utm_content: sbjsValues.cnt,
      utm_term: sbjsValues.trm,
    };
  } else {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('ref')) {
      referrer = { ...referrer, referrer: urlParams.get('ref') ?? undefined };
    }
    if (urlParams.get('utm_source')) {
      referrer = {
        ...referrer,
        utm_source: urlParams.get('utm_source') ?? undefined,
        utm_medium: urlParams.get('utm_medium') ?? undefined,
        utm_campaign: urlParams.get('utm_campaign') ?? undefined,
        utm_content: urlParams.get('utm_content') ?? undefined,
        utm_term: urlParams.get('utm_term') ?? undefined,
        device: urlParams.get('device') ?? undefined,
        gclid: urlParams.get('gclid') ?? undefined,
        fbclid: urlParams.get('fbclid') ?? undefined,
      };
    }
  }

  const pathRef =
    window.location.pathname.indexOf('/r/') === -1
      ? undefined
      : window.location.pathname.split('/r/').pop();
  if (pathRef != null) {
    referrer = { ...referrer, pathReferrer: pathRef };
  }

  Cookies.set('referrer', JSON.stringify(referrer), {});

  return referrer;
};
