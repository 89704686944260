import { LoaderFunctionArgs } from 'react-router-dom';
import { getQueryKey } from '@trpc/react-query';
import { trpc, trpcProxyClient } from 'src/lib/trpc';
import queryClient from 'src/lib/queryClient';
import { auth } from 'src/lib/firebase';
import { getInsightsSearchParams } from '../../util/useInsightsSearchParams';

export async function insightsAnalysisRouteLoader({
  params,
  request,
}: LoaderFunctionArgs) {
  const accountUuid = params.accountUuid ?? '';
  const queryParams = getInsightsSearchParams(
    new URL(request.url).searchParams
  );

  const forTimePeriod =
    queryParams.timePeriod === 'custom'
      ? {
          datePreset: 'custom' as const,
          since: queryParams.since ?? '',
          until: queryParams.until ?? '',
        }
      : {
          datePreset: queryParams.timePeriod,
        };

  await auth.authStateReady();

  void queryClient.prefetchQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: getQueryKey(
      trpc.insights.getSprintCounts,
      { uuid: accountUuid, forTimePeriod },
      'query'
    ),
    queryFn: async () =>
      trpcProxyClient.insights.getSprintCounts.query(
        { uuid: accountUuid, forTimePeriod },
        { context: { skipBatch: true } }
      ),
  });

  void queryClient.prefetchQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: getQueryKey(
      trpc.insights.getSprintSettings,
      { uuid: accountUuid },
      'query'
    ),
    queryFn: async () =>
      trpcProxyClient.insights.getSprintSettings.query(
        { uuid: accountUuid },
        { context: { skipBatch: true } }
      ),
  });

  return null;
}
