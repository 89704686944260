const Tag: React.FunctionComponent<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <div className="flex h-9 min-w-0 items-center justify-center rounded-full border border-solid border-purple-200 bg-white px-4 text-sm font-semibold text-primary">
      {children}
    </div>
  );
};

export default Tag;
