import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

/**
 * A utility function to merge Tailwind CSS classes with arbitrary class names.
 * This provides flexibility when providing arbitrary styles/classes to components.
 * Order of precedence is right-to-left.
 */
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
