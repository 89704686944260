import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SimpleModal } from 'src/components/Modal';
import { useI18nContext } from 'src/i18n/i18n-react';
import { trpc } from 'src/lib/trpc';
import CreateNamedObjectModal from '../CreateNamedObjectModal/CreateNamedObjectModal';
import { useDuplicateBrief } from '../../hooks';
import CollectionIndicator from '../CollectionIndicator/CollectionIndicator';
import type { Brief } from '@magicbrief/prisma/generated/client2';

type Props = Omit<
  React.ComponentProps<typeof SimpleModal>,
  'title' | 'onClose'
> & {
  onClose: () => void;
  brief: Omit<Brief, 'data'>;
  collectionID?: number;
  createAsTemplate?: boolean;
};

const DuplicateBriefModal: React.FunctionComponent<Props> = ({
  onClose,
  collectionID,
  brief,
  createAsTemplate,
  ...rest
}): JSX.Element => {
  const briefCollections = trpc.briefs.getBriefCollections.useQuery();
  const matchingCollection =
    collectionID && briefCollections.data
      ? briefCollections.data.find((x) => x.id === collectionID)
      : null;
  const { LL } = useI18nContext();
  const [error, setError] = useState<string | null>();
  const duplicateBrief = useDuplicateBrief();
  const navigate = useNavigate();

  const onSubmit = (name: string) => {
    duplicateBrief.mutate(
      {
        uuid: brief.uuid,
        name,
        collectionID: createAsTemplate ? undefined : collectionID,
        createAsTemplate: createAsTemplate,
      },
      {
        onError: (error) => {
          setError(error.message);
        },
        onSuccess: (data) => {
          onClose();
          if (!createAsTemplate) {
            navigate(`/briefs/${data.uuid}`);
          } else {
            toast(LL.briefs.createdTemplate(), { className: 'toast-success' });
          }
        },
      }
    );
  };

  return (
    <CreateNamedObjectModal
      {...rest}
      onClose={onClose}
      onSubmit={onSubmit}
      error={error}
      initialName={createAsTemplate ? undefined : `${brief.name} (Copy)`}
      title={
        createAsTemplate
          ? LL.briefs.createTemplate()
          : LL.briefs.duplicateBrief()
      }
      isSubmitting={duplicateBrief.isLoading}
      submitLabel={
        createAsTemplate
          ? LL.briefs.createTemplate()
          : LL.briefs.duplicateBrief()
      }
    >
      {matchingCollection && !createAsTemplate && (
        <CollectionIndicator name={matchingCollection.name} />
      )}
    </CreateNamedObjectModal>
  );
};

export default DuplicateBriefModal;
