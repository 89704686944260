import {
  DeploymentEnvironment,
  getFeatureFlagValue as commonGetFeatureFlagValue,
} from '@magicbrief/common';
import type { Feature, FeatureFlags } from '@magicbrief/common';

/** @important if you need to get personalised values for a user or org,
 * please use the hook version of this {@link ./useFeatureFlag.ts} */
export function getFeatureFlagValue<T extends Feature>(
  key: T,
  override?: FeatureFlags[T]
): FeatureFlags[T] {
  return commonGetFeatureFlagValue(
    key,
    import.meta.env.VITE_DEPLOYMENT as DeploymentEnvironment,
    override
  );
}
