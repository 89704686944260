import { AppRouter } from '@magicbrief/server/src/trpc/router';
import { getQueryKey } from '@trpc/react-query';
import { inferProcedureOutput } from '@trpc/server';
import { LoaderFunctionArgs } from 'react-router-dom';
import queryClient from 'src/lib/queryClient';
import { trpc } from 'src/lib/trpc';
import { brandProfileRouteLoader } from '../BrandProfile/routeLoader';

export async function productProfileRouteLoader(data: LoaderFunctionArgs) {
  const productProfiles = queryClient.getQueryData<
    inferProcedureOutput<
      AppRouter['brandProfiles']['getProductProfilesForBrand']
    >
  >(
    getQueryKey(
      trpc.brandProfiles.getProductProfilesForBrand,
      { brandUUID: data.params.brandUUID },
      'query'
    )
  );

  const brandProfile = await brandProfileRouteLoader(data);

  return {
    brandProfile,
    productProfile:
      productProfiles?.find((x) => x.uuid === data.params.productUUID) ?? null,
  };
}
