import { useState } from 'react';
import { Variants } from 'framer-motion';

export const PopoverAnimationVariants = {
  initial: {
    opacity: 0,
    scale: 0.95,
    transition: {
      duration: 0.075,
      ease: 'easeIn',
    },
  },
  animate: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.1,
      ease: 'easeOut',
    },
  },
} satisfies Variants;

export type PopoverAnimationState =
  | keyof typeof PopoverAnimationVariants
  | 'unmounted';

export function usePopoverState() {
  return useState<PopoverAnimationState>('unmounted');
}
