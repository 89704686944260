export const COLOR_RANK_1 = '#60A5FA';
export const COLOR_RANK_2 = '#8B5CF6';
export const COLOR_RANK_3 = '#FA8A89';
export const COLOR_RANK_4 = '#9274FF';
export const COLOR_RANK_5 = '#3BA974';
export const COLOR_RANK_6 = '#FF7557';
export const COLOR_RANK_7 = '#7FE1D8';
export const COLOR_RANK_8 = '#7856FF';
export const COLOR_RANK_9 = '#72BEF4';
export const COLOR_RANK_10 = '#B3596E';
export const COLOR_RANK_11 = '#0F7EA0';
export const COLOR_RANK_12 = '#FEBBB2';
export const COLOR_RANK_13 = '#FDB179';
export const COLOR_RANK_14 = '#7DDFD6';
export const COLOR_RANK_15 = '#F8BC3C';
export const COLOR_RANK_16 = '#CB80DC';
export const COLOR_RANK_17 = '#E60F89';
export const COLOR_RANK_18 = '#C4E5AD';
export const COLOR_RANK_19 = '#E6605A';
export const COLOR_RANK_20 = '#E5BDA2';
export const COLOR_RANK_SCALE = [
  COLOR_RANK_1,
  COLOR_RANK_2,
  COLOR_RANK_3,
  COLOR_RANK_4,
  COLOR_RANK_5,
  COLOR_RANK_6,
  COLOR_RANK_7,
  COLOR_RANK_8,
  COLOR_RANK_9,
  COLOR_RANK_10,
  COLOR_RANK_11,
  COLOR_RANK_12,
  COLOR_RANK_13,
  COLOR_RANK_14,
  COLOR_RANK_15,
  COLOR_RANK_16,
  COLOR_RANK_17,
  COLOR_RANK_18,
  COLOR_RANK_19,
  COLOR_RANK_20,
];
