import { FC } from 'react';
import { GetInAppNotificationsResponse } from '@magicbrief/server/src/notifications/notifications.trpc';
import { ArrayElement } from '@magicbrief/common';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import Avatar from 'src/components/Misc/Avatar';
import Mail01 from 'src/assets/svgicons/duocolor/mail-01.svg';
import File02 from 'src/assets/svgicons/duocolor/file-02.svg';
import MessageSquare02 from 'src/assets/svgicons/line/message-square-02.svg';
import dayjs from 'src/lib/dayjs';
import MagicBriefBolt from 'src/assets/svgicons/magicbrief/MagicBriefBolt.svg';
import { Icon } from 'src/components/Icon';
import { trpc } from 'src/lib/trpc';
import { useI18nContext } from 'src/i18n/i18n-react';
import { addToSearchParams } from 'src/utils/queryParams';
import FolderReport from 'src/assets/svgicons/custom/FolderReport.svg';
import Folder from 'src/assets/svgicons/duocolor/folder.svg';
import { useUserAndOrganisation } from '../../utils/useUserAndOrganisation';

type Props = {
  notification: ArrayElement<GetInAppNotificationsResponse>;
  onClicked?: () => void;
  className?: string;
};

const NotificationItem: FC<Props> = ({
  notification,
  onClicked,
  className,
}: Props) => {
  const { LL } = useI18nContext();
  const navigate = useNavigate();
  const user = useUserAndOrganisation();

  const acknowledgeNotification =
    trpc.notifications.acknowledgeNotification.useMutation({
      onSuccess: () => {
        onClicked?.();
      },
    });

  const sentAtTimeAgo = dayjs(notification.createdAt).from(dayjs(), true);

  const getIconForNotification = () => {
    let icon;

    if (notification.icon === 'MagicBriefBolt') {
      icon = <MagicBriefBolt />;
    } else if (notification.icon === 'File02') {
      icon = <File02 />;
    } else if (notification.icon === 'MessageSquare02') {
      icon = <MessageSquare02 />;
    } else if (notification.icon === 'FolderReport') {
      icon = <FolderReport />;
    } else if (notification.icon === 'Folder') {
      icon = <Folder />;
    } else {
      icon = <Mail01 />;
    }

    return <Icon>{icon}</Icon>;
  };

  const handleInviteEvent = () => {
    const searchParams = new URLSearchParams(window.location.toString());

    if (notification.action.metadata.entityType === 'Organisation') {
      if (
        user.data?.organisation.uuid !== notification.action.metadata.entityUuid
      ) {
        navigate(
          `/organisations/switch/${
            notification.action.metadata.entityUuid
          }?${searchParams.toString()}`
        );
      }
    } else if (notification.action.metadata.entityType === 'Brief') {
      navigate(
        `/briefs/${
          notification.action.metadata.entityUuid
        }?${searchParams.toString()}`
      );
    } else if (notification.action.metadata.entityType === 'InsightsReport') {
      navigate(
        `/insights/reports/${notification.action.metadata.accountUuid}/${
          notification.action.metadata.entityUuid
        }?${searchParams.toString()}`
      );
    } else if (notification.action.metadata.entityType === 'DocumentNode') {
      navigate(
        `/library/d/${
          notification.action.metadata.entityUuid
        }?${searchParams.toString()}`
      );
    } else {
      console.debug("Entity type not yet supported for 'invite' action");
    }
  };

  const handleMentionEvent = () => {
    const searchParams = addToSearchParams('show-comments', 'true');

    if (notification.action.metadata.entityType === 'OrganisationAd') {
      navigate(
        `/ad/${
          notification.action.metadata.entityUuid
        }?${searchParams.toString()}`
      );
    } else if (notification.action.metadata.entityType === 'Storyboard') {
      navigate(
        `/briefs/concepts/${
          notification.action.metadata.entityUuid
        }?${searchParams.toString()}`
      );
    } else if (notification.action.metadata.entityType === 'Brief') {
      navigate(
        `/briefs/${
          notification.action.metadata.entityUuid
        }?${searchParams.toString()}`
      );
    } else {
      console.debug("Entity type not yet supported for 'mention' action");
    }
  };

  const handleStatusChangeEvent = () => {
    if (notification.action.metadata.entityType === 'Brief') {
      navigate(`/briefs/${notification.action.metadata.entityUuid}`);
    } else {
      console.debug("Entity type not yet supported for 'statusChange' action");
    }
  };

  const handleStageChangeEvent = () => {
    if (notification.action.metadata.entityType === 'Brief') {
      navigate(`/briefs/${notification.action.metadata.entityUuid}`);
    } else {
      console.debug("Entity type not yet supported for 'stageChange' action");
    }
  };

  const handleDueDateChangeEvent = () => {
    if (notification.action.metadata.entityType === 'Brief') {
      navigate(`/briefs/${notification.action.metadata.entityUuid}`);
    } else {
      console.debug("Entity type not yet supported for 'dueDate' action");
    }
  };

  const handleAssignedChangeEvent = () => {
    if (notification.action.metadata.entityType === 'Brief') {
      navigate(`/briefs/${notification.action.metadata.entityUuid}`);
    } else {
      console.debug("Entity type not yet supported for 'assigned' action");
    }
  };

  const handleOnClick = async () => {
    if (notification.readAt == null) {
      await acknowledgeNotification.mutateAsync({
        uuid: notification.uuid,
        mediumUuid: notification.mediumUuid,
        action: 'click',
      });
    }

    if (notification.action.type === 'invite') {
      handleInviteEvent();
    } else if (notification.action.type === 'mention') {
      handleMentionEvent();
    } else if (
      /** @todo clean this up when we move to new 'type' model */
      notification.action.type === 'statusChange' ||
      notification.action.type === 'brief_status-change'
    ) {
      handleStatusChangeEvent();
    } else if (notification.action.type === 'stageChange') {
      handleStageChangeEvent();
    } else if (notification.action.type === 'dueDateChange') {
      handleDueDateChangeEvent();
    } else if (notification.action.type === 'assignedChange') {
      handleAssignedChangeEvent();
    } else {
      console.debug('Action type not yet supported');
    }
  };

  return (
    <div
      tabIndex={0}
      role="button"
      className={classNames(
        'flex flex-row items-center gap-2 p-4',
        notification.readAt == null && 'bg-purple-100',
        className
      )}
      onClick={handleOnClick}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          void handleOnClick();
        }
      }}
    >
      <div>
        {notification.imgUrl != null ? (
          <Avatar
            className="w-9 h-9 p-2"
            initial={user.data?.user.name.charAt(0)}
            src={notification.imgUrl}
            fallbackIcon={getIconForNotification()}
          />
        ) : (
          <Mail01 />
        )}
      </div>
      <div className="text-primary text-sm overflow-hidden">
        {notification.contentAsHtml != null && (
          <p
            className="text-ellipsis line-clamp-1"
            dangerouslySetInnerHTML={{
              __html: notification.contentAsHtml,
            }}
          />
        )}
        {notification.contentAsHtml == null &&
          notification.contentAsText != null && (
            <p className="text-ellipsis line-clamp-1">
              {notification.contentAsText}
            </p>
          )}
        <p className="text-purple-300 text-xs">
          {LL.general.timeAgo({ time: sentAtTimeAgo })}
        </p>
      </div>
    </div>
  );
};

export default NotificationItem;
