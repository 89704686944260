export type NormalisedUTM = Record<string, string>;

const UTM_STORAGE_KEY = 'stored_utm_value';

export const getUTMSFromQueryString = (
  input: URLSearchParams
): NormalisedUTM | null => {
  const result: NormalisedUTM = {};
  input.forEach((val, key) => {
    if (key.substring(0, 4) === 'utm_') {
      let param = key.substring(4);
      if (param === 'campaign') param = 'name';
      result[param] = val;
    }
  });
  if (Object.keys(result).length > 0) {
    return result;
  }
  return null;
};

export const saveUTMMetadataToLocalStorage = (utm: NormalisedUTM) => {
  const utmStringValue = JSON.stringify(utm);
  localStorage.setItem(UTM_STORAGE_KEY, utmStringValue);
};

export const retrieveUTMMetadataFromLocalStorage = (): NormalisedUTM | null => {
  const value = localStorage.getItem(UTM_STORAGE_KEY);
  try {
    if (value) {
      return JSON.parse(value);
    }
  } catch (e) {
    return null;
  }
  return null;
};

export const clearLocalUTMData = () => {
  localStorage.removeItem(UTM_STORAGE_KEY);
};
