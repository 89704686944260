import { initializeApp } from 'firebase/app';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { appConfigs, firebaseConfigs } from 'src/configs';

export const app = initializeApp({
  apiKey: firebaseConfigs.apiKey,
  projectId: firebaseConfigs.projectId,
  authDomain: firebaseConfigs.authDomain,
  storageBucket: firebaseConfigs.storageBucket,
});

export const auth = getAuth(app);

export const functions = getFunctions(app);

if (appConfigs.isFirebaseEmulator) {
  connectAuthEmulator(
    auth,
    `http://${appConfigs.firebaseEmulatorHost}:${appConfigs.firebaseEmulatorAuthPort}`
  );
  connectFunctionsEmulator(
    functions,
    appConfigs.firebaseEmulatorHost,
    appConfigs.firebaseFunctionsEmulatorPort
  );
}
