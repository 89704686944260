import { useEffect, useState } from 'react';
import { AppRouter } from '@magicbrief/server/src/trpc/router';
import { TRPCClientErrorLike } from '@trpc/client';
import { UseTRPCMutationOptions } from '@trpc/react-query/shared';
import { inferProcedureInput, inferProcedureOutput } from '@trpc/server';
import * as Sentry from '@sentry/react';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { getQueryKey } from '@trpc/react-query';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { trpc } from 'src/lib/trpc';
import { useUploadUserAsset } from 'src/utils/useUploadUserAsset';
import { segment } from 'src/lib/segment';
import type { BriefCollection } from '@magicbrief/prisma/generated/client2';

function handleCacheOnBriefCreate(
  queryClient: QueryClient,
  newBrief:
    | inferProcedureOutput<AppRouter['briefs']['createBrief']>
    | inferProcedureOutput<AppRouter['briefs']['duplicateBrief']>,
  collectionID?: number
) {
  queryClient.setQueriesData<
    inferProcedureOutput<AppRouter['briefs']['getBriefs']>
  >(
    {
      queryKey: getQueryKey(trpc.briefs.getBriefs, undefined, 'query'),
      predicate: (query) =>
        !query.queryHash.includes('collections') &&
        !query.queryHash.includes('COMPLETE'),
    },
    (oldData) =>
      oldData
        ? [{ ...newBrief, UserAssetsContentInBriefs: [] }, ...oldData]
        : [{ ...newBrief, UserAssetsContentInBriefs: [] }]
  );
  if (collectionID) {
    queryClient.setQueriesData<
      inferProcedureOutput<AppRouter['briefs']['getBriefs']>
    >(
      {
        queryKey: getQueryKey(
          trpc.briefs.getBriefs,
          { collections: [collectionID] },
          'query'
        ),
        predicate: (query) => !query.queryHash.includes('COMPLETE'),
      },

      (oldData) =>
        oldData
          ? [{ ...newBrief, UserAssetsContentInBriefs: [] }, ...oldData]
          : [{ ...newBrief, UserAssetsContentInBriefs: [] }]
    );
    queryClient.setQueriesData<
      inferProcedureOutput<AppRouter['briefs']['getBriefCollections']>
    >(
      getQueryKey(trpc.briefs.getBriefCollections, undefined, 'query'),
      (oldData) => {
        if (!oldData) {
          return oldData;
        }
        const affectedCollection = oldData.findIndex(
          (x) => x.id === collectionID
        );
        return affectedCollection === -1
          ? oldData
          : [
              ...oldData.slice(0, affectedCollection),
              {
                ...oldData[affectedCollection],
                _count: {
                  BriefsInCollections:
                    oldData[affectedCollection]._count.BriefsInCollections + 1,
                },
              },
              ...oldData.slice(affectedCollection + 1),
            ];
      }
    );
  }
}

export function useCreateBrief<TContext = unknown>(
  options?: UseTRPCMutationOptions<
    inferProcedureInput<AppRouter['briefs']['createBrief']>,
    TRPCClientErrorLike<AppRouter['briefs']['createBrief']>,
    inferProcedureOutput<AppRouter['briefs']['createBrief']>,
    TContext
  >
) {
  const queryClient = useQueryClient();
  const createBrief = trpc.briefs.createBrief.useMutation({
    ...options,
    onSuccess(data, variables, context) {
      handleCacheOnBriefCreate(queryClient, data, variables.collectionID);
      options?.onSuccess?.(data, variables, context);
    },
    onError(error, variables, context) {
      Sentry.captureException(error, (scope) => {
        scope.setTransactionName('Create brief');
        scope.setExtra('requestData', variables);
        return scope;
      });
      options?.onError?.(error, variables, context);
    },
  });

  return createBrief;
}

export function useDuplicateBrief<TContext = unknown>(
  options?: UseTRPCMutationOptions<
    inferProcedureInput<AppRouter['briefs']['duplicateBrief']>,
    TRPCClientErrorLike<AppRouter['briefs']['duplicateBrief']>,
    inferProcedureOutput<AppRouter['briefs']['duplicateBrief']>,
    TContext
  >
) {
  const queryClient = useQueryClient();
  const duplicateBrief = trpc.briefs.duplicateBrief.useMutation({
    ...options,
    onSuccess(data, variables, context) {
      if (variables.createAsTemplate) {
        void queryClient.invalidateQueries(
          getQueryKey(trpc.briefs.getBriefTemplates)
        );
      } else {
        handleCacheOnBriefCreate(queryClient, data, variables.collectionID);
      }
      if (options?.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      Sentry.captureException(error, (scope) => {
        scope.setTransactionName('Duplicate brief');
        scope.setExtra('requestData', variables);
        return scope;
      });
      if (options?.onError) {
        options.onError(error, variables, context);
      }
    },
  });

  return duplicateBrief;
}

export function useUpdateBrief<TContext = unknown>(
  options?: UseTRPCMutationOptions<
    inferProcedureInput<AppRouter['briefs']['updateBrief']>,
    TRPCClientErrorLike<AppRouter['briefs']['updateBrief']>,
    inferProcedureOutput<AppRouter['briefs']['updateBrief']>,
    TContext
  >
) {
  const queryClient = useQueryClient();
  const updateBrief = trpc.briefs.updateBrief.useMutation({
    ...options,
    onSuccess(data, variables, context) {
      const datalessClone = { ...data, data: undefined };
      delete datalessClone.data; // We don't want editorjs data in the list of briefs

      // Invalidate queries for lists of briefs as it is too difficult
      // to determine what queries a brief will added or removed from given
      // the possible filters
      void queryClient.invalidateQueries<
        inferProcedureOutput<AppRouter['briefs']['getBriefs']>
      >(getQueryKey(trpc.briefs.getBriefs, undefined, 'query'));

      // Overwrite entire getBrief query cache with new data, including editorjs data
      queryClient.setQueryData<
        inferProcedureOutput<AppRouter['briefs']['getBriefByUuid']>
      >(
        getQueryKey(trpc.briefs.getBriefByUuid, { uuid: data.uuid }, 'query'),
        data
      );

      queryClient.setQueryData<
        inferProcedureOutput<AppRouter['briefs']['getBriefByUuid']>
      >(
        getQueryKey(trpc.briefs.getBriefByUuid, { uuid: data.uuid }, 'query'),
        data
      );

      if (variables.data.assignees) {
        void queryClient.invalidateQueries<
          inferProcedureOutput<AppRouter['briefs']['getUsersAssignedToBriefs']>
        >(
          getQueryKey(trpc.briefs.getUsersAssignedToBriefs, undefined, 'query')
        );
      }

      const {
        arbitraryStatus,
        assignees,
        brandProfileUUID,
        dueDate,
        name,
        public: briefPublic,
        stage,
        type: briefType,
      } = variables.data;

      if (dueDate || arbitraryStatus || briefType || stage || assignees) {
        void segment?.track('edited_briefstatus');
      }
      if (name || brandProfileUUID || briefPublic) {
        void segment?.track('edited_brief');
      }

      options?.onSuccess?.(data, variables, context);
    },
    onError(error, variables, context) {
      Sentry.captureException(error, (scope) => {
        scope.setTransactionName('Update brief');
        scope.setExtra('requestData', variables);
        return scope;
      });
      options?.onError?.(error, variables, context);
    },
  });

  return updateBrief;
}

export function useRegenerateBriefShareLink<TContext = unknown>(
  options?: UseTRPCMutationOptions<
    inferProcedureInput<AppRouter['briefs']['regenerateBriefShareLink']>,
    TRPCClientErrorLike<AppRouter['briefs']['regenerateBriefShareLink']>,
    inferProcedureOutput<AppRouter['briefs']['regenerateBriefShareLink']>,
    TContext
  >
) {
  const queryClient = useQueryClient();
  const regenerateBriefShareLink =
    trpc.briefs.regenerateBriefShareLink.useMutation({
      ...options,
      onSuccess(data, variables, context) {
        void queryClient.setQueriesData<
          inferProcedureOutput<AppRouter['briefs']['getBriefs']>
        >(getQueryKey(trpc.briefs.getBriefs, undefined, 'query'), (oldData) => {
          if (!oldData || data.shareLink == null) {
            return oldData;
          }
          return oldData.map((x) => {
            if (x.uuid === data.uuid) {
              return {
                ...x,
                shareLink: data.shareLink,
              };
            }
            return x;
          });
        });

        queryClient.setQueryData<
          inferProcedureOutput<AppRouter['briefs']['getBriefByUuid']>
        >(
          getQueryKey(trpc.briefs.getBriefByUuid, { uuid: data.uuid }, 'query'),
          (oldData) => {
            if (!oldData || data.shareLink == null) {
              return oldData;
            }
            return { ...oldData, shareLink: data.shareLink };
          }
        );

        options?.onSuccess?.(data, variables, context);
      },
      onError(error, variables, context) {
        Sentry.captureException(error, (scope) => {
          scope.setTransactionName('Update brief');
          scope.setExtra('requestData', variables);
          return scope;
        });
        options?.onError?.(error, variables, context);
      },
    });

  return regenerateBriefShareLink;
}

export function useDeleteBriefs<TContext = unknown>(
  options?: UseTRPCMutationOptions<
    inferProcedureInput<AppRouter['briefs']['deleteBriefs']>,
    TRPCClientErrorLike<AppRouter['briefs']['deleteBriefs']>,
    inferProcedureOutput<AppRouter['briefs']['deleteBriefs']>,
    TContext
  >
) {
  const queryClient = useQueryClient();
  const deleteBrief = trpc.briefs.deleteBriefs.useMutation({
    ...options,
    onSuccess(data, variables, context) {
      // Remove matching brief from all getBriefs queries
      queryClient.setQueriesData<
        inferProcedureOutput<AppRouter['briefs']['getBriefs']>
      >(getQueryKey(trpc.briefs.getBriefs, undefined, 'query'), (oldData) => {
        return oldData?.filter(
          (brief) => !variables.uuids.includes(brief.uuid)
        );
      });

      // Refetch brief counters for collections in getBriefCollecitons
      void queryClient.invalidateQueries<
        inferProcedureOutput<AppRouter['briefs']['getBriefCollections']>
      >(getQueryKey(trpc.briefs.getBriefCollections, undefined, 'query'));

      // Entirely evict getBrief query for the deleted briefs
      data.forEach((brief) => {
        queryClient.removeQueries(
          getQueryKey(trpc.briefs.getBriefByUuid, { uuid: brief.uuid }, 'query')
        );
      });

      options?.onSuccess?.(data, variables, context);
    },
    onError(error, variables, context) {
      Sentry.captureException(error, (scope) => {
        scope.setTransactionName('Delete brief');
        scope.setExtra('requestData', variables);
        return scope;
      });
      options?.onError?.(error, variables, context);
    },
  });

  return deleteBrief;
}

export function useAddBriefToCollection<TContext = unknown>(
  options?: UseTRPCMutationOptions<
    inferProcedureInput<AppRouter['briefs']['addBriefToCollection']>,
    TRPCClientErrorLike<AppRouter['briefs']['addBriefToCollection']>,
    inferProcedureOutput<AppRouter['briefs']['addBriefToCollection']>,
    TContext
  >
) {
  const queryClient = useQueryClient();
  const addBriefToCollection = trpc.briefs.addBriefToCollection.useMutation({
    ...options,
    onSuccess(data, variables, context) {
      // Increment the brief count for collection that brief was just added to
      queryClient.setQueriesData<
        inferProcedureOutput<AppRouter['briefs']['getBriefCollections']>
      >(
        getQueryKey(trpc.briefs.getBriefCollections, undefined, 'query'),
        (oldData) => {
          if (!oldData) {
            return oldData;
          }
          const affectedCollection = oldData.findIndex(
            (x) => x.id === variables.collectionID
          );
          return affectedCollection === -1
            ? oldData
            : [
                ...oldData.slice(0, affectedCollection),
                {
                  ...oldData[affectedCollection],
                  _count: {
                    BriefsInCollections:
                      oldData[affectedCollection]._count.BriefsInCollections +
                      1,
                  },
                },
                ...oldData.slice(affectedCollection + 1),
              ];
        }
      );

      // Add collection to list of collections on matching brief in getBriefs list
      queryClient.setQueriesData<
        inferProcedureOutput<AppRouter['briefs']['getBriefs']>
      >(getQueryKey(trpc.briefs.getBriefs, undefined, 'query'), (oldData) => {
        if (!oldData) {
          return oldData;
        }
        const affectedBrief = oldData.findIndex(
          (x) => x.id === variables.briefID
        );
        return affectedBrief === -1
          ? oldData
          : [
              ...oldData.slice(0, affectedBrief),
              {
                ...oldData[affectedBrief],
                BriefsInCollections: [
                  { BriefCollection: data.BriefCollection, ...variables },
                  ...oldData[affectedBrief].BriefsInCollections,
                ],
              },
              ...oldData.slice(affectedBrief + 1),
            ];
      });

      // Invalidate list of briefs where the collection filter matches the collection
      // the brief was just added to (we do this to ensure the order is preserved)
      void queryClient.invalidateQueries<
        inferProcedureOutput<AppRouter['briefs']['getBriefs']>
      >(
        getQueryKey(
          trpc.briefs.getBriefs,
          { collections: [variables.collectionID] },
          'query'
        )
      );

      // Update list of collections brief is in on the query for the given brief
      queryClient.setQueryData<
        inferProcedureOutput<AppRouter['briefs']['getBriefByUuid']>
      >(
        getQueryKey(
          trpc.briefs.getBriefByUuid,
          { uuid: data.Brief.uuid },
          'query'
        ),
        (oldData) => {
          if (oldData) {
            return {
              ...oldData,
              BriefsInCollections: [...oldData.BriefsInCollections, data],
            };
          }
          return oldData;
        }
      );
      options?.onSuccess?.(data, variables, context);
    },
    onError(error, variables, context) {
      Sentry.captureException(error, (scope) => {
        scope.setTransactionName('Add brief to existing collection');
        scope.setExtra('requestData', variables);
        return scope;
      });
      options?.onError?.(error, variables, context);
    },
  });

  return addBriefToCollection;
}

export function useAddBriefToNewCollection<TContext = unknown>(
  options?: UseTRPCMutationOptions<
    inferProcedureInput<AppRouter['briefs']['addBriefToNewCollection']>,
    TRPCClientErrorLike<AppRouter['briefs']['addBriefToNewCollection']>,
    inferProcedureOutput<AppRouter['briefs']['addBriefToNewCollection']>,
    TContext
  >
) {
  const queryClient = useQueryClient();
  const addBriefToNewCollection =
    trpc.briefs.addBriefToNewCollection.useMutation({
      ...options,
      onSuccess(data, variables, context) {
        // Add the newly created collection to the list of brief collections
        queryClient.setQueryData<
          inferProcedureOutput<AppRouter['briefs']['getBriefCollections']>
        >(
          getQueryKey(trpc.briefs.getBriefCollections, undefined, 'query'),
          (oldData) => {
            if (oldData) {
              return [
                { ...data, _count: { BriefsInCollections: 1 } },
                ...oldData,
              ];
            }
            return oldData;
          }
        );

        // Add the newly created collection to the list of collections on the brief itself
        // within the list of all briefs
        queryClient.setQueriesData<
          inferProcedureOutput<AppRouter['briefs']['getBriefs']>
        >(getQueryKey(trpc.briefs.getBriefs, undefined, 'query'), (oldData) => {
          if (!oldData) {
            return oldData;
          }
          const affectedBrief = oldData.findIndex(
            (x) => x.id === variables.briefID
          );

          return affectedBrief === -1
            ? oldData
            : [
                ...oldData.slice(0, affectedBrief),
                {
                  ...oldData[affectedBrief],
                  BriefsInCollections: [
                    {
                      BriefCollection: data,
                      briefID: variables.briefID,
                      collectionID: data.id,
                    },
                    ...oldData[affectedBrief].BriefsInCollections,
                  ],
                },
                ...oldData.slice(affectedBrief + 1),
              ];
        });

        // Add the newly created collection to the list of collections on the brief itself
        queryClient.setQueryData<
          inferProcedureOutput<AppRouter['briefs']['getBriefByUuid']>
        >(
          getQueryKey(
            trpc.briefs.getBriefByUuid,
            { uuid: data.briefUuid },
            'query'
          ),
          (oldData) => {
            if (oldData) {
              return {
                ...oldData,
                BriefsInCollections: [
                  ...oldData.BriefsInCollections,
                  {
                    BriefCollection: data,
                    briefID: variables.briefID,
                    collectionID: data.id,
                  },
                ],
              };
            }
            return oldData;
          }
        );
        options?.onSuccess?.(data, variables, context);
      },
      onError(error, variables, context) {
        Sentry.captureException(error, (scope) => {
          scope.setTransactionName('Add brief to new collection');
          scope.setExtra('requestData', variables);
          return scope;
        });
        options?.onError?.(error, variables, context);
      },
    });

  return addBriefToNewCollection;
}

export function useRemoveBriefFromCollection<TContext = unknown>(
  options?: UseTRPCMutationOptions<
    inferProcedureInput<AppRouter['briefs']['removeBriefFromCollection']>,
    TRPCClientErrorLike<AppRouter['briefs']['removeBriefFromCollection']>,
    inferProcedureOutput<AppRouter['briefs']['removeBriefFromCollection']>,
    TContext
  >
) {
  const queryClient = useQueryClient();
  const removeBriefFromCollection =
    trpc.briefs.removeBriefFromCollection.useMutation({
      ...options,
      onSuccess(data, variables, context) {
        // Decrease brief count on collection that contained brief
        queryClient.setQueriesData<
          inferProcedureOutput<AppRouter['briefs']['getBriefCollections']>
        >(
          getQueryKey(trpc.briefs.getBriefCollections, undefined, 'query'),
          (oldData) => {
            if (!oldData) {
              return oldData;
            }
            const affectedCollection = oldData.findIndex(
              (x) => x.id === variables.collectionID
            );
            return affectedCollection === -1
              ? oldData
              : [
                  ...oldData.slice(0, affectedCollection),
                  {
                    ...oldData[affectedCollection],
                    _count: {
                      BriefsInCollections:
                        oldData[affectedCollection]._count.BriefsInCollections -
                        1,
                    },
                  },
                  ...oldData.slice(affectedCollection + 1),
                ];
          }
        );

        // Remove the collection from the list of collections on the brief within
        // the list of briefs
        queryClient.setQueriesData<
          inferProcedureOutput<AppRouter['briefs']['getBriefs']>
        >(getQueryKey(trpc.briefs.getBriefs, undefined, 'query'), (oldData) => {
          if (!oldData) {
            return oldData;
          }
          const affectedBrief = oldData.findIndex(
            (x) => x.id === variables.briefID
          );
          if (affectedBrief) {
            const affectedBriefInCollection = oldData[
              affectedBrief
            ].BriefsInCollections.findIndex(
              (x) => x.collectionID === variables.collectionID
            );

            return affectedBriefInCollection === -1
              ? oldData
              : [
                  ...oldData.slice(0, affectedBrief),
                  {
                    ...oldData[affectedBrief],
                    BriefsInCollections: [
                      ...oldData[affectedBrief].BriefsInCollections.slice(
                        0,
                        affectedBriefInCollection
                      ),
                      ...oldData[affectedBrief].BriefsInCollections.slice(
                        affectedBriefInCollection + 1
                      ),
                    ],
                  },
                  ...oldData.slice(affectedBrief + 1),
                ];
          }
          return oldData;
        });

        // Remove brief from any getBriefs query where the collection filter
        // was set to the collection we just removed the brief from
        queryClient.setQueriesData<
          inferProcedureOutput<AppRouter['briefs']['getBriefs']>
        >(
          getQueryKey(
            trpc.briefs.getBriefs,
            { collections: [variables.collectionID] },
            'query'
          ),
          (oldData) => {
            return oldData?.filter((x) => x.id !== variables.briefID);
          }
        );

        // Remove the collection from the list of collections on the brief
        queryClient.setQueryData<
          inferProcedureOutput<AppRouter['briefs']['getBriefByUuid']>
        >(
          getQueryKey(
            trpc.briefs.getBriefByUuid,
            { uuid: data.briefUuid },
            'query'
          ),
          (oldData) => {
            if (oldData) {
              return {
                ...oldData,
                BriefsInCollections: oldData.BriefsInCollections.filter(
                  (x) => x.collectionID !== variables.collectionID
                ),
              };
            }
          }
        );
        options?.onSuccess?.(data, variables, context);
      },
      onError(error, variables, context) {
        Sentry.captureException(error, (scope) => {
          scope.setTransactionName('Remove brief from collection');
          scope.setExtra('requestData', variables);
          return scope;
        });
        options?.onError?.(error, variables, context);
      },
    });

  return removeBriefFromCollection;
}

export function useCreateBriefCollection<TContext = unknown>(
  options?: UseTRPCMutationOptions<
    inferProcedureInput<AppRouter['briefs']['createBriefCollection']>,
    TRPCClientErrorLike<AppRouter['briefs']['createBriefCollection']>,
    inferProcedureOutput<AppRouter['briefs']['createBriefCollection']>,
    TContext
  >
) {
  const queryClient = useQueryClient();
  const createBriefCollection = trpc.briefs.createBriefCollection.useMutation({
    ...options,
    onSuccess(data, variables, context) {
      // Add the newly created brief collection to list of collections
      queryClient.setQueryData<
        inferProcedureOutput<AppRouter['briefs']['getBriefCollections']>
      >(
        getQueryKey(trpc.briefs.getBriefCollections, undefined, 'query'),
        (oldData) => {
          if (oldData) {
            return [
              ...oldData,
              { ...data, _count: { BriefsInCollections: 0 } },
            ];
          }
          return [{ ...data, _count: { BriefsInCollections: 0 } }];
        }
      );
      void segment?.track('created_briefcollection');
      options?.onSuccess?.(data, variables, context);
    },
    onError(error, variables, context) {
      Sentry.captureException(error, (scope) => {
        scope.setTransactionName('Create brief collection');
        scope.setExtra('requestData', variables);
        return scope;
      });
      options?.onError?.(error, variables, context);
    },
  });

  return createBriefCollection;
}

export function useDeleteBriefCollection<TContext = unknown>(
  options?: UseTRPCMutationOptions<
    inferProcedureInput<AppRouter['briefs']['deleteBriefCollection']>,
    TRPCClientErrorLike<AppRouter['briefs']['deleteBriefCollection']>,
    inferProcedureOutput<AppRouter['briefs']['deleteBriefCollection']>,
    TContext
  >
) {
  const queryClient = useQueryClient();
  const deleteBriefCollection = trpc.briefs.deleteBriefCollection.useMutation({
    ...options,
    onSuccess(data, variables, context) {
      queryClient.setQueriesData<
        inferProcedureOutput<AppRouter['briefs']['getBriefCollections']>
      >(
        getQueryKey(trpc.briefs.getBriefCollections, undefined, 'query'),
        (oldData) => {
          return oldData?.filter((x) => x.id !== variables.id);
        }
      );

      if (variables.deleteBriefs) {
        void queryClient.invalidateQueries(
          getQueryKey(trpc.briefs.getBriefByUuid, undefined, 'query')
        );
        void queryClient.invalidateQueries(
          getQueryKey(trpc.briefs.getBriefs, undefined, 'query')
        );
      } else {
        queryClient.setQueriesData<
          inferProcedureOutput<AppRouter['briefs']['getBriefByUuid']>
        >(
          getQueryKey(trpc.briefs.getBriefByUuid, undefined, 'query'),
          (oldData) => {
            if (!oldData) {
              return oldData;
            }

            const briefWasInCollection = oldData.BriefsInCollections.findIndex(
              (y) => y.collectionID === data.id
            );
            return briefWasInCollection === -1
              ? oldData
              : {
                  ...oldData,
                  BriefsInCollections: [
                    ...oldData.BriefsInCollections.slice(
                      0,
                      briefWasInCollection
                    ),
                    ...oldData.BriefsInCollections.slice(
                      briefWasInCollection + 1
                    ),
                  ],
                };
          }
        );

        queryClient.removeQueries(
          getQueryKey(
            trpc.briefs.getBriefs,
            { collections: [variables.id] },
            'query'
          )
        );

        queryClient.setQueriesData<
          inferProcedureOutput<AppRouter['briefs']['getBriefs']>
        >(getQueryKey(trpc.briefs.getBriefs, undefined, 'query'), (oldData) => {
          if (!oldData) {
            return oldData;
          }
          return oldData.map((x) => {
            const briefWasInCollection = x.BriefsInCollections.findIndex(
              (y) => y.collectionID === data.id
            );
            return briefWasInCollection === -1
              ? x
              : {
                  ...x,
                  BriefsInCollections: [
                    ...x.BriefsInCollections.slice(0, briefWasInCollection),
                    ...x.BriefsInCollections.slice(briefWasInCollection + 1),
                  ],
                };
          });
        });
      }
      options?.onSuccess?.(data, variables, context);
    },
    onError(error, variables, context) {
      Sentry.captureException(error, (scope) => {
        scope.setTransactionName('Delete brief collection');
        scope.setExtra('requestData', variables);
        return scope;
      });
      options?.onError?.(error, variables, context);
    },
  });

  return deleteBriefCollection;
}

function updateBriefInCollectionWithinBrief<
  T extends { BriefsInCollections: { BriefCollection: BriefCollection }[] },
>(brief: T, collectionID: number, update: Partial<BriefCollection>): T {
  const affectedCollection = brief.BriefsInCollections.findIndex(
    (x) => x.BriefCollection.id === collectionID
  );

  return affectedCollection === -1
    ? brief
    : {
        ...brief,
        StoryboardInCollections: [
          ...brief.BriefsInCollections.slice(0, affectedCollection),
          {
            ...brief.BriefsInCollections[affectedCollection],
            collection: {
              ...brief.BriefsInCollections[affectedCollection].BriefCollection,
              ...update,
            },
          },
          ...brief.BriefsInCollections.slice(affectedCollection + 1),
        ],
      };
}

export function useUpdateBriefCollection<TContext = unknown>(
  options?: UseTRPCMutationOptions<
    inferProcedureInput<AppRouter['briefs']['updateBriefCollection']>,
    TRPCClientErrorLike<AppRouter['briefs']['updateBriefCollection']>,
    inferProcedureOutput<AppRouter['briefs']['updateBriefCollection']>,
    TContext
  >
) {
  const queryClient = useQueryClient();
  const updateBriefCollection = trpc.briefs.updateBriefCollection.useMutation({
    ...options,

    onSuccess(data, variables, context) {
      queryClient.setQueriesData<
        inferProcedureOutput<AppRouter['briefs']['getBriefs']>
      >(getQueryKey(trpc.briefs.getBriefs, undefined, 'query'), (oldData) => {
        if (!oldData) {
          return oldData;
        }

        return oldData.map((brief) =>
          updateBriefInCollectionWithinBrief(brief, variables.id, data)
        );
      });

      queryClient.setQueriesData<
        inferProcedureOutput<AppRouter['briefs']['getBriefByUuid']>
      >(
        getQueryKey(trpc.briefs.getBriefByUuid, undefined, 'query'),
        (oldData) => {
          if (!oldData) {
            return oldData;
          }
          return updateBriefInCollectionWithinBrief(
            oldData,
            variables.id,
            data
          );
        }
      );

      queryClient.setQueriesData<
        inferProcedureOutput<AppRouter['briefs']['getBriefCollections']>
      >(
        getQueryKey(trpc.briefs.getBriefCollections, undefined, 'query'),
        (oldData) => {
          if (!oldData) {
            return oldData;
          }
          const affectedCollection = oldData.findIndex(
            (x) => x.id === variables.id
          );

          return affectedCollection === -1
            ? oldData
            : [
                ...oldData.slice(0, affectedCollection),
                {
                  ...oldData[affectedCollection],
                  name: data.name,
                },
                ...oldData.slice(affectedCollection + 1),
              ];
        }
      );

      queryClient.setQueriesData<
        inferProcedureOutput<AppRouter['briefs']['getBriefCollection']>
      >(
        getQueryKey(trpc.briefs.getBriefCollection, { id: data.id }, 'query'),
        (oldData) => {
          if (!oldData) {
            return oldData;
          }

          return { ...oldData, name: data.name };
        }
      );

      options?.onSuccess?.(data, variables, context);
    },
    onError(error, variables, context) {
      Sentry.captureException(error, (scope) => {
        scope.setTransactionName('Update brief collection');
        scope.setExtra('requestData', variables);
        return scope;
      });
      options?.onError?.(error, variables, context);
    },
  });
  return updateBriefCollection;
}

export function useUploadPreviewImage() {
  const { uploadFiles, state } = useUploadUserAsset();
  const [files, setFiles] = useState<Array<File & { preview: string }>>([]);

  useEffect(() => {
    return () => {
      files.forEach((file) => {
        URL.revokeObjectURL(file.preview);
      });
    };
  }, [files]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/webp': [],
      'image/avif': [],
    },
    disabled: state === 'uploadsInProgress',
    maxFiles: 1,
    multiple: false,
    maxSize: 500000,
    onDropRejected(fileRejections) {
      const error = fileRejections[0].errors[0];
      switch (error.code) {
        case 'too-many-files':
          toast.error('Only one file allowed.', { className: 'toast-danger' });
          break;
        case 'file-too-large':
          toast.error(
            'Selected image is too large. Your image must be under 500 kB.',
            {
              className: 'toast-danger',
            }
          );
          break;
        case 'file-invalid-type':
          toast.error('Only JPEG, PNG, WebP, or AVIF images can be used.', {
            className: 'toast-danger',
          });
          break;
        default:
          toast.error(error.message, {
            className: 'toast-danger',
          });
      }
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  return { uploadFiles, getRootProps, getInputProps, files, state };
}
