export function sanitizeRedirectPath(path?: string | null): string {
  if (path == null) {
    return '/';
  }

  if (path.length > 1 && path.charAt(0) !== '/') {
    return `/${path}`;
  }
  return path;
}
