import { ToastContainer, cssTransition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ToastWrapper.css';

const ToastWrapper = () => {
  const toastAnimation = cssTransition({
    enter: 'animate-fade-in-down',
    exit: 'animate-fade-out-up',
    collapse: false,
  });

  return (
    <ToastContainer
      transition={toastAnimation}
      autoClose={1900}
      bodyClassName="toast-body"
      position="top-center"
      closeButton={false}
      hideProgressBar={true}
    />
  );
};

export default ToastWrapper;
