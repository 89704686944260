import {
  FC,
  useEffect,
  useRef,
  useCallback,
  useState,
  ReactEventHandler,
  useMemo,
  CSSProperties,
  useId,
} from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { captureException } from '@sentry/react';
import PlayCircle from 'src/assets/svgicons/solid/play-circle.svg';
import PauseCircle from 'src/assets/svgicons/solid/pause-circle.svg';
import Maximise02 from 'src/assets/svgicons/solid/maximize-02.svg';
import VolumeMax from 'src/assets/svgicons/solid/volume-max.svg';
import VolumeX from 'src/assets/svgicons/solid/volume-x.svg';
import Loading02 from 'src/assets/svgicons/solid/loading-02.svg';
import secondsToDuration from 'src/utils/secondsToDuration';
import { buttonAnimationVariants } from './constants';
import { VideoEventChannel, VideoEventData } from './VideoEventChannel';
import './styles.css';
import { Progress } from './components/Progress';
import { ProgressRef } from './components/Progress/Progress';

type Props = {
  id?: string;
  onPlay?: ReactEventHandler<HTMLVideoElement>;
  onTimeChange?: (time: number) => void;
  sources: Array<{ type: string; src: string }>;
  width?: number;
  height?: number;
  initialDuration?: number;
  poster: string | null;
  className?: string;
  onVideoMount?: (videoEl: HTMLVideoElement | null) => void;
  preload?: 'none' | 'metadata';
  shouldShowControls?: boolean;
  autoPlay?: boolean;
};

const Video: FC<Props> = ({
  id: providedId,
  sources,
  width,
  height,
  poster,
  className,
  onPlay,
  onTimeChange,
  onVideoMount,
  initialDuration = null,
  preload = 'none',
  shouldShowControls = true,
  autoPlay = false,
}) => {
  const placeholderId = useId();
  const id = providedId ?? placeholderId;
  const containerRef = useRef<HTMLDivElement | null>(null);
  const player = useRef<HTMLVideoElement | null>(null);
  const progressRef = useRef<ProgressRef | null>(null);
  const eventChannel = useRef(new VideoEventChannel());
  const [isLoading, setIsLoading] = useState(preload !== 'none');
  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(false);
  const [duration, setDuration] = useState<number | null>(initialDuration);
  const [time, setTime] = useState<number | null>(null);
  const [showControls, setShowControls] = useState(shouldShowControls);

  const togglePlayPause = useCallback(async () => {
    if (player.current?.paused) {
      await player.current?.play();
      setPlaying(true);
    } else {
      player.current?.pause();
      setPlaying(false);
    }
  }, []);

  const toggleMute = useCallback(() => {
    if (player.current) {
      const nextMuteValue = !player.current.muted;
      player.current.muted = nextMuteValue;
      setMuted(nextMuteValue);
    }
  }, []);

  const enterFullscreen = useCallback(async () => {
    if (document.fullscreenElement) {
      if (document.exitFullscreen) {
        await document.exitFullscreen();
      }
    } else {
      if (player.current?.requestFullscreen) {
        await player.current.requestFullscreen();
      } else if (
        player.current &&
        'webkitEnterFullscreen' in player.current &&
        typeof player.current.webkitEnterFullscreen === 'function'
      ) {
        player.current.webkitEnterFullscreen();
      }
    }
  }, []);

  const onPlayHandler: ReactEventHandler<HTMLVideoElement> = useCallback(
    (e) => {
      onPlay?.(e);
      setPlaying(true);
      eventChannel.current.dispatch({
        type: 'playbackStarted',
        targetId: id,
      });
    },
    [id, onPlay]
  );

  const onPauseHandler: ReactEventHandler<HTMLVideoElement> =
    useCallback(() => {
      setPlaying(false);
    }, []);

  const onTimeUpdateHandler: ReactEventHandler<HTMLVideoElement> = useCallback(
    (e) => {
      if (progressRef.current) {
        progressRef.current.setValue(e.currentTarget.currentTime);
        setTime(e.currentTarget.currentTime);
        onTimeChange?.(e.currentTarget.currentTime);
      }
    },
    [onTimeChange]
  );

  const onLoadedMetadataHandler: ReactEventHandler<HTMLVideoElement> =
    useCallback(() => {
      setIsLoading(false);
      setDuration(player.current?.duration ?? null);
    }, []);

  const onEndedHandler: ReactEventHandler<HTMLVideoElement> =
    useCallback(() => {
      if (!shouldShowControls) {
        return;
      }
      setShowControls(true);
    }, [shouldShowControls]);

  const style: CSSProperties = useMemo(
    () => ({ aspectRatio: width && height ? width / height : undefined }),
    [width, height]
  );

  useEffect(() => {
    const container = containerRef.current;
    let timeout: ReturnType<typeof setTimeout> | undefined = undefined;

    const onMouseEnter = () => {
      clearTimeout(timeout);
      timeout = undefined;
      setShowControls(shouldShowControls);
    };

    const onMouseLeave = () => {
      if (!shouldShowControls) {
        return;
      }
      setShowControls(true);
      if (!player.current?.paused) {
        timeout = setTimeout(() => {
          if (!player.current?.paused) {
            setShowControls(false);
          }
        }, 2000);
      }
    };

    const onEnded = () => {
      clearTimeout(timeout);
      if (!shouldShowControls) {
        return;
      }
      setShowControls(true);
    };

    const onError = (ev: ErrorEvent) => {
      captureException(ev.error, (scope) => {
        scope.setTransactionName('Playing video');
        return scope;
      });
    };

    container?.addEventListener('mouseenter', onMouseEnter);
    container?.addEventListener('mouseleave', onMouseLeave);
    container?.addEventListener('ended', onEnded);
    container?.addEventListener('error', onError);

    return () => {
      clearTimeout(timeout);
      container?.removeEventListener('mouseenter', onMouseEnter);
      container?.removeEventListener('mouseleave', onMouseLeave);
      container?.removeEventListener('ended', onEnded);
      container?.removeEventListener('error', onError);
    };
  }, [shouldShowControls]);

  useEffect(() => {
    const channel = eventChannel.current;
    const listener = (ev: VideoEventData) => {
      if (ev.type === 'playbackStarted' && ev.targetId !== id) {
        player.current?.pause();
      }
    };
    channel.listen(listener);

    return () => {
      channel.close();
    };
  }, [id]);

  return (
    <div
      ref={containerRef}
      style={style}
      className={classNames(
        className,
        'library-video-player relative z-0 flex rounded-md'
      )}
    >
      <video
        id={id}
        playsInline
        autoPlay={autoPlay}
        onClick={togglePlayPause}
        controls={false}
        ref={(el) => {
          player.current = el;
          onVideoMount?.(el);
        }}
        onPlay={onPlayHandler}
        onPause={onPauseHandler}
        onEnded={onEndedHandler}
        onCanPlay={onLoadedMetadataHandler}
        onLoadedMetadata={onLoadedMetadataHandler}
        onLoadedData={onLoadedMetadataHandler}
        onTimeUpdate={onTimeUpdateHandler}
        poster={poster ?? undefined}
        style={style}
        preload={preload}
        className="relative rounded-[inherit]"
        onPlaying={() => setIsLoading(false)}
        onWaiting={() => setIsLoading(true)}
      >
        {sources.map((source) => (
          <source key={source.src} src={source.src} type={source.type} />
        ))}
      </video>
      {shouldShowControls && (
        <div
          className={classNames(
            'absolute bottom-0 left-0 z-10 flex h-16 w-full flex-col items-center justify-between bg-[linear-gradient(180deg,_rgba(0,0,0,0)_0%,_rgba(0,0,0,0.6)_100%)] px-4 pb-4 text-white transition-opacity duration-200 ease-linear',
            showControls
              ? 'pointer-events-auto opacity-100'
              : 'pointer-events-none opacity-0'
          )}
          role="group"
          aria-label="Media Controls"
        >
          <div className="flex w-full flex-col gap-3">
            <Progress
              duration={duration}
              ref={progressRef}
              onTimeUpdate={(time) => {
                if (player.current) player.current.currentTime = time;
              }}
            />

            <div className="flex flex-row items-center justify-between gap-2">
              <div className="flex flex-row items-center gap-2">
                <Loading02
                  className={
                    isLoading
                      ? 'block h-8 w-8 animate-spin drop-shadow-lg'
                      : 'hidden'
                  }
                />

                <motion.div
                  className={isLoading ? 'hidden' : 'block'}
                  variants={buttonAnimationVariants}
                  whileHover="hover"
                  whileTap="tap"
                >
                  <button
                    onClick={togglePlayPause}
                    className="flex h-8 w-8 appearance-none text-white"
                    aria-label="Play/Pause"
                  >
                    <PlayCircle
                      className={classNames(
                        'h-8 w-8 drop-shadow-lg',
                        playing ? 'hidden' : 'block'
                      )}
                    />
                    <PauseCircle
                      className={classNames(
                        'h-8 w-8 drop-shadow-lg',
                        playing ? 'block' : 'hidden'
                      )}
                    />
                  </button>
                </motion.div>
                {duration !== null && (
                  <time className="flex items-center text-sm font-bold leading-none text-white drop-shadow-md">
                    {secondsToDuration(time !== null ? time : duration)}
                  </time>
                )}
              </div>

              <div className="flex flex-row items-center gap-2">
                <motion.button
                  onClick={toggleMute}
                  variants={buttonAnimationVariants}
                  whileHover="hover"
                  whileTap="tap"
                  className="appearance-none"
                >
                  <VolumeMax
                    className={classNames(
                      'h-6 w-6 drop-shadow-lg',
                      muted ? 'hidden' : 'block'
                    )}
                  />
                  <VolumeX
                    className={classNames(
                      'h-6 w-6 drop-shadow-lg',
                      muted ? 'block' : 'hidden'
                    )}
                  />
                </motion.button>

                <motion.button
                  onClick={enterFullscreen}
                  variants={buttonAnimationVariants}
                  whileHover="hover"
                  whileTap="tap"
                  className="appearance-none"
                >
                  <Maximise02
                    className="flex h-6 w-6 text-white"
                    aria-label="Fullscreen"
                  />
                </motion.button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Video;
