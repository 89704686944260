import React, { Fragment, forwardRef, useState } from 'react';
import { Listbox } from '@headlessui/react';
import { Float } from '@headlessui-float/react';
import classNames from 'classnames';
import { Icon } from 'src/components/Icon';
import Check from 'src/assets/svgicons/duocolor/check.svg';
import ChevronDown from 'src/assets/svgicons/duocolor/chevron-down.svg';
import { useI18nContext } from 'src/i18n/i18n-react';
import Input from '../Input';
import type { SelectOption, SelectProps } from './types';

function search<T>(
  query: string,
  options:
    | (T extends SelectOption<infer E> ? SelectOption<E> : T)[]
    | readonly (T extends SelectOption<infer E> ? SelectOption<E> : T)[]
): (T extends SelectOption<infer E, string> ? SelectOption<E, string> : T)[] {
  return options.filter((option) => {
    if (option && typeof option === 'object' && 'label' in option) {
      return typeof option.label === 'string'
        ? option.label.toLowerCase().includes(query.toLowerCase())
        : '';
    }

    return typeof option === 'string'
      ? option.toLowerCase().includes(query.toLowerCase())
      : '';
  });
}

function Select<T extends SelectOption<unknown> | string>(
  {
    options,
    value,
    onChange,
    name,
    action,
    label,
    renderOptionLabel,
    disabled,
    defaultValue,
    by,
    className,
    error,
    optional,
    allowSearch,
    withCheckbox = true,
  }: SelectProps<T>,
  ref?: React.Ref<HTMLElement>
): JSX.Element {
  const { LL } = useI18nContext();
  const [searchQuery, setSearchQuery] = useState('');

  return (
    <div className={className}>
      <Listbox
        ref={ref}
        disabled={disabled}
        value={value}
        onChange={onChange}
        by={by}
        name={name}
        defaultValue={defaultValue}
      >
        <div>
          {label && (
            <Listbox.Label
              htmlFor={name}
              className="mb-1 block text-xs font-semibold text-primary sm:text-sm"
            >
              {label}
              {optional && (
                <span className="text-[#9176DD]"> {LL.optional()}</span>
              )}
            </Listbox.Label>
          )}
          <Float
            flip
            as="div"
            className="relative w-full"
            floatingAs={Fragment}
            placement="bottom-start"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
            offset={8}
          >
            <Listbox.Button
              className={classNames(
                'form-input relative h-10 w-full cursor-pointer rounded-md border border-solid bg-white py-1.5 pl-3 pr-2 text-left text-sm shadow-sm disabled:cursor-not-allowed disabled:border-purple-200 disabled:text-purple-200',
                error
                  ? 'border-danger text-danger focus:border-red-500 focus:ring-red-500'
                  : 'border-purple-300 text-primary placeholder:text-primary/50 focus:border-purple-500 focus:ring-purple-500',
                className
              )}
            >
              {(render) => (
                <>
                  <span
                    className={classNames(
                      'block truncate pr-4',
                      render.value
                        ? error
                          ? 'text-danger'
                          : ''
                        : error
                        ? 'text-danger/50'
                        : 'text-primary/50'
                    )}
                  >
                    {render.value && renderOptionLabel && (
                      <span>{renderOptionLabel(render.value)}</span>
                    )}
                    {render.value &&
                      !renderOptionLabel &&
                      typeof render.value !== 'string' && (
                        <span>{render.value?.label}</span>
                      )}
                    {render.value &&
                      !renderOptionLabel &&
                      typeof render.value === 'string' && (
                        <span>{render.value}</span>
                      )}
                    {!render.value && (
                      <span className="font-medium">{LL.noneSelected()}</span>
                    )}
                  </span>
                  <span
                    className={classNames(
                      'pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2',
                      error ? 'text-danger' : 'text-purple-500'
                    )}
                  >
                    <Icon className="h-5 w-5 text-primary" aria-hidden="true">
                      <ChevronDown />
                    </Icon>
                  </span>
                </>
              )}
            </Listbox.Button>

            <div className="flex max-h-60 w-full flex-col overflow-hidden text-clip rounded-md border border-solid border-purple-300 bg-white shadow-md focus:outline-none">
              <Listbox.Options className="w-full list-none overflow-auto focus:border-purple-500 focus:outline-none focus:ring-purple-500">
                {allowSearch && (
                  <div className="sticky top-0 z-[2] bg-white p-2">
                    <Input
                      className="sticky top-0 z-10 flex-auto bg-white"
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                      }}
                      value={searchQuery}
                      placeholder={LL.searchPlaceholder()}
                      name="select-search"
                      label=""
                    />
                  </div>
                )}
                {search(searchQuery, options).map((option) => (
                  <Fragment
                    key={typeof option === 'object' ? option.id : option}
                  >
                    <Listbox.Option
                      className={({ active }) =>
                        classNames(
                          active
                            ? 'bg-purple-100 text-primary'
                            : 'text-primary',
                          withCheckbox ? 'pl-9' : 'pl-4',
                          'relative cursor-default select-none gap-3 py-2 pr-4 text-sm font-semibold',
                          typeof option === 'object' && option.disabled
                            ? 'cursor-not-allowed opacity-50'
                            : ''
                        )
                      }
                      value={option}
                      disabled={
                        (typeof option === 'object' && option.disabled) || false
                      }
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? 'font-semibold' : 'font-normal'
                            }`}
                          >
                            {renderOptionLabel
                              ? renderOptionLabel(option)
                              : typeof option === 'object'
                              ? option.label
                              : option}
                          </span>
                          {selected && withCheckbox ? (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-primary">
                              <Icon className="h-3 w-3" aria-hidden="true">
                                <Check />
                              </Icon>
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  </Fragment>
                ))}
              </Listbox.Options>
              {action}
            </div>
          </Float>
        </div>
      </Listbox>
      {error && (
        <p className="mt-2 text-xs text-danger sm:text-sm" id={`${name}-error`}>
          {error}
        </p>
      )}
    </div>
  );
}

const ForwardedSelect = forwardRef(Select) as <
  T extends SelectOption<unknown> | string,
>(
  props: SelectProps<T> & { ref?: React.Ref<HTMLElement> }
) => React.ReactElement;

export default ForwardedSelect;
