import { FC, PropsWithChildren } from 'react';
import { Dialog } from '@headlessui/react';
import classNames from 'classnames';
import ModalCloseButton from './ModalCloseButton';

type Props = {
  onClose?: (() => void) | null;
  className?: string;
};

const ModalHeader: FC<PropsWithChildren<Props>> = ({
  children,
  onClose,
  className,
}) => {
  return (
    <div className={classNames('flex flex-row justify-between', className)}>
      <Dialog.Title
        as="h3"
        className="truncate text-2xl font-bold text-primary"
      >
        {children}
      </Dialog.Title>
      {onClose && <ModalCloseButton onClick={onClose} />}
    </div>
  );
};

export default ModalHeader;
