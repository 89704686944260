import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { getQueryKey } from '@trpc/react-query';
import { useI18nContext } from 'src/i18n/i18n-react';
import { SimpleModal } from 'src/components/Modal';
import { trpc } from 'src/lib/trpc';
import CreateNamedObjectModal from '../CreateNamedObjectModal/CreateNamedObjectModal';

type Props = Omit<
  React.ComponentProps<typeof SimpleModal>,
  'title' | 'onClose'
> & { onClose: () => void; briefUuid: string; name: string };

export const RenameTemplateModal: React.FunctionComponent<Props> = ({
  onClose,
  name,
  briefUuid,
  ...rest
}): JSX.Element => {
  const { LL } = useI18nContext();
  const [error, setError] = useState<string | null>();
  const updateBrief = trpc.briefs.updateBrief.useMutation();
  const queryClient = useQueryClient();

  const onSubmit = async (name: string) => {
    updateBrief.mutate(
      { data: { name }, uuid: briefUuid },
      {
        onError: (error) => {
          setError(error.message);
        },
        onSuccess: () => {
          onClose();
          void queryClient.invalidateQueries(
            getQueryKey(trpc.briefs.getBriefTemplates, undefined, 'any')
          );
        },
      }
    );
  };

  return (
    <CreateNamedObjectModal
      {...rest}
      onClose={onClose}
      onSubmit={onSubmit}
      error={error}
      title={LL.rename()}
      isSubmitting={updateBrief.isLoading}
      initialName={name}
      submitLabel={LL.rename()}
    />
  );
};
