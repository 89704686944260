import { FC, useEffect, useState } from 'react';
import { useMatch } from 'react-router-dom';
import OnboardingModal from 'src/components/OnboardingModal';
import { useChurnkeyCheck } from 'src/utils/useChurnkeyPausewall';
import useFeatureFlag from 'src/utils/useFeatureFlag';
import { useOrganisationInvitation } from 'src/utils/useOrganisationInvitation';
import { useUserAndOrganisation } from 'src/utils/useUserAndOrganisation';
import { UpgradeModalV2 } from 'src/components/Modals/UpgradeModalV2/UpgradeModalV2';
import { OrganisationInvitationModal } from '../OrganisationInvitation';
import './churnkey.css';

const UniversalModals: FC = () => {
  const user = useUserAndOrganisation();
  const intercomTourId = useFeatureFlag('INTERCOM_TOUR_ID');
  const { inviteCode } = useOrganisationInvitation();

  const [isOnboarding, setIsOnboarding] = useState(false);
  const [paywallDismissed, setPaywallDismissed] = useState(false);

  const isSettings = !!useMatch({ path: '/settings', end: true });

  // shows pausewall if organisation subscription is paused
  useChurnkeyCheck({
    show: user.data?.organisation.billingState === 'paused',
    stripeCustomerID: user.data?.organisation.stripeCustomerID,
  });

  useEffect(() => {
    const isUserEligibleToOnboard =
      user.data && !user.data.isOnboarded && !isOnboarding;

    if (isUserEligibleToOnboard) {
      setIsOnboarding(true);
    }
  }, [user.data, isOnboarding]);

  return (
    <>
      {user.data && isOnboarding && !inviteCode && (
        <OnboardingModal
          onComplete={() => {
            if (intercomTourId) {
              Intercom('startTour', intercomTourId);
            }
            setIsOnboarding(false);
          }}
        />
      )}
      <OrganisationInvitationModal />
      <UpgradeModalV2
        isOpen={
          !paywallDismissed &&
          !isSettings &&
          user.data?.organisation.billingState === 'paywalled'
        }
        close={() => setPaywallDismissed(true)}
      />
    </>
  );
};

export default UniversalModals;
