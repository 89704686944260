import { Column, ColumnProps, Group } from 'react-aria-components';
import classNames from 'classnames';
import { Icon } from 'src/components/Icon';
import ArrowUp from 'src/assets/svgicons/duocolor/arrow-up.svg';

export const TableColumn: React.FC<React.PropsWithChildren<ColumnProps>> = ({
  children,
  className,
  ...columnProps
}) => {
  return (
    <Column
      {...columnProps}
      className={(params) =>
        classNames(
          'align-middle z-10 bg-[#F9F8FF] backdrop-blur-md border-b-gray-200 border-solid border-b sticky top-0 p-0 cursor-default whitespace-nowrap outline-none',
          typeof className === 'function' ? className(params) : className
        )
      }
    >
      {({ allowsSorting, sortDirection }) => (
        <div className="flex items-center pl-4 py-1 text-xs font-semibold h-11 text-left">
          <Group
            role="presentation"
            tabIndex={-1}
            className="flex flex-1 items-center overflow-hidden outline-none rounded focus-visible:ring-2 ring-purple-600"
          >
            <span className="flex-1 truncate">{children}</span>
            {allowsSorting && (
              <span
                className={`ml-1 w-4 h-4 flex items-center justify-center transition ${
                  sortDirection === 'descending' ? 'rotate-180' : ''
                }`}
              >
                {sortDirection && (
                  <Icon className="w-2.5 h-2.5">
                    <ArrowUp />
                  </Icon>
                )}
              </span>
            )}
          </Group>
        </div>
      )}
    </Column>
  );
};

TableColumn.displayName = 'TableColumn';

export default TableColumn;
