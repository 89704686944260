import Analytics, { AnalyticsInstance } from 'analytics';
import googleTagManager from '@analytics/google-tag-manager';
import { getFeatureFlagValue } from '../utils/getFeatureFlagValue';

export let analyticsInstance: AnalyticsInstance;

export function analytics() {
  try {
    if (analyticsInstance == null) {
      analyticsInstance = Analytics({
        plugins: [
          googleTagManager({
            containerId: getFeatureFlagValue('GTM_CONTAINER_ID'),
          }),
        ],
      });
    }

    return analyticsInstance;
  } catch (err) {
    console.error('Error loading GTM', err);
  }
}
