import { IntRange } from '@magicbrief/common';

type ColorStop = {
  color: {
    r: number;
    g: number;
    b: number;
    a?: number;
  };
  position: number;
};

const defaultColorStops: ColorStop[] = [
  { color: { r: 231, g: 95, b: 64 }, position: 0 },
  { color: { r: 237, g: 120, b: 55 }, position: 25 },
  { color: { r: 247, g: 195, b: 85 }, position: 50 },
  { color: { r: 138, g: 220, b: 77 }, position: 75 },
  { color: { r: 64, g: 212, b: 80 }, position: 100 },
];

/**
 * Extract the colour for a percent value within a range of colours
 * @param {Object} params - the function parameters
 * @param {IntRange<0, 100>} params.percentage - the percentage to extract the sentiment colour for (0-100 range)
 * @param {ColorStop} params.colors - the colour range of which the above percentage value falls within
 * @param {string} params.defaultColor - the fallback colour when we cannot plot the percentage within the colour range
 * @param {boolean} params.gradient - whether to apply a subtle gradient to the final colour or not
 **/
export function getSentimentColor({
  percentage,
  colors = defaultColorStops,
  defaultColor = '#F4F4F4',
  gradient = false,
}: {
  percentage: IntRange<0, 100>;
  colors?: ColorStop[];
  defaultColor?: string;
  gradient?: boolean;
}): string {
  const rounded = Math.min(percentage, 100);

  for (let i = 0; i < colors.length - 1; i++) {
    const startStop = colors[i];
    const endStop = colors[i + 1];

    if (rounded >= startStop.position && rounded <= endStop.position) {
      const endPercentage = endStop.position;
      const startPercentage = startStop.position;
      const ratioBetweenStops =
        (endPercentage - rounded) / (endPercentage - startPercentage);

      if (ratioBetweenStops === 0) {
        return `rgb(${endStop.color.r},${endStop.color.g},${endStop.color.b})`;
      }

      const r = Math.ceil(
        startStop.color.r * ratioBetweenStops +
          endStop.color.r * (1 - ratioBetweenStops)
      );
      const g = Math.ceil(
        startStop.color.g * ratioBetweenStops +
          endStop.color.g * (1 - ratioBetweenStops)
      );
      const b = Math.ceil(
        startStop.color.b * ratioBetweenStops +
          endStop.color.b * (1 - ratioBetweenStops)
      );

      if (!gradient) {
        return `rgb(${r},${g},${b})`;
      }

      const stepBackRatio = Math.max(0, ratioBetweenStops - 0.1);
      const r2 = Math.ceil(
        startStop.color.r * stepBackRatio +
          endStop.color.r * (1 - stepBackRatio)
      );
      const g2 = Math.ceil(
        startStop.color.g * stepBackRatio +
          endStop.color.g * (1 - stepBackRatio)
      );
      const b2 = Math.ceil(
        startStop.color.b * stepBackRatio +
          endStop.color.b * (1 - stepBackRatio)
      );

      return `linear-gradient(to right, rgb(${r2},${g2},${b2}), rgb(${r},${g},${b}))`;
    }
  }

  return defaultColor;
}
