import { FC, useMemo } from 'react';
import classNames from 'classnames';

type Props = {
  id?: string;
  html: string;
  height: number | null;
  width: number | null;
  className?: string;
};

const heightRegex = /height="(\d+)"/;
const widthRegex = /width="(\d+)"/;

export const FacebookVideoIframe: FC<Props> = ({
  id,
  html,
  height,
  width,
  className,
}) => {
  const [style, htmlObj] = useMemo(() => {
    const heightMatch = height === null ? html.match(heightRegex) : null;
    const widthMatch = width === null ? html.match(widthRegex) : null;

    const calcHeight = heightMatch ? parseInt(heightMatch[1]) : null;
    const calcWidth = widthMatch ? parseInt(widthMatch[1]) : null;

    return [
      { aspectRatio: `${width ?? calcWidth} / ${height ?? calcHeight}` },
      { __html: html },
    ];
  }, [html, width, height]);

  return (
    <div
      id={id}
      style={style}
      className={classNames(
        'flex w-full flex-auto [&>iframe]:h-full [&>iframe]:w-full',
        className
      )}
      dangerouslySetInnerHTML={htmlObj}
    />
  );
};
