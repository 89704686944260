import classNames from 'classnames';

type Props = React.PropsWithChildren<{
  className?: string;
}>;

type ToolbarComposite = {
  Content: React.FunctionComponent<Props>;
};

const Toolbar: React.FunctionComponent<Props> & ToolbarComposite = ({
  children,
  className,
}) => {
  return (
    <div
      className={classNames(
        'min-h-16 border-b border-solid border-b-purple-300 bg-purple-50/90 backdrop-blur-md',
        className
      )}
    >
      {children}
    </div>
  );
};

const ToolbarContent: React.FunctionComponent<Props> = ({
  children,
  className,
}) => {
  return (
    <div className={classNames('px-5 py-3 md:pl-10', className)}>
      {children}
    </div>
  );
};

Toolbar.Content = ToolbarContent;

export default Toolbar;
