import classNames from 'classnames';
import { Icon } from 'src/components/Icon';
import MagicBriefBolt from 'src/assets/svgicons/magicbrief/MagicBriefBolt.svg';

type Props = {
  profilePicURL?: string | null;
  className?: string;
};

const OrganisationAvatar: React.FunctionComponent<Props> = ({
  profilePicURL,
  className,
}): JSX.Element => {
  return (
    <div
      className={classNames(
        'flex h-full w-full items-center justify-center overflow-hidden rounded-lg bg-purple-800',
        className
      )}
    >
      {profilePicURL ? (
        <img alt="" className="h-full object-cover" src={profilePicURL} />
      ) : (
        <div className="w-1/2 text-white">
          <Icon className="w-full">
            <MagicBriefBolt />
          </Icon>
        </div>
      )}
    </div>
  );
};

export default OrganisationAvatar;
