import React from 'react';
import { DeprecatedCollectionType } from '@magicbrief/prisma/generated/client2';
import { Navigate, useParams } from 'react-router-dom';
import { Alert } from 'src/components/Alert';
import { LibraryLoader } from 'src/components/SuspenseLoaders/SuspenseLoaders';
import { trpc } from 'src/lib/trpc';
import useFeatureFlag from 'src/utils/useFeatureFlag';

type Props = React.PropsWithChildren<{
  basePath: string;
  collectionType: Exclude<DeprecatedCollectionType, 'ad_folder'>;
  by: 'collectionId' | 'collectionUuid' | 'collectionShare';
  param: string;
}>;

function createQuery(
  by: Props['by'],
  collectionType: Props['collectionType'],
  value: string | undefined
) {
  if (!value) {
    return { by: 'collectionId', collectionType, collectionId: 0 } as const;
  }
  switch (by) {
    case 'collectionId':
      return {
        by: 'collectionId',
        collectionId: parseInt(value),
        collectionType,
      } as const;
    case 'collectionUuid':
      return {
        by: 'collectionUuid',
        collectionUuid: value,
        collectionType,
      } as const;
    case 'collectionShare':
      return {
        by: 'collectionShare',
        collectionShare: value,
        collectionType,
      } as const;
  }
}

export const RedirectCollectionToDirectory: React.FC<Props> = ({
  basePath,
  children,
  by,
  param,
  collectionType,
}) => {
  const featureFlag = useFeatureFlag('DIRECTORIES');
  const params = useParams();
  const queryInput = createQuery(by, collectionType, params[param]);

  const query =
    trpc.directories.getDirectoryUuidForDeprecatedCollection.useQuery(
      queryInput,
      {
        enabled: !!featureFlag && !!queryInput,
      }
    );

  if ((!!featureFlag && query.isLoading) || featureFlag === null) {
    return <LibraryLoader />;
  }

  if (!!featureFlag && query.error) {
    return <Alert type="error">Invalid URL</Alert>;
  }

  if (featureFlag === false) {
    return children;
  }

  if (!!featureFlag && !query.isLoading && query.data) {
    return <Navigate to={`${basePath}/${query.data.uuid}`} />;
  }

  return <Navigate to="/library" />;
};
