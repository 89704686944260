import { FC, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Icon } from 'src/components/Icon';
import Sidebar from 'src/components/Sidebar/Sidebar';
import File02 from 'src/assets/svgicons/duocolor/file-02.svg';
import Hash01 from 'src/assets/svgicons/duocolor/hash-01.svg';
import PlusCircle from 'src/assets/svgicons/duocolor/plus-circle.svg';
import Building06 from 'src/assets/svgicons/duocolor/building-06.svg';
import Grid01 from 'src/assets/svgicons/duocolor/grid-01.svg';
import { useI18nContext } from 'src/i18n/i18n-react';
import { BaseButton } from 'src/components/Button';
import { trpc } from 'src/lib/trpc';
import useFeatureFlag from 'src/utils/useFeatureFlag';
import { SidebarDirectories } from 'src/components/Directories/SidebarDirectories/SidebarDirectories';
import { useBriefsDispatchContext } from '../../context/useBriefsDispatchContext';

const BriefsSidebar: FC = () => {
  const { LL } = useI18nContext();
  const collections = trpc.briefs.getBriefCollections.useQuery();
  const dispatch = useBriefsDispatchContext();
  const directoriesFeatureFlag = useFeatureFlag('DIRECTORIES');

  useEffect(() => {
    if (collections.error) {
      toast.error(LL.briefs.errors.listCollections(), {
        className: 'toast-danger',
      });
    }
  }, [collections.error, LL.briefs.errors]);

  return (
    <Sidebar
      side="left"
      className="flex h-full w-full flex-col justify-between gap-6 overflow-y-auto bg-white p-5"
    >
      <Sidebar.Content className="min-h-0">
        <Sidebar.Section title={LL.navigation.briefs()}>
          <Sidebar.NavLink end to="/briefs">
            <Icon>
              <File02 />
            </Icon>
            <span>{LL.briefs.briefs()}</span>
          </Sidebar.NavLink>
          <Sidebar.NavLink to="/briefs/concepts">
            <Icon>
              <Grid01 />
            </Icon>
            <span>{LL.briefs.concepts()}</span>
          </Sidebar.NavLink>
          <Sidebar.NavLink to="/briefs/brands">
            <Icon>
              <Building06 />
            </Icon>
            <span>{LL.briefs.profiles()}</span>
          </Sidebar.NavLink>
        </Sidebar.Section>
        {directoriesFeatureFlag ? (
          <SidebarDirectories basePath="/briefs/d" />
        ) : (
          <Sidebar.Section
            className="min-h-[200px]"
            contentClassName="overflow-y-auto min-h-0"
            title={
              <div className="flex flex-auto flex-row items-center justify-between">
                {LL.briefs.collections()}
                <BaseButton
                  className="h-5 w-5 text-primary"
                  icon={
                    <Icon>
                      <PlusCircle />
                    </Icon>
                  }
                  onClick={() =>
                    dispatch({ type: 'showCreateCollectionModal' })
                  }
                />
              </div>
            }
          >
            {collections.data?.map((x) => (
              <Sidebar.NavLink key={x.id} to={`/briefs/collection/${x.id}`}>
                <Icon className="h-4 w-4 shrink-0 text-primary/80 icon-path:stroke-[3]">
                  <Hash01 />
                </Icon>
                <span className="truncate">{x.name}</span>
                <span className="items flex-auto pr-[5px] text-end text-purple-300 group-hover:text-primary group-[.sidebar-nav-active]:text-primary">
                  {x._count.BriefsInCollections}
                </span>
              </Sidebar.NavLink>
            ))}
          </Sidebar.Section>
        )}
      </Sidebar.Content>
    </Sidebar>
  );
};

export default BriefsSidebar;
