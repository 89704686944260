import { useRef, useState } from 'react';
import * as Sentry from '@sentry/react';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { inferProcedureOutput } from '@trpc/server';
import { AppRouter } from '@magicbrief/server/src/trpc/router';
import { getQueryKey } from '@trpc/react-query';
import { Icon } from 'src/components/Icon';
import { BaseModal } from 'src/components/Modal';
import { Button } from 'src/components/Button';
import { useI18nContext } from 'src/i18n/i18n-react';
import Input from 'src/components/Input/Input';
import TextArea from 'src/components/TextArea/TextArea';
import { uploadFile } from 'src/utils/requests';
import XClose from 'src/assets/svgicons/duocolor/x-close.svg';
import Upload01 from 'src/assets/svgicons/duocolor/upload-01.svg';
import { trpc } from 'src/lib/trpc';
import ImageComponent from 'src/components/Image/Image';
import { Checkbox } from 'src/components/Checkbox';
import { Alert } from 'src/components/Alert';

// Using a standard template to ensure all ad pack cover images are of dimension 520 x 800px
const IMAGE_DIMENSION = {
  width: 520,
  height: 800,
};

type CommonProps = {
  show: boolean;
  onClose: () => void;
} & React.PropsWithChildren;

type DirectoryIdProps = CommonProps & {
  directoryId: number;
  directoryUuid?: never;
};

type DirectoryUuidProps = CommonProps & {
  directoryId?: never;
  directoryUuid: string;
};

type Props = (DirectoryIdProps | DirectoryUuidProps) & React.PropsWithChildren;

const CreatePackModal: React.FunctionComponent<Props> = ({
  directoryId,
  directoryUuid,
  show,
  onClose,
}) => {
  const { LL } = useI18nContext();
  const uploadInput = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [isUploading, setIsUploading] = useState(false);
  const [name, setName] = useState('');
  const [thumbnailUrl, setThumbnailUrl] = useState('');
  const [description, setDescription] = useState('');
  const [isFree, setIsFree] = useState(true);
  const queryClient = useQueryClient();
  const [uploadedWidth, setUploadedWidth] = useState<number>();
  const [uploadedHeight, setUploadedHeight] = useState<number>();

  const createAdPack = trpc.adPacks.createAdPack.useMutation({
    onSuccess(data) {
      toast.success(LL.packs.create.success());
      queryClient.setQueriesData<
        inferProcedureOutput<AppRouter['adPacks']['getAdPacks']>
      >(getQueryKey(trpc.adPacks.getAdPacks, undefined, 'query'), (oldData) => {
        return oldData ? [...oldData, data] : oldData;
      });
      onClose();
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  const handleUploadThumbnail = async (file: File) => {
    try {
      const res = await uploadFile(file, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (res.status === 201) {
        const { data } = res;

        setThumbnailUrl(data.src);
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const handleCreatePack = async () => {
    if (isUploading || !name || !description || !thumbnailUrl) return;
    try {
      setIsUploading(true);

      createAdPack.mutate({
        directoryId,
        directoryUuid,
        details: {
          name,
          description,
          thumbnailUrl,
          isFree,
        },
      });
    } catch (e) {
      Sentry.captureException(e);
    } finally {
      setIsUploading(false);
    }
    setIsUploading(false);
  };

  const imageOnChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files || files.length === 0) return;
    const objectURL = URL.createObjectURL(files[0]);

    const image = new Image();
    image.src = objectURL;
    await image.decode();

    if (
      IMAGE_DIMENSION.width !== image.width ||
      IMAGE_DIMENSION.height !== image.height
    ) {
      setUploadedWidth(image.width);
      setUploadedHeight(image.height);
      return;
    } else {
      setUploadedWidth(undefined);
      setUploadedHeight(undefined);
    }

    await handleUploadThumbnail(files[0]);
  };

  const handleOnClose = () => {
    setUploadedWidth(undefined);
    setUploadedHeight(undefined);
    onClose();
  };

  return (
    <BaseModal
      show={show}
      panelClassName="bg-white rounded-xl relative w-full sm:w-11/12 max-w-xl p-5"
      onClose={handleOnClose}
    >
      <button
        className="absolute right-6 top-6 h-6 w-6 appearance-none text-text-secondary"
        onClick={handleOnClose}
      >
        <Icon>
          <XClose />
        </Icon>
      </button>
      <div className="flex flex-col gap-2">
        <h3 className="text-[24px] font-bold leading-8 text-primary">
          {LL.packs.create.title()}
        </h3>

        <div className="aspect-[19/10] flex flex-row gap-3 py-4">
          <button
            className="w-[35%] shrink-0 relative"
            onClick={() => uploadInput.current?.click()}
          >
            {thumbnailUrl ? (
              <ImageComponent
                className="w-full h-full shrink-0 rounded-md object-cover"
                src={thumbnailUrl}
                alt="Ad Pack Image"
              />
            ) : (
              <div className="absolute top-0 left-0 w-full gap-2 text-primary flex-col justify-center h-full flex items-center bg-purple-50 rounded-md border border-dashed border-secondary">
                <Icon>
                  <Upload01 />
                </Icon>
                <span className="text-xs">
                  {LL.packs.create.thumbnailPlaceholder()}
                </span>
              </div>
            )}

            <input
              className="hidden"
              max={1}
              type="file"
              name="thumbnail"
              ref={uploadInput}
              onChange={imageOnChange}
              accept="image/png,image/jpeg,image/jpg"
            />
          </button>

          <div className="grow flex flex-col gap-2">
            <Checkbox
              checked={isFree}
              onChange={(e) => setIsFree(e.target.checked)}
            >
              Free
            </Checkbox>
            <Input
              value={name}
              placeholder={LL.packs.create.namePlaceholder()}
              label=""
              name="Name"
              onChange={(e) => {
                setName(e.target.value);
              }}
            />

            <TextArea
              rows={9}
              label=""
              name="Description"
              value={description}
              placeholder={LL.packs.create.descriptionPlaceholder()}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </div>
        </div>

        <Button
          variant="primary"
          colour="primary"
          onClick={handleCreatePack}
          loading={isUploading}
        >
          {LL.packs.create.create()}
        </Button>
        <Button variant="secondary" onClick={handleOnClose}>
          {LL.cancel()}
        </Button>
      </div>
      {uploadedWidth && uploadedHeight && (
        <div className="p-8">
          <Alert type="error">
            <div className="flex flex-col">
              <div className="text-base pb-3">Invalid image dimensions :(</div>
              <div className="text-sm font-normal">
                Required: {IMAGE_DIMENSION.width} x {IMAGE_DIMENSION.height}
              </div>
              <div className="text-sm font-normal">
                Uploaded: {uploadedWidth} x {uploadedHeight}
              </div>
            </div>
          </Alert>
        </div>
      )}
    </BaseModal>
  );
};

export default CreatePackModal;
