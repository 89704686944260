import { FC, useState } from 'react';
import { captureException } from '@sentry/react';
import { useI18nContext } from 'src/i18n/i18n-react';
import { SimpleModal } from 'src/components/Modal';
import CreateNamedObjectModal from 'src/pages/Briefs/components/CreateNamedObjectModal';
import { useCreateAssetCollection } from '../AssetCard/useSaveAssetToCollection';

type Props = Omit<
  React.ComponentProps<typeof SimpleModal>,
  'title' | 'onClose'
> & { onClose: () => void };

const CreateAssetCollectionModal: FC<Props> = ({ onClose, ...rest }) => {
  const { LL } = useI18nContext();
  const [error, setError] = useState<string | null>();
  const createCollection = useCreateAssetCollection(() => onClose());

  const onSubmit = async (name: string) => {
    createCollection.mutate(
      { name },
      {
        onError: (error) => {
          captureException(error);
          setError(error.message);
        },
      }
    );
  };

  return (
    <CreateNamedObjectModal
      {...rest}
      onClose={onClose}
      onSubmit={onSubmit}
      error={error}
      title={LL.briefs.createCollection()}
      isSubmitting={createCollection.isLoading}
      submitLabel={LL.briefs.createCollection()}
    />
  );
};

export default CreateAssetCollectionModal;
