import React, { forwardRef } from 'react';

const Icon = forwardRef<SVGSVGElement, React.SVGAttributes<SVGElement>>(
  (props, ref) => {
    return (
      <svg ref={ref} {...props} height="24" width="24" viewBox="0 0 24 24" />
    );
  }
);

Icon.displayName = 'Icon';

export { Icon };
