import { toast } from 'react-toastify';
import { Icon } from 'src/components/Icon';
import RefreshCw02 from 'src/assets/svgicons/duocolor/refresh-cw-02.svg';
import { Button } from 'src/components/Button';
import ShareSettings from 'src/components/ShareSettings/ShareSettings';
import { useI18nContext } from 'src/i18n/i18n-react';
import {
  useRegenerateBriefShareLink,
  useUpdateBrief,
} from 'src/pages/Briefs/hooks';
import useBriefLink from 'src/utils/useBriefLink';
import { PermissionManager } from 'src/components/PermissionManager';
import { segment } from 'src/lib/segment';
import type { Brief } from '@magicbrief/prisma/generated/client2';

type Props = {
  brief: Omit<Brief, 'data'>;
};

const ShareBriefSettings: React.FunctionComponent<Props> = ({
  brief,
}): JSX.Element => {
  const { LL } = useI18nContext();
  const updateBrief = useUpdateBrief();
  const regenerateBriefShareLink = useRegenerateBriefShareLink();
  const { link, copyToClipboard } = useBriefLink(brief.id, brief.shareLink);
  const onSettingChange = (isPublic: boolean) => {
    updateBrief.mutate({
      uuid: brief.uuid,
      data: { public: isPublic },
    });
  };
  const onRevokeLink = () => {
    regenerateBriefShareLink.mutate(
      {
        id: brief.id,
      },
      {
        onSuccess: () => {
          toast.success(LL.revokeLinkSuccess(), { className: 'toast-success' });
        },
      }
    );
  };

  return (
    <div className="flex flex-col gap-8">
      <ShareSettings
        link={regenerateBriefShareLink.isLoading ? LL.regeneratingLink() : link}
        isLoading={updateBrief.isLoading}
        isPublic={brief.realm === 'public'}
        onUpdate={onSettingChange}
        onCopyToClipboard={() => {
          if (brief.realm === 'public') {
            void segment?.track('shared_brief_public');
          } else {
            void segment?.track('shared_brief_private');
          }
          copyToClipboard();
        }}
        screenReaderLabel={LL.briefs.shareScreenReader({
          name: brief.name,
        })}
        publicDescription={LL.briefs.share.public.description()}
        privateDescription={LL.briefs.share.private.description()}
      >
        <Button
          loading={regenerateBriefShareLink.isLoading}
          icon={
            <Icon>
              <RefreshCw02 />
            </Icon>
          }
          variant="secondary"
          onClick={onRevokeLink}
        >
          {LL.revokeLink()}
        </Button>
      </ShareSettings>
      <PermissionManager entityType="Brief" entityUuid={brief.uuid} />
    </div>
  );
};

export default ShareBriefSettings;
