import type { Directory } from '@magicbrief/server/src/directories/types';

export function computeDirectoryPathFromDirectoryToRoot(
  fromDirectory: string,
  directories: Partial<Record<string, Directory>>
) {
  const path: string[] = [];

  const directory = directories[fromDirectory];
  if (!directory) {
    return null;
  }

  let parent = directory.parent;

  while (parent) {
    path.push(parent);
    parent = directories[parent]?.parent;
  }

  return path;
}

export function isDirectoryChildOfDirectory(
  directoryNodeParentUuid: string,
  directoryUuid: string,
  directories: Partial<Record<string, Directory>>
) {
  if (directoryNodeParentUuid === directoryUuid) {
    return true;
  }

  const directory = directories[directoryNodeParentUuid];
  if (!directory?.parent) {
    return false;
  }

  return isDirectoryChildOfDirectory(
    directory.parent,
    directoryUuid,
    directories
  );
}

export function computeAllChildDirectoriesOfDirectory(
  fromDirectory: string,
  directories: Partial<Record<string, Directory>>
): string[] {
  const children: string[] = [];

  const process: string[] = [fromDirectory];

  while (process.length) {
    const next = process.pop();
    if (!next) {
      continue;
    }
    const directory = directories[next];
    if (!directory) {
      continue;
    }
    process.push(...directory.children);
    children.push(...directory.children);
  }

  return children;
}
