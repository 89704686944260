import { ComponentProps, FC, PropsWithChildren, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { SimpleModal } from 'src/components/Modal';
import Input from 'src/components/Input/Input';
import { useI18nContext } from 'src/i18n/i18n-react';
import { Alert } from 'src/components/Alert';
import { AriaButton } from '../../../../components/Button/Button';

type Props = PropsWithChildren<{
  error?: string | null;
  onSubmit: (name: string) => Promise<void> | void;
  submitLabel: string;
  isSubmitting: boolean;
  onClose: () => void;
  initialName?: string;
}> &
  Omit<ComponentProps<typeof SimpleModal>, 'onSubmit' | 'onClose'>;

const CreateNamedObjectModal: FC<Props> = ({
  show,
  onClose,
  title,
  onSubmit,
  error,
  submitLabel,
  isSubmitting,
  children,
  afterClose,
  initialName,
  ...modalProps
}) => {
  const { LL } = useI18nContext();
  const validator = useMemo(
    () =>
      z.object({
        name: z.string().min(1, { message: LL.errors.fieldRequired() }),
      }),
    [LL]
  );
  const ref = useRef<HTMLInputElement | null>(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<z.infer<typeof validator>>({
    resolver: zodResolver(validator),
    values: {
      name: initialName ?? '',
    },
  });

  const onSubmitHandler = handleSubmit(async ({ name }) => {
    await onSubmit(name);
  });

  const { ref: rhfRef, ...restRegister } = register('name');

  return (
    <SimpleModal
      {...modalProps}
      initialFocus={ref}
      show={show}
      title={title}
      onClose={onClose}
      afterClose={() => {
        reset();
        afterClose?.();
      }}
    >
      <form onSubmit={onSubmitHandler} className="flex flex-col gap-3">
        {error && <Alert type="error">{error}</Alert>}
        <Input
          {...restRegister}
          ref={(e) => {
            rhfRef(e);
            ref.current = e;
          }}
          label=""
          disabled={isSubmitting}
          placeholder={LL.labels.name()}
          error={errors.name?.message}
        />
        {children}
        <AriaButton loading={isSubmitting} htmlType="submit">
          {submitLabel}
        </AriaButton>
      </form>
    </SimpleModal>
  );
};

export default CreateNamedObjectModal;
