import React, { Fragment, forwardRef, useCallback, useState } from 'react';
import {
  Combobox as HeadlessCombobox,
  ComboboxOptionProps as HeadlessComboboxOptionProps,
} from '@headlessui/react';
import { Float } from '@headlessui-float/react';
import classNames from 'classnames';
import { Icon } from 'src/components/Icon';
import Check from 'src/assets/svgicons/duocolor/check.svg';
import ChevronSelectorVertical from 'src/assets/svgicons/duocolor/chevron-selector-vertical.svg';
import Input from '../Input/Input';
import type {
  AssetMediaType,
  PlatformAdAsset,
} from '@magicbrief/prisma/generated/client2';
import type { ComboboxProps } from './types';

const assets: PlatformAdAsset[] = [];

type ExcludeFromProperty<
  TType,
  TProperty extends keyof TType,
  TPropertyType,
> = {
  [TKey in keyof TType]: TKey extends TProperty
    ? Exclude<TType[TKey], TPropertyType>
    : TType[TKey];
};

assets
  .filter(
    (
      x
    ): x is ExcludeFromProperty<
      PlatformAdAsset,
      'mediaType',
      typeof AssetMediaType.other
    > => x.mediaType !== 'other'
  )
  .map((x) => {
    return {
      url: x.cloudURL,
      type: x.mediaType, // typeof x.mediaType is now 'image' | 'video'! :)
    };
  });

type ComboboxTransitionProps = {
  onHide?: () => void;
  children: React.ComponentProps<typeof Float>['children'];
  isPortal?: boolean;
  zIndex?: number;
};

export const ComboboxTransition: React.FunctionComponent<
  ComboboxTransitionProps
> = ({ children, onHide, isPortal, zIndex }) => {
  return (
    <Float
      flip
      shift
      portal={isPortal}
      adaptiveWidth={isPortal}
      zIndex={zIndex}
      as="div"
      className="relative w-full"
      floatingAs={Fragment}
      placement="bottom-start"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
      offset={8}
      onHide={onHide}
    >
      {children}
    </Float>
  );
};

type ComboboxOptionLabelProps = React.PropsWithChildren<{
  selected: boolean;
  disabled: boolean;
}>;

export const ComboboxOptionLabel: React.FunctionComponent<
  ComboboxOptionLabelProps
> = ({ selected, disabled, children }) => {
  return (
    <>
      <span
        className={classNames(
          'block truncate disabled:opacity-60',
          disabled ? 'opacity-60' : 'opacity-100',
          selected ? 'font-semibold' : 'font-normal'
        )}
      >
        {children}
      </span>
      {selected ? (
        <span
          className={classNames(
            disabled ? 'text-gray-600' : 'text-primary',
            'absolute inset-y-0 left-0 flex items-center pl-3'
          )}
        >
          <Icon className="h-3 w-3" aria-hidden="true">
            <Check />
          </Icon>
        </span>
      ) : null}
    </>
  );
};

type ComboboxOptionProps<T> = {
  value: T;
  children: HeadlessComboboxOptionProps<
    React.ElementType<unknown>,
    T
  >['children'];
  activeClassName?: string;
  inactiveClassName?: string;
};

export function ComboboxOption<T>({
  value,
  children,
  activeClassName,
  inactiveClassName,
}: ComboboxOptionProps<T>) {
  return (
    <HeadlessCombobox.Option
      className={({ active }) =>
        classNames(
          active
            ? activeClassName ?? 'bg-purple-100 text-primary'
            : inactiveClassName ?? 'text-primary',
          'relative cursor-default select-none gap-3 py-2 pl-9 pr-4 text-sm font-semibold'
        )
      }
      value={value}
    >
      {children}
    </HeadlessCombobox.Option>
  );
}

type ComboboxOptionsProps = Pick<
  React.ComponentProps<typeof HeadlessCombobox.Options>,
  'children'
> & {
  className?: string;
};

export const ComboboxOptions = forwardRef<
  HTMLUListElement,
  ComboboxOptionsProps
>(({ children, className }, ref) => {
  return (
    <HeadlessCombobox.Options
      ref={ref}
      className={classNames(
        'flex max-h-[50vh] w-full flex-col overflow-y-auto overflow-x-hidden rounded-md border border-solid border-purple-300 bg-white shadow-md focus:outline-none',
        className
      )}
    >
      {children}
    </HeadlessCombobox.Options>
  );
});

ComboboxOptions.displayName = 'ComboboxOptions';

type ComboboxInputProps<T> = {
  placeholder?: string;
  displayValue?: (option?: T | T[]) => string;
  handleQueryChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  buttonClassName?: string;
  inputClassName?: string;
};

export function ComboboxInput<T>({
  placeholder,
  displayValue,
  handleQueryChange,
  buttonClassName,
  inputClassName,
}: ComboboxInputProps<T>) {
  return (
    <>
      <HeadlessCombobox.Input
        as={Input}
        name=""
        label=""
        placeholder={placeholder}
        displayValue={displayValue}
        onChange={handleQueryChange}
        className={inputClassName}
      />

      <HeadlessCombobox.Button
        className={classNames(
          'absolute inset-y-0 right-0 h-9.5 cursor-pointer pr-2',
          buttonClassName
        )}
      >
        <div className="text-purple-500">
          <Icon className="h-5 w-5 text-primary">
            <ChevronSelectorVertical />
          </Icon>
        </div>
      </HeadlessCombobox.Button>
    </>
  );
}

ComboboxInput.displayName = 'ComboboxInput';

type State<T> = {
  filteredOptions: T[];
  filterValue: string;
};

function ComboboxContent<T>({
  options,
  filter,
  name,
  className,
  displayLabel,
  displayValue,
  identify,
  label,
  placeholder,
}: ComboboxProps<T> & { placeholder?: string }): JSX.Element {
  const [state, setState] = useState<State<T>>({
    filteredOptions: options,
    filterValue: '',
  });

  const handleQueryChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const query = event.target.value;
      setState({ filterValue: query, filteredOptions: filter(options, query) });
    },
    [filter, options]
  );

  const resetQuery = useCallback(() => {
    setState({ filteredOptions: options, filterValue: '' });
  }, [options]);

  return (
    <>
      {label && (
        <HeadlessCombobox.Label
          htmlFor={name}
          className="mb-1 block text-xs font-semibold text-primary sm:text-sm"
        >
          {label}
        </HeadlessCombobox.Label>
      )}
      <ComboboxTransition onHide={resetQuery}>
        <div className="relative">
          <ComboboxInput
            buttonClassName={className}
            placeholder={placeholder}
            displayValue={displayValue}
            handleQueryChange={handleQueryChange}
          />
        </div>

        <ComboboxOptions>
          {state.filteredOptions.map((option) => (
            <ComboboxOption value={option} key={identify(option)}>
              {({ selected }) => (
                <ComboboxOptionLabel selected={selected} disabled={false}>
                  {displayLabel(option)}
                </ComboboxOptionLabel>
              )}
            </ComboboxOption>
          ))}
        </ComboboxOptions>
      </ComboboxTransition>
    </>
  );
}

export default ComboboxContent;
