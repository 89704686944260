import { cva } from 'class-variance-authority';

export const drawerContentVariants = cva(
  [
    /* Base */
    'group fixed z-400 border-solid border-purple-200 bg-white shadow transition duration-300 ease-in-out',
    /* Focus */
    'focus-within:outline-none focus:outline-none focus-visible:outline-none',
    /* Entering */
    'data-[entering]:duration-300 data-[entering]:animate-in',
    /* Exiting */
    'data-[exiting]:duration-300 data-[exiting]:animate-out',
  ],
  {
    variants: {
      side: {
        top: 'inset-x-0 top-0 h-3/4 border-b data-[entering]:slide-in-from-top data-[exiting]:slide-out-to-top',
        bottom:
          'inset-x-0 bottom-0 h-3/4 border-t data-[entering]:slide-in-from-bottom data-[exiting]:slide-out-to-bottom',
        left: 'inset-y-0 left-0 h-full w-80 border-r data-[entering]:slide-in-from-left data-[exiting]:slide-out-to-left',
        right:
          'inset-y-0 right-0 h-full w-80 border-l data-[entering]:slide-in-from-right data-[exiting]:slide-out-to-right',
      },
    },
  }
);

export const drawerCloseButtonVariants = cva(
  [
    /* Base */
    'absolute -m-2.5 flex w-16 justify-center p-2.5',
    /* Entering */
    'group-data-[entering]:duration-300 group-data-[entering]:animate-in group-data-[entering]:fade-in-0',
    /* Exiting */
    'group-data-[exiting]:duration-300 group-data-[exiting]:animate-out group-data-[exiting]:fade-out-0',
  ],
  {
    variants: {
      side: {
        top: '-bottom-12 left-1/2 -translate-x-1/2',
        bottom: '-top-12 left-1/2 -translate-x-1/2',
        left: '-right-14 top-2',
        right: '-left-14 top-2',
      },
    },
  }
);
