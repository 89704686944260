import classNames from 'classnames';
import { Link } from 'react-router-dom';
import XClose from 'src/assets/svgicons/duocolor/x-close.svg';
import { Icon } from 'src/components/Icon';
import { Button } from '../Button';

type AlertType = 'info' | 'success' | 'warning' | 'error';

type Props = React.PropsWithChildren<{
  type?: AlertType;
  icon?: React.ReactNode | null;
  actions?: React.ReactNode | null;
  onClose?: () => void;
  className?: string;
}>;

const baseStyle =
  'relative p-3 sm:px-6 sm:py-4 flex sm:items-center text-white rounded-lg justify-between gap-2 sm:gap-4';

const infoStyle = 'bg-purple-700';
const infoIconStyle = 'bg-purple-800';

const successStyle = 'bg-green-700';
const successIconStyle = 'bg-green-800';

const warningStyle = 'bg-orange-600';
const warningIconStyle = 'bg-orange-700';

const errorStyle = 'bg-red-700';
const errorIconStyle = 'bg-red-800';

const containerStyles: Record<AlertType, string> = {
  info: infoStyle,
  success: successStyle,
  warning: warningStyle,
  error: errorStyle,
};

const iconStyles: Record<AlertType, string> = {
  info: infoIconStyle,
  success: successIconStyle,
  warning: warningIconStyle,
  error: errorIconStyle,
};

export const Alert: React.FunctionComponent<Props> = ({
  type = 'info',
  icon,
  actions,
  children,
  onClose,
  className,
}): JSX.Element => {
  return (
    <div
      className={classNames(
        baseStyle,
        containerStyles[type],
        actions ? 'flex-col sm:flex-row' : 'flex-row',
        className
      )}
    >
      <div
        className={classNames(
          'flex flex-row items-center gap-4',
          onClose ? 'pr-16 sm:pr-16' : ''
        )}
      >
        {icon && (
          <div
            className={classNames(
              'flex h-10 w-10 shrink-0 flex-row items-center justify-center rounded-lg',
              iconStyles[type]
            )}
          >
            <div className="h-6 w-6">{icon}</div>
          </div>
        )}
        <div className="flex flex-row self-center text-sm font-semibold">
          {children}
        </div>
      </div>
      {actions && (
        <div
          className={classNames(
            'flex flex-row items-center gap-4 [&>*]:flex-auto',
            onClose ? 'pr-0 sm:pr-12' : ''
          )}
        >
          {actions}
        </div>
      )}
      {!!onClose && (
        <button
          onClick={onClose}
          className="absolute right-3 flex h-6 w-6 cursor-pointer appearance-none items-center justify-center text-white sm:right-6 sm:top-auto"
        >
          <Icon className="h-6 w-6">
            <XClose />
          </Icon>
        </button>
      )}
    </div>
  );
};

type AlertButtonProps = React.PropsWithChildren<{ className?: string }> &
  (
    | {
        type: 'internal';
        // A relative React Router path. Providing this will
        // cause the component to render as a Link.
        to: string;
      }
    | {
        type: 'external';
        // A URL to be navigated to in an external tab. When provided
        // this will cause the component to render as an anchor tag.
        href: string;
      }
  );

export const AlertButton: React.FunctionComponent<
  Omit<React.ComponentProps<typeof Button>, 'flat' | 'variant'>
> = ({ className, ...props }) => {
  return (
    <Button
      {...props}
      flat
      variant="white"
      className={classNames('shrink-0 rounded-md border-0', className)}
    />
  );
};

export const AlertLink: React.FunctionComponent<AlertButtonProps> = (props) => {
  if (props.type === 'external') {
    return (
      <a
        className={props.className}
        href={props.href}
        target="_blank"
        rel="noreferrer"
      >
        {props.children}
      </a>
    );
  }

  return (
    <Link className={props.className} to={props.to}>
      {props.children}
    </Link>
  );
};
