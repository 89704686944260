import React from 'react';
import { SUPPORT_EMAIL } from '@magicbrief/common';
import { captureException } from '@sentry/react';
import { User } from 'firebase/auth';
import Translate from 'src/components/HOCs/Translate';
import { Icon } from 'src/components/Icon';
import AlertOctagon from '../../../../../assets/svgicons/duocolor/alert-octagon.svg';
import ArrowNarrowRight from '../../../../../assets/svgicons/duocolor/arrow-narrow-right.svg';
import { Alert } from '../../../../../components/Alert';
import { Button } from '../../../../../components/Button';
import { useI18nContext } from '../../../../../i18n/i18n-react';
import { trpc } from '../../../../../lib/trpc';
import Loading from '../../../../Loading';

type Props = {
  firebaseUser: Pick<User, 'email' | 'uid'>;
  organisationId: number;
  onSuccess: () => void;
  referral?: string;
};

const JoinOrg: React.FunctionComponent<Props> = ({
  firebaseUser,
  organisationId,
  onSuccess,
  referral,
}): JSX.Element => {
  const { LL } = useI18nContext();
  const getShallowOrganisation = trpc.organisation.getShallow.useQuery({
    organisationId,
  });

  const joinOrganisation = trpc.organisation.join.useMutation({
    onSuccess: () => onSuccess(),
    onError: (err) => {
      captureException(err, (scope) => {
        scope.setLevel('error');
        scope.setTransactionName('join organisation');
        scope.setUser({
          id: firebaseUser.uid,
          email: firebaseUser.email ?? undefined,
        });
        return scope;
      });
    },
  });

  const createOrganisation = trpc.organisation.create.useMutation({
    onSuccess: () => onSuccess(),
    onError: (err) => {
      captureException(err, (scope) => {
        scope.setLevel('error');
        scope.setTransactionName('create organisation');
        scope.setUser({
          id: firebaseUser.uid,
          email: firebaseUser.email ?? undefined,
        });
        return scope;
      });
    },
  });

  if (getShallowOrganisation.isLoading) {
    return <Loading />;
  }

  const organisation = getShallowOrganisation.data;

  if (getShallowOrganisation.error || organisation == null) {
    return (
      <Alert
        icon={
          <Icon>
            <AlertOctagon />
          </Icon>
        }
        className="w-full"
        type="error"
      >
        <p className="text-xs font-normal">
          <Translate
            text={LL.joinOrg.errors.getOrg()}
            renderComponent={(infix) => (
              <a
                href={`mailto:${SUPPORT_EMAIL}?subject=Email%20verification%issue`}
              >
                {infix}
              </a>
            )}
          />
        </p>
      </Alert>
    );
  }

  const onJoinOrganisation = () =>
    joinOrganisation.mutate({
      organisationId: organisation.id,
    });

  const onCreateNewOrganisation = () => createOrganisation.mutate({ referral });

  const isLoading = joinOrganisation.isLoading || createOrganisation.isLoading;

  return (
    <div className="flex h-full w-full flex-auto items-center justify-center p-4">
      <div className="flex flex-col items-center gap-6">
        <img src="/images/MagicBriefLogo.png" alt="Magic Brief" width="159px" />
        <div className="flex max-w-2xl flex-col items-start gap-6 rounded-xl bg-white p-8 text-primary shadow">
          <h1 className="text-lg font-bold leading-4 text-primary">
            {LL.joinOrg.title()}
          </h1>
          <p className="text-base text-text-secondary">
            <Translate
              text={LL.joinOrg.description({ domain: organisation.domain })}
              renderComponent={(domain) => (
                <strong className="text-primary">{domain}</strong>
              )}
            />
          </p>
          <div className="flex w-full items-center gap-4 rounded-xl border border-solid border-purple-200 px-8 py-6 shadow-sm">
            <div className="w-1/2">
              <h2 className="truncate text-lg font-bold leading-7 text-primary">
                {organisation.name}
              </h2>
              <p className="text-sm text-text-secondary">
                {LL.joinOrg.orgMembers({ count: organisation.memberCount })}
              </p>
            </div>
            <div className="flex w-1/2 justify-end">
              <Button
                onClick={onJoinOrganisation}
                loading={joinOrganisation.isLoading}
                disabled={isLoading}
              >
                {LL.joinOrg.joinAction()}{' '}
                <Icon>
                  <ArrowNarrowRight />
                </Icon>
              </Button>
            </div>
          </div>
          <Button
            variant="text"
            onClick={onCreateNewOrganisation}
            loading={createOrganisation.isLoading}
            disabled={isLoading}
          >
            <span className="flex items-center whitespace-pre text-base font-normal text-text-secondary">
              <Translate
                text={LL.joinOrg.createAction()}
                renderComponent={[
                  (second) => (
                    <span className="font-semibold text-primary">{second}</span>
                  ),
                ]}
              />
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default JoinOrg;
