import { Button } from 'src/components/Button';
import { SimpleModal } from 'src/components/Modal';
import { useI18nContext } from 'src/i18n/i18n-react';
import { trpc } from 'src/lib/trpc';
import Spinner from 'src/components/Loaders/Spinner';
import useFeatureFlag from 'src/utils/useFeatureFlag';
import EntityDirectoryManagement from 'src/components/Directories/EntityDirectoryManagement';
import BriefCollectionsList from '../BriefCollectionsList/BriefCollectionsList';
import BriefStatusDisplay from '../../routes/BriefList/components/BriefStatusDropdown/BriefStatusDisplay';
import type { Brief } from '@magicbrief/prisma/generated/client2';

type Props = Omit<
  React.ComponentProps<typeof SimpleModal>,
  'title' | 'onClose'
> & {
  brief: Pick<Brief, 'uuid' | 'name'>;
  onClose: () => void;
};

const ManageBriefModal: React.FunctionComponent<Props> = ({
  brief,
  onClose,
  ...rest
}): JSX.Element => {
  return (
    <SimpleModal {...rest} title={brief.name} onClose={onClose}>
      {brief && <ManageBriefModalContent onClose={onClose} uuid={brief.uuid} />}
    </SimpleModal>
  );
};

type ManageBriefModalContentProps = {
  uuid: string;
  onClose: () => void;
};

const ManageBriefModalContent: React.FunctionComponent<
  ManageBriefModalContentProps
> = ({ uuid, onClose }) => {
  const { LL } = useI18nContext();
  const directoriesFeatureFlag = useFeatureFlag('DIRECTORIES');
  const brief = trpc.briefs.getBriefByUuid.useQuery({ uuid });

  if (brief.isLoading) {
    return <Spinner />;
  }

  return (
    <div className="flex flex-col gap-5">
      {brief.data && (
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <h3 className="text-sm font-semibold text-primary">
              {LL.briefs.status()}
            </h3>
            <BriefStatusDisplay
              uuid={brief.data.uuid}
              status={brief.data.arbitraryStatus}
            />
          </div>
          <div className="flex flex-col gap-2">
            <h3 className="text-sm font-semibold text-primary">
              {LL.briefs.collections()}
            </h3>
            {directoriesFeatureFlag ? (
              <EntityDirectoryManagement
                entityType="Brief"
                entityUuid={brief.data.uuid}
              />
            ) : (
              <BriefCollectionsList
                briefID={brief.data.id}
                briefInCollections={brief.data.BriefsInCollections}
              />
            )}
          </div>
        </div>
      )}
      <Button variant="secondary" className="w-full" onClick={onClose}>
        {LL.close()}
      </Button>
    </div>
  );
};

export default ManageBriefModal;
