import {
  Dialog as AriaDialog,
  type DialogProps as AriaDialogProps,
  DialogTrigger as AriaDialogTrigger,
  Modal as AriaModal,
  ModalOverlay as AriaModalOverlay,
  type ModalOverlayProps as AriaModalOverlayProps,
  composeRenderProps,
} from 'react-aria-components';
import XCircle from 'src/assets/svgicons/line/x-circle.svg';
import { useI18nContext } from 'src/i18n/i18n-react';
import { cn } from 'src/lib/cn';
import { Icon } from '../Icon';
import {
  drawerContentVariants,
  drawerCloseButtonVariants,
} from './Drawer.styles';

export const DrawerTrigger = AriaDialogTrigger;

export const DrawerOverlay = ({
  className,
  isDismissable = true,
  ...props
}: AriaModalOverlayProps) => {
  return (
    <AriaModalOverlay
      isDismissable={isDismissable}
      className={composeRenderProps(className, (className) =>
        cn(
          [
            /* Base */
            'fixed inset-0 z-400 bg-gray-900/80',
            // /* Entering */
            'data-[entering]:animate-[overlayShow_300ms_ease-in-out]',
            // /* Exiting */
            'data-[exiting]:animate-[overlayHide_300ms_ease-in-out]',
          ],
          className
        )
      )}
      {...props}
    />
  );
};

interface DrawerContentProps
  extends Omit<React.ComponentProps<typeof AriaModal>, 'children'> {
  children?: AriaDialogProps['children'];
  side?: 'top' | 'bottom' | 'left' | 'right';
  role?: AriaDialogProps['role'];
}

export const DrawerContent = ({
  className,
  children,
  side = 'left',
  role,
  ...props
}: DrawerContentProps) => {
  const { LL } = useI18nContext();

  return (
    <AriaModal
      className={cn(drawerContentVariants({ side }), className)}
      {...props}
    >
      <AriaDialog
        className="relative h-full focus-within:outline-none"
        role={role}
      >
        {({ close }) => (
          <>
            {children}

            <button
              className={drawerCloseButtonVariants({ side })}
              type="button"
              onClick={close}
            >
              <span className="sr-only">
                {LL.library.closeLibraryNavigation()}
              </span>
              <Icon className="size-6 text-white" aria-hidden="true">
                <XCircle />
              </Icon>
            </button>
          </>
        )}
      </AriaDialog>
    </AriaModal>
  );
};
