import { useCallback } from 'react';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';
import { appConfigs } from 'src/configs';
import { useI18nContext } from 'src/i18n/i18n-react';
import useAnalyticsEvent from './useAnalyticsEvent';
import { useCopyToClipboard } from './useCopyToClipboard';

export default function useBriefLink(id: number, shareLink: string | null) {
  const { LL } = useI18nContext();
  const userEvent = useAnalyticsEvent();
  const copy = useCopyToClipboard();

  const link = shareLink ? `${appConfigs.shareURL}/briefs/${shareLink}` : null;
  const copyToClipboard = useCallback(() => {
    if (link) {
      copy(link);
      void userEvent({
        eventName: 'brief:link_copied',
        metadata: {
          brief_id: id,
          link,
        },
      });
    } else {
      toast(LL.errors.genericTitle(), { className: 'toast-danger' });
      Sentry.captureException(
        new Error(
          'Attempt to copy brief link with an empty `shareLink` property.'
        )
      );
    }
  }, [link, copy, userEvent, id, LL]);

  return { link, copyToClipboard };
}
