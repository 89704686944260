import { FC, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { type UserOnboardInputV2 as UserOnboardInput } from '@magicbrief/server/src/user/types';
import { captureException } from '@sentry/react';
import { Controller, useForm } from 'react-hook-form';
import * as z from 'zod';
import BaseModal from 'src/components/AriaModal/BaseModal';
import { AriaButton } from 'src/components/Button/Button';
import Input from 'src/components/Input';
import { useI18nContext } from 'src/i18n/i18n-react';
import { trpc } from 'src/lib/trpc';
import { useUserAndOrganisation } from '../../utils/useUserAndOrganisation';
import { Checkbox } from '../Checkbox';

const interestsDefaultValues = {
  adInspiration: false,
  creativeAnalytics: false,
  trackCompetitors: false,
  createAndShareBriefs: false,
} as const;

type InterestKey = keyof typeof interestsDefaultValues;

type Props = {
  onComplete: () => void;
};

const OnboardingModal: FC<Props> = ({ onComplete }) => {
  const [show, setShow] = useState(true);
  const user = useUserAndOrganisation();
  const onboard = trpc.user.completeUserOnboardFormV2.useMutation();
  const { LL } = useI18nContext();
  const trpcUtils = trpc.useUtils();

  /**
   * @todo get imports working between server and frontend packages
   */
  const userOnboardInput = z.object({
    displayName: z
      .string({ required_error: LL.onboarding.errors.nameRequired() })
      .min(3, { message: LL.onboarding.errors.displayNameTooShort({ n: 3 }) })
      .max(100, {
        message: LL.onboarding.errors.displayNameTooLong({ n: 100 }),
      }),
    workspaceName: z
      .string({ required_error: LL.onboarding.errors.workspaceNameRequired() })
      .min(3, { message: LL.onboarding.errors.workspaceNameTooShort({ n: 3 }) })
      .max(100, {
        message: LL.onboarding.errors.workspaceNameTooLong({ n: 100 }),
      }),
    interests: z.object({
      adInspiration: z.boolean(),
      creativeAnalytics: z.boolean(),
      trackCompetitors: z.boolean(),
      createAndShareBriefs: z.boolean(),
    }),
  });

  const defaultValues = {
    displayName: '',
    workspaceName:
      user.data?.organisation.name !== 'My Organisation'
        ? user.data?.organisation.name ?? ''
        : '',
    interests: interestsDefaultValues,
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid, isSubmitting },
  } = useForm<UserOnboardInput>({
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: zodResolver(userOnboardInput),
  });

  const onSubmit = handleSubmit((data: UserOnboardInput) => {
    onboard.mutate(data, {
      onSuccess: (data) => {
        trpcUtils.user.getUserAndOrganisation.setData(undefined, data);
        setShow(false);
      },
      onError: (err, variables) => {
        captureException(err, (scope) => {
          scope.setLevel('error');
          scope.setTransactionName('User onboarding');
          scope.setExtras(variables);
          return scope;
        });
      },
    });
  });

  const hasEditPermission =
    user.data?.role != null &&
    ['wizard', 'superuser', 'owner', 'admin', 'editor'].includes(
      user.data.role
    );

  return (
    <BaseModal
      show={show}
      onClose={null}
      afterClose={onComplete}
      modalClassName="bg-white rounded-lg mt-auto -mb-2 w-full sm:w-auto sm:m-0"
      overlayClassName="!p-0 overflow-y-hidden"
      dialogClassName="w-full"
    >
      <div className="box-content flex flex-col sm:w-[420px]">
        <form
          onSubmit={onSubmit}
          className="flex flex-col gap-4 px-8 pb-8 pt-6 lg:py-10"
        >
          <div className="flex flex-col gap-4">
            <Input
              {...register('displayName')}
              error={errors.displayName?.message}
              disabled={isSubmitting}
              label={LL.onboarding.displayName.label()}
              placeholder={LL.onboarding.displayName.placeholder()}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
            />
            <Input
              {...register('workspaceName')}
              error={errors.workspaceName?.message}
              disabled={!hasEditPermission || isSubmitting}
              label={LL.onboarding.workspaceName.label()}
              placeholder={LL.onboarding.workspaceName.placeholder()}
            />
          </div>
          <div className="flex flex-col">
            <label className="mb-1 block text-xs font-semibold text-primary sm:text-sm">
              {LL.labels.whichFeatures()}
            </label>
            <Controller
              control={control}
              name="interests"
              render={({
                field: { name, onBlur, onChange, ref, disabled, value },
              }) => {
                return (
                  <div className="flex flex-col gap-2">
                    {Object.keys(interestsDefaultValues).map((interestKey) => (
                      <div
                        key={interestKey}
                        className="rounded-md border border-solid border-purple-200 p-3 shadow-sm"
                      >
                        <Checkbox
                          onChange={(e) =>
                            onChange({
                              ...value,
                              [interestKey]: e.target.checked,
                            })
                          }
                          checked={value[interestKey as InterestKey]}
                          name={`${name}.${interestKey}`}
                          onBlur={onBlur}
                          disabled={disabled}
                          ref={ref}
                        >
                          {LL.onboarding.interests[
                            interestKey as InterestKey
                          ]()}
                        </Checkbox>
                      </div>
                    ))}
                  </div>
                );
              }}
            />
            <AriaButton
              htmlType="submit"
              className="mt-4 w-full"
              variant="gradient"
              isDisabled={!isValid}
              loading={isSubmitting || onboard.isLoading}
            >
              {LL.onboarding.getStarted()}
            </AriaButton>
          </div>
        </form>
      </div>
    </BaseModal>
  );
};

export default OnboardingModal;
