import { useQueryClient } from '@tanstack/react-query';
import { getQueryKey } from '@trpc/react-query';
import { Button } from 'src/components/Button';
import { SimpleModal } from 'src/components/Modal';
import { useI18nContext } from 'src/i18n/i18n-react';
import { Alert } from 'src/components/Alert';
import { trpc } from 'src/lib/trpc';
import { useDeleteBriefs } from '../../hooks';

type Props = Omit<
  React.ComponentProps<typeof SimpleModal>,
  'title' | 'onClose'
> & {
  briefUuids: string[];
  template?: boolean;
  onSuccess?: () => void;
  onClose: () => void;
};

const DeleteBriefsModal: React.FunctionComponent<Props> = ({
  onClose,
  briefUuids,
  onSuccess,
  template,
  ...rest
}) => {
  const { LL } = useI18nContext();
  const queryClient = useQueryClient();
  const deleteTemplates = trpc.briefs.deleteBriefs.useMutation({
    onSuccess() {
      void queryClient.invalidateQueries(
        getQueryKey(trpc.briefs.getBriefTemplates, undefined, 'any')
      );
      onClose();
    },
  });
  const deleteBriefs = useDeleteBriefs({
    onSuccess() {
      onSuccess?.();
      onClose();
    },
  });

  return (
    <SimpleModal
      {...rest}
      title={
        template
          ? LL.briefs.deleteTemplates(briefUuids.length)
          : LL.briefs.deleteBriefs(briefUuids.length)
      }
      onClose={
        deleteBriefs.isLoading || deleteTemplates.isLoading ? null : onClose
      }
    >
      <div className="flex flex-col gap-3">
        {deleteBriefs.error && (
          <Alert type="error">
            {LL.errors.genericWithDetail({
              detail: deleteBriefs.error.message,
            })}
          </Alert>
        )}
        {!template && (
          <div className="flex flex-col gap-2">
            <p className="text-sm text-primary">
              {LL.briefs.deleteEntirelyDetail()}
            </p>
          </div>
        )}
        <Button
          variant="primary"
          colour="danger"
          disabled={deleteBriefs.isLoading || deleteTemplates.isLoading}
          loading={deleteBriefs.isLoading || deleteTemplates.isLoading}
          className="w-full"
          onClick={() => {
            if (template) {
              deleteTemplates.mutate({ uuids: briefUuids });
            } else {
              deleteBriefs.mutate({ uuids: briefUuids });
            }
          }}
        >
          {template ? (
            <span>{LL.briefs.deleteTemplates(briefUuids.length)}</span>
          ) : (
            <span>{LL.briefs.deleteBriefs(briefUuids.length)}</span>
          )}
        </Button>
      </div>
    </SimpleModal>
  );
};

export default DeleteBriefsModal;
