import { Disclosure } from '@headlessui/react';
import { Icon } from 'src/components/Icon';
import AlertHexagon from 'src/assets/svgicons/duocolor/alert-hexagon.svg';
import ChevronUp from 'src/assets/svgicons/duocolor/chevron-up.svg';
import { Alert } from 'src/components/Alert';

type Props = {
  error: Error;
};

const DefaultFallback: React.FunctionComponent<Props> = ({
  error,
}): JSX.Element => {
  return (
    <div className="p-4">
      <Alert
        icon={
          <Icon>
            <AlertHexagon />
          </Icon>
        }
        type="error"
        className="mb-2"
      >
        <div>
          <h3 className="text-sm font-medium text-white">
            Something went wrong
          </h3>
          <div className="mt-2 text-sm font-normal text-gray-50">
            <p>
              An unexpected error occurred that we weren&apos;t able to recover
              from. Try reloading the page and contact support if the issue
              persists.
            </p>
          </div>
        </div>
      </Alert>

      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex w-full justify-between rounded-lg bg-red-100 px-4 py-2 text-left text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring focus-visible:ring-red-500/75">
              <span>Error Details</span>
              <Icon
                className={`${open ? 'rotate-180' : ''} h-5 w-5 text-red-500`}
              >
                <ChevronUp />
              </Icon>
            </Disclosure.Button>
            <Disclosure.Panel className="mt-2 overflow-x-auto rounded-lg bg-white p-4 text-sm">
              <div className="mb-4">
                <h2 className="mb-1 font-semibold">Message</h2>
                <p className="pre font-mono text-gray-500">{error.message}</p>
              </div>

              <div>
                <h2 className="mb-1 font-semibold">Stack Trace</h2>
                <pre className="pre font-mono text-gray-500">
                  {error.stack || 'No stack trace'}
                </pre>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default DefaultFallback;
