import { useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import {
  INSIGHTS_FACEBOOK_TIME_SERIES_ALL_METRICS,
  sortAlphabetically,
} from '@magicbrief/common';
import Fuse from 'fuse.js';
import useNewAnalyticsEvent from 'src/utils/useNewAnalyticsEvent';
import {
  getMetricDefinitions,
  getMetricLabelAsText,
  getMetricSynonyms,
} from './useParseMetric';

type SearchableItem = {
  metric: string;
  label: string;
  synonyms: string;
  definition: string;
};

type UseInsightsMetricsListParam = {
  /**
   * List of allowable metrics. Defaults to all metrics if not provided.
   * This should be memoised or a static reference to avoid performance issues.
   */
  metrics?: string[];
  customEvents: string[] | null;
  customConversions: Array<{ facebookId: string; name: string }> | null;
  /** Prevents sorting of the metric list when true. False by default. */
  preserveOrder?: boolean;
};

export function useInsightsMetricsList({
  metrics = INSIGHTS_FACEBOOK_TIME_SERIES_ALL_METRICS,
  customEvents,
  customConversions,
  preserveOrder = false,
}: UseInsightsMetricsListParam) {
  const searchableItems = useMemo(() => {
    const list = [
      ...metrics,
      ...(customEvents ?? []).flatMap((x) => [
        `action:cost_per:${x}`,
        `action:total:${x}`,
        `action:value:${x}`,
      ]),
      ...(customConversions ?? []).flatMap((x) => {
        const fullName = `offsite_conversion.custom.${x.facebookId}`;
        return [
          `action:cost_per:${fullName}`,
          `action:total:${fullName}`,
          `action:value:${fullName}`,
        ];
      }),
    ].reduce<Array<SearchableItem>>((acc, option) => {
      const label = getMetricLabelAsText(
        option,
        customEvents,
        customConversions
      );
      if (label == null) {
        return acc;
      }

      const definition = getMetricDefinitions(option)?.toString() ?? '';
      const synonyms = getMetricSynonyms(option)?.toString() ?? '';

      acc.push({ metric: option, label, synonyms, definition });
      return acc;
    }, []);
    if (preserveOrder) return list;
    return list.sort((a, b) => sortAlphabetically(a.label, b.label));
  }, [customEvents, customConversions, metrics, preserveOrder]);

  return searchableItems;
}

export function useMetricFuse(items: SearchableItem[]) {
  const fuse = useMemo(
    () =>
      new Fuse(items, {
        keys: [
          { name: 'label', weight: 1 },
          { name: 'metric', weight: 2 },
          { name: 'synonyms', weight: 3 },
          { name: 'definition', weight: 4 },
        ],
      }),
    [items]
  );
  return fuse;
}

export function useMetricSearch(
  builtinMetrics: string[],
  customEvents: string[] | null,
  customConversions: Array<{ facebookId: string; name: string }> | null
) {
  const [query, setQuery] = useState('');

  const { debouncedRecordEvent } = useNewAnalyticsEvent();

  const searchableItems = useInsightsMetricsList({
    metrics: builtinMetrics,
    customEvents,
    customConversions,
  });

  const fuse = useMetricFuse(searchableItems);

  const results = useMemo(
    () =>
      query.trim() === ''
        ? searchableItems.map((item) => item.metric)
        : fuse.search(query).map((result) => result.item.metric),
    [fuse, query, searchableItems]
  );

  const onChange = useDebouncedCallback((query: string) => {
    if (query.length > 2) {
      void debouncedRecordEvent({
        action: 'Search',
        target: 'Insights Metric',
        metadata: {
          searchTerm: query,
        },
      });
    }
    setQuery(query);
  }, 150);

  return {
    query,
    onChange,
    results,
  };
}
