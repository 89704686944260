import { useContext } from 'react';
import { FirebaseContext, FirebaseDispatchContext } from './context';

export function useFirebaseContext() {
  const value = useContext(FirebaseContext);
  return value;
}

export function useFirebaseDispatchContext() {
  const value = useContext(FirebaseDispatchContext);
  return value;
}
