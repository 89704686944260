import withLeadingZero from './withLeadingZero';

/**
 * Convert a time in seconds to a human-readable duration of the form hh:mm:ss or mm:ss.
 *
 * @param s The time in seconds to be converted to a human readable duration
 * @returns A duration of the form hh:mm:ss, or mm:ss when there are zero hours.
 */
export default function secondsToDuration(s: number) {
  const literalMinutes = s / 60;
  const literalHours = literalMinutes / 60;

  const hours = s >= 1 ? Math.floor(literalHours) : 0;

  const minutes = hours
    ? Math.floor(s / 60) - hours * 60
    : Math.floor(literalMinutes);

  const seconds = Math.floor(s < 60 ? s : s % 60);

  const zeroPaddedMinutes = withLeadingZero(minutes);
  const zeroPaddedSeconds = withLeadingZero(seconds);

  // Don't include hours at all if not in the hour or more duration
  if (hours) {
    return `${withLeadingZero(
      hours
    )}:${zeroPaddedMinutes}:${zeroPaddedSeconds}`;
  }

  // Always include minutes, even when zero
  return `${zeroPaddedMinutes}:${zeroPaddedSeconds}`;
}
