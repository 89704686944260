import { DirectoryItemType } from '@magicbrief/common';
import { useTypesafeSearchParams } from 'src/utils/useTypesafeSearchParams';

type NewDirectoryParams = {
  newDir: 'true';
  parentDirectoryUuid?: string | null;
};

export function useInitiateNewDirectory() {
  const params = useNewDirectoryParams();
  return (args: Omit<NewDirectoryParams, 'newDir'>) => {
    params.setMany({ ...args, newDir: 'true' });
  };
}

type UseListenForNewDirectoryReturnType = [
  NewDirectoryParams | null,
  () => void,
];

export function useListenForInitiateNewDirectory(): UseListenForNewDirectoryReturnType {
  const params = useNewDirectoryParams();
  const obj = params.toObject();
  if (obj.newDir === 'true') {
    return [obj as NewDirectoryParams, () => params.clear()];
  }

  return [null, () => params.clear()];
}

function useNewDirectoryParams() {
  return useTypesafeSearchParams<NewDirectoryParams>({
    newDir: null,
    parentDirectoryUuid: null,
  });
}

type EditDirectoryParams = {
  action: 'edit-dir';
  directoryUuid: string;
  name: string;
};

export function useInitiateEditDirectory() {
  const params = useEditDirectoryParams();
  return (args: Omit<EditDirectoryParams, 'action'>) => {
    params.setMany({ ...args, action: 'edit-dir' });
  };
}

type UseListenForEditDirectoryReturnType = [
  EditDirectoryParams | null,
  () => void,
];

export function useListenForInitiateEditDirectory(): UseListenForEditDirectoryReturnType {
  const params = useEditDirectoryParams();
  const obj = params.toObject();
  if (
    obj.action === 'edit-dir' &&
    obj.directoryUuid &&
    typeof obj.name === 'string'
  ) {
    return [obj as EditDirectoryParams, () => params.clear()];
  }

  return [null, () => params.clear()];
}

function useEditDirectoryParams() {
  return useTypesafeSearchParams<EditDirectoryParams>({
    action: null,
    name: null,
    directoryUuid: null,
  });
}

type AddItemParams = {
  action: 'add-to-dir';
  entityName: string;
  entityUuid: string;
  entityType: DirectoryItemType;
  proxyEntityType: 'PlatformAd' | null;
};

type MoveItemParams = {
  action: 'move-to-dir';
  entityName: string;
  directoryNodeUuids: string[];
  originDirectoryNodeUuid: string | null;
};

export function useInitiateAddEntityToDirectory() {
  const params = useAddEntityToDirectoryParams();
  return (args: Omit<AddItemParams, 'action'>) => {
    params.setMany({ ...args, action: 'add-to-dir' });
  };
}

type UseListenForInitiateAddEntityToDirectoryReturnType = [
  AddItemParams | null,
  () => void,
];

export function useListenForInitiateAddEntityToDirectory(): UseListenForInitiateAddEntityToDirectoryReturnType {
  const params = useAddEntityToDirectoryParams();
  const obj = params.toObject();
  if (obj.action === 'add-to-dir' && obj.entityUuid && obj.entityType) {
    return [obj as AddItemParams, () => params.clear()];
  }

  return [null, () => params.clear()];
}

function useAddEntityToDirectoryParams() {
  return useTypesafeSearchParams<AddItemParams>({
    action: null,
    entityName: null,
    entityUuid: null,
    entityType: null,
    proxyEntityType: null,
  });
}

export function useInitiateMoveNodeToDirectory() {
  const params = useMoveNodeToDirectoryParams();
  return (args: Omit<MoveItemParams, 'action'>) => {
    params.setMany({ ...args, action: 'move-to-dir' });
  };
}

type UseListenForInitiateMoveNodeToDirectoryReturnType = [
  MoveItemParams | null,
  () => void,
];

export function useListenForInitiateMoveNodeToDirectory(): UseListenForInitiateMoveNodeToDirectoryReturnType {
  const params = useMoveNodeToDirectoryParams();
  const obj = params.toObject();
  if (obj.action === 'move-to-dir' && obj.directoryNodeUuids) {
    return [obj as MoveItemParams, () => params.clear()];
  }

  return [null, () => params.clear()];
}

function useMoveNodeToDirectoryParams() {
  return useTypesafeSearchParams<MoveItemParams>({
    action: null,
    entityName: null,
    directoryNodeUuids: [],
    originDirectoryNodeUuid: null,
  });
}

type DeleteItemParams = {
  action: 'delete-from-dir';
  entityName: string;
  directoryNodeUuid: string;
};

export function useInitiateDeleteItemFromDirectory() {
  const params = useDeleteOrUnpinItemInDirectoryParams();
  return (args: Omit<DeleteItemParams, 'action'>) => {
    params.setMany({ ...args, action: 'delete-from-dir' });
  };
}

type UseListenForInitiateDeleteItemFromDirectoryReturnType = [
  DeleteItemParams | null,
  () => void,
];

export function useListenForInitiateDeleteItemFromDirectory(): UseListenForInitiateDeleteItemFromDirectoryReturnType {
  const params = useDeleteOrUnpinItemInDirectoryParams();
  const obj = params.toObject();
  if (obj.action === 'delete-from-dir' && obj.directoryNodeUuid) {
    return [obj as DeleteItemParams, () => params.clear()];
  }

  return [null, () => params.clear()];
}

function useDeleteOrUnpinItemInDirectoryParams() {
  return useTypesafeSearchParams<DeleteItemParams>({
    action: null,
    entityName: null,
    directoryNodeUuid: null,
  });
}

type ShareItemParams = {
  action: 'share-dir';
  entityName: string;
  directoryNodeUuid: string;
};

export function useInitiateShareDirectory() {
  const params = useShareDirectoryParams();
  return (args: Omit<ShareItemParams, 'action'>) => {
    params.setMany({ ...args, action: 'share-dir' });
  };
}

type UseListenForInitiateShareDirectoryReturnType = [
  ShareItemParams | null,
  () => void,
];

export function useListenForShareDirectory(): UseListenForInitiateShareDirectoryReturnType {
  const params = useShareDirectoryParams();
  const obj = params.toObject();
  if (obj.action === 'share-dir' && obj.directoryNodeUuid) {
    return [obj as ShareItemParams, () => params.clear()];
  }

  return [null, () => params.clear()];
}

function useShareDirectoryParams() {
  return useTypesafeSearchParams<ShareItemParams>({
    action: null,
    entityName: null,
    directoryNodeUuid: null,
  });
}
