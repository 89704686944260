import { createContext, useCallback, useMemo, useState } from 'react';
import { PermissibleEntityType } from '@magicbrief/prisma/generated/client2';
import {
  Presence,
  PresenceResponse,
} from '@magicbrief/server/src/presence/@types';
import { useUserAndOrganisation } from '../utils/useUserAndOrganisation';

type UsePresenceState = {
  presences: PresenceResponse;
  currentUserPresence: Presence | null;
  getPresence: (
    userUuid: string,
    entityType: PermissibleEntityType,
    entityUuid: string
  ) => Presence | null;
};

type UsePresenceProps = {
  entityType: PermissibleEntityType;
  entityUuid?: string;
};

/** @deprecated remove if we never bring this back (in favour of other implementations of realtime multiplayer) */
export function usePresence({
  entityType,
  entityUuid,
}: UsePresenceProps): UsePresenceState {
  const user = useUserAndOrganisation();
  const [presences] = useState({});

  /** @todo as above, if this implementation remains, uncomment this */
  // useEffect(() => {
  //   socket.on('presencesMutated', (next: PresenceResponse) => {
  //     if (next != null && Object.keys(next).length === 0) {
  //       return;
  //     }
  //     setPresences((current) => mergeDeep(current, next));
  //   });
  //
  //   if (entityUuid != null && userInfo.data != null) {
  //     socket.emit('presenceConnect', entityType, entityUuid);
  //   }
  //
  //   // re-kick presence connection if user reconnects
  //   socket.on('connect', () => {
  //     if (entityUuid != null && userInfo.data != null)
  //       socket.emit('presenceConnect', entityType, entityUuid);
  //   });
  //
  //   socket.on('disconnect', () => {
  //     if (entityUuid != null && userInfo.data != null)
  //       socket.emit('presenceDisconnect', entityType, entityUuid);
  //   });
  //
  //   return () => {
  //     if (entityUuid != null && userInfo.data != null)
  //       socket.emit('presenceDisconnect', entityType, entityUuid);
  //   };
  // }, [entityType, entityUuid, userInfo.data]);

  const getPresence = useCallback(
    (userUuid: string, entityType: PermissibleEntityType, entityUuid: string) =>
      getPresenceForUserByEntity(presences, userUuid, entityType, entityUuid),
    [presences]
  );

  const currentUserPresence = useMemo(
    () =>
      user.data != null && entityUuid != null
        ? getPresenceForUserByEntity(
            presences,
            user.data.user.uuid,
            entityType,
            entityUuid
          )
        : null,
    [presences, user.data, entityType, entityUuid]
  );

  return { presences, currentUserPresence, getPresence };
}

type PresenceContextState = {
  presences: PresenceResponse;
  currentUserPresence: Presence | null;
};

export const PresenceContext = createContext<PresenceContextState>({
  presences: {},
  currentUserPresence: null,
});

export const getPresenceForUserByEntity = (
  presences: PresenceResponse,
  userUuid: string,
  entityType: PermissibleEntityType,
  entityUuid: string
) => {
  return (
    presences?.[`${entityType}|${entityUuid}`]?.[`User|${userUuid}`] ?? null
  );
};
