import { LoaderFunctionArgs } from 'react-router-dom';
import {
  GetManyFacebookAdsWithInsightsV2Input,
  GetStatisticsForFacebookAdsV2Input,
} from '@magicbrief/server/src/insights/insights.trpc';
import { getQueryKey } from '@trpc/react-query';
import { trpc, trpcProxyClient } from 'src/lib/trpc';
import queryClient from 'src/lib/queryClient';
import { auth } from 'src/lib/firebase';
import { INSIGHTS_PAGE_TAKE_LIMIT } from '../../util/constants';
import {
  createInsightsDefaultPersistentState,
  createInsightsStandardStorage,
} from '../../util/useInsightsPersistentState';
import { getInsightsSearchParams } from '../../util/useInsightsSearchParams';

export async function insightsAccountRouteLoader({
  params,
  request,
}: LoaderFunctionArgs) {
  const accountUuid = params.accountUuid ?? '';
  const state = createInsightsStandardStorage().getItem(
    `insights_account_${accountUuid}_overview`,
    createInsightsDefaultPersistentState()
  );

  const queryParams = getInsightsSearchParams(
    new URL(request.url).searchParams
  );

  const forTimePeriod =
    queryParams.timePeriod === 'custom'
      ? {
          datePreset: 'custom' as const,
          since: queryParams.since ?? '',
          until: queryParams.until ?? '',
        }
      : {
          datePreset: queryParams.timePeriod,
        };

  const baseQuery = {
    type: 'account' as const,
    insightsAdAccountFacebookUuid: params.accountUuid ?? '',
    group: state.group,
    attributionWindow: queryParams.attributionWindow,
    filter: {
      metric: state.filter,
      forTimePeriod,
    },
  } satisfies GetStatisticsForFacebookAdsV2Input;

  const query = {
    ...baseQuery,
    count: INSIGHTS_PAGE_TAKE_LIMIT,
    cursor: undefined,
    sort: state.sort,
  } satisfies GetManyFacebookAdsWithInsightsV2Input;

  const adsQueryKey = getQueryKey(
    trpc.insights.getManyFacebookAdsWithInsightsV2,
    query,
    'infinite'
  );

  const statisticsQueryKey = getQueryKey(
    trpc.insights.getStatisticsForFacebookAdsV2,
    baseQuery,
    'query'
  );

  const firstPage = queryClient.getQueryData(adsQueryKey);

  if (firstPage) {
    return firstPage;
  }

  await auth.authStateReady();

  void queryClient.prefetchInfiniteQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: adsQueryKey,
    queryFn: async () => {
      const ads =
        await trpcProxyClient.insights.getManyFacebookAdsWithInsightsV2.query(
          query,
          { context: { skipBatch: true } }
        );

      for (const ad of ads) {
        queryClient.setQueryData(
          getQueryKey(
            trpc.insights.getFacebookAdInsights,
            {
              type: 'account',
              attributionWindow: queryParams.attributionWindow,
              insightsAdAccountFacebookUuid: params.accountUuid ?? '',
              forTimePeriod,
              group: state.group,
              identifier: ad.type === 'ad' ? ad.uuid : ad.group,
            },
            'query'
          ),
          ad
        );
      }
      return ads;
    },
  });

  void queryClient.prefetchQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: statisticsQueryKey,
    queryFn: async () =>
      trpcProxyClient.insights.getStatisticsForFacebookAdsV2.query(baseQuery, {
        context: { skipBatch: true },
      }),
  });

  if (Array.isArray(state.selected)) {
    for (const selected of state.selected) {
      const adInsightsParams = {
        type: 'account',
        insightsAdAccountFacebookUuid: params.accountUuid ?? '',
        forTimePeriod,
        attributionWindow: queryParams.attributionWindow,
        group: state.group,
        identifier: selected,
      } as const;

      void queryClient.prefetchQuery({
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: getQueryKey(
          trpc.insights.getFacebookAdInsights,
          adInsightsParams,
          'query'
        ),
        queryFn: async () =>
          trpcProxyClient.insights.getFacebookAdInsights.query(
            adInsightsParams
          ),
      });
    }
  }

  void queryClient.prefetchQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: getQueryKey(
      trpc.insights.getFacebookAdAccount,
      { uuid: accountUuid },
      'query'
    ),
    queryFn: async () =>
      trpcProxyClient.insights.getFacebookAdAccount.query(
        {
          uuid: accountUuid,
        },
        { context: { skipBatch: true } }
      ),
  });

  void queryClient.prefetchQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: getQueryKey(trpc.insights.getInsightsTags, undefined, 'query'),
    queryFn: async () =>
      trpcProxyClient.insights.getInsightsTags.query(undefined, {
        context: { skipBatch: true },
      }),
  });

  return null;
}
