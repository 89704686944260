import { FC } from 'react';
import classNames from 'classnames';
import MessageCircle01 from 'src/assets/svgicons/line/message-circle-01.svg';
import MessageNotificationCircle from 'src/assets/svgicons/duotone/message-notification-circle.svg';
import { Icon } from '../Icon';

type Props = {
  showCommentCount?: boolean;
  count?: number;
  className?: string;
};

export const CommentIcon: FC<Props> = ({
  showCommentCount = true,
  count = 0,
  className,
}) => {
  const countDisplay = count ? `${count > 99 ? '99+' : count}` : '';

  return (
    <div
      className={classNames(
        'flex-centered flex gap-1 text-ellipsis align-middle text-sm font-semibold text-primary',
        className
      )}
    >
      <Icon className="shrink-0">
        {count && count > 0 ? (
          <MessageNotificationCircle />
        ) : (
          <MessageCircle01 />
        )}
      </Icon>
      {showCommentCount && count > 0 && (
        <span className="ml-0.25 max-w-[3ch] truncate">{countDisplay}</span>
      )}
    </div>
  );
};
