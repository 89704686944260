import React from 'react';
import { Button } from 'react-aria-components';
import CopyInviteLink from 'src/components/CopyInviteLink';
import { useI18nContext } from 'src/i18n/i18n-react';
import { useUserAndOrganisation } from 'src/utils/useUserAndOrganisation';
import XClose from 'src/assets/svgicons/line/x-close.svg';
import { BaseModal } from 'src/components/AriaModal';
import { PermissionManager } from 'src/components/PermissionManager';
import { trpc } from 'src/lib/trpc';
import { Icon } from '../Icon';

type Props = {
  show: boolean;
  onClose: () => void;
};

const InviteTeamModal: React.FunctionComponent<Props> = ({
  show,
  onClose,
}): JSX.Element => {
  const { LL } = useI18nContext();
  const user = useUserAndOrganisation();
  /** @todo remove this call to query organisation and use the above call */
  const organisation = trpc.organisation.getOrganisation.useQuery();

  const copyUrl = organisation.data?.links.find(
    (link) => link.type === 'editor'
  )?.fullURL;

  return (
    <BaseModal
      title="test"
      show={show}
      onClose={onClose}
      modalClassName="bg-white rounded-xl relative"
    >
      <div className="relative flex flex-col lg:grid lg:min-h-[478px] lg:grid-cols-2">
        <Button className="absolute right-4 top-4" onPress={onClose}>
          <Icon className="text-[#3D1CAF80]">
            <XClose />
          </Icon>
        </Button>

        <div className="flex flex-col justify-between rounded-t-xl bg-purple-100 px-5 pb-6 pt-8 text-primary lg:col-span-1 lg:rounded-l-xl lg:rounded-tr-none">
          <div className="relative flex w-max flex-col gap-3 lg:gap-6">
            <div>
              <div className="px-3">
                <h3 className="text-wrap text-[24px] font-bold leading-8 text-primary">
                  {LL.inviteModal.title()}
                </h3>
                <p className="mt-3 max-w-xs text-wrap text-sm text-primary lg:pr-8">
                  {LL.inviteModal.description()}
                </p>
              </div>
              <div
                aria-hidden="true"
                className="mt-4 hidden flex-col gap-5 rounded-lg border-2 border-solid border-[#D8CAFA] p-3.5 lg:flex"
              >
                <div className="flex flex-row items-center justify-between">
                  <div className="flex flex-col gap-1">
                    <span className="text-xs font-semibold text-purple-800">
                      {LL.inviteModal.heroTitle()}
                    </span>
                    <span className="text-xxs font-semibold text-purple-800/60">
                      {LL.inviteModal.heroSubtitle()}
                    </span>
                  </div>

                  <div className="flex flex-row">
                    <img
                      alt=""
                      height="48"
                      width="48"
                      src="/images/invite_card_examples/avatar_example_1.jpeg"
                      className="z-10 h-6 w-6 rounded-full ring-2 ring-white drop-shadow-sm"
                    />
                    <img
                      alt=""
                      height="48"
                      width="48"
                      src="/images/invite_card_examples/avatar_example_2.jpeg"
                      className="h-6 w-6 rounded-full ring-2 ring-white drop-shadow-sm"
                    />
                  </div>
                </div>
                <div className="flex flex-row gap-1">
                  <img
                    alt="MagicBrief example ad"
                    height="344"
                    width="205"
                    src="/images/invite_card_examples/card_example_1.jpeg"
                    className="w-28 rounded drop-shadow-md"
                  />
                  <img
                    alt="MagicBrief example ad"
                    height="344"
                    width="206"
                    src="/images/invite_card_examples/card_example_2.jpeg"
                    className="w-28 rounded drop-shadow-md"
                  />
                  <img
                    alt="MagicBrief example ad"
                    height="344"
                    width="206"
                    src="/images/invite_card_examples/card_example_3.jpeg"
                    className="w-28 rounded drop-shadow-md"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex max-w-[416px] flex-col px-5 pb-8 pt-6 lg:col-span-1 lg:py-10">
          <h5 className="block text-sm font-bold text-primary sm:text-lg">
            {LL.inviteYourTeam()}
          </h5>
          <div className="mt-4 rounded-lg bg-purple-50 px-4 py-3">
            {copyUrl && (
              <CopyInviteLink
                url={copyUrl}
                analyticsMetadata={{
                  target: 'Organisation',
                  action: 'Invite Link Copied',
                  metadata: {
                    copyUrl,
                  },
                }}
              />
            )}
          </div>
          <div className="mt-4 rounded-lg bg-purple-50 px-4 py-3">
            {user.data && (
              <PermissionManager
                entityType="Organisation"
                entityUuid={user.data.organisation.uuid}
                showRoles={false}
                showActions={true}
              />
            )}
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default InviteTeamModal;
