import { useContext } from 'react';
import { BriefsDispatchContext } from './BriefsContext';

export function useBriefsDispatchContext() {
  const value = useContext(BriefsDispatchContext);

  if (!value) {
    throw new Error(
      'useBriefsDispatchContext cannot be called outside of a BriefsProvider'
    );
  }
  return value;
}
