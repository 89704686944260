import { FC, ReactNode } from 'react';
import { Button, Label } from 'react-aria-components';
import { InsightsFacebookWizardMetric } from '@magicbrief/common';
import { Tooltip } from 'src/components/Tooltip/Tooltip';
import { useI18nContext } from 'src/i18n/i18n-react';
import { WizardScoreMeter, WizardScoreTooltip } from './WizardScore';

type Props = {
  sentimentColor?: string;
  title: ReactNode;
  description?: string;
  value: string | null;
  diffFromWeightedAvg: number | null;
  isWizardScore: boolean | null;
  metric?: InsightsFacebookWizardMetric;
};

export const AdMetadataCell: FC<Props> = ({
  sentimentColor,
  title,
  value,
  diffFromWeightedAvg,
  isWizardScore,
  metric,
}) => {
  const { LL } = useI18nContext();

  const locale = window.navigator.languages
    ? window.navigator.languages[0]
    : window.navigator.language;

  const formattedDiffFromWeightedAvg =
    diffFromWeightedAvg != null
      ? Intl.NumberFormat(locale, {
          style: 'percent',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(diffFromWeightedAvg)
      : null;

  return (
    <div className="flex flex-row items-center justify-between gap-1 overflow-hidden">
      {!!title && (
        <Label className="flex h-6 min-w-[20%] shrink grow items-center truncate text-xs font-semibold text-purple-800">
          {title}
        </Label>
      )}

      <div className="min-w flex shrink-0 grow-0 overflow-hidden text-xs font-semibold text-gray-800">
        <Tooltip
          delay={0}
          isDisabled={diffFromWeightedAvg == null}
          style={{ zIndex: 100 }}
        >
          <Button>
            {value == null ? (
              <div className="rounded bg-[#F4F4F4] px-2.5 py-1.5">
                {LL.insights.metrics.none()}
              </div>
            ) : isWizardScore && value != null && !!metric ? (
              <WizardScoreTooltip score={Number(value)} type={metric}>
                <WizardScoreMeter score={Number(value)} />
              </WizardScoreTooltip>
            ) : (
              <div
                className="truncate rounded bg-[#F4F4F4] px-2.5 py-1.5"
                style={{
                  background: sentimentColor,
                }}
                title={value ?? 'None'}
              >
                <span className="truncate">
                  {value ?? LL.insights.metrics.none()}
                </span>
              </div>
            )}
          </Button>
          <p>
            {diffFromWeightedAvg != null && (
              <>
                Diff from avg{' '}
                <span style={{ color: sentimentColor }}>
                  {formattedDiffFromWeightedAvg}
                </span>
              </>
            )}
          </p>
        </Tooltip>
      </div>
    </div>
  );
};
