import { AppRouter } from '@magicbrief/server/src/trpc/router';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { TRPCClientErrorLike } from '@trpc/client';
import { getQueryKey } from '@trpc/react-query';
import { UseTRPCMutationOptions } from '@trpc/react-query/shared';
import { inferProcedureInput, inferProcedureOutput } from '@trpc/server';
import {
  ArrayElement,
  PartialByKey,
  DirectoryItemType,
  DirectoryAliasTypes,
} from '@magicbrief/common';
import { AddEntitiesToDirectoryNodeOutputFulfilledItem } from '@magicbrief/server/src/directories/services/add-entities-to-directory-node';
import { BaseDirectoryMutationResult } from '@magicbrief/server/src/directories/types';
import { trpc } from 'src/lib/trpc';
import { GetAdsOutput } from 'src/types/ad';
import {
  setAdInInfiniteQueryToSaved,
  updateOrganisationAdInInfiniteQuery,
} from './useSaveAd';

async function onEntityAddedToDirectory(
  trpcUtils: ReturnType<typeof trpc.useUtils>,
  queryClient: ReturnType<typeof useQueryClient>,
  {
    entityType,
    entityUuid,
    originalEntity,
  }: AddEntitiesToDirectoryNodeOutputFulfilledItem,
  targetDirectoryNodeUuid: string | undefined
) {
  if (originalEntity?.entityType === 'PlatformAd') {
    trpcUtils.ads.getAdByUUID.setData(
      { uuid: originalEntity.entityUuid },
      (old) =>
        old
          ? {
              ...old,
              organisationAdUUID: entityUuid,
            }
          : old
    );
    queryClient.setQueriesData<InfiniteData<GetAdsOutput>>(
      getQueryKey(trpc.ads.getAds, undefined, 'infinite'),
      (data) =>
        setAdInInfiniteQueryToSaved(
          originalEntity.entityUuid,
          entityUuid,
          targetDirectoryNodeUuid,
          data
        )
    );
  } else if (entityType === 'OrganisationAd' && targetDirectoryNodeUuid) {
    queryClient.setQueriesData<InfiniteData<GetAdsOutput>>(
      getQueryKey(trpc.ads.getAds, undefined, 'infinite'),
      (data) =>
        updateOrganisationAdInInfiniteQuery(
          entityUuid,
          (ad) => {
            return {
              ...ad,
              inDirectories: [targetDirectoryNodeUuid, ...ad.inDirectories],
            };
          },
          data
        )
    );
  }

  void trpcUtils.directories.getDirectoryNodesAliasedToEntity.invalidate({
    entityType,
    entityUuid,
  });

  // Invalidate parent contents if we pinned an ad or asset to a child,
  // as we will need to update the preview displayed within the parent directory
  const structure =
    trpcUtils.directories.getDirectoryStructureForOrganisation.getData();
  if (
    targetDirectoryNodeUuid &&
    structure &&
    (entityType === 'OrganisationAd' || entityType === 'UserAsset')
  ) {
    const directory = structure.directories[targetDirectoryNodeUuid];
    if (directory.parent) {
      void trpcUtils.directories.getDirectoryNodeContents.invalidate({
        uuid: directory.parent,
      });
    }
  }
}

async function handleDirectoryMutationResponse(
  utils: ReturnType<typeof trpc.useUtils>,
  result: BaseDirectoryMutationResult
) {
  const structure =
    utils.directories.getDirectoryStructureForOrganisation.getData();
  for (const node of result.affectedDirectoryNodes) {
    if (node.type === 'directory') {
      void utils.directories.getDirectoryNodeContents.invalidate({
        uuid: node.uuid,
      });
      void utils.directories.getDirectoryNodeDetail.invalidate({
        uuid: node.uuid,
      });

      // Invalidate parent contents to update previews
      const parent = structure?.directories[node.uuid]?.parent;
      if (parent) {
        void utils.directories.getDirectoryNodeContents.invalidate({
          uuid: parent,
        });
      }
    }
  }

  for (const { entityType, entityUuid } of result.affectedEntities) {
    if (entityType === 'OrganisationAd') {
      void utils.directories.getDirectoryNodesAliasedToEntity.invalidate({
        entityType,
        entityUuid,
      });
    }
  }

  if (result.isStructureChanged) {
    void utils.directories.getDirectoryStructureForOrganisation.invalidate();
  }
}

export function useCreateDirectory<TContext = unknown>(
  options?: UseTRPCMutationOptions<
    inferProcedureInput<AppRouter['directories']['createDirectory']>,
    TRPCClientErrorLike<AppRouter['directories']['createDirectory']>,
    inferProcedureOutput<AppRouter['directories']['createDirectory']>,
    TContext
  >
) {
  const trpcUtils = trpc.useUtils();
  const queryClient = useQueryClient();

  const mutation = trpc.directories.createDirectory.useMutation({
    ...options,
    async onSuccess(result, opts, context) {
      await handleDirectoryMutationResponse(trpcUtils, result);

      for (const entity of result.affectedEntities) {
        await onEntityAddedToDirectory(
          trpcUtils,
          queryClient,
          entity,
          opts.targetDirectoryNodeUuid
        );
      }

      options?.onSuccess?.(result, opts, context);
    },
  });

  return mutation;
}

export function useAddEntitiesToDirectory<TContext = unknown>(
  options?: UseTRPCMutationOptions<
    inferProcedureInput<AppRouter['directories']['addEntitiesToDirectoryNode']>,
    TRPCClientErrorLike<AppRouter['directories']['addEntitiesToDirectoryNode']>,
    inferProcedureOutput<
      AppRouter['directories']['addEntitiesToDirectoryNode']
    >,
    TContext
  >
) {
  const utils = trpc.useUtils();
  const queryClient = useQueryClient();

  const mutation = trpc.directories.addEntitiesToDirectoryNode.useMutation({
    ...options,
    async onSuccess(result, opts, context) {
      void utils.directories.getDirectoryNodeContents.invalidate({
        uuid: opts.destinationDirectoryNodeUuid,
      });
      for (const entity of result.fulfilled) {
        await onEntityAddedToDirectory(
          utils,
          queryClient,
          entity,
          opts.destinationDirectoryNodeUuid
        );
      }
      options?.onSuccess?.(result, opts, context);
    },
  });

  return mutation;
}

export function useMoveNodesToDirectory<TContext = unknown>(
  options?: UseTRPCMutationOptions<
    inferProcedureInput<AppRouter['directories']['moveNodesToDirectory']>,
    TRPCClientErrorLike<AppRouter['directories']['moveNodesToDirectory']>,
    inferProcedureOutput<AppRouter['directories']['moveNodesToDirectory']>,
    TContext
  >
) {
  const utils = trpc.useUtils();
  const queryClient = useQueryClient();

  const mutation = trpc.directories.moveNodesToDirectory.useMutation({
    ...options,
    async onSuccess(result, opts, context) {
      await handleDirectoryMutationResponse(utils, result);

      // Mark ads as un-saved from origin directory and saved in target directory
      for (const affectedEntity of result.affectedEntities) {
        if (affectedEntity.entityType === 'OrganisationAd') {
          queryClient.setQueriesData<InfiniteData<GetAdsOutput>>(
            getQueryKey(trpc.ads.getAds, undefined, 'infinite'),
            (data) =>
              updateOrganisationAdInInfiniteQuery(
                affectedEntity.entityUuid,
                (ad) => {
                  const inDirectories = ad.inDirectories.filter(
                    (x) =>
                      !result.affectedDirectoryNodes.find((y) => y.uuid === x)
                  );
                  return {
                    ...ad,
                    inDirectories: opts.destinationDirectoryNodeUuid
                      ? [...inDirectories, opts.destinationDirectoryNodeUuid]
                      : inDirectories,
                  };
                },
                data
              )
          );
        }
      }

      options?.onSuccess?.(result, opts, context);
    },
  });

  return mutation;
}

export function useEditDirectory<TContext = unknown>(
  options?: UseTRPCMutationOptions<
    inferProcedureInput<AppRouter['directories']['updateDirectoryNode']>,
    TRPCClientErrorLike<AppRouter['directories']['updateDirectoryNode']>,
    inferProcedureOutput<AppRouter['directories']['updateDirectoryNode']>,
    TContext
  >
) {
  const utils = trpc.useUtils();

  const mutation = trpc.directories.updateDirectoryNode.useMutation({
    ...options,
    async onSuccess(result, opts, context) {
      utils.directories.getDirectoryNodeDetail.setData(
        { uuid: result.uuid },
        (old) => (old ? { ...old, ...result } : old)
      );

      utils.directories.getDirectoryStructureForOrganisation.setData(
        undefined,
        (old) => {
          const existing = old?.directories[result.uuid];

          return old && existing
            ? {
                ...old,
                directories: {
                  ...old.directories,
                  [result.uuid]: {
                    ...existing,
                    name: result.name ?? 'Untitled Board',
                  },
                },
              }
            : old ?? undefined;
        }
      );

      const parent = result.ParentDirectoryNode?.uuid;

      if (parent) {
        void utils.directories.getDirectoryNodeContents.invalidate({
          uuid: parent,
        });
      }

      options?.onSuccess?.(result, opts, context);
    },
  });

  return mutation;
}

export function useDeleteNodesFromDirectory<TContext = unknown>(
  options?: UseTRPCMutationOptions<
    inferProcedureInput<AppRouter['directories']['deleteDirectoryNodes']>,
    TRPCClientErrorLike<AppRouter['directories']['deleteDirectoryNodes']>,
    inferProcedureOutput<AppRouter['directories']['deleteDirectoryNodes']>,
    TContext
  >
) {
  const utils = trpc.useUtils();
  const queryClient = useQueryClient();

  const mutation = trpc.directories.deleteDirectoryNodes.useMutation({
    ...options,
    async onSuccess(result, opts, context) {
      await handleDirectoryMutationResponse(utils, result);

      // Mark ads as un-saved from directory
      for (const affectedEntity of result.affectedEntities) {
        if (affectedEntity.entityType === 'OrganisationAd') {
          queryClient.setQueriesData<InfiniteData<GetAdsOutput>>(
            getQueryKey(trpc.ads.getAds, undefined, 'infinite'),
            (data) =>
              updateOrganisationAdInInfiniteQuery(
                affectedEntity.entityUuid,
                (ad) => {
                  const inDirectories = ad.inDirectories.filter(
                    (x) =>
                      !result.affectedDirectoryNodes.find((y) => y.uuid === x)
                  );
                  return {
                    ...ad,
                    inDirectories,
                  };
                },
                data
              )
          );
        }
      }
      options?.onSuccess?.(result, opts, context);
    },
  });

  return mutation;
}

export type DirectoryItem = PartialByKey<
  ArrayElement<
    inferProcedureOutput<AppRouter['directories']['getDirectoryNodeContents']>
  >,
  DirectoryItemType | 'ParentDirectoryNode'
>;

type UseDirectoryQueryParams = {
  input: Omit<
    inferProcedureInput<AppRouter['directories']['getDirectoryNodeContents']>,
    'count' | 'cursor'
  >;
  options?: Parameters<
    typeof trpc.directories.getDirectoryNodeContents.useInfiniteQuery
  >[1];
};

type GetDirectoryContentsInfiniteQueryResult = ReturnType<
  typeof trpc.directories.getDirectoryNodeContents.useInfiniteQuery
>;

type UseDirectoryQueryGroupedResult = {
  queries: Partial<
    Record<DirectoryItemType, GetDirectoryContentsInfiniteQueryResult>
  >;
  isLoading: boolean;
  isFetching: boolean;
};

export const TAKE_LIMIT = 20;

export function useDirectoryContentsUngroupedQuery({
  input,
  options,
}: UseDirectoryQueryParams): GetDirectoryContentsInfiniteQueryResult {
  const query = trpc.directories.getDirectoryNodeContents.useInfiniteQuery(
    {
      ...input,
      count: TAKE_LIMIT,
    },
    {
      ...options,
      getNextPageParam(lastPage, pages) {
        if (pages.length === 0) {
          return undefined;
        }

        if (lastPage.length < TAKE_LIMIT) {
          return undefined;
        }

        return (pages.length - 1) * TAKE_LIMIT + lastPage.length;
      },
    }
  );

  return query;
}

export function useDirectoryContentsGroupedQuery({
  input,
  options,
}: UseDirectoryQueryParams): [
  GetDirectoryContentsInfiniteQueryResult,
  UseDirectoryQueryGroupedResult,
] {
  const directoriesQuery =
    trpc.directories.getDirectoryNodeContents.useInfiniteQuery(
      {
        ...input,
        filter: {
          nodeType: 'directory',
        },
        count: TAKE_LIMIT,
      },
      {
        ...options,
        getNextPageParam(lastPage, pages) {
          if (pages.length === 0) {
            return undefined;
          }

          if (lastPage.length < TAKE_LIMIT) {
            return undefined;
          }

          return (pages.length - 1) * TAKE_LIMIT + lastPage.length;
        },
      }
    );
  const queries = DirectoryAliasTypes.reduce(
    (acc, entityType) => {
      const query = trpc.directories.getDirectoryNodeContents.useInfiniteQuery(
        {
          ...input,
          filter: {
            entityType: [entityType],
          },
          count: TAKE_LIMIT,
        },
        {
          ...options,
          getNextPageParam(lastPage, pages) {
            if (pages.length === 0) {
              return undefined;
            }

            if (lastPage.length < TAKE_LIMIT) {
              return undefined;
            }

            return (pages.length - 1) * TAKE_LIMIT + lastPage.length;
          },
        }
      );

      return {
        ...acc,
        isLoading: acc.isLoading || query.isLoading,
        isFetching: acc.isFetching || query.isFetching,
        queries: {
          ...acc.queries,
          [entityType]: query,
        },
      };
    },
    {
      isLoading: directoriesQuery.isLoading,
      isFetching: directoriesQuery.isFetching,
      queries: {},
    } as UseDirectoryQueryGroupedResult
  );

  return [directoriesQuery, queries];
}
