import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Button, ButtonProps } from 'react-aria-components';

function isElementOrNullish(children: React.ReactNode) {
  if (!children) {
    return true;
  }
  switch (typeof children) {
    case 'number':
    case 'string':
    case 'boolean':
      return false;
    default:
      return true;
  }
}

const baseStyle = 'gap-2 flex items-center';

const justifyStyles: Record<ButtonJustify, string> = {
  left: 'justify-start',
  centre: 'justify-center',
  right: 'justify-end',
};

type ButtonJustify = 'left' | 'centre' | 'right';

type BaseProps<T> = React.PropsWithChildren<React.PropsWithRef<T>>;

type HTMLButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export type BaseButtonProps = BaseProps<HTMLButtonProps> & {
  icon?: React.ReactNode | null;
  justify?: ButtonJustify;
};

const BaseButton = forwardRef<HTMLButtonElement, BaseButtonProps>(
  // eslint-disable-next-line react/prop-types
  (
    { className, icon, children, justify = 'centre', ...props },
    ref
  ): JSX.Element => {
    return (
      <button
        className={classNames(baseStyle, justifyStyles[justify], className)}
        ref={ref}
        {...props}
      >
        {icon ?? null}
        {isElementOrNullish(children) ? children : <span>{children}</span>}
      </button>
    );
  }
);

BaseButton.displayName = 'BaseButton';

export type AriaBaseButtonProps = Omit<ButtonProps, 'className' | 'children'> &
  React.PropsWithChildren<{
    icon?: React.ReactNode | null;
    justify?: ButtonJustify;
    className?: string;
  }>;

export const AriaBaseButton = forwardRef<
  HTMLButtonElement,
  AriaBaseButtonProps
>(
  // eslint-disable-next-line react/prop-types
  (
    { className, icon, children, justify = 'centre', ...props },
    ref
  ): JSX.Element => {
    return (
      <Button
        className={classNames(baseStyle, justifyStyles[justify], className)}
        ref={ref}
        {...props}
      >
        {icon ?? null}
        {isElementOrNullish(children) ? children : <span>{children}</span>}
      </Button>
    );
  }
);

AriaBaseButton.displayName = 'BaseButton';

type ButtonLinkProps = BaseProps<React.ComponentProps<'a'>> & {
  icon?: React.ReactNode | null;
  justify?: ButtonJustify;
} & (
    | {
        type: 'internal';
        // A relative React Router path. Providing this will
        // cause the component to render as a Link.
        to: string;
      }
    | {
        type: 'external';
        // A URL to be navigated to in an external tab. When provided
        // this will cause the component to render as an anchor tag.
        href: string;
      }
  );

export const BaseButtonLink = forwardRef<HTMLAnchorElement, ButtonLinkProps>(
  // eslint-disable-next-line react/prop-types
  (
    { className, icon, children, justify = 'centre', ...props },
    ref
  ): JSX.Element => {
    return props.type === 'external' ? (
      <a
        className={classNames(baseStyle, justifyStyles[justify], className)}
        ref={ref}
        target="_blank"
        {...props}
      >
        {icon ?? null}
        <span>{children ?? null}</span>
      </a>
    ) : (
      <Link className={classNames(baseStyle, className)} ref={ref} {...props}>
        {icon ?? null}
        {isElementOrNullish(children) ? children : <span>{children}</span>}
      </Link>
    );
  }
);

BaseButtonLink.displayName = 'BaseButtonLink';

export default BaseButton;
