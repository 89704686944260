const hexRegex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;

export function hslToHex(h: number, s: number, l: number) {
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n: number) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0'); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}

export function hexToHSL(hex: string, cssString = false) {
  const result = hexRegex.exec(hex);

  if (!result) {
    throw new Error('Could not parse hex color');
  }

  const rHex = parseInt(result[1], 16);
  const gHex = parseInt(result[2], 16);
  const bHex = parseInt(result[3], 16);

  const r = rHex / 255;
  const g = gHex / 255;
  const b = bHex / 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  let h = (max + min) / 2;
  let s = h;
  let l = h;

  if (max === min) {
    // Achromatic
    return { h: 0, s: 0, l };
  }

  const d = max - min;
  s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
  switch (max) {
    case r:
      h = (g - b) / d + (g < b ? 6 : 0);
      break;
    case g:
      h = (b - r) / d + 2;
      break;
    case b:
      h = (r - g) / d + 4;
      break;
  }
  h /= 6;

  s = s * 100;
  s = Math.round(s);
  l = l * 100;
  l = Math.round(l);
  h = Math.round(360 * h);

  return cssString ? `hsl(${h}, ${s}%, ${l}%)` : { h, s, l };
}

export function hexToRgb(hex: string) {
  const result = hexRegex.exec(hex);

  if (!result) {
    throw new Error('Could not parse hex color');
  }

  return {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  };
}

export function hashStringToHexColour(
  str: string,
  saturation = 100,
  lightness = 80
) {
  const hash =
    str.split('').reduce<number>((acc, curr) => {
      return curr.charCodeAt(0) + ((acc << 5) - acc);
    }, 0) % 360;

  const hue = hash % 360;
  return hslToHex(hue, saturation, lightness);
}

export function generateSecondaryColorFromHex(hex: string) {
  const hsl = hexToHSL(hex);

  if (typeof hsl === 'string') {
    throw new Error('Expected HSL object');
  }

  const perceivedLightness = getPerceivedLightnessFromHex(hex);

  // Adjust lightness based on perceived lightness
  if (perceivedLightness > 50) {
    hsl.l = Math.max(0, hsl.l - 10); // Reduce lightness if bright
  } else {
    hsl.l = Math.min(100, hsl.l + 10); // Increase lightness if dark
  }

  return hslToHex(hsl.h, hsl.s, hsl.l);
}

/**
 * From https://stackoverflow.com/questions/59603278/how-to-determine-colors-perceived-brightness
 */
export function getPerceivedLightnessFromHex(hex: string) {
  const { r, g, b } = hexToRgb(hex);

  // Convert int to decimal 0-1 and linearize. 2.218 Gamma for sRGB linearization.
  const Rlin = (r / 255.0) ** 2.218;
  const Glin = (g / 255.0) ** 2.218;
  const Blin = (b / 255.0) ** 2.218;

  const Ylum = Rlin * 0.2126 + Glin * 0.7156 + Blin * 0.0722; // convert to Luminance Y

  return Math.pow(Ylum, 0.68) * 100; // Convert to lightness (0 to 100)
}
