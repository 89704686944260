import { deleteFromSearchParams, getQueryParam } from './queryParams';
import { sanitizeRedirectPath } from './santitizePath';

export const disallowedRedirectPaths = ['/register', '/login', '/logout'];

// this caters for redirects from external sources that include a redirect parameter
// and possible ensuing query params
// e.g.
// magicbrief.com/?redirect=/some/path
// magicbrief.com/?redirect=/some/path?user=123
// magicbrief.com/?redirect=/some/path&user=123

export const useRedirect = ():
  | { shouldRedirect: false }
  | { shouldRedirect: true; redirectPath: string } => {
  const redirect = getQueryParam('redirect');

  // this caters for redirects that include additional query params via '?'
  // e.g. magicbrief.com/?redirect=/some/path?user=123
  let redirectPath = sanitizeRedirectPath(redirect);

  if (
    redirectPath == null ||
    window.location.pathname === redirectPath ||
    redirectPath === '/' ||
    disallowedRedirectPaths.includes(redirectPath)
  ) {
    deleteFromSearchParams('redirect', true);

    return {
      shouldRedirect: false,
    };
  }

  // this caters for redirects that include '&' rather than '?' for the new paths own query params
  // e.g. magicbrief.com/?redirect=/some/path&user=123
  const searchParams = new URLSearchParams(window.location.search);
  if (redirectPath && searchParams.size > 1) {
    const fixed = deleteFromSearchParams('redirect');
    redirectPath = `${redirectPath}?${fixed.toString()}`;
  }

  return { redirectPath, shouldRedirect: true };
};
