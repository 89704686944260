import {
  INSIGHTS_FACEBOOK_TIME_SERIES_TEXT_OPERATIONS_V2,
  INSIGHTS_FACEBOOK_TIME_SERIES_MULTI_OPTION_FIELD_OPERATIONS_V2,
  INSIGHTS_FACEBOOK_TIME_SERIES_NUMERIC_OPERATIONS_V2,
  INSIGHTS_FACEBOOK_TIME_SERIES_DATE_OPERATIONS_V2,
  isInsightsFacebookTextMetric,
  isInsightsFacebookDateMetric,
  isInsightsFacebookEnumerableMetric,
  InsightsFacebookNumericMetricOperationV2,
  InsightsFacebookDateFieldOperationV2,
  InsightsFacebookMultiOptionFieldOperationV2,
  InsightsFacebookTextFieldOperationV2,
} from '@magicbrief/common';

export const NUMERICAL_OPTION_LABELS: Record<
  InsightsFacebookNumericMetricOperationV2,
  string
> = {
  '=': 'Equals',
  '!=': 'Not equal to',
  '>': 'Greater than',
  '>=': 'Greater than or equal to',
  '<': 'Less than',
  '<=': 'Less than or equal to',
  between: 'Between',
  notBetween: 'Not between',
};

export const DATE_OPTION_LABELS: Record<
  InsightsFacebookDateFieldOperationV2,
  string
> = {
  '=': 'On',
  '!=': 'Not on',
  '>': 'After',
  '<': 'Before',
  between: 'Between',
  notBetween: 'Not between',
  withinLast: 'Within last',
  withinDatePreset: 'Within selected time period',
};

export const ENUMERABLE_OPTION_LABELS: Record<
  InsightsFacebookMultiOptionFieldOperationV2,
  string
> = {
  in: 'Is one of',
  notIn: 'Is not one of',
};

export const TEXT_OPTION_LABELS: Record<
  InsightsFacebookTextFieldOperationV2,
  string
> = {
  '=': 'Is',
  '!=': 'Is not',
  contains: 'Contains',
  notContains: 'Does not contain',
  startsWith: 'Starts with',
  endsWith: 'Ends with',
  isSet: 'Is not empty',
  isNotSet: 'Is empty',
};

export function getOperationsAndLabelsForTarget(target: string) {
  if (isInsightsFacebookTextMetric(target)) {
    return {
      options: INSIGHTS_FACEBOOK_TIME_SERIES_TEXT_OPERATIONS_V2,
      labels: TEXT_OPTION_LABELS,
    };
  } else if (isInsightsFacebookDateMetric(target)) {
    return {
      options: INSIGHTS_FACEBOOK_TIME_SERIES_DATE_OPERATIONS_V2,
      labels: DATE_OPTION_LABELS,
    };
  } else if (isInsightsFacebookEnumerableMetric(target)) {
    return {
      options: INSIGHTS_FACEBOOK_TIME_SERIES_MULTI_OPTION_FIELD_OPERATIONS_V2,
      labels: ENUMERABLE_OPTION_LABELS,
    };
  } else {
    return {
      options: INSIGHTS_FACEBOOK_TIME_SERIES_NUMERIC_OPERATIONS_V2,
      labels: NUMERICAL_OPTION_LABELS,
    };
  }
}
