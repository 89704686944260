import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import User01 from 'src/assets/svgicons/duotone/user-01.svg';
import { Icon } from '../Icon';

export type AvatarProps = {
  src?: string;
  className?: string;
  style?: React.CSSProperties;
  showDefaultOnFail?: boolean;
  fallbackIcon?: React.ReactNode;
  round?: boolean;
  initial?: string;
  size?: number;
};

const Avatar: React.FC<AvatarProps> = ({
  src,
  className,
  fallbackIcon,
  showDefaultOnFail = true,
  round = true,
  initial,
  size = 32,
  ...rest
}: AvatarProps) => {
  const [imgSrc, setImgSrc] = useState<string | undefined>(src);
  const Img = imgSrc
    ? imgSrc
    : showDefaultOnFail
    ? fallbackIcon ?? (
        <Icon>
          <User01 />
        </Icon>
      )
    : undefined;

  useEffect(() => {
    setImgSrc(src);
  }, [src]);

  const strokeWidth = 2;
  const halfStroke = strokeWidth / 2;

  return (
    <span
      {...rest}
      className={classNames(
        'flex items-center justify-center overflow-hidden text-white',
        round && 'rounded-full',
        !imgSrc && 'bg-ternary',
        className
      )}
    >
      {typeof Img === 'string' && (
        <img
          className="h-full w-full object-cover"
          src={Img}
          onError={() => setImgSrc(undefined)}
          alt="Avatar"
        />
      )}
      {typeof Img !== 'string' && initial && (
        <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
          <rect
            x={halfStroke}
            y={halfStroke}
            rx="100"
            ry="100"
            width={size - strokeWidth}
            height={size - strokeWidth}
            fill="#DBD1F4"
            stroke="#ffffff"
            strokeWidth={strokeWidth}
          />
          <text
            x="50%"
            y="50%"
            dominantBaseline="middle"
            textAnchor="middle"
            fill="#ffffff"
            fontSize="14"
            dy="1"
          >
            {initial.toUpperCase()}
          </text>
        </svg>
      )}
      {typeof Img !== 'string' && !initial && !!Img && (
        <span className="[&>svg]:h-full [&>svg]:w-full">{Img}</span>
      )}
    </span>
  );
};

export default Avatar;
