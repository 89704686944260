import { createContext, useMemo, useReducer } from 'react';
import { Nullable } from '@magicbrief/common';
import CreateBriefCollectionModal from '../components/CreateBriefCollectionModal';
import DeleteBriefsModal from '../components/DeleteBriefsModal/DeleteBriefsModal';
import ManageBriefModal from '../components/ManageBriefModal/ManageBriefModal';
import DuplicateBriefModal from '../components/DuplicateBriefModal/DuplicateBriefModal';
import ShareBriefModal from '../components/ShareBriefModal/ShareBriefModal';
import AddBriefsToCollectionsModal from '../components/AddBriefsToCollectionsModal';
import { CreateBriefFromTemplateModal } from '../components/CreateBriefFromTemplateModal';
import { RenameTemplateModal } from '../components/RenameTemplateModal';
import type {
  Brief,
  BriefCollection,
} from '@magicbrief/prisma/generated/client2';

type BriefsContextShowModalState = {
  createBriefFromTemplateModal: {
    show: boolean;
    data: {
      template: Pick<Brief, 'uuid' | 'name'>;
      collectionID?: number;
    };
  };
  duplicateBriefModal: {
    show: boolean;
    data: {
      brief: Omit<Brief, 'data'>;
      collectionID?: number;
      createAsTemplate?: boolean;
    };
  };
  deleteBriefsModal: {
    show: boolean;
    data: { briefUuids: string[]; onSuccess?: () => void; template?: boolean };
  };
  createCollectionModal: { show: boolean };
  deleteCollectionModal: { show: boolean; data: BriefCollection };
  manageBriefModal: { show: boolean; data: Omit<Brief, 'data'> };
  addBriefsToCollectionsModal: {
    show: boolean;
    data: { briefUuids: string[] };
  };
  shareBriefModal: { show: boolean; data: Omit<Brief, 'data'> };
  renameTemplateModal: { show: boolean; data: Pick<Brief, 'name' | 'uuid'> };
};

type BriefsContextState = {
  showSidebar: boolean;
  isSearching: boolean;
} & Nullable<BriefsContextShowModalState>;

type ShowModalActionType = {
  [K in keyof BriefsContextShowModalState]: BriefsContextShowModalState[K] extends {
    data: infer U;
  }
    ? { type: `show${Capitalize<K>}`; data: U }
    : { type: `show${Capitalize<K>}` };
}[keyof BriefsContextShowModalState];

export type BriefsContextAction =
  | {
      type: 'toggleSidebar';
    }
  | { type: 'hideModal'; modal: keyof BriefsContextShowModalState }
  | { type: 'unmountModal'; modal: keyof BriefsContextShowModalState }
  | { type: 'setIsSearching'; payload: boolean }
  | ShowModalActionType;

const reducer: React.Reducer<BriefsContextState, BriefsContextAction> = (
  state,
  action
) => {
  switch (action.type) {
    case 'toggleSidebar':
      return { ...state, showSidebar: !state.showSidebar };
    case 'showCreateBriefFromTemplateModal':
      return {
        ...state,
        createBriefFromTemplateModal: { show: true, data: action.data },
      };
    case 'showDeleteBriefsModal':
      return {
        ...state,
        deleteBriefsModal: {
          show: true,
          data: action.data,
        },
      };
    case 'showAddBriefsToCollectionsModal':
      return {
        ...state,
        addBriefsToCollectionsModal: {
          show: true,
          data: action.data,
        },
      };
    case 'showManageBriefModal':
      return {
        ...state,
        manageBriefModal: { show: true, data: action.data },
      };
    case 'showDuplicateBriefModal':
      return {
        ...state,
        duplicateBriefModal: { show: true, data: action.data },
      };
    case 'showCreateCollectionModal':
      return {
        ...state,
        createCollectionModal: { show: true },
      };
    case 'showDeleteCollectionModal':
      return {
        ...state,
        deleteCollectionModal: { show: true, data: action.data },
      };
    case 'showShareBriefModal':
      return {
        ...state,
        shareBriefModal: { show: true, data: action.data },
      };
    case 'showRenameTemplateModal':
      return {
        ...state,
        renameTemplateModal: { show: true, data: action.data },
      };
    case 'hideModal':
      return {
        ...state,
        [action.modal]: { ...state[action.modal], show: false },
      };
    case 'unmountModal':
      return {
        ...state,
        [action.modal]: null,
      };
    case 'setIsSearching':
      return { ...state, isSearching: action.payload };
  }
};

export const BriefsStateContext = createContext<Pick<
  BriefsContextState,
  'showSidebar' | 'isSearching'
> | null>(null);

export const BriefsDispatchContext =
  createContext<React.Dispatch<BriefsContextAction> | null>(null);

export const BriefsProvider: React.FunctionComponent<
  React.PropsWithChildren
> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    showSidebar: false,
    createBriefFromTemplateModal: null,
    duplicateBriefModal: null,
    deleteBriefsModal: null,
    addBriefsToCollectionsModal: null,
    createCollectionModal: null,
    deleteCollectionModal: null,
    shareBriefModal: null,
    manageBriefModal: null,
    renameTemplateModal: null,
    isSearching: false,
  });

  const providerState = useMemo(
    () => ({
      showSidebar: state.showSidebar,
      isSearching: state.isSearching,
    }),
    [state.showSidebar, state.isSearching]
  );

  return (
    <BriefsDispatchContext.Provider value={dispatch}>
      <BriefsStateContext.Provider value={providerState}>
        {!!state.createBriefFromTemplateModal && (
          <CreateBriefFromTemplateModal
            appear
            template={state.createBriefFromTemplateModal.data.template}
            collectionID={state.createBriefFromTemplateModal.data.collectionID}
            show={state.createBriefFromTemplateModal.show}
            onClose={() =>
              dispatch({
                type: 'hideModal',
                modal: 'createBriefFromTemplateModal',
              })
            }
            afterClose={() =>
              dispatch({
                type: 'unmountModal',
                modal: 'createBriefFromTemplateModal',
              })
            }
          />
        )}
        {!!state.createCollectionModal && (
          <CreateBriefCollectionModal
            appear
            show={state.createCollectionModal.show}
            onClose={() =>
              dispatch({ type: 'hideModal', modal: 'createCollectionModal' })
            }
            afterClose={() =>
              dispatch({ type: 'unmountModal', modal: 'createCollectionModal' })
            }
          />
        )}
        {!!state.deleteBriefsModal && (
          <DeleteBriefsModal
            appear
            template={state.deleteBriefsModal.data.template}
            show={state.deleteBriefsModal.show}
            briefUuids={state.deleteBriefsModal.data.briefUuids}
            onSuccess={state.deleteBriefsModal?.data.onSuccess}
            onClose={() =>
              dispatch({ type: 'hideModal', modal: 'deleteBriefsModal' })
            }
            afterClose={() =>
              dispatch({ type: 'unmountModal', modal: 'deleteBriefsModal' })
            }
          />
        )}
        {!!state.addBriefsToCollectionsModal && (
          <AddBriefsToCollectionsModal
            appear
            show={state.addBriefsToCollectionsModal.show}
            briefUuids={state.addBriefsToCollectionsModal.data.briefUuids}
            onClose={() =>
              dispatch({
                type: 'hideModal',
                modal: 'addBriefsToCollectionsModal',
              })
            }
            afterClose={() =>
              dispatch({
                type: 'unmountModal',
                modal: 'addBriefsToCollectionsModal',
              })
            }
          />
        )}
        {!!state.manageBriefModal && (
          <ManageBriefModal
            appear
            show={state.manageBriefModal.show}
            brief={state.manageBriefModal.data}
            onClose={() =>
              dispatch({ type: 'hideModal', modal: 'manageBriefModal' })
            }
            afterClose={() =>
              dispatch({ type: 'unmountModal', modal: 'manageBriefModal' })
            }
          />
        )}
        {!!state.duplicateBriefModal && (
          <DuplicateBriefModal
            appear
            createAsTemplate={state.duplicateBriefModal.data.createAsTemplate}
            show={state.duplicateBriefModal.show}
            brief={state.duplicateBriefModal.data.brief}
            collectionID={state.duplicateBriefModal.data.collectionID}
            onClose={() =>
              dispatch({ type: 'hideModal', modal: 'duplicateBriefModal' })
            }
            afterClose={() =>
              dispatch({ type: 'unmountModal', modal: 'duplicateBriefModal' })
            }
          />
        )}
        {!!state.shareBriefModal && (
          <ShareBriefModal
            show={state.shareBriefModal.show}
            briefUuid={state.shareBriefModal.data.uuid}
            briefName={state.shareBriefModal.data.name}
            onClose={() =>
              dispatch({ type: 'hideModal', modal: 'shareBriefModal' })
            }
          />
        )}
        {!!state.renameTemplateModal && (
          <RenameTemplateModal
            appear
            show={state.renameTemplateModal.show}
            briefUuid={state.renameTemplateModal.data.uuid}
            name={state.renameTemplateModal.data.name}
            onClose={() =>
              dispatch({ type: 'hideModal', modal: 'renameTemplateModal' })
            }
            afterClose={() =>
              dispatch({ type: 'unmountModal', modal: 'renameTemplateModal' })
            }
          />
        )}
        {children}
      </BriefsStateContext.Provider>
    </BriefsDispatchContext.Provider>
  );
};
