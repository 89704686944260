import { AddToDirectoryModal } from 'src/components/Directories/AddToDirectoryModal/AddToDirectoryModal';
import { DeleteDirectoryModal } from 'src/components/Directories/DeleteDirectoryModal/DeleteDirectoryModal';
import { MoveToDirectoryModal } from 'src/components/Directories/MoveToDirectoryModal/MoveToDirectoryModal';
import { ShareDirectoryModal } from 'src/components/Directories/ShareDirectoryModal/ShareDirectoryModal';
import { NewDirectoryModal } from 'src/components/Directories/NewDirectoryModal/NewDirectoryModal';
import { EditDirectoryModal } from 'src/components/Directories/EditDirectoryModal/EditDirectoryModal';
import {
  useListenForInitiateAddEntityToDirectory,
  useListenForInitiateMoveNodeToDirectory,
  useListenForInitiateDeleteItemFromDirectory,
  useListenForShareDirectory,
  useListenForInitiateNewDirectory,
  useListenForInitiateEditDirectory,
} from '../../../components/Directories/useDirectoryActions';

export const DirectoriesOutlet: React.FC<React.PropsWithChildren> = ({
  children,
}): JSX.Element => {
  const [newDirectoryParams, clearNewDirectoryParams] =
    useListenForInitiateNewDirectory();
  const [addParams, clearAdd] = useListenForInitiateAddEntityToDirectory();
  const [moveParams, clearMove] = useListenForInitiateMoveNodeToDirectory();
  const [deleteParams, clearDelete] =
    useListenForInitiateDeleteItemFromDirectory();
  const [shareParams, clearShare] = useListenForShareDirectory();
  const [editParams, clearEdit] = useListenForInitiateEditDirectory();

  return (
    <>
      <NewDirectoryModal
        isOpen={!!newDirectoryParams}
        onOpenChange={(isOpen) => {
          if (!isOpen) {
            clearNewDirectoryParams();
          }
        }}
        parentDirectoryUuid={newDirectoryParams?.parentDirectoryUuid ?? null}
      />
      <AddToDirectoryModal
        entity={addParams ?? null}
        onOpenChange={(isOpen) => {
          if (!isOpen) {
            clearAdd();
          }
        }}
      />
      <EditDirectoryModal
        entity={editParams ?? null}
        onOpenChange={(isOpen) => {
          if (!isOpen) {
            clearEdit();
          }
        }}
      />
      <MoveToDirectoryModal
        entity={moveParams ?? null}
        onOpenChange={(isOpen) => {
          if (!isOpen) {
            clearMove();
          }
        }}
      />
      <DeleteDirectoryModal
        entity={deleteParams ?? null}
        onOpenChange={(isOpen) => {
          if (!isOpen) {
            clearDelete();
          }
        }}
      />
      <ShareDirectoryModal
        entity={shareParams ?? null}
        onOpenChange={(isOpen) => {
          if (!isOpen) {
            clearShare();
          }
        }}
      />
      {children}
    </>
  );
};
