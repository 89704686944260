import { captureException } from '@sentry/react';

export type ShowParams = {
  customerId: string;
  authHash: string;
  appId: string;
  mode: 'live' | 'test';
  provider: 'stripe';
  record: boolean;

  /**
   * Handler Callbacks
   * {@link https://docs.churnkey.co/installing-churnkey/ Docs}
   * */
  handlePause?: () => Promise<void>;
  handleCancel?: () => Promise<void>;
  handleDiscount?: () => Promise<void>;
  handleTrialExtension?: () => Promise<void>;
  handleSupportRequest?: () => void;
  handlePlanRequest?: (
    customer: string,
    { plan }: { plan: unknown }
  ) => Promise<void>;

  /**
   * Listener Callbacks
   * {@link https://docs.churnkey.co/installing-churnkey/ Docs}
   * */
  onPause?: (
    customer: string,
    { pauseDuration }: { pauseDuration: unknown }
  ) => void;
  onCancel?: (customer: string, surveyResponse: unknown) => void;
  onDiscount?: (customer: string, coupon: unknown) => void;
  onTrialExtension?: (
    customer: string,
    { trialExtensionDays }: { trialExtensionDays: unknown }
  ) => void;
  onPlanChange?: (customer: string, { planId }: { planId: unknown }) => void;

  /** whilst this is an accepted param, we do not need to use this in our setup */
  subscriptionId?: string;
};

export function show(params: ShowParams) {
  try {
    if (window.churnkey == null) {
      throw new Error('window.churnkey is undefined');
    }

    window.churnkey.init('show', params);
  } catch (e) {
    console.error(e);
    captureException(e, (scope) => {
      scope.setTransactionName('churnkey->show');
      return scope;
    });
    throw new Error('Could not show churnkey');
  }
}

export type CheckParams = {
  customerId: string;
  authHash: string;
  appId: string;
  mode: 'live' | 'test';
  provider: 'stripe';
  softWall: boolean;
  forceCheck: boolean;

  /**
   * Handler Callbacks
   * {@link https://docs.churnkey.co/pause-wall/ Docs}
   * */
  handleLogout?: () => void;
  handleResume?: (customer: string) => Promise<void>;
  handleCancel?: (customer: string) => Promise<void>;

  /**
   * Listener Callbacks
   * {@link https://docs.churnkey.co/pause-wall/ Docs}
   * */
  onPauseWallActivated?: () => void;
  onCancel?: (customer: string) => void;
  onResume?: (customer: string) => void;
  onPauseWallClose?: () => void;
  onError: (
    error: Error,
    type:
      | 'PAUSE_WALL_INITIALIZATION_ERROR'
      | 'PAUSE_WALL_CANCEL_ERROR'
      | 'PAUSE_WALL_RESUME_ERROR'
  ) => void;

  /** whilst this is an accepted param, we do not need to use this in our setup */
  subscriptionId?: string;
};

export function check(params: CheckParams) {
  try {
    if (window.churnkey == null) {
      throw new Error('window.churnkey is undefined');
    }

    window.churnkey.check('pause', params);
  } catch (e) {
    console.error(e);
    captureException(e, (scope) => {
      scope.setTransactionName('churnkey->show');
      return scope;
    });
    throw new Error('Could not check churnkey');
  }
}
