import { useEffect } from 'react';
import { useRouteError } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import Navigation from 'src/components/Navigation/Navigation';
import MagicBriefLogoHalfColoured from 'src/assets/svgicons/magicbrief/MagicBriefLogoHalfColoured.svg';
import DefaultFallback from './components/DefaultFallback/DefaultFallback';

const MagicBriefRouterErrorBoundary: React.FunctionComponent =
  (): JSX.Element => {
    const error = useRouteError() as Error;

    useEffect(() => {
      Sentry.captureException(error, (scope) => {
        scope.setLevel('fatal');
        scope.setExtra('componentStack', error.stack);
        return scope;
      });
    }, [error]);

    return (
      <div>
        <Navigation.Base mobileMenu={null}>
          <MagicBriefLogoHalfColoured className="h-7 max-w-[143px]" />
        </Navigation.Base>
        <DefaultFallback error={error} />
      </div>
    );
  };

export default MagicBriefRouterErrorBoundary;
