interface Intercom {
  boot: () => void;
  showNewMessage: (message: string) => void;
}

function isIntercomAvailable(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  window: any
): window is Window & { Intercom: Intercom } {
  return document.querySelector('[id^="intercom-"]') !== null;
}

const defaultUnavailableMessage =
  'We use Intercom to process requests, please disable your adblocker on MagicBrief, reload and try again. If you cannot see the popup, email support@magicbrief.com';

export function showNewMessage({
  message,
  unavailableMessage = defaultUnavailableMessage,
}: {
  message: string;
  unavailableMessage?: string;
}) {
  if (isIntercomAvailable(window)) {
    window.Intercom('showNewMessage', message);
  } else {
    alert(unavailableMessage);
  }
}
