import { AxiosRequestConfig, AxiosResponse } from 'axios';
import instance from 'src/lib/axios';
import { FileUploadResponse } from 'src/types/DataObjects';

export async function uploadFile(
  file: File,
  config: AxiosRequestConfig = {}
): Promise<AxiosResponse<FileUploadResponse>> {
  const formData = new FormData();

  formData.append('asset', file);

  const res = await instance.put<FileUploadResponse>(
    '/storage',
    formData,
    config
  );

  return res;
}
