import { useLayoutEffect, useRef } from 'react';
import {
  INSIGHTS_FACEBOOK_TIME_SERIES_ALL_METRICS,
  isInsightsFacebookMetricNumeric,
} from '@magicbrief/common';
import classNames from 'classnames';
import SearchMd from 'src/assets/svgicons/solid/search-md.svg';
import { Checkbox } from 'src/components/Checkbox';
import { Icon } from 'src/components/Icon';
import Input from 'src/components/Input';
import { useI18nContext } from 'src/i18n/i18n-react';
import { useMetricSearch } from 'src/pages/Insights/util/useMetricSearch';
import { useParseMetric } from 'src/pages/Insights/util/useParseMetric';
import { InsightsMetricLabelTooltip } from '../../InsightsMetricLabelTooltip/InsightsMetricLabelTooltip';
import InsightsFilterMetricResultsSearch from './InsightsFilterMetricResultsSearch';

type Props = {
  selected: string[];
  customEvents: string[] | null;
  customConversions: Array<{ facebookId: string; name: string }> | null;
  onToggle: (metric: string) => void;
  allowedMetricTypes?: 'numeric'[];
};

const fadeOutStyle =
  "after:left-0 after:bottom-0 after:sticky after:content-[''] after:z-10 after:bg-gradient-to-b after:from-white/0 after:to-white after:h-3 after:w-full after:shrink-0 after:pointer-events-none";
const fadeInStyle =
  "before:-mt-3 before:left-0 before:top-0 before:sticky before:content-[''] before:z-10 before:bg-gradient-to-t before:from-white/0 before:to-white before:h-3 before:w-full before:shrink-0 before:pointer-events-none";

export const InsightsMetricCheckboxList: React.FC<Props> = ({
  customEvents,
  customConversions,
  selected,
  onToggle,
  allowedMetricTypes,
}) => {
  const { LL } = useI18nContext();
  const { getMetricLabelAsText } = useParseMetric();

  const metrics = INSIGHTS_FACEBOOK_TIME_SERIES_ALL_METRICS.filter((metric) => {
    if (allowedMetricTypes != null) {
      if (allowedMetricTypes.includes('numeric')) {
        if (!isInsightsFacebookMetricNumeric(metric)) {
          return false;
        }
      }
    }
    return true;
  });

  const {
    results: items,
    onChange,
    query,
  } = useMetricSearch(metrics, customEvents, customConversions);

  /**
   * Note on autofocus with elements that animate in. Popover will be briefly at end of
   * page before positioning itself, so autofocusing anything inside it will
   * cause janky scroll behaviour. Need to focus manually!
   * https://github.com/adobe/react-spectrum/issues/4898#issuecomment-1674219912
   */
  const inputRef = useRef<HTMLInputElement>(null);
  useLayoutEffect(() => {
    /**
     * Match the timeout to the popover animation time
     * @see {@link src/components/AriaPopover/usePopoverState}
     */
    const timeout = setTimeout(() => inputRef.current?.focus(), 100);
    return () => clearTimeout(timeout);
  }, []);

  const results = items.reduce<
    {
      metric: string;
      labelAsText: string;
    }[]
  >((acc, metric) => {
    const labelAsText: string | null = getMetricLabelAsText(
      metric,
      customEvents,
      customConversions
    );

    if (labelAsText == null) {
      return acc;
    }

    acc.push({ metric, labelAsText });
    return acc;
  }, []);

  return (
    <div className="flex w-full flex-auto flex-col">
      <div className="p-3 pb-1">
        <Input
          ref={inputRef}
          className="top-0 z-10 flex-auto bg-white"
          onChange={(e) => {
            onChange(e.target.value);
          }}
          placeholder={LL.insights.metrics.search()}
          name="insights-filter-search"
          label=""
          suffixIcon={
            <Icon className="w-5 opacity-50">
              <SearchMd />
            </Icon>
          }
          iconPosition="left"
        />
      </div>
      <div
        className={classNames(
          'relative flex flex-col gap-3 overflow-auto px-3',
          fadeOutStyle,
          fadeInStyle
        )}
      >
        {results.length === 0 ? (
          <p className="primary text-sm italic text-purple-400">
            {LL.insights.metrics.noResults()}
          </p>
        ) : query.trim().toLowerCase().includes('result') ? (
          <InsightsFilterMetricResultsSearch />
        ) : (
          results.map(({ metric }) => {
            return (
              <Checkbox
                key={metric}
                checked={selected.includes(metric)}
                onChange={() => onToggle(metric)}
                checkboxClassName="rounded-[0.1875rem] !size-[1.125rem]"
              >
                <span className="truncate text-sm font-semibold text-primary">
                  <InsightsMetricLabelTooltip
                    metric={metric}
                    customConversions={customConversions}
                    customEvents={customEvents}
                  />
                </span>
              </Checkbox>
            );
          })
        )}
      </div>
    </div>
  );
};
