import { useI18nContext } from 'src/i18n/i18n-react';
import { Icon } from 'src/components/Icon';
import CurvedArrow from 'src/assets/svgicons/custom/CurvedArrow.svg';

const InsightsFilterMetricResultsSearch: React.FC = () => {
  const { LL } = useI18nContext();

  return (
    <div className="rounded-md border border-solid border-[#E8E5FF] bg-purple-100/80 p-4 text-primary">
      <p className="pb-3 font-bold">
        {LL.insights.metrics.resultsSearch.title()}
      </p>
      <p className="text-sm font-normal">
        {LL.insights.metrics.resultsSearch.body()}
      </p>
      <div className="flex items-center gap-3 pt-5">
        <div className="flex w-48 flex-col items-end rounded-xl bg-white px-4 py-2 shadow-sm">
          <span className="text-xl font-normal text-[#1C2B33] underline underline-offset-4">
            59
          </span>
          <span className="text-lg font-normal text-[#1C2B33]/60">
            {LL.insights.metrics.resultsSearch.websiteLeads()}
          </span>
        </div>
        <Icon className="size-10">
          <CurvedArrow />
        </Icon>
      </div>
    </div>
  );
};

export default InsightsFilterMetricResultsSearch;
