import {
  HorizontalTabbedRadio,
  HorizontalTabbedRadioGroup,
} from 'src/components/HorizontalTabbedRadio/HorizontalTabbedRadio';
import { Icon } from 'src/components/Icon';
import Grid01 from 'src/assets/svgicons/duocolor/grid-01.svg';
import Columns01 from 'src/assets/svgicons/duocolor/table.svg';
import {
  useInsightsLayout,
  useInsightsStoreDispatch,
} from '../util/useInsightsPersistentState';

export const InsightsViewRadioGroup: React.FC = () => {
  const dispatch = useInsightsStoreDispatch();
  const layout = useInsightsLayout();

  return (
    <HorizontalTabbedRadioGroup
      data-intercom-target="insight_viewMode"
      groupAriaLabel="View"
      value={layout}
      onChange={(value) => {
        const layout = value as 'table' | 'grid';
        dispatch({
          type: 'setLayout',
          value: layout,
        });
      }}
    >
      <HorizontalTabbedRadio aria-label="Table" value="table">
        <Icon>
          <Columns01 />
        </Icon>
      </HorizontalTabbedRadio>
      <HorizontalTabbedRadio aria-label="Grid" value="grid">
        <Icon>
          <Grid01 />
        </Icon>
      </HorizontalTabbedRadio>
    </HorizontalTabbedRadioGroup>
  );
};
