import { FC, PropsWithChildren } from 'react';
import { Heading } from 'react-aria-components';
import classNames from 'classnames';
import ModalCloseButton from './ModalCloseButton';

type Props = {
  onClose?: (() => void) | null;
  className?: string;
};

const ModalHeader: FC<PropsWithChildren<Props>> = ({
  children,
  onClose,
  className,
}) => {
  return (
    <div className={classNames('flex flex-row justify-between', className)}>
      <Heading
        slot="title"
        className="truncate text-2xl font-bold text-primary"
      >
        {children}
      </Heading>
      {onClose && <ModalCloseButton onPress={onClose} />}
    </div>
  );
};

export default ModalHeader;
