import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { SimpleModal } from 'src/components/AriaModal';
import { Icon } from 'src/components/Icon';
import Avatar from 'src/components/Misc/Avatar';
import { useI18nContext } from 'src/i18n/i18n-react';
import { useUserAndOrganisation } from 'src/utils/useUserAndOrganisation';
import Link01 from '../../../assets/svgicons/line/link-01.svg';
import LinkBroken01 from '../../../assets/svgicons/line/link-broken-01.svg';
import { AriaButton } from '../../../components/Button/Button';
import { useInsightsFacebookAccount } from '../util/useInsightsQueries';

type Props = {
  show: boolean;
  onClose: (event?: 'contact-support' | 'connect-accounts') => void;
  userUuid?: string;
  userName?: string;
  userEmail?: string;
  userProfilePictureUrl?: string;
};

const ReconnectRequiredModal: FC<Props> = ({
  show,
  onClose,
  userUuid,
  userName,
  userEmail,
  userProfilePictureUrl,
}) => {
  const { LL } = useI18nContext();
  const user = useUserAndOrganisation();
  const { accountUuid } = useParams();
  const { facebookAdAccount } = useInsightsFacebookAccount({ accountUuid });

  return (
    <SimpleModal
      title={
        <div className="flex w-full flex-row items-center justify-between gap-3">
          <Icon className="h-6 w-6 text-primary">
            <LinkBroken01 />
          </Icon>
          {LL.insights.reconnectRequiredModal.title()}
        </div>
      }
      show={show}
      onClose={onClose}
      footer={
        <div className="flex w-full flex-row justify-between gap-3">
          <AriaButton
            variant="secondary"
            onPress={() => onClose('contact-support')}
            className="grow"
          >
            {LL.insights.reconnectRequiredModal.supportCta()}
          </AriaButton>
          <AriaButton
            variant="primary"
            onPress={() => onClose('connect-accounts')}
            className="grow"
            icon={
              <Icon>
                <Link01 />
              </Icon>
            }
          >
            {LL.insights.reconnectRequiredModal.connectAccountCta()}
          </AriaButton>
        </div>
      }
    >
      <div className="flex flex-col">
        <div>
          <p className="text-sm text-purple-600">
            {LL.insights.reconnectRequiredModal.body1({
              name: facebookAdAccount.data?.name ?? 'Unknown',
            })}
          </p>
        </div>
        {userName != null && (
          <div className="mt-3 flex flex-col gap-3">
            <p className="text-sm text-purple-600">
              {LL.insights.reconnectRequiredModal.body2()}
            </p>
            <div className="flex flex-row items-center">
              <Avatar
                src={userProfilePictureUrl}
                className="ml-1.5 !h-6 !w-6 shrink-0"
              />

              <div className="overflow-hidden text-ellipsis">
                <div className="px-3.5 text-primary">
                  <p className="line-clamp-1 text-sm font-semibold">
                    {userName}
                    {user.data && userUuid === user.data.user.uuid && ` (You)`}
                  </p>
                  <p className="truncate text-xs font-normal text-purple-500">
                    {userEmail}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </SimpleModal>
  );
};

export default ReconnectRequiredModal;
