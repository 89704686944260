import { ForTimePeriodInput } from '@magicbrief/server/src/insights/types';
import dayjs from 'dayjs';
import { TranslationFunctions } from 'src/i18n/i18n-types';

export function getTimePeriodLabel(
  forTimePeriod: ForTimePeriodInput,
  LL: TranslationFunctions
) {
  if (forTimePeriod.datePreset === 'custom') {
    if (forTimePeriod.since && forTimePeriod.until) {
      const startDate = dayjs(forTimePeriod.since);
      const endDate = dayjs(forTimePeriod.until);

      // If user selects the same date for both start and end, just display a single date
      if (startDate.isSame(endDate, 'day')) {
        return startDate.format('MMM D, YYYY');
      }

      // For date ranges within the same year, just display the year once at the end of the custom label
      if (startDate.year() === endDate.year()) {
        return `${startDate.format('MMM D')} - ${endDate.format(
          'MMM D, YYYY'
        )}`;
      }

      return `${startDate.format('MMM D, YYYY')} - ${endDate.format(
        'MMM D, YYYY'
      )}`;
    }
  }

  return LL.insights.timePeriod[
    forTimePeriod.datePreset as keyof typeof LL.insights.timePeriod
  ]();
}
