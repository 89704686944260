import { createContext } from 'react';
import { SingleUploadState } from 'src/utils/useUploadUserAsset';

export type GlobalContextValue = {
  /* /assets related global state */
  assets: {
    activeUploads: SingleUploadState[];
  };
};

export type GlobalContextAction = {
  type: 'setAssetsUploads';
  payload: SingleUploadState[];
};

export const GlobalStateContext = createContext<GlobalContextValue | null>(
  null
);

export const GlobalDispatchContext =
  createContext<React.Dispatch<GlobalContextAction> | null>(null);
