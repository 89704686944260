import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

type Props = {
  id?: string;
  src: string;
  fallbackSrc?: string;
  height?: number;
  width?: number;
  className?: string;
  alt: string;
};

const placeholderSrc = '/images/placeholder.jpg';

const Image: React.FunctionComponent<Props> = ({
  id,
  src,
  fallbackSrc = placeholderSrc,
  alt,
  height,
  width,
  className,
}): JSX.Element => {
  const [error, setError] = useState<'srcError' | 'fallbackError' | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const ref = useRef<HTMLImageElement>(null);

  useEffect(() => {
    const img = ref.current;
    const onLoad = () => {
      if (img) {
        img.removeEventListener('load', onLoad);
        img.removeEventListener('error', onLoad);
      }
      setIsLoading(false);
    };
    if (!img?.complete) {
      if (img) {
        img.addEventListener('load', onLoad);
        img.addEventListener('error', onLoad);
      }
    } else {
      setIsLoading(false);
    }

    return () => {
      img?.removeEventListener('load', onLoad);
      img?.removeEventListener('error', onLoad);
    };
  }, []);

  return (
    <img
      id={id}
      ref={ref}
      loading="lazy"
      src={
        error === 'srcError'
          ? fallbackSrc
          : error === 'fallbackError'
          ? placeholderSrc
          : src
      }
      alt={alt}
      height={height}
      width={width}
      onError={({ currentTarget }) => {
        if (
          currentTarget.src === fallbackSrc &&
          fallbackSrc !== placeholderSrc
        ) {
          // the provided fallback source has failed also, we need to fallback to the placeholder
          setError('fallbackError');
        } else if (currentTarget.src !== fallbackSrc) {
          setError('srcError');
        }
      }}
      className={classNames(
        'relative',
        isLoading &&
          'after:absolute after:inset-0 after:h-full after:w-full after:animate-pulse after:bg-purple-100',
        error === 'fallbackError' && 'bg-repeat',
        className
      )}
    />
  );
};

export default Image;
