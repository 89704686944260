import { createContext, useContext } from 'react';
import { ShallowAdResponse } from 'src/types/ad';

type LibraryDetailContextData = {
  ad: ShallowAdResponse;
  queryID: string | null;
  pagination: {
    flatIndex: number;
    getAdAtFlatIndex: (
      index: number
    ) => { ad: ShallowAdResponse; queryID: string | null } | null;
  } | null;
};

export type LibraryDetailContextParams = {
  /**
   * UUID of the ad to be displayed.
   */
  uuid: string;
  /**
   * Preloaded ad data that can be provided if available to speed
   * up displaying the ad details and avoiding additional network requests.
   * Also contains methods for navigating back and forth between ads.
   */
  data: LibraryDetailContextData | null;
};

type LibraryDetailContextValue = (params: LibraryDetailContextParams) => void;

const LibraryDetailContext = createContext<LibraryDetailContextValue | null>(
  null
);

export function useLibraryDetailContext() {
  const context = useContext(LibraryDetailContext);

  if (!context) {
    throw new Error(
      'useLibraryDetailContext can only be called within a LibraryDetailContextProvider'
    );
  }

  return context;
}

export const LibraryDetailContextProvider = LibraryDetailContext.Provider;
