import { useEffect } from 'react';
import { isProdDeploy } from '@magicbrief/common';
import {
  init,
  reactRouterV6BrowserTracingIntegration,
  replayIntegration,
} from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { sentryConfigs } from 'src/configs';
import appConfig from '../configs/app';

export function initSentry() {
  if (appConfig.env === 'development' && !appConfig.debug) {
    return;
  }

  init({
    dsn: sentryConfigs.dsn,
    integrations: [
      reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      replayIntegration(),
    ],
    tracesSampleRate: isProdDeploy(import.meta.env) ? 0.1 : 1,
    replaysSessionSampleRate: isProdDeploy(import.meta.env) ? 0.1 : 1,
    replaysOnErrorSampleRate: 1.0,
    release: appConfig.release,
    environment: appConfig.deployment,
  });
}
