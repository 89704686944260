import { FC, useState } from 'react';
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  Button,
  Dialog,
  DialogTrigger,
  Heading,
  Modal,
  ModalOverlay,
  Text,
} from 'react-aria-components';
import Eye from 'src/assets/svgicons/duotone/eye.svg';
import XClose from 'src/assets/svgicons/line/x-close.svg';
import DotsGrid from 'src/assets/svgicons/solid/dots-grid.svg';
import { AriaButton } from 'src/components/Button/Button';
import { Icon } from 'src/components/Icon';
import { UpgradeModalV2 } from 'src/components/Modals/UpgradeModalV2/UpgradeModalV2';
import { useI18nContext } from 'src/i18n/i18n-react';
import { useEntitlementQuotas } from 'src/utils/useEntitlementQuotas';
import useNewAnalyticsEvent from 'src/utils/useNewAnalyticsEvent';
import useFeatureFlag from 'src/utils/useFeatureFlag';
import {
  useInsightsDisplay,
  useInsightsStoreDispatch,
} from '../../util/useInsightsPersistentState';
import { useParseMetric } from '../../util/useParseMetric';
import { InsightsMetricCheckboxList } from './components/InsightsMetricCheckboxList';
import { InsightsFilterDisplayMenuV2 } from './InsightsFilterDisplayMenuV2';

type Props = {
  customConversions: Array<{ facebookId: string; name: string }> | null;
  customEvents: string[] | null;
};

export const InsightsFilterDisplayMenu: FC<Props> = ({
  customConversions,
  customEvents,
}) => {
  const { LL } = useI18nContext();
  const { hasEntitlement } = useEntitlementQuotas('insights_ad_account');
  const display = useInsightsDisplay();
  const dispatch = useInsightsStoreDispatch();
  const { recordEvent } = useNewAnalyticsEvent();
  const isDisplayMetricsModalV2Enabled = useFeatureFlag(
    'INSIGHTS_DISPLAY_METRICS_V2_ENABLED'
  );

  const [isOpen, setIsOpen] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      const oldIndex = display.indexOf(active.id as string);
      const newIndex = display.indexOf(over.id as string);
      const newDisplayOrder = arrayMove(display, oldIndex, newIndex);
      dispatch({ type: 'setDisplay', value: newDisplayOrder });
    }
  }

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  if (isDisplayMetricsModalV2Enabled) {
    return <InsightsFilterDisplayMenuV2 />;
  }

  return (
    <>
      <DialogTrigger>
        <AriaButton
          data-intercom-target="insight_customMetrics"
          variant="white"
          icon={
            <Icon>
              <Eye />
            </Icon>
          }
          onPress={() => {
            if (hasEntitlement) {
              setIsOpen(true);
            } else {
              setShowUpgradeModal(true);
            }
          }}
        >
          {LL.insights.card.tabs.metrics()}
        </AriaButton>
        <ModalOverlay
          isOpen={isOpen}
          onOpenChange={setIsOpen}
          className="fixed inset-0 z-[10000] flex min-h-full items-center justify-center bg-gray-900/60 p-4 text-center"
        >
          <Modal className="flex max-h-[80%] w-full max-w-3xl overflow-hidden rounded-xl bg-white text-left align-middle shadow-xl">
            <Dialog className="relative flex flex-auto outline-none">
              {({ close }) => (
                <div className="flex w-full flex-auto flex-col">
                  <div className="flex flex-row items-center justify-between border-b border-solid border-b-purple-200 px-6 py-4 text-primary">
                    <Heading className="text-xl font-bold text-primary">
                      Customise Visible Metrics
                    </Heading>
                    <Button className="h-6 w-6" onPress={close}>
                      <Icon className="">
                        <XClose />
                      </Icon>
                    </Button>
                  </div>
                  <div className="grid grid-cols-5 overflow-hidden">
                    <div className="col-span-3 flex overflow-hidden py-1 pl-3 pr-1">
                      <InsightsMetricCheckboxList
                        selected={display}
                        customEvents={customEvents}
                        customConversions={customConversions}
                        onToggle={(metric) => {
                          const action = display.includes(metric)
                            ? 'remove'
                            : 'add';

                          dispatch({
                            type: 'toggleDisplay',
                            value: metric,
                          });

                          void recordEvent({
                            action: `Display ${action}`,
                            target: 'Insights Display',
                            metadata: {
                              metric,
                            },
                          });
                        }}
                      />
                    </div>
                    <div className="col-span-2 flex min-h-0 flex-col gap-3 bg-purple-50 pt-3">
                      <Heading className="px-6 text-sm font-semibold text-primary">
                        {display.length} metrics selected
                      </Heading>
                      <div className="flex min-h-0 flex-auto flex-col gap-2 overflow-auto px-6 py-2">
                        <DndContext
                          collisionDetection={closestCenter}
                          onDragEnd={handleDragEnd}
                          sensors={sensors}
                        >
                          <SortableContext
                            items={display}
                            strategy={verticalListSortingStrategy}
                          >
                            {display.map((x) => (
                              <InsightsFilterDisplayMenuListItem
                                id={x}
                                key={x}
                                customEvents={customEvents ?? null}
                                customConversions={customConversions ?? null}
                              />
                            ))}
                          </SortableContext>
                        </DndContext>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end border-t border-solid border-t-purple-200 px-6 py-3 text-primary">
                    <AriaButton onPress={close}>Save</AriaButton>
                  </div>
                </div>
              )}
            </Dialog>
          </Modal>
        </ModalOverlay>
      </DialogTrigger>
      <UpgradeModalV2
        isOpen={showUpgradeModal}
        close={() => setShowUpgradeModal(false)}
        prompt="to unlock insights"
      />
    </>
  );
};

const InsightsFilterDisplayMenuListItem: FC<{
  id: string;
  customEvents: string[] | null;
  customConversions: Array<{ facebookId: string; name: string }> | null;
}> = ({ id, customEvents, customConversions }) => {
  const { getMetricLabelAsText } = useParseMetric();
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
  });
  const dispatch = useInsightsStoreDispatch();

  const styles: React.CSSProperties = {
    zIndex: isDragging ? 1 : 0,
    transform: CSS.Translate.toString(transform),
    opacity: isDragging ? 0.8 : 1,
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={styles}
      className="flex flex-row items-center justify-between rounded-md border border-solid border-purple-200 bg-white px-5 py-2 shadow-sm last:mb-2"
    >
      <div className="flex flex-row items-center gap-2 overflow-hidden">
        <button
          {...attributes}
          {...listeners}
          className="cursor-grab text-purple-300 active:cursor-grabbing"
        >
          <Icon className="h-4 w-4">
            <DotsGrid />
          </Icon>
        </button>
        <Text slot="label" className="truncate text-sm text-primary">
          {getMetricLabelAsText(id, customEvents, customConversions)}
        </Text>
      </div>
      <Button
        className="flex h-6 w-6 items-center justify-center text-purple-300"
        onPress={() =>
          dispatch({
            type: 'toggleDisplay',
            value: id,
          })
        }
      >
        <Icon className="h-4 w-4">
          <XClose />
        </Icon>
      </Button>
    </div>
  );
};
