import { createContext, useContext, useMemo } from 'react';
import { useI18nContext } from 'src/i18n/i18n-react';
import useFeatureFlag from 'src/utils/useFeatureFlag';
import { useUserAndOrganisation } from '../../utils/useUserAndOrganisation';

type NavigationLink = {
  to: string;
  label: string;
};

export function useDefaultNavigationLinks(): NavigationLink[] {
  const { LL } = useI18nContext();
  const user = useUserAndOrganisation();
  const isInspireUiEnabled = useFeatureFlag('INSPIRE_UI_ENABLED');
  const isAssetsUiEnabled = useFeatureFlag('ASSETS_UI');
  const isInsightsUiEnabled =
    useFeatureFlag('INSIGHTS_UI_ENABLED') || user.data?.user.undiesUser;

  const links = useMemo((): NavigationLink[] => {
    return [
      ...(isInspireUiEnabled
        ? [
            {
              to: '/library',
              label: LL.navigation.adLibrary(),
            },
          ]
        : []),
      ...(isInsightsUiEnabled
        ? [
            {
              to: '/insights',
              label: LL.navigation.insights(),
            },
          ]
        : []),
      { to: '/briefs', label: LL.navigation.briefs() },
      ...(isAssetsUiEnabled
        ? [
            {
              to: '/assets',
              label: LL.navigation.assets(),
            },
          ]
        : []),
    ];
  }, [
    LL.navigation,
    isAssetsUiEnabled,
    isInsightsUiEnabled,
    isInspireUiEnabled,
  ]);
  return links;
}

type NavigationContextState = {
  isMobileMenuOpen: boolean;
};

export const NavigationContext = createContext<boolean>(false);

export function useNavigationContext(): NavigationContextState {
  const context = useContext(NavigationContext);
  const state = useMemo(
    (): NavigationContextState => ({
      isMobileMenuOpen: context,
    }),
    [context]
  );
  return state;
}
