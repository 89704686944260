import { createContext, PropsWithChildren, useEffect, useState } from 'react';
import useNewAnalyticsEvent from 'src/utils/useNewAnalyticsEvent';
import type { EventTarget } from '@magicbrief/server/src/services/analytics/analytics';

type BillingFrequency = 'monthly' | 'yearly';

type UpgradeModalV2ContextState = {
  isOpen: boolean;
  close: () => void;
  prompt?: string;
  billingFrequency: BillingFrequency;
  setBillingFrequency: React.Dispatch<React.SetStateAction<BillingFrequency>>;
};

const UpgradeModalV2Context = createContext<UpgradeModalV2ContextState>({
  isOpen: false,
  close: () => {},
  prompt: '',
  billingFrequency: 'monthly',
  setBillingFrequency: () => {},
});

interface UpgradeModalV2ContextProps extends PropsWithChildren {
  isOpen: boolean;
  close: () => void;
  prompt?: string;
  target?: EventTarget;
}

const UpgradeModalV2Provider = ({
  isOpen,
  close,
  prompt,
  target = 'Paywall',
  children,
}: UpgradeModalV2ContextProps) => {
  const { recordEvent } = useNewAnalyticsEvent();

  const [billingFrequency, setBillingFrequency] =
    useState<BillingFrequency>('monthly');

  useEffect(() => {
    if (isOpen) {
      void recordEvent({
        target: target,
        action: 'Opened',
      });
    }
  }, [isOpen, recordEvent, target]);

  return (
    <UpgradeModalV2Context.Provider
      value={{
        isOpen,
        close,
        prompt,
        billingFrequency,
        setBillingFrequency,
      }}
    >
      {children}
    </UpgradeModalV2Context.Provider>
  );
};

export { UpgradeModalV2Context, UpgradeModalV2Provider };
