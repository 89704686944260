import classNames from 'classnames';
import { Button } from 'react-aria-components';
import { Icon } from 'src/components/Icon';
import XClose from 'src/assets/svgicons/line/x-close.svg';

type Props = {
  onPress: () => void;
  className?: string;
};

const ModalCloseButton: React.FunctionComponent<Props> = ({
  onPress,
  className,
}) => {
  return (
    <Button
      onPress={onPress}
      className={classNames(
        'appearance-none cursor-pointer text-purple-800 outline-none',
        className
      )}
    >
      <Icon>
        <XClose />
      </Icon>
    </Button>
  );
};

export default ModalCloseButton;
