import { useCallback, useMemo, useState } from 'react';
import { Combobox } from '@headlessui/react';
import { BriefCollection } from '@magicbrief/prisma/generated/client2';
import { useQueryClient } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import { inferProcedureOutput } from '@trpc/server';
import { AppRouter } from '@magicbrief/server/src/trpc/router';
import { getQueryKey } from '@trpc/react-query';
import Tag from 'src/components/LibraryDetailsView/components/LibraryDetailsSidebar/components/Tag/Tag';
import { Button } from 'src/components/Button';
import { Icon } from 'src/components/Icon';
import { SimpleModal } from 'src/components/Modal';
import {
  ComboboxInput,
  ComboboxOption,
  ComboboxOptionLabel,
  ComboboxOptions,
  ComboboxTransition,
} from 'src/components/Select/ComboboxContent';
import { useI18nContext } from 'src/i18n/i18n-react';
import XClose from 'src/assets/svgicons/duocolor/x-close.svg';
import { trpc } from 'src/lib/trpc';

type Props = Omit<
  React.ComponentProps<typeof SimpleModal>,
  'title' | 'onClose'
> & {
  briefUuids: string[];
  onClose: () => void;
};

const AddBriefsToCollectionsModal: React.FunctionComponent<Props> = ({
  briefUuids,
  onClose,
  ...rest
}): JSX.Element => {
  const { LL } = useI18nContext();
  const queryClient = useQueryClient();
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCollections, setSelectedCollections] = useState<
    {
      uuid?: string;
      name: string;
    }[]
  >([]);
  const addBriefsToCollections = trpc.briefs.addBriefsToCollections.useMutation(
    {
      onSuccess() {
        void queryClient.invalidateQueries<
          inferProcedureOutput<AppRouter['briefs']['getBriefCollections']>
        >(
          getQueryKey(trpc.briefs.getBriefCollections, undefined, 'query'),
          undefined
        );
        void queryClient.invalidateQueries<
          inferProcedureOutput<AppRouter['briefs']['getBriefs']>
        >(getQueryKey(trpc.briefs.getBriefs, undefined, 'query'), undefined);
        onClose();
      },
      onError(error, variables) {
        Sentry.captureException(error, (scope) => {
          scope.setTransactionName(
            'Add briefs to collections from multi select'
          );
          scope.setExtra('requestData', variables);
          return scope;
        });
      },
    }
  );

  const allCollections = trpc.briefs.getBriefCollections.useQuery();

  const filteredCollections = useMemo(
    () =>
      allCollections.data?.filter((col) => {
        const contains = col.name
          .trim()
          .toLowerCase()
          .includes(searchQuery.trim().toLowerCase());

        return contains;
      }),
    [allCollections.data, searchQuery]
  );

  const confirm = () => {
    if (selectedCollections.length === 0) return;

    addBriefsToCollections.mutate({
      briefUuids,
      collectionNames: selectedCollections.map((col) => col.name),
    });
  };

  const closeCollectionModal = () => {
    onClose();
    setSearchQuery('');
    setSelectedCollections([]);
  };

  const onCollectionSelectHide = useCallback(() => {
    setSearchQuery('');
  }, []);

  const onSelect = (selected: string | BriefCollection | null) => {
    if (!selected) return;

    const collectionLabel =
      typeof selected === 'string' ? selected : selected.name;
    const collectionUuid =
      typeof selected === 'string' ? undefined : selected.uuid;

    const duplicate = selectedCollections.find(
      (col) => col.name === collectionLabel
    );
    if (duplicate) return;

    setSelectedCollections((prev) => [
      ...prev,
      {
        name: collectionLabel,
        uuid: collectionUuid,
      },
    ]);
  };

  return (
    <SimpleModal
      {...rest}
      title={LL.ad.addToCollection()}
      onClose={onClose}
      footer={
        <>
          <Button className="w-full" onClick={confirm}>
            {LL.briefs.multiSelect.addToCollections({
              count: briefUuids.length,
            })}
          </Button>
          <Button
            onClick={closeCollectionModal}
            variant="secondary"
            className="w-full"
          >
            {LL.cancel()}
          </Button>
        </>
      }
    >
      <Combobox<BriefCollection | string | null>
        value={null}
        multiple={false}
        onChange={onSelect}
      >
        <ComboboxTransition onHide={onCollectionSelectHide}>
          <div className="relative">
            <ComboboxInput
              displayValue={() => ''}
              placeholder={LL.ad.boardSearchInput()}
              handleQueryChange={(ev) => setSearchQuery(ev.target.value)}
            />
          </div>
          <div className="absolute w-full">
            {(filteredCollections?.length || searchQuery) && (
              <ComboboxOptions>
                {filteredCollections?.map((col) => {
                  return (
                    <ComboboxOption key={col.id} value={col}>
                      {({ selected }) => (
                        <ComboboxOptionLabel
                          selected={selected}
                          disabled={false}
                        >
                          {col.name}
                        </ComboboxOptionLabel>
                      )}
                    </ComboboxOption>
                  );
                })}
                {searchQuery && (
                  <ComboboxOption
                    key="--mb-new-collection-button--"
                    value={searchQuery}
                  >
                    <ComboboxOptionLabel selected={false} disabled={false}>
                      {LL.ad.createCollection({ name: searchQuery })}
                    </ComboboxOptionLabel>
                  </ComboboxOption>
                )}
              </ComboboxOptions>
            )}
          </div>
        </ComboboxTransition>
      </Combobox>
      {selectedCollections.length > 0 && (
        <div className="flex flex-row flex-wrap gap-3 pt-3">
          {selectedCollections.map((col, colIndex) => (
            <Tag key={col.name}>
              <div className="flex min-w-0 flex-row items-center gap-3">
                <div className="truncate">{col.name}</div>
                <button
                  onClick={() =>
                    setSelectedCollections((prev) =>
                      prev.filter((_, index) => index !== colIndex)
                    )
                  }
                  className="cursor-pointer appearance-none text-primary"
                >
                  <Icon className="h-4 w-4">
                    <XClose />
                  </Icon>
                </button>
              </div>
            </Tag>
          ))}
        </div>
      )}
    </SimpleModal>
  );
};

export default AddBriefsToCollectionsModal;
