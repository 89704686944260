import {
  FC,
  KeyboardEvent as ReactKeyboardEvent,
  Suspense,
  lazy,
  useState,
} from 'react';
import { PermissibleEntityType } from '@magicbrief/prisma/generated/client2';
import classNames from 'classnames';
import { PressEvent } from 'react-aria';
import { trpc } from 'src/lib/trpc';
import { AriaButton } from '../Button/Button';
import { DialogPopover } from '../DialogPopover/DialogPopover';
import Spinner from '../Loaders/Spinner';
import { CommentIcon } from './CommentIcon';

const CommentPopover = lazy(() => import('./CommentPopover'));

type Props = {
  parentEntityType: PermissibleEntityType;
  parentUuid: string;
  presenceEntityType?: PermissibleEntityType;
  presenceuuid?: string;
  showFetchError?: boolean;
  onCommentClick?: (shiftKey: boolean) => void;
  className?: string;
  hideOnDesktop?: boolean;
  beforeCreate?: () => Promise<
    | {
        parentUuid: string;
        parentEntityType: PermissibleEntityType;
      }
    | undefined
  >;
  shouldFetch?: boolean;
  readonly: boolean;
};

export const CommentButton: FC<Props> = ({
  onCommentClick,
  parentEntityType,
  parentUuid,
  presenceEntityType = 'Organisation',
  showFetchError,
  beforeCreate,
  className,
  shouldFetch = true,
  readonly,
}) => {
  const fetchEnabled = shouldFetch && parentEntityType != null && !!parentUuid;
  const [active, setActive] = useState(false);

  const getCommentsQuery = trpc.comments.getComments.useQuery(
    {
      parentUuid,
      parentEntityType,
    },
    {
      enabled: fetchEnabled,
    }
  );

  const commentCount = getCommentsQuery.data?.length ?? 0;

  return (
    <div
      className={classNames(
        !active &&
          !commentCount &&
          'hidden group-hover:block group-focus:block',
        (active || commentCount > 0) && 'visible'
      )}
    >
      <DialogPopover
        isKeyboardDismissDisabled
        shouldCloseOnInteractOutside={(elem) => {
          const mentionList = document.getElementById('mention-list');
          if (mentionList && mentionList.contains(elem)) {
            return false;
          }
          return true;
        }}
        className={classNames('z-90 w-96', className)}
        onOpenChange={(isOpen) => {
          if (isOpen) {
            setActive(true);
          } else {
            setActive(false);
          }
        }}
      >
        <AriaButton
          variant="text"
          onKeyUp={(e: ReactKeyboardEvent) => {
            if (e.key === 'Enter') {
              if (e.shiftKey) {
                onCommentClick?.(e.shiftKey);
              }
            }
          }}
          onPress={(e: PressEvent) => {
            if (e.shiftKey) {
              onCommentClick?.(e.shiftKey);
            }
          }}
          icon={<CommentIcon count={commentCount} showCommentCount={false} />}
        />
        {({ close }) => (
          <div
            className={classNames(
              'flex min-h-full w-96 flex-col',
              // stops flicker
              getCommentsQuery.data && commentCount > 0
                ? 'max-h-[510px]'
                : 'max-h-[80px]'
            )}
          >
            <Suspense
              fallback={
                <div className="flex w-full flex-auto items-center justify-center p-4">
                  <Spinner />
                </div>
              }
            >
              <CommentPopover
                parentEntityType={parentEntityType}
                parentUuid={parentUuid}
                presenceEntityType={presenceEntityType}
                showFetchError={showFetchError}
                beforeCreate={beforeCreate}
                close={close}
                readonly={readonly}
              />
            </Suspense>
          </div>
        )}
      </DialogPopover>
    </div>
  );
};
