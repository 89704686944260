import { FC, useState } from 'react';
import { getMetricFormat } from '@magicbrief/common';
import classNames from 'classnames';
import { Button } from 'react-aria-components';
import {
  SortableContext,
  arrayMove,
  horizontalListSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable';
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import { CSS } from '@dnd-kit/utilities';
import Plus from 'src/assets/svgicons/line/plus.svg';
import XClose from 'src/assets/svgicons/line/x-close.svg';
import { AriaMenu } from 'src/components/AriaMenu/AriaMenu';
import { AriaButton } from 'src/components/Button/Button';
import { Icon } from 'src/components/Icon';
import { COLOR_RANK_SCALE } from 'src/utils/rankColors';
import DotsGrid from 'src/assets/svgicons/solid/dots-grid.svg';
import { UpgradeModalV2 } from 'src/components/Modals/UpgradeModalV2/UpgradeModalV2';
import { useEntitlementQuotas } from 'src/utils/useEntitlementQuotas';
import useNewAnalyticsEvent from 'src/utils/useNewAnalyticsEvent';
import { useParseMetric } from '../../util/useParseMetric';
import { INSIGHTS_TABLE_SELECTION_MAX } from '../const';
import { InsightsMetricLabelTooltip } from '../InsightsMetricLabelTooltip/InsightsMetricLabelTooltip';
import {
  InsightsSort,
  useInsightsDisplay,
  useInsightsSort,
  useInsightsStoreDispatch,
} from '../../util/useInsightsPersistentState';
import { InsightsMetricCheckboxList } from './components/InsightsMetricCheckboxList';

type Props = {
  customEvents: string[];
  customConversions: Array<{ facebookId: string; name: string }>;
};

const InsightsFilterSortList: FC<Props> = ({
  customConversions,
  customEvents,
}) => {
  const { hasEntitlement } = useEntitlementQuotas('insights_ad_account');
  const display = useInsightsDisplay();
  const sort = useInsightsSort();
  const dispatch = useInsightsStoreDispatch();
  const { recordEvent } = useNewAnalyticsEvent();

  const [isOpen, setIsOpen] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  return (
    <>
      <div className="flex flex-col gap-2.5 bg-purple-200/20 p-2.5">
        <p className="text-xxs text-primary/40">Sort by</p>
        <div className="flex flex-row flex-wrap gap-2.5">
          <InsightsSortFilterDraggableList
            items={sort}
            customConversions={customConversions}
            customEvents={customEvents}
          />

          <AriaMenu className="w-auto" isOpen={isOpen} onOpenChange={setIsOpen}>
            <AriaButton
              data-intercom-target="insight_sort"
              variant="white"
              icon={
                <Icon className="opacity-100">
                  <Plus />
                </Icon>
              }
              onPress={() => setIsOpen(true)}
            >
              Add Metric
            </AriaButton>
            <InsightsMetricCheckboxList
              selected={sort.map((x) => x.id)}
              customConversions={customConversions}
              customEvents={customEvents}
              onToggle={(metric) => {
                const action = sort.some((x) => x.id === metric)
                  ? 'remove'
                  : 'add';

                if (hasEntitlement) {
                  dispatch({
                    type: action === 'remove' ? 'removeSort' : 'addSort',
                    value: { id: metric, desc: true },
                  });
                  const newDisplay = [...new Set([...display, metric])];
                  dispatch({ type: 'setDisplay', value: newDisplay });
                } else {
                  setIsOpen(false);
                  setShowUpgradeModal(true);
                }

                void recordEvent({
                  action: `Metric ${action}`,
                  target: 'Insights Sort',
                  metadata: {
                    metric,
                  },
                });
              }}
            />
          </AriaMenu>
        </div>
      </div>
      <UpgradeModalV2
        isOpen={showUpgradeModal}
        close={() => setShowUpgradeModal(false)}
        prompt="to unlock insights"
      />
    </>
  );
};

const InsightsSortFilterDraggableList: FC<{
  items: Array<InsightsSort>;
  customEvents: string[];
  customConversions: Array<{ facebookId: string; name: string }>;
}> = ({ items, customEvents, customConversions }) => {
  const { getMetricLabelAsText } = useParseMetric();
  const dispatch = useInsightsStoreDispatch();

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      const oldIndex = items.findIndex(
        ({ id }) => id === (active.id as string)
      );
      const newIndex = items.findIndex(({ id }) => id === (over.id as string));
      const newSortOrder = arrayMove(items, oldIndex, newIndex);
      dispatch({ type: 'setSort', value: newSortOrder });
    }
  }

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  let coloredIndex = 0;

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToHorizontalAxis]}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <SortableContext items={items} strategy={horizontalListSortingStrategy}>
        {items.map((sortKey, index) => {
          const label = getMetricLabelAsText(
            sortKey.id,
            customEvents,
            customConversions
          );
          const format = getMetricFormat(sortKey.id);

          if (label == null) {
            return null;
          }

          const rankedColor =
            coloredIndex < INSIGHTS_TABLE_SELECTION_MAX &&
            (format === 'currency' ||
              format === 'numeric' ||
              format === 'percentage')
              ? COLOR_RANK_SCALE[coloredIndex]
              : undefined;

          if (rankedColor) coloredIndex++;

          return (
            <InsightsSortFilterListItem
              id={sortKey.id}
              key={sortKey.id}
              desc={sortKey.desc}
              position={index + 1}
              color={rankedColor}
              customConversions={customConversions}
              customEvents={customEvents}
            />
          );
        })}
      </SortableContext>
    </DndContext>
  );
};

const InsightsSortFilterListItem: FC<{
  position: number;
  color: string | undefined;
  id: string;
  desc: boolean;
  customEvents: string[];
  customConversions: Array<{ facebookId: string; name: string }>;
}> = ({ position, color, id, desc, customConversions, customEvents }) => {
  const dispatch = useInsightsStoreDispatch();
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
  });
  const { recordEvent } = useNewAnalyticsEvent();

  const styles: React.CSSProperties = {
    zIndex: isDragging ? 1 : 0,
    transform: CSS.Translate.toString(transform),
    opacity: isDragging ? 0.8 : 1,
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={styles}
      className={classNames(
        'group flex h-10 w-max flex-row items-center gap-2 rounded-lg border border-solid border-purple-200 bg-white px-3 py-2.5 text-sm font-semibold shadow-sm outline-none',
        'text-purple-800'
      )}
    >
      <span
        style={{ background: color }}
        className={classNames(
          'flex h-5 w-5 cursor-grab items-center justify-center rounded text-xs font-semibold active:cursor-grabbing group-hover:hidden',
          !color
            ? 'border border-solid border-purple-500 text-purple-500'
            : 'text-white'
        )}
      >
        <span>{position}</span>
      </span>
      <span
        {...attributes}
        {...listeners}
        className={classNames(
          'hidden h-5 w-5 cursor-grab items-center justify-center rounded-full text-xs font-semibold text-purple-300 active:cursor-grabbing group-hover:flex'
        )}
      >
        <Icon className="h-5 w-5">
          <DotsGrid />
        </Icon>
      </span>
      <div className="pointer-events-none flex flex-col">
        <span>
          <InsightsMetricLabelTooltip
            metric={id}
            customConversions={customConversions}
            customEvents={customEvents}
          />
        </span>
      </div>
      <Button
        className="h-5 w-5 text-purple-900/50 outline-none"
        onPress={() => {
          dispatch({ type: 'removeSort', value: { id, desc } });

          void recordEvent({
            action: `Metric removed`,
            target: 'Insights Sort',
            metadata: {
              metric: id,
              desc,
            },
          });
        }}
      >
        <Icon className="h-5 w-5">
          <XClose />
        </Icon>
      </Button>
    </div>
  );
};

export default InsightsFilterSortList;
