import { useFirebaseContext } from '../pages/outlets/FirebaseOutlet/useFirebaseContext';
import { trpc } from '../lib/trpc';

/** gets the current authenticated user and their active organisation */
export function useUserAndOrganisation() {
  const firebaseUser = useFirebaseContext();

  const result = trpc.user.getUserAndOrganisation.useQuery(undefined, {
    enabled: firebaseUser != null && firebaseUser.emailVerified,
  });

  return result;
}
