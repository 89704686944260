import { useState, createContext } from 'react';
import {
  Button,
  Dialog,
  Heading,
  Modal,
  ModalOverlay,
} from 'react-aria-components';
import { toast } from 'react-toastify';
import Input from 'src/components/Input';
import { Icon } from 'src/components/Icon';
import { useI18nContext } from 'src/i18n/i18n-react';
import { AriaButton } from 'src/components/Button/Button';
import XClose from 'src/assets/svgicons/line/x-close.svg';
import { Alert } from 'src/components/Alert';
import AlertOctagon from 'src/assets/svgicons/duocolor/alert-octagon.svg';
import { useMoveNodesToDirectory } from 'src/utils/useDirectories';
import { trpc } from 'src/lib/trpc';
import Move from 'src/assets/svgicons/line/arrow-circle-right.svg';
import { DirectoryPicker } from '../DirectoryPicker/DirectoryPicker';
import { computeAllChildDirectoriesOfDirectory } from '../computeDirectoryPath';

type Props = {
  onOpenChange: (isOpen: boolean) => void;
  entity: {
    directoryNodeUuids: string[];
    entityName: string;
    originDirectoryNodeUuid: string | null;
  } | null;
};

export const MoveToDirectoryModal: React.FC<Props> = ({
  onOpenChange,
  entity,
  ...props
}): JSX.Element => {
  return (
    <ModalOverlay
      isOpen={!!entity}
      onOpenChange={onOpenChange}
      className="fixed inset-0 z-500 overflow-y-auto bg-gray-900/60 flex min-h-full items-center justify-center p-4 text-center"
    >
      <Modal className="flex w-full max-w-md rounded-xl bg-white p-6 text-left align-middle shadow-xl max-h-[60%]">
        <Dialog className="flex outline-none min-h-0 flex-auto min-w-0">
          {({ close }) => (
            <>
              {entity && (
                <MoveToDirectoryModalBody
                  close={close}
                  entity={entity}
                  {...props}
                />
              )}
            </>
          )}
        </Dialog>
      </Modal>
    </ModalOverlay>
  );
};

type MoveToDirectoryModalBodyProps = {
  entity: Exclude<Props['entity'], null>;
  close: () => void;
};

export const BoardFilterContext = createContext('');

const MoveToDirectoryModalBody: React.FC<MoveToDirectoryModalBodyProps> = ({
  close,
  entity,
}) => {
  const { LL } = useI18nContext();
  const [filterQuery, setFilterQuery] = useState('');
  const directoryStructure =
    trpc.directories.getDirectoryStructureForOrganisation.useQuery();

  const [selectedDirectoryUuid, setSelectedDirectoryUuid] = useState<
    { type: 'directory'; uuid: string } | { type: 'root' } | null
  >(null);
  const mutate = useMoveNodesToDirectory();

  const submit = () => {
    if (!selectedDirectoryUuid) {
      return;
    }

    mutate.mutate(
      {
        destinationDirectoryNodeUuid:
          selectedDirectoryUuid.type === 'directory'
            ? selectedDirectoryUuid.uuid
            : null,
        directoryNodeUuids: entity.directoryNodeUuids,
      },
      {
        async onSuccess(response) {
          if (response.rejected.length) {
            toast('An error occurred moving the board', {
              className: 'toast-danger',
            });
            return;
          }
          close();
          toast('Moved to board', {
            className: 'toast-success',
          });
        },
      }
    );
  };

  let isAllDirectories = true;
  for (const nodeUuid of entity.directoryNodeUuids) {
    if (!directoryStructure.data?.directories[nodeUuid]) {
      isAllDirectories = false;
      break;
    }
  }

  const disabledKeys = [...entity.directoryNodeUuids];

  if (directoryStructure.data) {
    for (const nodeUuid of entity.directoryNodeUuids) {
      disabledKeys.push(
        ...computeAllChildDirectoriesOfDirectory(
          nodeUuid,
          directoryStructure.data.directories
        )
      );
    }
  }

  if (entity.originDirectoryNodeUuid) {
    disabledKeys.push(entity.originDirectoryNodeUuid);
  }

  return (
    <div className="flex flex-auto flex-col gap-4 min-w-0">
      <div className="flex flex-row items-center justify-between text-purple-800 gap-4">
        <Heading className="text-xl font-bold truncate">
          {`Move ${entity.entityName}`}
        </Heading>
        <Button onPress={close}>
          <Icon>
            <XClose />
          </Icon>
        </Button>
      </div>

      {(mutate.error || !!mutate.data?.rejected.length) && (
        <Alert
          icon={
            <Icon>
              <AlertOctagon />
            </Icon>
          }
          className="w-full"
          type="error"
        >
          {mutate.error && (
            <p className="text-xs font-normal">
              {LL.errors.genericWithDetail({
                detail: mutate.error.message,
              })}
            </p>
          )}
          {mutate.data?.rejected && (
            <p className="text-xs font-normal">
              <p>
                The following errors occurred while trying to move the board:
              </p>
              <ul className="list-disc pl-2 pt-2">
                {mutate.data.rejected.map((x) => (
                  <li key={x.directoryNodeUuid}>{x.reason}</li>
                ))}
              </ul>
            </p>
          )}
        </Alert>
      )}

      <Input
        onChange={(e) => setFilterQuery(e.target.value)}
        name="filter-boards"
        label=""
        placeholder="Filter boards..."
      />

      <div className="flex-auto overflow-y-auto">
        <BoardFilterContext.Provider value={filterQuery}>
          <DirectoryPicker
            showRoot={isAllDirectories}
            disabledKeys={disabledKeys}
            onSelectionChange={(uuid) =>
              setSelectedDirectoryUuid(
                uuid === 'root'
                  ? { type: 'root' }
                  : uuid
                  ? { type: 'directory', uuid }
                  : null
              )
            }
          />
        </BoardFilterContext.Provider>
      </div>

      {selectedDirectoryUuid && directoryStructure.data && (
        <div className="p-2 bg-purple-100 rounded-lg flex-auto text-xs font-semibold text-purple-800 grid grid-cols-7 grid-rows-1 justify-between items-center">
          <span className="col-span-3 truncate text-center">
            {entity.entityName}
          </span>
          <span className="col-span-1 flex items-center justify-center animate-pulse">
            <Icon className="h-4 w-4">
              <Move />
            </Icon>
          </span>
          {selectedDirectoryUuid.type === 'directory' ? (
            <span className="col-span-3 truncate text-center">
              {directoryStructure.data.directories[selectedDirectoryUuid.uuid]
                .name ?? 'Unknown Board'}
            </span>
          ) : (
            <span className="col-span-3 truncate text-center italic">Root</span>
          )}
        </div>
      )}

      <AriaButton
        isDisabled={!selectedDirectoryUuid}
        onPress={submit}
        loading={mutate.isLoading}
      >
        Move
      </AriaButton>
    </div>
  );
};
