import {
  Checkbox,
  CheckboxProps,
  composeRenderProps,
} from 'react-aria-components';
import clsx from 'clsx';
import Check from 'src/assets/svgicons/line/check.svg';
import Minus from 'src/assets/svgicons/line/minus.svg';
import { cn } from 'src/lib/cn';
import { Icon } from '../Icon';

type Props = CheckboxProps & {
  selectedClassName?: string;
  indeterminateClassName?: string;
};

export const TableCheckbox: React.FC<Props> = ({
  className,
  selectedClassName,
  indeterminateClassName,
  ...rest
}) => {
  return (
    <Checkbox
      {...rest}
      slot="selection"
      className={composeRenderProps(className, (className) =>
        clsx(
          'group/checkbox flex cursor-pointer items-center justify-center disabled:opacity-50',
          className
        )
      )}
    >
      {({ isSelected, isIndeterminate }) => (
        <Icon
          className={cn(
            'h-5 w-5 rounded border border-primary/50 p-0.5 text-white opacity-50 transition-all duration-150 ease-in-out group-indeterminate/checkbox:opacity-100 group-hover:opacity-100 group-selected/checkbox:opacity-100 icon-path:stroke-[4]',
            isSelected && 'bg-primary',
            isSelected && selectedClassName,
            isIndeterminate && 'bg-primary',
            isIndeterminate && indeterminateClassName
          )}
        >
          <Minus className="hidden group-indeterminate/checkbox:block" />
          <Check className="hidden group-selected/checkbox:block" />
        </Icon>
      )}
    </Checkbox>
  );
};
