import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { useSquareLoaders } from '../SquareLoaders/hook';

const defaultBreakpoints = {
  2500: 7,
  2200: 6,
  1800: 5,
  1400: 4,
  1200: 3,
  950: 2,
  576: 1,
};

type Props = React.PropsWithChildren<{
  isLoading: boolean;
  isFetching: boolean;
  breakpoints?: Record<number, number>;
}>;

const LibraryGrid: React.FunctionComponent<Props> = ({
  isLoading,
  isFetching,
  children,
  breakpoints,
}): JSX.Element => {
  const getLoaders = useSquareLoaders({
    amount: 20,
    className: 'h-44',
  });

  return (
    <div className="relative z-0 flex flex-auto">
      <ResponsiveMasonry
        className="flex-auto"
        columnsCountBreakPoints={breakpoints ?? defaultBreakpoints}
      >
        <Masonry className="flex-auto" gutter="20px">
          {/* Ads Cards */}
          {!isLoading && children}

          {/* Ads Loader */}
          {(isLoading || isFetching) && getLoaders()}
        </Masonry>
      </ResponsiveMasonry>
    </div>
  );
};

export default LibraryGrid;
