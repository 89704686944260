import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useI18nContext } from 'src/i18n/i18n-react';
import AlertTriangle from 'src/assets/svgicons/duocolor/alert-triangle.svg';
import { Alert } from 'src/components/Alert';
import dayjs from 'src/lib/dayjs';
import { Icon } from 'src/components/Icon';
import LinkBroken from 'src/assets/svgicons/solid/link-broken-01.svg';
import { useInsightsFacebookAccount } from '../../util/useInsightsQueries';
import { useInsightsSearchParams } from '../../util/useInsightsSearchParams';
import { useInsightsJob } from '../../util/useInsightsJob';

const JobInfo = () => {
  const { LL } = useI18nContext();
  const job = useInsightsJob();

  if (job?.status !== 'failed') {
    return null;
  }

  return (
    <div className="flex flex-col gap-4">
      <Alert
        type="warning"
        icon={
          <Icon>
            <AlertTriangle />
          </Icon>
        }
      >
        {job.status === 'failed' && (
          <div>
            {LL.insights.list.refresh.banner.failed({
              time: dayjs(job.asOfTime).from(dayjs(), true),
            })}
          </div>
        )}
      </Alert>
    </div>
  );
};

type Props = React.PropsWithChildren<{
  toolbar: React.ReactNode;
  className?: string;
}>;

export const InsightsPage: React.FC<Props> = ({
  toolbar,
  children,
  className,
}) => {
  const { LL } = useI18nContext();
  const { accountUuid } = useParams();
  const { params } = useInsightsSearchParams();
  const location = useLocation();

  const shouldFetchAccount = location.pathname.startsWith(
    '/insights/reports/share'
  );

  const { facebookAdAccount } = useInsightsFacebookAccount({
    accountUuid,
    shouldFetch: shouldFetchAccount,
  });

  const view = params.get('view') ?? 'table';

  const isFacebookAccountDisabled =
    facebookAdAccount.data?.InsightsOrganisationAdAccountFacebook?.Integration
      ?.config?.status === 'disabled';

  return (
    <>
      <div className={classNames('flex w-full min-w-0 flex-col', className)}>
        {toolbar}

        <div
          className={classNames(
            'box-border flex h-full flex-auto flex-col gap-4 px-5 py-4 lg:px-9',
            view === 'table' && 'overflow-hidden',
            className
          )}
        >
          {shouldFetchAccount && isFacebookAccountDisabled && (
            <Alert
              type="error"
              icon={
                <Icon>
                  <LinkBroken />
                </Icon>
              }
            >
              <p>{LL.insights.account.reauthenticateText()}</p>
            </Alert>
          )}

          {shouldFetchAccount && <JobInfo />}

          {children}
        </div>
      </div>
    </>
  );
};
