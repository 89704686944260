import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import '../src/styles/reset.css';
import '../src/styles/index.css';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { IntercomProvider } from 'react-use-intercom';
import App from './App';
import '@magicbrief/prisma/src/jsonTypes';
import { MagicBriefErrorBoundary } from './lib/MagicBriefErrorBoundary';
import SEO from './components/SEO/SEO';
import { trpc, trpcReactClient } from './lib/trpc';
import queryClient from './lib/queryClient';
import TypesafeI18nContext from './contexts/TypesafeI18nContext';
import { appConfigs } from './configs';
import ToastWrapper from './components/Utils/ToastWrapper';
import { initSentry } from './lib/sentry';

import { initAnalytics } from './lib/segment';
import { analytics } from './lib/gtm';
import { getFeatureFlagValue } from './utils/getFeatureFlagValue';
import { setAttributionData } from './lib/attribution';

void setAttributionData();
void initAnalytics();
analytics();
initSentry();

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <HelmetProvider>
    <SEO
      title="MagicBrief"
      name="MagicBrief"
      description="Create storyboard briefs in minutes. Upload your assets or pull together references to make the process of building performance assets magical."
    />
    <StrictMode>
      <MagicBriefErrorBoundary>
        <trpc.Provider client={trpcReactClient} queryClient={queryClient}>
          <QueryClientProvider client={queryClient}>
            <TypesafeI18nContext>
              <IntercomProvider
                appId={getFeatureFlagValue('INTERCOM_APP_ID')}
                autoBoot
              >
                <ToastWrapper />
                <App />
                {appConfigs.env === 'development' && (
                  <ReactQueryDevtools initialIsOpen={false} />
                )}
              </IntercomProvider>
            </TypesafeI18nContext>
          </QueryClientProvider>
        </trpc.Provider>
      </MagicBriefErrorBoundary>
    </StrictMode>
  </HelmetProvider>
);
