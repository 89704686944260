import { getWeightForRole } from './get-weight-for-role';
import type { PermissionRole } from '@magicbrief/prisma/generated/client2';

/** @example
 * ```
 * const permission1 = { data: { role: 'editor' }};
 * const isAllowed = isRoleAllowed(permission.data.role, 'admin'); // false
 *
 * const permission2 = { data: { role: 'admin' }};
 * const isAllowed = isRoleAllowed(permission2.data.role, 'admin'); // true
 *
 * const permission3 = { data: { role: 'owner' }};
 * const isAllowed = isRoleAllowed(permission3.data.role, 'admin'); // true
 * ```
 * */

export function isRoleAllowed(
  userRole: PermissionRole,
  roleToCompareWith: PermissionRole,
  comparator: '>=' | '>' = '>='
) {
  return comparator === '>='
    ? getWeightForRole(userRole) >= getWeightForRole(roleToCompareWith)
    : getWeightForRole(userRole) > getWeightForRole(roleToCompareWith);
}
