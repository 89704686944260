export const INSIGHTS_TABLE_NULL_ROW_ID =
  '9e50b465-9006-4e8a-884d-292dc6924120';

export const POSITIVE_TABLE_COLORS = [
  { color: { r: 255, g: 255, b: 255 }, position: 0 },
  { color: { r: 255, g: 255, b: 255 }, position: 5 },
  { color: { r: 104, g: 220, b: 143 }, position: 100 },
];
export const NEUTRAL_TABLE_COLORS = [
  { color: { r: 255, g: 255, b: 255 }, position: 0 },
  { color: { r: 255, g: 255, b: 255 }, position: 100 },
];

export const NEGATIVE_TABLE_COLORS = [
  { color: { r: 255, g: 255, b: 255 }, position: 0 },
  { color: { r: 255, g: 255, b: 255 }, position: 5 },
  { color: { r: 238, g: 114, b: 115 }, position: 100 },
];
