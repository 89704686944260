import { FC } from 'react';
import { captureException } from '@sentry/react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Alert } from 'src/components/Alert';
import { SimpleModal } from 'src/components/Modal';
import { useI18nContext } from 'src/i18n/i18n-react';
import useNewAnalyticsEvent from 'src/utils/useNewAnalyticsEvent';
import { AriaButton } from '../../../components/Button/Button';
import { trpc } from '../../../lib/trpc';

type Props = {
  reportUuid: string;
  reportName: string;
  show: boolean;
  onClose: () => void;
  reportType: 'report' | 'comparison-report';
};

const DeleteReportModal: FC<Props> = ({
  reportUuid,
  reportName,
  show,
  reportType,
  onClose,
}) => {
  const { accountUuid } = useParams();
  const navigate = useNavigate();
  const trpcUtils = trpc.useUtils();
  const { LL } = useI18nContext();
  const { recordEvent } = useNewAnalyticsEvent();

  const deleteInsightsReport =
    trpc.insightsReports.deleteInsightsReport.useMutation({
      onSuccess: (data) => {
        onClose();
        navigate(`/insights/accounts/${data.insightsAdAccountFacebookUuid}`);
        void trpcUtils.insightsReports.getManyInsightsReports.invalidate();
        void trpcUtils.insightsReports.getManyInsightsReportsAndComparisonReports.invalidate();
        toast.success(LL.insights.reports.deleteModal.success(), {
          className: 'toast-success',
        });
        void recordEvent({
          action: 'Deleted',
          target: 'Insights Report',
          metadata: {
            reportName,
            uuid: reportUuid,
          },
        });
      },
      onError: (error) => {
        captureException(error);
      },
    });

  const deleteInsightsComparisonReport =
    trpc.insightsComparisonReports.delete.useMutation({
      onSuccess: () => {
        onClose();
        navigate(
          accountUuid ? `/insights/accounts/${accountUuid}` : `/insights`
        );
        void trpcUtils.insightsComparisonReports.getAll.invalidate();
        void trpcUtils.insightsReports.getManyInsightsReportsAndComparisonReports.invalidate();
        toast.success(LL.insights.reports.deleteModal.success(), {
          className: 'toast-success',
        });
        void recordEvent({
          action: 'Deleted',
          target: 'Insights Report',
          metadata: {
            reportName,
            uuid: reportUuid,
          },
        });
      },
      onError: (error) => {
        captureException(error);
      },
    });

  const isLoading =
    deleteInsightsReport.isLoading || deleteInsightsComparisonReport.isLoading;

  return (
    <SimpleModal
      title={LL.insights.reports.deleteModal.title({ name: reportName })}
      show={show}
      onClose={deleteInsightsReport.isLoading ? null : onClose}
    >
      <div className="flex flex-col gap-3">
        {deleteInsightsReport.error && (
          <Alert type="error">
            {LL.errors.genericWithDetail({
              detail: deleteInsightsReport.error.message,
            })}
          </Alert>
        )}

        <div className="flex flex-col gap-2">
          <p className="text-sm text-primary">
            {LL.insights.reports.deleteModal.detail()}
          </p>
        </div>
        <AriaButton
          variant="primary"
          colour="danger"
          loading={isLoading}
          className="w-full"
          onPress={() => {
            if (reportType === 'report') {
              deleteInsightsReport.mutate({ uuid: reportUuid });
            } else {
              deleteInsightsComparisonReport.mutate({ uuid: reportUuid });
            }
          }}
        >
          {LL.insights.reports.deleteModal.submitBtn()}
        </AriaButton>
      </div>
    </SimpleModal>
  );
};

export default DeleteReportModal;
